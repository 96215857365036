import React from 'react';
import './style.scss';

const TextArea = React.forwardRef((props, ref) => {
  const { className, ...restProps } = props;

  return (
    <textarea
      ref={ref}
      className={`form-text-area  ${className ?? ''}`}
      {...restProps}
    />
  );
});

export { TextArea };
