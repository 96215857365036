import {
  GET_AREAS_SUCCESS,
  GET_AREAS_PENDING,
  GET_AREAS_ERROR,
  AREA_PHOTO_REQUEST_SUCCESS,
  AREA_PHOTO_REQUEST_PENDING,
  AREA_PHOTO_REQUEST_ERROR,
  SELECTED_AREA_CHANGE,
  SELECTED_STORE_CHANGE,
  SELECTED_RECT_CHANGE,
  SET_AREA_RADIO_BUTTONS,
  INITIALIZE_STATE,
  RESET_STATE,
  DELETE_SELECTED_RECT_NAME,
  SELECTED_DEPARTMENT_CHANGE,
  UPDATE_AREA
} from '../ReduxActions/types';

var initialState = {
  allAreas: [],
  pending: false,
  success: false,
  error: false,
  areaPhoto: {
    pending: true,
    success: false,
    error: null
  },
  maxLayer: 0
};

export default function (state = null, action) {
  let nextState = {};
  switch (action.type) {
    case INITIALIZE_STATE:
      nextState = {
        ...state,
        ...initialState,
        selectedSketchs:
          JSON.parse(localStorage.getItem('SelectedSketchs')) || {},
        selectedRect: JSON.parse(localStorage.getItem('SelectedRect')) || {},
        selectedDepartment:
          JSON.parse(localStorage.getItem('selectedDepartment')) || null,
        areaRadios: JSON.parse(localStorage.getItem('areaRadios')) || {}
      };
      break;
    case RESET_STATE:
      nextState = null;
      break;
    case GET_AREAS_PENDING:
      nextState = {
        ...state,
        pending: true,
        success: false,
        allAreas: [],
        maxLayer: 0
      };
      break;
    case GET_AREAS_SUCCESS:
      nextState = {
        ...state,
        allAreas: action.payload,
        pending: false,
        success: true,
        maxLayer: action.maxLayer
      };
      break;
    case GET_AREAS_ERROR:
      nextState = {
        ...state,
        pending: false,
        success: false,
        error: action.payload
      };
      break;
    case SELECTED_STORE_CHANGE:
      nextState = {
        ...state,
        selectedSketchs: {},
        areaPhoto: { pending: false, error: null, success: false }
      };
      localStorage.removeItem('SelectedSketchs');
      break;
    case SELECTED_AREA_CHANGE:
      nextState = {
        ...state,
        selectedSketchs: {
          ...state.selectedSketchs,
          [action.uniqueName]: action.payload
        }
      };
      localStorage.setItem(
        'SelectedSketchs',
        JSON.stringify(nextState.selectedSketchs)
      );
      break;
    case UPDATE_AREA:
      nextState = {
        ...state,
        selectedSketchs: {
          ...state.selectedSketchs,
          [action.uniqueName]: {
            ...state.selectedSketchs[action.uniqueName],
            ...action.payload
          }
        }
      };
      localStorage.setItem(
        'SelectedSketchs',
        JSON.stringify(nextState.selectedSketchs)
      );
      break;
    case AREA_PHOTO_REQUEST_PENDING:
      nextState = {
        ...state,
        selectedSketchs: {
          ...state.selectedSketchs,
          [action.uniqueName]: {
            ...state.selectedSketchs[action.uniqueName],
            photo: null,
            success: false,
            pending: true,
            error: false
          }
        }
      };
      break;
    case AREA_PHOTO_REQUEST_SUCCESS:
      nextState = {
        ...state,
        selectedSketchs: {
          ...state.selectedSketchs,
          [action.uniqueName]: {
            ...state.selectedSketchs[action.uniqueName],
            photo: action.payload,
            success: true,
            pending: false,
            error: false
          }
        }
      };
      break;
    case AREA_PHOTO_REQUEST_ERROR:
      nextState = {
        ...state,
        selectedSketchs: {
          ...state.selectedSketchs,
          [action.uniqueName]: {
            ...state.selectedSketchs[action.uniqueName],
            photo: null,
            success: false,
            pending: false,
            error: action.payload
          }
        }
      };
      break;
    case SELECTED_RECT_CHANGE:
      nextState = {
        ...state,
        selectedRect: action.payload === '' ? {} : action.payload,
        selectedDepartment: null
      };
      localStorage.setItem('selectedDepartment', JSON.stringify(null));
      localStorage.setItem('SelectedRect', JSON.stringify(action.payload));
      break;
    case DELETE_SELECTED_RECT_NAME:
      nextState = {
        ...state,
        selectedRect: {},
        selectedDepartment: null
      };
      localStorage.setItem('SelectedRect', JSON.stringify({}));
      localStorage.setItem('selectedDepartment', JSON.stringify(null));
      break;
    case SELECTED_DEPARTMENT_CHANGE:
      nextState = {
        ...state,
        selectedDepartment: action.payload
      };
      localStorage.setItem(
        'selectedDepartment',
        JSON.stringify(action.payload)
      );
      break;
    case SET_AREA_RADIO_BUTTONS:
      nextState = {
        ...state,
        areaRadios: {
          ...state.areaRadios,
          [action.uniqueName]: action.payload
        }
      };
      localStorage.setItem('areaRadios', JSON.stringify(nextState.areaRadios));
      break;
    default:
      return state;
  }
  return nextState;
}
