import {
  GET_AREAS_SUCCESS,
  GET_AREAS_PENDING,
  GET_AREAS_ERROR,
  AREA_PHOTO_REQUEST_SUCCESS,
  AREA_PHOTO_REQUEST_PENDING,
  AREA_PHOTO_REQUEST_ERROR,
  SELECTED_AREA_CHANGE,
  SELECTED_RECT_CHANGE,
  SET_AREA_RADIO_BUTTONS,
  DELETE_SELECTED_RECT_NAME,
  SELECTED_DEPARTMENT_CHANGE,
  UPDATE_AREA
} from './types';
import { setParam } from './paramsActions';
import {
  GetAreaImage,
  GetAreaSketchs,
  _GetAreaImage,
  GetAreaSketchMeterSquare
} from '../Helper/api';
import { getPageNotification } from './pageActions';
import { Products } from '../Constants';

export const getAllAreaSketchs = storeID => (dispatch, getState) => {
  dispatch({
    type: GET_AREAS_PENDING
  });
  GetAreaSketchs(storeID)
    .then(res => {
      const maxLayer = res.reduce(
        (max, sketch) =>
          max < sketch.LayerCount ? (max = sketch.LayerCount) : max,
        0
      );
      const { params, store } = getState();
      if (params.SelectedSketchLayer > maxLayer) {
        dispatch(setParam('SelectedSketchLayer', 1));
      }
      dispatch({
        type: GET_AREAS_SUCCESS,
        payload: res,
        maxLayer
      });
      if (
        res.length === 0 &&
        store?.selectedStores?.globalStoreParam?.Products[
          Products.InstoreAnalyticsProduct
        ]
      ) {
        dispatch(
          getPageNotification({
            Id: Date.now(),
            Header: 'global.sketch',
            message: 'notification.sketchWarning',
            navigate: {
              url: '/Settings/Sketch',
              text: 'notification.skectAdd'
            }
          })
        );
      }
    })
    .catch(err => {
      dispatch({
        type: GET_AREAS_ERROR,
        payload: err
      });
    });
};

export const newLayerGetSketchs =
  (storeID, selectedSketch, uniqueName) => dispatch => {
    dispatch({
      type: GET_AREAS_PENDING
    });
    GetAreaSketchs(storeID)
      .then(res => {
        const maxLayer = res.reduce(
          (max, sketch) =>
            max < sketch.LayerCount ? (max = sketch.LayerCount) : max,
          0
        );
        dispatch({
          type: GET_AREAS_SUCCESS,
          payload: res,
          maxLayer
        });
        const area = res.find(sketch => sketch.Id === selectedSketch.Id);
        dispatch({
          type: UPDATE_AREA,
          payload: area,
          uniqueName: uniqueName
        });
      })
      .catch(err => {
        dispatch({
          type: GET_AREAS_ERROR,
          payload: err
        });
      });
  };

export const areaOnChange = (newArea, uniqueName, photoRequest) => dispatch => {
  dispatch({
    type: SELECTED_AREA_CHANGE,
    payload: newArea,
    uniqueName: uniqueName
  });
  if (photoRequest) {
    dispatch(getAreaImage(newArea.Id, uniqueName));
  }
  if (uniqueName === 'settingsPageSketch') {
    // GetAreaSketchMeterSquare.
  }
};

export const getAreaImage = (sketchID, uniqueName) => dispatch => {
  dispatch({
    type: AREA_PHOTO_REQUEST_PENDING,
    uniqueName: uniqueName
  });
  GetAreaImage(sketchID)
    .then(res => {
      dispatch({
        type: AREA_PHOTO_REQUEST_SUCCESS,
        uniqueName: uniqueName,
        payload: res
      });
    })
    .catch(err => {
      dispatch({
        type: AREA_PHOTO_REQUEST_ERROR,
        uniqueName: uniqueName,
        payload: err
      });
    });
};
export const _getAreaImage = (sketchID, uniqueName) => dispatch => {
  dispatch({
    type: AREA_PHOTO_REQUEST_PENDING,
    uniqueName: uniqueName
  });
  _GetAreaImage(sketchID)
    .then(res => {
      dispatch({
        type: AREA_PHOTO_REQUEST_SUCCESS,
        uniqueName: uniqueName,
        payload: res
      });
    })
    .catch(err => {
      dispatch({
        type: AREA_PHOTO_REQUEST_ERROR,
        uniqueName: uniqueName,
        payload: err
      });
    });
};
export const rectOnChange = rect => dispatch => {
  dispatch({
    type: SELECTED_RECT_CHANGE,
    payload: rect
  });
};
export const deleteSelectedRectName = () => dispatch => {
  dispatch({
    type: DELETE_SELECTED_RECT_NAME
  });
};
export const departmentOnChange = department => dispatch => {
  dispatch({
    type: SELECTED_DEPARTMENT_CHANGE,
    payload: department
  });
};

export const areaRadioButtons = (radioBut, uniqueName) => dispatch => {
  dispatch({
    type: SET_AREA_RADIO_BUTTONS,
    uniqueName: uniqueName,
    payload: radioBut
  });
};
