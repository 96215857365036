import React from 'react';
import './style.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from '../../../Hooks/useTranslation';

export function Field({ children, label, required, error }) {
  const { t } = useTranslation();

  return (
    <div className="form-field">
      {label ? (
        <label className="form-field-label">
          {label ? label : ''}
          {required && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="required">{t('global.requiredField')}</Tooltip>
              }
              trigger="hover"
            >
              <span className="form-field-required">*</span>
            </OverlayTrigger>
          )}
        </label>
      ) : (
        <></>
      )}
      <div className="form-field-container">
        {children}
        {error && <small className="form-field-error">{error.message}</small>}
      </div>
    </div>
  );
}
