import storeReducer from './storeReducer';
import uniqueReducer from './uniqueReducer';
import paramsReducer from './paramsReducer';
import userReducer from './userReducer';
import cameraReducer from './cameraReducer';
import areaReducer from './areaReducer';
import brandReducer from './brandReducer';
import pageReducer from './pageReducer';
import { combineReducers } from 'redux';
import setAuth, { AuthToken } from '../Helper/setAuth';
import boardReducer from './boardReducer';
import receiverReducer from './receiverReducer';
import personnelReducer from './personnelReducer';
import beaconReducer from './beaconReducer';
import reportReducer from './reportReducer';
const appReducer = combineReducers({
  store: storeReducer,
  uniques: uniqueReducer,
  params: paramsReducer,
  user: userReducer,
  camera: cameraReducer,
  area: areaReducer,
  brand: brandReducer,
  page: pageReducer,
  board: boardReducer,
  receiver: receiverReducer,
  personnel: personnelReducer,
  beacon: beaconReducer,
  report: reportReducer
});

const rootReducer = (state, action) => {
  if (
    action.type === 'USER_REQUEST_LOGOUT' ||
    action.type === 'USER_REQUEST_LOGIN_ERROR'
  ) {
    setAuth(new AuthToken());
    localStorage.clear();
    state = undefined;
    // var element = document.getElementsByClassName("zsiq_custommain")[0];
    // element.parentNode.removeChild(element);
    // var element2 = document.getElementById("zsiqscript");
    // element2.parentNode.removeChild(element2);
  }
  // if (action.type === 'USER_REQUEST_LOGOUT') {
  //     setAuth(false)
  //     localStorage.clear();
  //     state = undefined;
  // }
  else if (action.type === 'SELECTED_BRAND_CHANGE') {
    state.params.ComparisonV2 = {};
    state.params.comparisonStore = {};
    state.params.comparisonStoreId = {};
    state.params.comparisonStore2 = {};
    state.params.comparisonStore2Id = {};
  }

  return appReducer(state, action);
};

export default rootReducer;
