import {
  addMinutes,
  addSeconds,
  format,
  isAfter,
  parse,
  parseISO
} from 'date-fns';

export function humanizeNotificationDate(notificationDate) {
  const date = parseISO(notificationDate);

  return format(addMinutes(date, date.getTimezoneOffset()), 'dd/MM/yyyy HH:mm');
}

export function findIsTimedOut(notification) {
  if (!notification.TimeOut) return false;

  const formattedDate = humanizeNotificationDate(notification.CreateDate);

  const parsedDate = parse(formattedDate, 'dd/MM/yyyy HH:mm', new Date());

  // Check if "now" is later than timeout duration added to created date
  return isAfter(new Date(), addSeconds(parsedDate, notification.TimeOut));
}
