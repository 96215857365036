import {
  GET_PERSONNEL_PENDING,
  GET_PERSONNEL_SUCCESS,
  GET_PERSONNEL_ERROR,
  INITIALIZE_STATE,
  RESET_STATE
} from '../ReduxActions/types';

var initialState = {
  allPersonnels: [],
  pending: false,
  success: false,
  error: false
};

export default function (state = null, action) {
  let nextState = {};
  switch (action.type) {
    case INITIALIZE_STATE:
      nextState = {
        ...state,
        ...initialState
      };
      break;
    case RESET_STATE:
      nextState = null;
      break;
    case GET_PERSONNEL_PENDING:
      nextState = {
        ...state,
        pending: true,
        success: false,
        allPersonnels: []
      };
      break;
    case GET_PERSONNEL_SUCCESS:
      nextState = {
        ...state,
        allPersonnels: action.payload,
        pending: false,
        success: true
      };
      break;
    case GET_PERSONNEL_ERROR:
      nextState = {
        ...state,
        pending: false,
        success: false,
        error: action.payload
      };
      break;
    default:
      return state;
  }
  return nextState;
}
