import {
  GET_PERSONNEL_SUCCESS,
  GET_PERSONNEL_PENDING,
  GET_PERSONNEL_ERROR
} from './types';
import { getPersonnels } from '../Helper/api';

export const getAllPersonnels = storeID => dispatch => {
  dispatch({
    type: GET_PERSONNEL_PENDING
  });
  getPersonnels(storeID)
    .then(res =>
      dispatch({
        type: GET_PERSONNEL_SUCCESS,
        payload: res
      })
    )
    .catch(err => {
      dispatch({
        type: GET_PERSONNEL_ERROR,
        payload: err
      });
    });
};
