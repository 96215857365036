import {
  GET_CAMERA_PENDING,
  SELECTED_CAMERA_CHANGE,
  GET_CAMERA_SUCCESS,
  GET_CAMERA_ERROR,
  CAMERA_PHOTO_REQUEST_SUCCESS,
  CAMERA_PHOTO_REQUEST_PENDING,
  CAMERA_PHOTO_REQUEST_ERROR,
  GET_CAMERA_WITH_RECTS_PENDING,
  GET_CAMERA_WITH_RECTS_SUCCESS,
  GET_CAMERA_WITH_RECTS_ERROR,
  CAMERAS_PHOTO_REQUEST_SUCCESS,
  CAMERAS_PHOTO_REQUEST_PENDING,
  CAMERAS_PHOTO_REQUEST_ERROR,
  GET_CAMERA_RECTS_CAM_ID_PENDING,
  GET_CAMERA_RECTS_CAM_ID_SUCCESS,
  GET_CAMERA_RECTS_CAM_ID_ERROR,
  CLEAR_CAMERA_REDUCER
} from './types';

import {
  GetCameras,
  GetCameraPhoto,
  GetCamerasWithRects,
  GetCameraRects,
  GetSettingsCameraPhoto
} from '../Helper/api';

import { DepartmentRectangleType } from '../Constants';

export const resetCameraReducer = () => dispatch => {
  dispatch({
    type: CLEAR_CAMERA_REDUCER
  });
};

export const getAllCameras = storeID => dispatch => {
  dispatch({
    type: GET_CAMERA_PENDING
  });
  GetCameras(storeID)
    .then(res =>
      dispatch({
        type: GET_CAMERA_SUCCESS,
        payload: res
      })
    )
    .catch(err => {
      dispatch({
        type: GET_CAMERA_ERROR,
        payload: err
      });
    });
};

export const cameraOnChange =
  (newCamera, uniqueName, photoRequest) => dispatch => {
    dispatch({
      type: SELECTED_CAMERA_CHANGE,
      payload: newCamera,
      uniqueName: uniqueName
    });

    if (photoRequest) {
      dispatch(getCameraPhoto(newCamera.Id, uniqueName));
    }
  };

export const getCameraWithRects = storeID => dispatch => {
  dispatch({
    type: GET_CAMERA_WITH_RECTS_PENDING
  });
  GetCamerasWithRects(storeID)
    .then(res =>
      dispatch({
        type: GET_CAMERA_WITH_RECTS_SUCCESS,
        payload: res
      })
    )
    .catch(err => {
      dispatch({
        type: GET_CAMERA_WITH_RECTS_ERROR,
        payload: err
      });
    });
};

export const getCameraPhoto = (camID, uniqueName) => dispatch => {
  dispatch({
    type: CAMERA_PHOTO_REQUEST_PENDING,
    uniqueName: uniqueName
  });

  GetSettingsCameraPhoto(camID)
    .then(res => {
      dispatch({
        type: CAMERA_PHOTO_REQUEST_SUCCESS,
        payload: res,
        uniqueName: uniqueName
      });
    })
    .catch(err => {
      dispatch({
        type: CAMERA_PHOTO_REQUEST_ERROR,
        payload: err,
        uniqueName: uniqueName
      });
    });
};

export const getAllCameraPhoto = (camIds, uniqueName) => dispatch => {
  dispatch({
    type: CAMERAS_PHOTO_REQUEST_PENDING,
    uniqueName: uniqueName
  });
  camIds.map(Id => {
    GetCameraPhoto(Id)
      .then(res => {
        dispatch({
          type: CAMERAS_PHOTO_REQUEST_SUCCESS,
          payload: { [Id]: res },
          uniqueName: uniqueName
        });
      })
      .catch(err => {
        dispatch({
          type: CAMERAS_PHOTO_REQUEST_ERROR,
          payload: err,
          uniqueName: uniqueName
        });
      });
    return 0;
  });
};
export const getCameraRectsWithCamId = (camIds, uniqueName) => dispatch => {
  dispatch({
    type: GET_CAMERA_RECTS_CAM_ID_PENDING,
    uniqueName: uniqueName
  });

  camIds.map(Id => {
    GetCameraRects({ cameraID: Id })
      .then(res => {
        const rectangles = {
          ...res,
          Rectangles: res.Rectangles.filter(
            r =>
              r.RectType === DepartmentRectangleType.Shelf ||
              r.RectType === DepartmentRectangleType.Checkout ||
              r.RectType === DepartmentRectangleType.CheckoutWaiting ||
              r.RectType === DepartmentRectangleType.Correlation ||
              r.RectType === DepartmentRectangleType.CustomerServices ||
              r.RectType === DepartmentRectangleType.FirstStand
          ),
          Lines: [
            ...res.Lines,
            ...res.TwoCornerLines.map(line => ({ ...line, In: line.Direction }))
          ]
        };
        dispatch({
          type: GET_CAMERA_RECTS_CAM_ID_SUCCESS,
          payload: {
            [Id]: rectangles
          },
          uniqueName: uniqueName
        });
      })
      .catch(err => {
        dispatch({
          type: GET_CAMERA_RECTS_CAM_ID_ERROR,
          payload: err,
          uniqueName: uniqueName
        });
      });
    return 0;
  });
};
