import React, { Component } from 'react';
import './style.css';
import NotificationChild from './NotificationChild';
import { Transition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  seenNotifications,
  getNotifications,
  getNotificationInitCount
} from '../../ReduxActions/userActions';
import PermissionCheck from '../../HOC/PermissionCheck';
import Permissions from '../../Contexts/Permissions';
import { RadioButtons } from '../../Components/form-elements';
import { RespondedFilter, SeenFilter } from '../../Constants';

export const PAGE_SIZE = 20;

class Notification extends Component {
  constructor(state) {
    super(state);
    this.state = {
      Animation: false,
      lastPage: undefined,
      seenFilter: SeenFilter.ALL,
      respondedFilter: RespondedFilter.ALL
    };
  }
  componentDidMount() {
    if (
      this.props.fetchNotificationCountOnMount &&
      this.props.Notifications.lastFetchedNotificationCount <
        this.props.NotificationsCount
    ) {
      this.fetchNewNotifications(
        this.props.NotificationsCount -
          this.props.Notifications.lastFetchedNotificationCount
      );
    }

    if (this.props.Notifications.data.length === 0) {
      this.apiRequest({});
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.NotificationsCount < this.props.NotificationsCount &&
      prevProps.NotificationsCount !== 0
    ) {
      this.fetchNewNotifications(
        this.props.NotificationsCount - prevProps.NotificationsCount
      );
    }
  }

  fetchNewNotifications(difference) {
    // how many pages must be fetched to receive all new notifications
    const pageCountToFetch = Math.ceil(difference / PAGE_SIZE);

    // do not send more than 5 page size (diff could be hundreds if user stayed afk too much)
    if (pageCountToFetch > 5) {
      this.apiRequest({ resetNotifications: true });
      return;
    }

    for (let pageIndex = 1; pageIndex <= pageCountToFetch; pageIndex++) {
      this.apiRequest({ pageIndex });
    }
  }

  getNextPageParam(lastPage) {
    if (!lastPage) return 1;

    return Array.isArray(lastPage?.Data) &&
      lastPage.Data.length === lastPage.PageSize
      ? lastPage.PageIndex + 1
      : lastPage.PageIndex;
  }

  apiRequest({ lastItem, resetNotifications, pageIndex }) {
    if (this.props.Notifications.pending) return;

    // send request if resetNotifications is true or notification list is not finished yet
    if (!(resetNotifications || !this.props.Notifications.atLast)) return;

    const searches = [];

    if (this.state.seenFilter !== SeenFilter.ALL) {
      searches.push({
        Key: 'Seen',
        Value: this.state.seenFilter === SeenFilter.SEEN ? '1' : '0'
      });
    }

    if (this.state.respondedFilter !== RespondedFilter.ALL) {
      searches.push({
        Key: 'Responded',
        Value:
          this.state.respondedFilter === RespondedFilter.RESPONDED ? '1' : '0'
      });
    }

    let finalPageIndex = 1;

    if (typeof pageIndex === 'number') {
      finalPageIndex = pageIndex;
    } else if (lastItem && !resetNotifications) {
      finalPageIndex = lastItem.PageIndex + 1;
    }

    this.props.getNotifications(
      {
        SortBy: 'CreateDate',
        OrderBy: 'desc',
        PageIndex: finalPageIndex,
        PageSize: PAGE_SIZE,
        Searches: searches
      },
      resetNotifications,
      this.props.NotificationsCount
    );
  }

  cancel() {
    this.setState({
      Animation: true
    });
    setTimeout(() => {
      this.setState({
        Animation: false
      });
      this.props.cancel();
    }, 800);
  }

  handleSeenFilterChange(value) {
    if (value === this.state.seenFilter) return;

    this.setState({ seenFilter: value }, () => {
      this.apiRequest({ resetNotifications: true });
    });
  }

  handleRespondedFilterChange(value) {
    if (value === this.state.respondedFilter) return;

    this.setState({ respondedFilter: value }, () => {
      this.apiRequest({ resetNotifications: true });
    });
  }

  render() {
    return (
      <Transition in={true} timeout={1000} unmountOnExit>
        <div className="Notification_wrapper">
          {this.props.Modal && (
            <div className="Overlay" onClick={this.cancel.bind(this)} />
          )}
          <div
            className={` ${
              this.props.Modal
                ? this.state.Animation
                  ? 'Notification_container_Remove'
                  : 'Notification_container'
                : this.props.className
            }`}
          >
            {this.props.showFilters && (
              <div className="notification-list-filters">
                <div className="notification-list-filter">
                  <FormattedMessage id="notificationFilter.seenFilter" />
                  <RadioButtons
                    value={this.state.seenFilter}
                    onChange={this.handleSeenFilterChange.bind(this)}
                    isDisabled={this.props.Notifications.pending}
                    options={[
                      {
                        label: (
                          <FormattedMessage id="notificationFilter.seen" />
                        ),
                        value: SeenFilter.SEEN
                      },
                      {
                        label: (
                          <FormattedMessage id="notificationFilter.nonSeen" />
                        ),
                        value: SeenFilter.NON_SEEN
                      },
                      {
                        label: <FormattedMessage id="notificationFilter.all" />,
                        value: SeenFilter.ALL
                      }
                    ]}
                  />
                </div>

                <div className="notification-list-filter">
                  <FormattedMessage id="notificationFilter.respondedFilter" />
                  <RadioButtons
                    value={this.state.respondedFilter}
                    onChange={this.handleRespondedFilterChange.bind(this)}
                    isDisabled={this.props.Notifications.pending}
                    options={[
                      {
                        label: (
                          <FormattedMessage id="notificationFilter.responded" />
                        ),
                        value: RespondedFilter.RESPONDED
                      },
                      {
                        label: (
                          <FormattedMessage id="notificationFilter.nonResponded" />
                        ),
                        value: RespondedFilter.NON_RESPONDED
                      },
                      {
                        label: <FormattedMessage id="notificationFilter.all" />,
                        value: RespondedFilter.ALL
                      }
                    ]}
                  />
                </div>
              </div>
            )}

            <NotificationChild
              maxHeight={this.props.maxHeight}
              data={this.props.Notifications.data}
              newReqest={this.apiRequest.bind(this)}
              Animation={this.props.Notifications.pending}
              atLastNotification={this.props.Notifications.atLast}
            />
            {this.props.Modal && (
              <div className="Notification_footer">
                <PermissionCheck requiredRole={Permissions.PAGE_NOTIFICATIONS}>
                  <Link
                    className="Notification_seeAll"
                    onClick={this.cancel.bind(this)}
                    to={`/Notifications${this.props.urlParamsSearchString}`}
                  >
                    <FormattedMessage id="info.SeeAll" />
                  </Link>
                </PermissionCheck>
              </div>
            )}
          </div>
        </div>
      </Transition>
    );
  }
}

const mapStateToProps = state => ({
  urlParamsSearchString: state.page.urlParamsSearchString,
  Notifications: state.user.Notifications,
  NotificationsCount: state.user.NotificationsCount
});

const mapDispatchToProps = dispatchEvent => ({
  getNotificationInitCount: () => dispatchEvent(getNotificationInitCount()),
  getNotifications: (...args) => dispatchEvent(getNotifications(...args)),
  seenNotifications: seenNotif => dispatchEvent(seenNotifications(seenNotif))
});

Notification.defaultProps = {
  showFilters: true
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
