import {
  GET_CAMERA_PENDING,
  SELECTED_CAMERA_CHANGE,
  GET_CAMERA_SUCCESS,
  GET_CAMERA_ERROR,
  CAMERA_PHOTO_REQUEST_SUCCESS,
  CAMERA_PHOTO_REQUEST_PENDING,
  CAMERA_PHOTO_REQUEST_ERROR,
  CAMERAS_PHOTO_REQUEST_SUCCESS,
  CAMERAS_PHOTO_REQUEST_PENDING,
  CAMERAS_PHOTO_REQUEST_ERROR,
  SELECTED_STORE_CHANGE,
  GET_CAMERA_WITH_RECTS_SUCCESS,
  GET_CAMERA_WITH_RECTS_ERROR,
  GET_CAMERA_WITH_RECTS_PENDING,
  GET_CAMERA_RECTS_CAM_ID_PENDING,
  GET_CAMERA_RECTS_CAM_ID_SUCCESS,
  GET_CAMERA_RECTS_CAM_ID_ERROR,
  INITIALIZE_STATE,
  RESET_STATE,
  CLEAR_CAMERA_REDUCER
} from '../ReduxActions/types';

var initialState = {
  allCameras: [],
  cameraWithRects: [],
  selectedCameras: {},
  allCamerasPhoto: {},
  pending: false,
  success: false,
  error: false,
  cameraRectsWithCamId: {},
  cameraWithRectsPending: false,
  cameraWithRectsSuccess: false,
  cameraWithRectsError: false
};

export default function (state = null, action) {
  let nextState = {};
  switch (action.type) {
    case INITIALIZE_STATE:
      nextState = {
        ...state,
        ...initialState
      };
      break;
    case RESET_STATE:
      nextState = null;
      break;
    case GET_CAMERA_PENDING:
      nextState = {
        ...state,
        pending: true,
        success: false,
        allCameras: []
      };
      break;
    case GET_CAMERA_SUCCESS:
      nextState = {
        ...state,
        allCameras: action.payload,
        pending: false,
        success: true
      };
      break;
    case GET_CAMERA_ERROR:
      nextState = {
        ...state,
        pending: false,
        success: false,
        error: action.payload
      };
      break;
    case CLEAR_CAMERA_REDUCER:
      nextState = {
        ...state,
        ...initialState
      };
      break;
    case GET_CAMERA_WITH_RECTS_PENDING:
      nextState = {
        ...state,
        cameraWithRectsPending: true,
        cameraWithRectsSuccess: false,
        cameraWithRects: []
      };
      break;
    case GET_CAMERA_WITH_RECTS_SUCCESS:
      nextState = {
        ...state,
        cameraWithRectsPending: false,
        cameraWithRectsSuccess: true,
        cameraWithRects: action.payload
      };

      break;
    case GET_CAMERA_WITH_RECTS_ERROR:
      nextState = {
        ...state,
        cameraWithRectsPending: false,
        cameraWithRectsSuccess: false,
        cameraWithRectsError: action.payload,
        cameraWithRects: []
      };
      break;
    case SELECTED_STORE_CHANGE:
      nextState = {
        ...state,
        selectedCameras: {}
      };
      // localStorage.removeItem('SelectedCameras')
      break;
    case SELECTED_CAMERA_CHANGE:
      nextState = {
        ...state,
        selectedCameras: {
          ...state.selectedCameras,
          [action.uniqueName]: action.payload
        }
      };
      // localStorage.setItem('SelectedCameras',JSON.stringify(nextState.selectedCameras))
      break;
    case CAMERA_PHOTO_REQUEST_PENDING:
      nextState = {
        ...state,
        selectedCameras: {
          ...state.selectedCameras,
          [action.uniqueName]: {
            ...state.selectedCameras[action.uniqueName],
            photo: null,
            success: false,
            pending: true,
            error: false
          }
        }
      };
      break;
    case CAMERA_PHOTO_REQUEST_SUCCESS:
      nextState = {
        ...state,
        selectedCameras: {
          ...state.selectedCameras,
          [action.uniqueName]: {
            ...state.selectedCameras[action.uniqueName],
            photo: action.payload,
            success: true,
            pending: false,
            error: false
          }
        }
      };
      break;
    case CAMERA_PHOTO_REQUEST_ERROR:
      nextState = {
        ...state,
        selectedCameras: {
          ...state.selectedCameras,
          [action.uniqueName]: {
            ...state.selectedCameras[action.uniqueName],
            photo: null,
            success: false,
            pending: false,
            error: action.payload
          }
        }
      };
      break;
    case CAMERAS_PHOTO_REQUEST_PENDING:
      nextState = {
        ...state,
        allCamerasPhoto: {
          ...state.allCamerasPhoto,
          [action.uniqueName]: {
            ...state.allCamerasPhoto[action.uniqueName],
            success: false,
            pending: true
          }
        }
      };
      break;
    case CAMERAS_PHOTO_REQUEST_SUCCESS:
      nextState = {
        ...state,
        allCamerasPhoto: {
          ...state.allCamerasPhoto,
          [action.uniqueName]: {
            ...state.allCamerasPhoto[action.uniqueName],
            photos: {
              ...state.allCamerasPhoto[action.uniqueName].photos,
              ...action.payload
            },
            success: true,
            pending: false,
            error: false
          }
        }
      };
      break;
    case CAMERAS_PHOTO_REQUEST_ERROR:
      nextState = {
        ...state,
        allCamerasPhoto: {
          ...state.allCamerasPhoto,
          [action.uniqueName]: {
            ...state.allCamerasPhoto[action.uniqueName],
            success: false,
            pending: false,
            error: action.payload
          }
        }
      };
      break;
    case GET_CAMERA_RECTS_CAM_ID_PENDING:
      nextState = {
        ...state,
        cameraRectsWithCamId: {
          ...state.cameraRectsWithCamId,
          [action.uniqueName]: {
            ...state.cameraRectsWithCamId[action.uniqueName],
            success: false,
            pending: true,
            error: false
          }
        }
      };
      break;
    case GET_CAMERA_RECTS_CAM_ID_SUCCESS:
      nextState = {
        ...state,
        cameraRectsWithCamId: {
          ...state.cameraRectsWithCamId,
          [action.uniqueName]: {
            ...state.cameraRectsWithCamId[action.uniqueName],
            rects: {
              ...state.cameraRectsWithCamId[action.uniqueName].rects,
              ...action.payload
            },
            success: true,
            pending: false,
            error: false
          }
        }
      };

      break;
    case GET_CAMERA_RECTS_CAM_ID_ERROR:
      nextState = {
        ...state,
        cameraRectsWithCamId: {
          ...state.cameraRectsWithCamId,
          [action.uniqueName]: {
            ...state.cameraRectsWithCamId[action.uniqueName],
            rects: undefined,
            success: false,
            pending: false,
            error: action.payload
          }
        }
      };
      break;
    default:
      return state;
  }
  return nextState;
}
