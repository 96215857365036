import { GET_BOARD_SUCCESS, GET_BOARD_PENDING, GET_BOARD_ERROR } from './types';
import { GetBoards } from '../Helper/api';

export const getAllBoards = storeID => dispatch => {
  dispatch({
    type: GET_BOARD_PENDING
  });
  GetBoards(storeID)
    .then(res =>
      dispatch({
        type: GET_BOARD_SUCCESS,
        payload: res
      })
    )
    .catch(err => {
      dispatch({
        type: GET_BOARD_ERROR,
        payload: err
      });
    });
};
