import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './ReduxReducers';

let middleware = applyMiddleware(thunk);
if (process.env.NODE_ENV !== 'production') {
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const initialState = {};
const store = createStore(rootReducer, initialState, middleware);
export default store;
export const { dispatch } = store;
