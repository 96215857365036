import {
  SET_LISTEN_PARAMS,
  UNIQUE_REQUEST_SUCCESS,
  UNIQUE_REQUEST_ERROR,
  UNIQUE_REQUEST_PENDING,
  SET_SHOULD_UPDATE,
  REMOVE_ITEM_UNIQUE,
  SET_DATA_UNIQUE_REQUEST,
  RESET_STATE
} from '../ReduxActions/types';

const initialState = {};

export default function (state = initialState, action) {
  let nextState = { ...state };
  const { type, uniqueID, data, error, paramsKeys, payload } = action;

  switch (type) {
    case SET_LISTEN_PARAMS:
      nextState[uniqueID] = {
        data: data,
        pending: false,
        paramsKeys: paramsKeys
      };
      break;
    case RESET_STATE:
      nextState = null;
      break;
    case UNIQUE_REQUEST_PENDING:
      nextState[uniqueID] = {
        paramsKeys: state[uniqueID].paramsKeys,
        pending: true,
        success: false,
        error: false,
        data: data
      };
      break;
    case UNIQUE_REQUEST_SUCCESS:
      nextState[uniqueID] = {
        paramsKeys: state[uniqueID].paramsKeys,
        data: data,
        pending: false,
        success: true,
        error: null,
        shouldUpdate: false
      };
      break;
    case UNIQUE_REQUEST_ERROR:
      nextState[uniqueID] = {
        paramsKeys: state[uniqueID] ? state[uniqueID].paramsKeys : null,
        error: state[uniqueID] ? error : null,
        pending: false,
        success: false,
        shouldUpdate: false,
        data: data
      };
      break;
    case SET_SHOULD_UPDATE:
      nextState[uniqueID] = {
        ...state[uniqueID],
        shouldUpdate: payload
      };
      break;
    case SET_DATA_UNIQUE_REQUEST:
      var newdata = JSON.parse(JSON.stringify(nextState[uniqueID].data));
      var index = newdata.findIndex(d => d[action.datakey] === action.search);
      newdata[index] = payload;
      nextState[uniqueID] = {
        ...state[uniqueID],
        data: newdata
      };
      break;
    case REMOVE_ITEM_UNIQUE:
      delete nextState[uniqueID];
      break;
    default:
      break;
  }
  return nextState;
}
