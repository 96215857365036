const Permissions = {
  PAGE_COMPANY: 'PANEL_PAGE_COMPANY',
  PAGE_COMPANY_IN_STORE: 'PANEL_PAGE_COMPANY_IN_STORE',
  PAGE_COMPANY_CATEGORY: 'PANEL_PAGE_COMPANY_CATEGORY',
  PAGE_COMPANY_GENDER_AGE: 'PANEL_PAGE_COMPANY_GENDER_AGE',
  PAGE_LOCATION: 'PANEL_PAGE_LOCATION',
  PAGE_REPORT: 'PANEL_PAGE_REPORT',
  PAGE_AREA: 'PANEL_PAGE_AREA',
  PAGE_PERFORMANCE_TABLE: 'PANEL_PAGE_PERFORMANCE_TABLE',
  PAGE_TRENDS: 'PANEL_PAGE_TRENDS',

  PAGE_SUGGESTION: 'PANEL_PAGE_SUGGESTION',
  PAGE_SUGGESTION_DETAIL: 'PANEL_PAGE_SUGGESTION_DETAIL',

  PAGE_CHECKOUT: 'PANEL_PAGE_CHECKOUT',
  PAGE_DEPARTMENT: 'PANEL_PAGE_DEPARTMENT',
  PAGE_SHELF: 'PANEL_PAGE_SHELF',

  PAGE_CAMERAS: 'PANEL_PAGE_CAMERAS',
  PAGE_CAMERAS_DETAIL: 'PANEL_PAGE_CAMERAS_DETAIL',
  PAGE_CAMERAS_LIVE_AREAS: 'PANEL_PAGE_CAMERAS_LIVE_AREAS',

  PAGE_LOCATION_COMPARISON: 'PANEL_PAGE_LOCATION_COMPARISON',
  PAGE_CATEGORY_COMPARISON: 'PANEL_PAGE_CATEGORY_COMPARISON',

  PAGE_RECEIVER: 'PANEL_PAGE_RECEIVER',
  PAGE_PERSONNEL: 'PANEL_PAGE_PERSONNEL',
  PAGE_PERSONNEL_PERSONNEL: 'PANEL_PAGE_PERSONNEL_PERSONNEL',

  PAGE_LIVE: 'PANEL_PAGE_LIVE',
  PAGE_LIVE_CUSTOMER_COUNT: 'PANEL_PAGE_LIVE_CUSTOMER_COUNT',
  PAGE_STORE_COUNT: 'PANEL_PAGE_STORE_COUNT',

  PAGE_CUSTOMER_GENDER_AGE: 'PANEL_PAGE_CUSTOMER_GENDER_AGE',
  PAGE_DEPARTMENT_AGE_GENDER: 'PANEL_PAGE_DEPARTMENT_AGE_GENDER',
  PAGE_GENDER_AGE_COMPANY: 'PANEL_PAGE_GENDER_AGE_COMPANY',
  PAGE_AGE_GENDER_COMPARISON: 'PANEL_PAGE_AGE_GENDER_COMPARISON',

  PAGE_CAMPAIGNS: 'PANEL_PAGE_CAMPAIGNS',
  PAGE_CAMPAIGN_ANALYSIS: 'PANEL_PAGE_CAMPAIGN_ANALYSIS',

  PAGE_USER: 'PANEL_PAGE_USER',
  PAGE_NOTIFICATIONS: 'PANEL_PAGE_NOTIFICATIONS',
  PAGE_SALES_CONFIGURATION: 'PANEL_PAGE_SALES_CONFIGURATION',
  PAGE_IFRAME_VIEW: 'PANEL_PAGE_IFRAME_VIEW',

  //Ayarlar

  PAGE_SETTINGS: 'PANEL_PAGE_SETTINGS',
  PAGE_SETTINGS_CAMERA: 'PANEL_PAGE_SETTINGS_CAMERA',
  PAGE_SETTINGS_SKETCH: 'PANEL_PAGE_SETTINGS_SKETCH',
  PAGE_SETTINGS_NOTIFICATIONS: 'PANEL_PAGE_SETTINGS_NOTIFICATIONS',
  PAGE_SETTINGS_DEPARTMENT: 'PANEL_PAGE_SETTINGS_DEPARTMENT',
  PAGE_SETTINGS_WORKING_HOURS: 'PANEL_PAGE_SETTINGS_WORKING_HOURS',
  PAGE_SETTINGS_SALES_UPLOADING: 'PANEL_PAGE_SETTINGS_SALES_UPLOADING',
  PAGE_SETTINGS_CAMERA_SNAPSHOT: 'PANEL_PAGE_SETTINGS_CAMERA_SNAPSHOT',
  PAGE_SETTINGS_FORECAST: 'PANEL_PAGE_SETTINGS_FORECAST',
  PAGE_SETTINGS_CAMPAIGN: 'PANEL_PAGE_SETTINGS_CAMPAIGN',
  PAGE_SETTINGS_IFRAME: 'PANEL_PAGE_SETTINGS_IFRAME',
  PAGE_SETTINGS_UPLOAD_REPORTS: 'PANEL_PAGE_SETTINGS_UPLOAD_REPORTS',
  PAGE_SETTINGS_REPORTS:'PANEL_PAGE_SETTINGS_REPORTS'
};
export default Permissions;
