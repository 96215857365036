import ReactExport from 'react-data-export';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { eachDayOfInterval } from 'date-fns/esm';
import {
  addHours,
  formatDistanceStrict,
  getHours,
  getMinutes,
  getSeconds,
  isAfter,
  parse
} from 'date-fns';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function dig(obj, ...propNames) {
  var last = propNames.pop();

  var current = obj;
  for (var i = 0, limit = propNames.length; i < limit; i++) {
    var prop = propNames[i];
    if (typeof current === 'undefined') {
      break;
    }

    if (!(prop in current)) {
      break;
    }

    if (!(current = current[prop])) {
      break;
    }

    if (typeof current !== 'object') {
      break;
    }
  }
  if (typeof last === 'object' && 'default' in last) {
    return current || last['default'];
  }
  if (current !== null && typeof current === 'object') {
    current = current[last];
  }

  return current;
}

export function queuedProcess(handlers, defaultHandler) {
  handlers = handlers || {};

  if (typeof defaultHandler !== 'function') {
    defaultHandler = function () {
      console.log(arguments);
    };
  }

  var resultList = {};

  function process(fun, index, callback) {
    var result = fun();
    resultList[index] = result;

    if (index in handlers) {
      var handlerFunc = handlers[index] || defaultHandler;
      if (typeof handlerFunc === 'function') {
        handlers(result, callback);
      }
    }

    if (typeof callback === 'function') {
      callback(fun, index);
    }
  }

  return function processer(funcList, startIndex = 0) {
    if (!funcList || !funcList.length) return resultList;
    var func = funcList.shift();
    if (typeof func !== 'function') return resultList;

    process(func, startIndex, function (prevFun, index) {
      processer(funcList, index + 1);
    });

    return resultList;
  };
}

/*
// Saniyorum ki bitti, simdi bunun olayi su, burada ilk once cevaplari isleyecek elemani veriyorsun
// Ardindan sira ile kendini cagiracak makine olusturuyor sana bu, yani fonksiyon veriyor tekrar, dolayisiyla ()() gibi bir cagri mumkun :)
// mesela

var resultList = queuedProcess()([
    function (a, b) { return a + b },
    function (b, c) { return -b - c }
])

// Simdi burada soyle bir olay var, birinci ve ikinci fonksiyonlar ornek burada tabi, bunlar biri birince digeri calisacak sekilde yapildi
// Parametrelere buradan ulasmak tabi mumkun olmuyor, hemen onu da ayarlayabiliriz tabi, yani parametre geceirmen gerekecekse
// ona gore hamle almak gekeir, burada ona gore yapmadim tabi, her ne kadar uzun surmeyecekse de bu, simdi boyle yapmakla sira ile birbirini
// cagiran fnoksiyonlar yaptin, her biri, isini bitirince bu donus aldigin resultList {} nesnesine [ index ] = fonksiyon cevabi olarak atama yapiyor
// dolayisiyla butun fonksiyonlar isini bitirince artik bunu kullanabilirsin.

// Bunu boyle uzun uzun yazdim, aslinda 10 satiri gecmezdi, ama genel olarak cok islevli bir sey yaptim ki incele isine yarayacak seyler kaparsan yararina.
// anladım kanka eyvallah da bunu api fonksiyonunda kullanabilirmiyim 
// parametre lazim olur muhtemelen o vakit, bak ustekini kullanmazsin belki ama orayi ogrenmeye calis, simdi ben ne yazacagim bak

export function oneByOne(functionArgsPair, callback, onDone, resultList, startIndex) {
    startIndex = startIndex || 0
    resultList = resultList || {}

    if (!Array.isArray(functionArgsPair) || !functionArgsPair.length) {
        // Yanlis veya bos liste verildi, atla

        if (startIndex) {
            if (typeof onDone === 'function') onDone(resultList)
        }
        return
    } 

    var funcArgPair = functionArgsPair.shift()
    var func = funcArgPair[0]
    var args = funcArgPair[1] || []
    var instanceTarget = funcArgPair[2] || func
    var result = func.apply(instanceTarget, args)
    resultList[ startIndex ] = result
    if (typeof callback === 'function') {
        callback(startIndex, result)
    }
    oneByOne(funcArgPair, callback, onDone, resultList, startIndex + 1)
}

function sum(a, b) { return a + b }

oneByOne([
    [ (a,b) => sum(a,b) , [3, 4] ],
    [ function (a, b) { return a - b }, [ 5, 7 ], this ]
], function (index, result) {
    // index sirasindaki function result cevabini verdi
}, function (result) {
    // tamami bitti, cevaplar result icerisinde mevcut
})*/

// simdi burada soyle bir durum var, kesin olarak oneByOne([]) yaziyorsun, bunun icine de [ FUNC, PARAMETRELER ] yaziyorsun bu sekilde gidiyorsun,
// eger HERHANGI BIRI SONUC VERDIGINDE DINLEMEK ISTERSEN, veya tamamini dinlemek istersen bunu yapabiliyorsun
// Anladin mi? Bu daha kolay
// az çok mantığını kavradım knka
// olay, bir tane calistirici func var, burada onun adi onebyone, fonksiyonu cagirdiginda, kendisini bir daha cagiriyor bak,
// zaten ilk is, siradaki fonksiyon arguman esini secmek ve index'i 1 arttiriyoruz zaten her iste, bu kadar.
// api de kullanalaımmı bunu
// Bu HER YERDE CALISIR, ancak JS itibariyle dikkat etmeniz gereken bir sey var, orada hata alacaginiz icin simdiden soyleyeyim,
// "this" olan yerlerde hep hata alirsiniz, cunku bunlar yerlerinden sokulup bu dosya uzerinde calisiyor, this leri esitlemek icin soyle yapin, argumanlardan
// sonra, this yazin sadece yeter, BAK SIMDI, gibi, boylelikle, icerisinde bulundugu ornegi de bu dosyaya gondermis oluyorsun.
// Bu reactaki olayin aynisi, orada this.ABC.bind(this) yapiyorsun ya aynisi, dolayisiya soyle de yapabilirsin bak

// Yani burada da bind lazim olacak, react, bu yuzden ihtiyac duyuyor, bunu da ogrenmis oldun, veya yine ayni reacttaki gibi,
// yaptiginda, baska bir func yapmis oluyorsun, o this'i otomatik yakaladigi icin sum dogru calisiyor, bu da mumkun, istedigin gibi
// burada () => veya .bind yaparsan, this'i belirtemene gerek yok. Bitti,
// kardo bir dakka biri geldi görüşmem lazım

class ExcelInfoExport extends Component {
  render() {
    var brand =
      this.props.brand ||
      dig(this.props.selectedBrand, 'globalBrandParam', 'Name');
    var storeName =
      !this.props.showAllBrand &&
      (this.props.storeName ||
        dig(this.props.selectedStores.globalStoreParam, 'Name'));
    var sdate = this.props.sdate || this.props.params.sdate;
    var edate = this.props.edate || this.props.params.edate;
    var chartName = this.props.chartName ? ' - ' + this.props.chartName : '';
    var excelFileName = '';
    excelFileName += brand;
    excelFileName +=
      storeName === 'false' || !storeName ? '' : ' - ' + storeName;
    excelFileName += this.props.depName ? ' - ' + this.props.depName : '';
    excelFileName += ' - ' + sdate + ' - ' + edate;
    if (this.props.metric) excelFileName += ' - ' + this.props.metric;
    if (this.props.fileName) {
      excelFileName = this.props.fileName;
    }
    var dataLength = this.props.data.length;
    var data = this.props.data ? Array.from(this.props.data) : null;
    var data2 = this.props.data2 ? Array.from(this.props.data2) : null;
    const BrandExcel = this.props.BrandExcel; // True Dönerse Şirket bazlı Excel Çıktısı
    var multiDataSet;
    if (this.props.columns1) {
      this.props.columns1.map(c => (c.header == null ? (c.header = '') : c));
    }
    //Comparison sayfası gibi 2 ayrı data gelirse bu if ile birleştirme işlemi yapıyor.
    if (data2 && this.props.storeinfo) {
      //İsme göre sıralama işlemi
      data.map((val, i) => {
        return data2.map((val2, j) => {
          if (val.Name.toUpperCase() === val2.Name.toUpperCase()) {
            data.splice(i, 1);
            data2.splice(j, 1);
            data.unshift(val);
            data2.unshift(val2);
          }
          return 0;
        });
      });
      multiDataSet = [
        {
          // xSteps: 5, // Will start putting cell with 1 empty cell on left  most
          ySteps: 1, //will put space of 5 rows,
          columns: this.props.columns.map(c => ({ title: c.header })),
          data: data.map(d =>
            this.props.columns.map(c => ({ value: d[c.dataKey] }))
          )
        },
        {
          ySteps: -dataLength - 4,
          xSteps: 5,
          columns: [
            {
              title: BrandExcel
                ? this.props.intl.formatMessage({ id: 'routes.Company' })
                : this.props.intl.formatMessage({ id: 'routes.store' })
            },
            {
              title: this.props.intl.formatMessage({ id: 'chart.legend.date' })
            }
          ],
          data: [
            [
              { value: this.props.storeinfo2.data[0][0] },
              { value: this.props.storeinfo2.data[0][1] }
            ]
          ]
        },
        {
          ySteps: 1,
          xSteps: 5,
          columns: this.props.columns.map(c => ({ title: c.header })),
          data: data2.map(d =>
            this.props.columns.map(c => ({ value: d[c.dataKey] }))
          )
        }
      ];
    } else {
      //Tek Data geldiği zaman burası çalışır.
      if (this.props.columns1) {
        var multiDataSet = [
          {
            // xSteps: 5, // Will start putting cell with 1 empty cell on left  most
            ySteps: 1, //will put space of 5 rows,
            columns: this.props.columns1.map(c => ({ title: c.header })),
            data: []
          },
          {
            // xSteps: 5, // Will start putting cell with 1 empty cell on left  most
            ySteps: 0, //will put space of 5 rows,
            columns: this.props.columns.map(c => ({ title: c.header })),
            data: data.map(d =>
              this.props.columns.map(c => ({ value: d[c.dataKey] }))
            )
          }
        ];
      } else {
        var multiDataSet = [
          {
            // xSteps: 5, // Will start putting cell with 1 empty cell on left  most
            ySteps: 1, //will put space of 5 rows,
            columns: this.props.columns.map(c => ({ title: c.header })),
            data: data.map(d =>
              this.props.columns.map(c => ({ value: d[c.dataKey] }))
            )
          }
        ];
      }
    }
    if (this.props.customTopInfo) {
      multiDataSet.unshift(this.props.customTopInfo);
    } else if (this.props.storeinfo) {
      multiDataSet.unshift({
        columns: [
          {
            title: BrandExcel
              ? this.props.intl.formatMessage({ id: 'routes.Company' })
              : this.props.intl.formatMessage({ id: 'routes.store' })
          },
          { title: this.props.intl.formatMessage({ id: 'chart.legend.date' }) }
        ],

        data: [
          [
            { value: this.props.storeinfo.data[0][0] },
            { value: this.props.storeinfo.data[0][1] }
          ]
        ]
      });
    } else {
      multiDataSet.unshift({
        columns: [
          {
            title: this.props.showAllBrand
              ? this.props.intl.formatMessage({ id: 'routes.Company' })
              : this.props.intl.formatMessage({ id: 'routes.store' })
          },
          { title: this.props.intl.formatMessage({ id: 'chart.legend.date' }) },
          { title: this.props.intl.formatMessage({ id: 'info.excel.hour' }) },
          { title: this.props.intl.formatMessage({ id: 'info.department' }) }
        ],

        data: [
          [
            { value: this.props.showAllBrand ? brand : storeName },
            {
              value: this.props.params.sdate + ' - ' + this.props.params.edate
            },
            {
              value: this.props.params.stime + ' - ' + this.props.params.etime
            },
            { value: this.props.depName }
          ]
        ]
      });
      if (!this.props.depName) {
        multiDataSet[0].columns.pop();
      }
      if (storeName === 'false') {
        multiDataSet[0].columns.shift();
        multiDataSet[0].data[0].shift();
      }
    }
    return (
      <ExcelFile
        element={
          this.props.DownloadElement ? (
            <span
              title={(excelFileName + chartName || 'UdentifyData') + '.xlsx'}
            >
              {' '}
              {this.props.DownloadElement}
            </span>
          ) : (
            <i
              title={(excelFileName + chartName || 'UdentifyData') + '.xlsx'}
              className="icon download link"
            ></i>
          )
        }
        filename={excelFileName + chartName || 'UdentifyData'}
      >
        <ExcelSheet dataSet={multiDataSet} name="Data">
          {this.props.columns.map(c => (
            <ExcelColumn
              key={c.dataKey}
              label={c.header}
              value={c.dataKey}
            ></ExcelColumn>
          ))}
        </ExcelSheet>
      </ExcelFile>
      // <ExcelColumn label={this.props.intl.formatMessage({ id: 'info.store.info' })} value={"StoreInfo"} />
    );
  }
}

const excelExportMapStateToProps = state => ({
  params: state.params,
  selectedStores: state.store.selectedStores,
  selectedBrand: state.store.selectedBrand,
  showAllBrand: state.params.showAllBrand
});
export const ExcelExport = injectIntl(
  connect(excelExportMapStateToProps)(ExcelInfoExport)
);

// excel export custom Container

export const CustomExcelExportIconContainer = ({ text }) => {
  return (
    <div className="excelexportContainer">
      <i className="icon download"></i>
      <p>{text}</p>
    </div>
  );
};

export function numberFormatter(number) {
  return new Intl.NumberFormat('de-DE').format(number);
}

export const SecondFormatter = (second, addition) => {
  return second.toFixed(2) + addition;
};

// export function scrollToDuration2(element, duration) {

//     var scrollTo = (element, duration) => {
//         var e = document.documentElement;
//         if (e.scrollTop === 0) {
//             var t = e.scrollTop;
//             ++e.scrollTop;
//             e = t + 1 === e.scrollTop-- ? e : document.body;
//         }
//         scrollToC(e, e.scrollTop, element, duration);
//     }
//     var scrollToC = (element, from, to, duration) => {
//         if (duration <= 0) return;
//         if (typeof from === "object") from = from.offsetTop;
//         if (typeof to === "object") to = to.offsetTop;

//         scrollToX(element, from, to, 0, 1 / duration, 20, easeOutCuaic);
//     }

//     var scrollToX = (element, xFrom, xTo, t01, speed, step, motion) => {
//         if (t01 < 0 || t01 > 1 || speed <= 0) {
//             element.scrollTop = xTo;
//             return;
//         }
//         element.scrollTop = xFrom - (xFrom - xTo) * motion(t01);
//         t01 += speed * step;

//         setTimeout(() => {
//             scrollToX(element, xFrom, xTo, t01, speed, step, motion);
//         }, step);
//     }
//     var easeOutCuaic = (t) => {
//         t--;
//         return t * t * t + 1;
//     }
//     return scrollTo(element, duration)
// }

export function scrollToDuration(pos, time) {
  /*Time is exact amount of time the scrolling will take (in milliseconds)*/
  /*Pos is the y-position to scroll to (in pixels)*/
  /*Code written by hev1*/
  if (typeof pos !== 'number') {
    pos = parseFloat(pos);
  }
  if (isNaN(pos)) {
    throw 'Position must be a number';
  }
  if (pos < 0 || time < 0) {
    return;
  }
  var currentPos = window.scrollY || window.screenTop;
  var start = null;
  time = time || 500;
  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    if (currentPos < pos) {
      var progress = currentTime - start;
      window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    } else {
      var progress = currentTime - start;
      window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, pos);
      }
    }
  });
}

/*b64toBlob yüklediğimiz pdfteki resmin base64 kodunu img file a çevirir*/
export function b64toBlob(b64Data, contentType, sliceSize, img) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function base64ToFile(img) {
  var block = img.split(';');
  var contentType = block[0].split(':')[1];
  var realData = block[1].split(',')[1];
  var blob = b64toBlob(realData, contentType);
  var file = new File([blob], 's.jpeg', {
    type: contentType,
    lastModified: Date.now()
  });
  return file;
}

export const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const stringToDate = string => {
  if (!string) return string;
  const [date, month, year] = string.split('/').map(n => parseInt(n));
  return new Date(year, month - 1, date);
};

export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

//Text check includes input
export function checkTextInclude(text, input = '') {
  if (!text || typeof text !== 'string') return false;
  if (input.indexOf('"') === 0 && input.lastIndexOf('"')) {
    return text
      .toLowerCase()
      .startsWith(
        input.trim().slice(1, input.lastIndexOf('"')).trim().toLowerCase()
      );
  } else {
    let val1 = input.replace(/I/g, 'i').replace(/İ/g, 'i');
    let val2 = text.replace(/I/g, 'i').replace(/İ/g, 'i');
    return val2.toLocaleLowerCase('tr').includes(val1.toLocaleLowerCase('tr'));
  }
}

export function checkTextEqual(text, input = '') {
  if (!text || typeof text !== 'string') return false;
  return text.toLowerCase() === input.toLowerCase();
}

export function tableValuesCalculation(
  val,
  toFixedControl,
  changeToFixedControl
) {
  if (val == Infinity) {
    return 'n/a';
  } else if (val == NaN || isNaN(val) || val == null) {
    return '-';
  } else if (toFixedControl && val !== null) {
    return parseFloat(val.toFixed(2));
  } else if (changeToFixedControl && val !== null) {
    return parseFloat(val.toFixed(1));
  } else return val;
}
export function findTwoDatesBetween(startDate, endDate) {
  const values = eachDayOfInterval({
    start: stringToDate(startDate),
    end: stringToDate(endDate)
  });
  return values;
}
export function isNumeric(d) {
  return !isNaN(parseFloat(d)) && isFinite(d);
}
export function isStringHasValue(string) {
  return Boolean(string);
}
export function calcTimezone() {
  const tzInMinutes = -new Date().getTimezoneOffset();
  var timezone = tzInMinutes / 60 > 0 ? 'GMT +' : 'GMT -';
  timezone =
    timezone +
    ((tzInMinutes / 60).toString().length === 1
      ? '0' + (tzInMinutes / 60).toString()
      : (tzInMinutes / 60).toString()) +
    ':00';
  return {
    timezone: timezone,
    timezoneInMinutes: tzInMinutes
  };
}
const turkishCharacterRegex = keyword => {
  return keyword
    .replace(/[ıİiI]/g, '[ıİiI]')
    .replace(/[şŞsS]/g, '[şŞsS]')
    .replace(/[çÇcC]/g, '[çÇcC]')
    .replace(/[ğĞgG]/g, '[ğĞgG]')
    .replace(/[öÖoO]/g, '[öÖoO]')
    .replace(/[üÜuU]/g, '[üÜuU]');
};
export function customFilter(option, searchText) {
  return turkishCharacterRegex(option.data.label)
    .toLowerCase()
    .includes(turkishCharacterRegex(searchText).toLowerCase());
}

const UNITS = ['day', 'hour', 'minute', 'second'];

// get distance data object
export const getDistanceTo = (date1, date2) => {
  let result = { days: 0, hours: 0, minutes: 0, seconds: 0 };

  if (!isAfter(date1, date2)) {
    const [days, hours, minutes, seconds] = UNITS.map(
      unit =>
        formatDistanceStrict(date1, date2, {
          unit,
          roundingMethod: 'floor'
        }).replace(/\D/g, '') // result contains words - remove it
    );

    result = { days, hours, minutes, seconds };
  }

  return result;
};

// format data object
export const formatDistance = ({ days, hours, minutes, seconds }) =>
  [
    ...[hours % 24, minutes % 60, seconds % 60].map(s =>
      `${s}`.padStart(2, '0')
    )
  ].join(':');

// Seconds -> DD HH:mm:ss
export const convertToDuration = (seconds, lang = 'tr') => {
  if (seconds === 0) return 0;
  if (!seconds) return '-';

  let result = '';

  // Add day value when input extends 24 hours
  if (seconds / 86400 >= 1) {
    const dayText = lang === 'tr' ? 'g' : 'd';
    const days = parseInt(seconds / 86400) + dayText + ' ';
    if (seconds / 86400 >= 1) result += days;
  }

  // Add HH:mm:ss values
  result += new Date(seconds * 1000).toISOString().substr(11, 8);

  return result;
};

export const editDateForBackend = oldDate => {
  // Old date => 23/12/2022
  const datePieces = oldDate.split('/');

  // New date => 2022.12.23
  return `${datePieces[2]}.${datePieces[1]}.${datePieces[0]}`;
};

export function convertStoreIdsToQueryParam(storeIds) {
  let storeIdsString = '';
  for (const storeId of storeIds) storeIdsString += storeId + ',';
  storeIdsString = storeIdsString.slice(0, -1);

  return storeIdsString;
}

export const formatApiDataToExcel = (res, intl, options) => {
  const storeIds = res.find(d => d.Name === 'Ids').Serial;
  const storeNames = res.find(d => d.Name === 'Stores').Serial;
  const labels = res.find(d => d.Name === 'Labels').Serial;
  const partnerIds = res.find(d => d.Name === 'PartnerIds').Serial;

  if (labels.length === 0) return { data: [], columns: [] };

  const newData = [];

  // Add stores as rows
  for (const [i, storeId] of storeIds.entries()) {
    const store = res.find(s => parseInt(s.Name) === storeId).Serial;
    let newStore = {};
    let total = 0;

    // Add store name
    const storeName = storeNames[i];
    newStore['Store'] = storeName;

    //Add partner id
    const partnerId = partnerIds[i];
    newStore['PartnerIds'] = partnerId;

    // Add days
    for (const [j, label] of labels.entries()) {
      newStore[label] = store[j];

      // Increment total sum
      total += store[j];
    }

    // Add sum of values
    newStore['Total'] = options?.calculateAsAverage
      ? total / labels.length
      : total;
    newData.push(newStore);
  }

  const totalsValuesOfEachColumn = labels.map((_, labelIndex) => {
    let columnSum = 0;
    res.forEach(i => {
      if (!isNaN(i.Name)) {
        columnSum += i.Serial[labelIndex];
      }
    });

    return columnSum;
  });

  // Add totals row
  let totalsRow = {};
  totalsRow['Store'] = intl.formatMessage({
    id: 'Total.Table.Value'
  });
  totalsValuesOfEachColumn.forEach((val, i) => {
    totalsRow[labels[i]] = val;
  });

  totalsRow['Total'] = totalsValuesOfEachColumn.reduce((a, b) => a + b, 0);

  newData.sort((a, b) => a.Store.localeCompare(b.Store));

  if (!options?.hideTotalColumn) {
    newData.push(totalsRow);
  }

  // Add all days to headers
  let columns = [
    {
      header: intl.formatMessage({ id: 'info.Location' }),
      dataKey: 'Store'
    },
    {
      header: 'Partner Id',
      dataKey: 'PartnerIds'
    },
    ...labels.map(label => ({
      header: label,
      dataKey: label
    }))
  ];

  if (!options?.hideTotalColumn) {
    columns.push({
      header: intl.formatMessage({ id: 'Total.Table.Value' }),
      dataKey: 'Total'
    });
  }

  return { data: newData, columns };
};

export function calcTime(opentime, closetime) {
  let hours1 = Math.floor(opentime / 60);
  let minutes1 = opentime - hours1 * 60;

  if (hours1.toString().length === 1) hours1 = '0' + hours1;
  if (minutes1.toString().length === 1) minutes1 = '0' + minutes1;
  if (minutes1 === 0) minutes1 = '00';

  let hours2 = Math.floor(closetime / 60);
  let minutes2 = closetime - hours2 * 60;

  if (hours2.toString().length === 1) hours2 = '0' + hours2;
  if (minutes2.toString().length === 1) minutes2 = '0' + minutes2;
  if (minutes2 === 0) minutes2 = '00';

  const stime = `${hours1}:${minutes1}`;
  const etime = `${hours2}:${minutes2}`;
  return { stime, etime };
}
export function equalNameFindSerialTotal(res) {
  const convertedResData = res.reduce((obj, currentValue) => {
    const currentIndex = obj.findIndex(data => data.Name === currentValue.Name);
    if (currentIndex === -1) {
      obj.push(currentValue);
    } else {
      const findEqual = obj.find(d => d.Name === currentValue.Name);
      var sum = findEqual.Serial.map(function (num, idx) {
        return num + currentValue.Serial[idx];
      });
      obj = obj.map(data => ({
        ...data,
        Serial: data.Name === currentValue.Name ? sum : data.Serial
      }));
    }
    return obj;
  }, []);
  return convertedResData;
}

export function parseHoursToMinutes(hoursString) {
  const date = parse(hoursString, 'HH:mm', new Date());
  return date.getHours() * 60 + date.getMinutes();
}

export function serializeError(error) {
  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return JSON.stringify(error);
}
