import {
  GET_STORE_SUCCESS,
  GET_STORE_PENDING,
  SELECTED_STORE_CHANGE,
  SELECTED_STORE_REFRESH,
  STORE_RECTS_PENDING,
  STORE_RECTS_SUCCESS,
  STORE_RECTS_ERROR,
  SET_COMPANY_SELECT_STORES,
  GET_SKETCHS_SUCCESS,
  GET_SKETCHS_ERROR,
  GET_BRANDS_PENDING,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_ERROR,
  SELECTED_BRAND_CHANGE,
  SET_COMPARISON_SKETCH_DATA,
  GET_PERFORMANCETABLE_PENDING,
  GET_PERFORMANCETABLE_SUCCESS,
  GET_PERFORMANCETABLE_ERROR,
  GET_BRAND_PERFORMANCETABLE_PENDING,
  GET_BRAND_PERFORMANCETABLE_SUCCESS,
  GET_BRAND_PERFORMANCETABLE_ERROR,
  GET_STORE_SUGGESTION_PENDING,
  GET_STORE_SUGGESTION_SUCCESS,
  GET_STORE_SUGGESTION_ERROR,
  PERFORMANCETABLE_DATE_ONCHANGE,
  URL_SEARCH_PARAMS,
  CLEAR_SELECTED_STORE,
  GET_WEATHER_DATA_PENDING,
  GET_WEATHER_DATA_SUCCESS,
  GET_WEATHER_DATA_ERROR,
  SET_USERS,
  GET_STOREPRODUCTTREE_PENDING,
  GET_STOREPRODUCTTREE_SUCCESS,
  GET_STOREPRODUCTTREE_ERROR,
  GET_MAPPEDCATEGORIES_PENDING,
  GET_MAPPEDCATEGORIES_SUCCESS,
  GET_MAPPEDCATEGORIES_ERROR,
  SET_STOREALLMAPPEDCATEGORIES,
  GET_NOTRESP_PENDING,
  GET_NOTRESP_SUCCESS,
  GET_NOTRESP_ERROR,
  SET_QUERYSTORESELECTOR,
  GET_STORE_COMMENT_POPUP_SEEN,
  SET_ALL_STORE_IDS,
  SET_BRAND_PRODUCTS,
  GET_CHECKOUTS_ERROR,
  GET_CHECKOUTS_PENDING,
  GET_CHECKOUTS_SUCCESS,
  SET_NOTRESP_BRAND_ID,
  SET_USERS_STATUS
} from './types';
import {
  getAllCameras,
  getCameraWithRects,
  resetCameraReducer
} from './cameraActions';
import { getAllAreaSketchs } from './areaActions';
import {
  getDepartments,
  getCategories,
  getBrandProductTree
} from './brandAction';
import {
  setIsPersonnelSelected,
  setParam,
  setSelectedPersonnel,
  setSketchId,
  setTZOffset
} from './paramsActions';
import { getAllBoards } from './boardAction';
import { getAllPersonnels } from './personnelAction';
import {
  GetUserStores,
  GetStoreRectangles,
  UpdateStoreLocation,
  GetAreaSketchs,
  GetAllBrands,
  GetChartMultipleTable,
  GetBrandChartMultipleTable,
  GetSuggestions,
  getWeather,
  getUsers,
  getStoreProductTree,
  GetStoreMappedCategories,
  GetNotResponses,
  storeCommentPopupSeen,
  GetCheckouts
} from '../Helper/api';
import { getAllReceivers } from './receiverAction';
import { getAllBeacons } from './beaconAction';
import { Products } from '../Constants';
import signalR from '../Helper/SignalR';
import { HubConnectionState } from '@microsoft/signalr';
import Status from '../Constants/Status';

export const getAllStores =
  (brandId, selectedStoreId) => (dispatch, getState) => {
    dispatch({
      type: GET_STORE_PENDING
    });
    GetUserStores(brandId).then(res => {
      if (res.length === 1) {
        dispatch(storeOnChange(res[0], 'globalStoreParam'));
      }
      dispatch({
        type: GET_STORE_SUCCESS,
        payload: res
      });
      dispatch({
        type: SET_ALL_STORE_IDS,
        payload: res?.map(s => s.Id) || []
      });
      // Set brand union products
      dispatch({
        type: SET_BRAND_PRODUCTS,
        payload: ProductsUnion(res)
      });
      dispatch(getDepartments(brandId));
      //check for path has query StoreId
      if (getState().store.queryParamSelectedStore.active) {
        const store = res.find(
          s => s.Id === getState().store.queryParamSelectedStore.Id
        );
        if (store) {
          dispatch(storeOnChange(store, 'globalStoreParam'));
        } else {
          dispatch(queryStoreSelector(false, null));
        }
      }
      if (selectedStoreId) {
        const store = res.find(s => s.Id === selectedStoreId);
        if (store) {
          dispatch({
            type: SELECTED_STORE_REFRESH,
            payload: {
              ...store
            },
            uniqueName: 'globalStoreParam'
          });
        }
      }
    });
  };

export const storeOnChange = (newStore, uniqueName) => dispatch => {
  dispatch({
    type: SELECTED_STORE_CHANGE,
    payload: newStore,
    uniqueName: uniqueName
  });
  // dispatch({
  //     type: PERFORMANCETABLE_DATE_ONCHANGE,
  //     payload: null
  // })
  if (uniqueName === 'globalStoreParam') {
    dispatch({
      type: URL_SEARCH_PARAMS,
      payload: { StoreID: newStore.Id }
    });
    dispatch(getAllCameras(newStore.Id));
    dispatch(getAllAreaSketchs(newStore.Id));
    dispatch(getStoreRects(newStore.Id));
    dispatch(getCameraWithRects(newStore.Id));
    dispatch(getStoreMappedProductTree(newStore.Id));
    dispatch(getStoreMappedCategories(newStore.Id));
    dispatch(getStoreCommentPopupSeen(newStore.Id));
    dispatch(setTZOffset(newStore.TZOffset));
    dispatch(getAllBoards(newStore.Id));
    dispatch(getAllReceivers(newStore.Id));
    dispatch(getAllPersonnels(newStore.Id));
    dispatch(getAllBeacons(newStore.Id));
    dispatch(getStoreSketchs(newStore.Id));
    dispatch(getCheckouts(newStore.Id));
    dispatch(setSketchId(null));

    subscribeToStoreOnSignalR(newStore.Id);
  }
};

export function subscribeToStoreOnSignalR(storeId) {
  const interval = setInterval(() => {
    if (!signalR.connection) return;

    switch (signalR.getConnectionState()) {
      case HubConnectionState.Connected:
        signalR.connection.invoke('SubscribeStore', storeId);
        clearInterval(interval);
        return;
    }
  }, 2_000);
}

//If path query has StoreId and it's different  with selectedStore will call this method to set next selectedStore which is passed from query
export const queryStoreSelector = (active, Id) => dispatch => {
  dispatch({
    type: SET_QUERYSTORESELECTOR,
    active: active,
    Id: Id
  });
  if (active)
    dispatch({
      type: URL_SEARCH_PARAMS,
      payload: { StoreID: Id }
    });
};
export const getStoreRects = storeID => dispatch => {
  dispatch({
    type: STORE_RECTS_PENDING
  });
  return GetStoreRectangles({ storeID: storeID })
    .then(res => {
      const rectangles = {
        ...res,
        Lines: [
          ...res.Lines,
          ...res.TwoCornerLines.map(line => ({ ...line, In: line.Direction }))
        ]
      };
      dispatch({
        type: STORE_RECTS_SUCCESS,
        payload: rectangles
      });
    })
    .catch(err =>
      dispatch({
        type: STORE_RECTS_ERROR,
        payload: err
      })
    );
};

export const setStoreLocation = (store, location, brandId) => dispatch => {
  UpdateStoreLocation(store.Id, location).then(res => {
    dispatch(getAllStores(brandId));
    dispatch({
      type: SELECTED_STORE_CHANGE,
      payload: {
        ...store,
        LatDouble: location.Lat,
        LngDouble: location.Lng
      },
      uniqueName: 'globalStoreParam'
    });
  });
  // .catch(err =>
  //     dispatch({
  //         type: STORE_LOCATION_UPDATE_ERROR,
  //         payload: err
  //     })
  // )
};

export const setCompanySelectStores = payload => dispatch => {
  dispatch({
    type: SET_COMPANY_SELECT_STORES,
    payload: payload
  });
};

export const getStoreSketchs = storeID => dispatch => {
  GetAreaSketchs(storeID)
    .then(res =>
      dispatch({
        type: GET_SKETCHS_SUCCESS,
        payload: { store: storeID, data: res }
      })
    )
    .catch(err => {
      dispatch({
        type: GET_SKETCHS_ERROR,
        payload: err
      });
    });
};

export const getBrands = () => (dispatch, getState) => {
  const { user, params } = getState();
  dispatch({
    type: GET_BRANDS_PENDING
  });
  return GetAllBrands()
    .then(res => {
      const currentBrandId = params.globalBrandID;
      const isExistInBrandList = res.some(brand => brand.Id === currentBrandId);
      if (user.user?.details?.IsCustomRole && !isExistInBrandList) {
        dispatch(brandOnChange(res[0], 'globalBrandParam'));
      }
      dispatch({
        type: GET_BRANDS_SUCCESS,
        payload: res
      });
    })
    .catch(err => {
      dispatch({
        type: GET_BRANDS_ERROR,
        payload: err
      });
    });
};

export const getNotResponses = brandId => (dispatch, getState) => {
  const prevBrandId = !!getState
    ? getState()?.brand?.notificationResponses?.brandId
    : undefined;

  if (prevBrandId === brandId) return;

  dispatch({ type: SET_NOTRESP_BRAND_ID, payload: brandId });
  dispatch({ type: GET_NOTRESP_PENDING });

  return GetNotResponses(brandId)
    .then(res => {
      dispatch({
        type: GET_NOTRESP_SUCCESS,
        payload: {
          data: res,
          brandId
        }
      });
    })
    .catch(err => {
      dispatch({
        type: GET_NOTRESP_ERROR,
        payload: err
      });
    });
};

export const brandOnChange = (newBrand, uniqueName) => dispatch => {
  localStorage.removeItem('comparisonStore');
  localStorage.removeItem('ComparisonV2Date');
  localStorage.removeItem('comparisonStore2');
  localStorage.removeItem('ComparisonV2');
  dispatch({
    type: SELECTED_BRAND_CHANGE,
    payload: newBrand,
    uniqueName: uniqueName
  });
  dispatch({
    type: GET_STORE_SUCCESS,
    payload: []
  });
  dispatch({
    type: CLEAR_SELECTED_STORE
  });
  dispatch({
    type: PERFORMANCETABLE_DATE_ONCHANGE,
    payload: null
  });
  if (uniqueName === 'globalBrandParam') {
    dispatch({ type: CLEAR_SELECTED_STORE });
    dispatch(resetCameraReducer());
    dispatch(getAllStores(newBrand.Id));
    dispatch(setParam('brandID', newBrand.Id));
    dispatch(setParam('globalBrandID', newBrand.Id));
    dispatch(setParam('comparisonStoreId', null));
    dispatch(setParam('comparisonStore2Id'));
    dispatch(getCategories(newBrand.Id));
    dispatch(getBrandProductTree(newBrand.Id));
    dispatch(getNotResponses(newBrand.Id));
    dispatch(setSelectedPersonnel({}));
    dispatch(setIsPersonnelSelected(true));
  }
};

export const brandOnChange2 = (newBrand, uniqueName) => dispatch => {
  dispatch({
    type: SELECTED_BRAND_CHANGE,
    payload: newBrand,
    uniqueName: uniqueName
  });
  dispatch({
    type: GET_STORE_SUCCESS,
    payload: []
  });
  if (uniqueName === 'globalBrandParam') {
    dispatch(getAllStores(newBrand.Id));
    dispatch(getDepartments(newBrand.Id));
    dispatch(setParam('globalBrandID', newBrand.Id));
    dispatch(setParam('brandID', newBrand.Id));
  }
};
export const setComparisonSketchData = (data, uniqueName) => dispatch => {
  dispatch({
    type: SET_COMPARISON_SKETCH_DATA,
    uniqueName: [uniqueName],
    payload: data
  });
};

export const getPeformanceTableData = (params, key) => dispatch => {
  dispatch({
    type: GET_PERFORMANCETABLE_PENDING,
    key: key,
    shouldUpdate: true
  });
  return GetChartMultipleTable(params)
    .then(res => {
      dispatch({
        type: GET_PERFORMANCETABLE_SUCCESS,
        key: key,
        params: params,
        payload: res,
        shouldUpdate: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_PERFORMANCETABLE_ERROR,
        key: key,
        payload: err,
        shouldUpdate: true
      });
    });
};
export const getBrandPeformanceTableData = (params, key) => dispatch => {
  dispatch({
    type: GET_BRAND_PERFORMANCETABLE_PENDING,
    key: key,
    shouldUpdate: true
  });
  return GetBrandChartMultipleTable(params)
    .then(res => {
      dispatch({
        type: GET_BRAND_PERFORMANCETABLE_SUCCESS,
        key: key,
        payload: res,
        shouldUpdate: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_BRAND_PERFORMANCETABLE_ERROR,
        key: key,
        payload: err,
        shouldUpdate: true
      });
    });
};

export const getWeatherData = params => dispatch => {
  dispatch({
    type: GET_WEATHER_DATA_PENDING
  });
  return getWeather(params)
    .then(res => {
      dispatch({
        type: GET_WEATHER_DATA_SUCCESS,
        payload: res
      });
    })
    .catch(err => {
      dispatch({
        type: GET_WEATHER_DATA_ERROR,
        payload: 'No Data'
      });
    });
};

export const getStoreUsers = StoreID => dispatch => {
  dispatch({
    type: SET_USERS_STATUS,
    payload: Status.Pending
  });
  dispatch({
    type: SET_USERS,
    payload: null
  });
  getUsers(StoreID)
    .then(res => {
      dispatch({
        type: SET_USERS,
        payload: res.map(d => ({ value: d.Id, label: d.UserName, allData: d }))
      });
      dispatch({
        type: SET_USERS_STATUS,
        payload: Status.Fullfilled
      });
    })
    .catch(() => {
      dispatch({
        type: SET_USERS_STATUS,
        payload: Status.Rejected
      });
    });
};
export const clearStoreUsers = () => dispatch => {
  dispatch({
    type: SET_USERS,
    payload: null
  });
};

export const getStoreMappedProductTree = StoreId => dispatch => {
  dispatch({
    type: GET_STOREPRODUCTTREE_PENDING
  });

  return getStoreProductTree(StoreId)
    .then(res => {
      dispatch({
        type: GET_STOREPRODUCTTREE_SUCCESS,
        payload: res
      });
      let MappedCategoriesIds = [];
      let MappedRectIds = [];
      res.map(MappedCategories => {
        MappedRectIds.push(MappedCategories.RectId);
        MappedCategories.ProductCategories.map(Category => {
          MappedCategoriesIds.push(Category.Id);
        });
      });
      dispatch({
        type: SET_STOREALLMAPPEDCATEGORIES,
        uniqueName: 'Configuration',
        payload: MappedCategoriesIds,
        uniqNameRect: 'ConfigurationRectIds',
        payloadRect: MappedRectIds
      });
    })
    .catch(err => {
      dispatch({
        type: GET_STOREPRODUCTTREE_ERROR,
        payload: err
      });
    });
};

export const getStoreMappedCategories = StoreId => dispatch => {
  dispatch({
    type: GET_MAPPEDCATEGORIES_PENDING
  });

  return GetStoreMappedCategories({ Id: StoreId })
    .then(res => {
      dispatch({
        type: GET_MAPPEDCATEGORIES_SUCCESS,
        payload: res
      });
      dispatch({
        type: SET_STOREALLMAPPEDCATEGORIES,
        uniqueName: 'Settings',
        payload: res.map(Category => Category.CategoryId),
        uniqNameRect: 'SettingsRectIds',
        payloadRect: res.map(Category => Category.RectId)
      });
    })
    .catch(err => {
      dispatch({
        type: GET_MAPPEDCATEGORIES_ERROR,
        payload: err
      });
    });
};

export const getStoreCommentPopupSeen = StoreId => dispatch => {
  return storeCommentPopupSeen(StoreId)
    .then(res => {
      dispatch({
        type: GET_STORE_COMMENT_POPUP_SEEN,
        payload: res
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: GET_STORE_COMMENT_POPUP_SEEN,
        payload: true
      });
    });
};

export const setReduxStoreCommentPopupSeen = payload => dispatch => {
  dispatch({
    type: GET_STORE_COMMENT_POPUP_SEEN,
    payload: payload
  });
};

const ProductsUnion = (stores = []) => {
  return stores.reduce(
    (acc, store) => {
      const StoreProducts = store.Products;
      return {
        [Products.CheckoutProduct]:
          acc[Products.CheckoutProduct] ||
          StoreProducts[Products.CheckoutProduct],
        [Products.InstoreAnalyticsProduct]:
          acc[Products.InstoreAnalyticsProduct] ||
          StoreProducts[Products.InstoreAnalyticsProduct],
        [Products.PeopleCountingGenderAgeProduct]:
          acc[Products.PeopleCountingGenderAgeProduct] ||
          StoreProducts[Products.PeopleCountingGenderAgeProduct],
        [Products.PeopleCountingProduct]:
          acc[Products.PeopleCountingProduct] ||
          StoreProducts[Products.PeopleCountingProduct],
        [Products.PersonnelTrackingProduct]:
          acc[Products.PersonnelTrackingProduct] ||
          StoreProducts[Products.PersonnelTrackingProduct]
      };
    },
    {
      [Products.CheckoutProduct]: false,
      [Products.InstoreAnalyticsProduct]: false,
      [Products.PeopleCountingGenderAgeProduct]: false,
      [Products.PeopleCountingProduct]: false,
      [Products.PersonnelTrackingProduct]: false
    }
  );
};

export const getCheckouts = storeID => dispatch => {
  dispatch({
    type: GET_CHECKOUTS_PENDING
  });
  GetCheckouts(storeID)
    .then(res =>
      dispatch({
        type: GET_CHECKOUTS_SUCCESS,
        payload: res
      })
    )
    .catch(err => {
      dispatch({
        type: GET_CHECKOUTS_ERROR,
        payload: err
      });
    });
};
