import Status from '../Constants/Status';
import {
  GET_STORE_SUCCESS,
  GET_STORE_PENDING,
  SELECTED_STORE_CHANGE,
  SELECTED_STORE_REFRESH,
  SELECTED_COMPARISON_STORE_CHANGE,
  STORE_RECTS_PENDING,
  STORE_RECTS_SUCCESS,
  STORE_RECTS_ERROR,
  SET_COMPANY_SELECT_STORES,
  GET_SKETCHS_SUCCESS,
  GET_SKETCHS_ERROR,
  GET_BRANDS_PENDING,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_ERROR,
  SELECTED_BRAND_CHANGE,
  SET_COMPARISON_SKETCH_DATA,
  GET_PERFORMANCETABLE_PENDING,
  GET_PERFORMANCETABLE_SUCCESS,
  GET_PERFORMANCETABLE_ERROR,
  GET_BRAND_PERFORMANCETABLE_PENDING,
  GET_BRAND_PERFORMANCETABLE_SUCCESS,
  GET_BRAND_PERFORMANCETABLE_ERROR,
  GET_STORE_SUGGESTION_PENDING,
  GET_STORE_SUGGESTION_SUCCESS,
  GET_STORE_SUGGESTION_ERROR,
  PERFORMANCETABLE_DATE_ONCHANGE,
  INITIALIZE_STATE,
  RESET_STATE,
  CLEAR_SELECTED_STORE,
  GET_WEATHER_DATA_SUCCESS,
  GET_WEATHER_DATA_PENDING,
  GET_WEATHER_DATA_ERROR,
  SET_USERS,
  GET_STOREPRODUCTTREE_PENDING,
  GET_STOREPRODUCTTREE_SUCCESS,
  GET_STOREPRODUCTTREE_ERROR,
  GET_MAPPEDCATEGORIES_PENDING,
  GET_MAPPEDCATEGORIES_SUCCESS,
  GET_MAPPEDCATEGORIES_ERROR,
  SET_STOREALLMAPPEDCATEGORIES,
  SET_QUERYSTORESELECTOR,
  GET_STORE_COMMENT_POPUP_SEEN,
  GET_CHECKOUTS_PENDING,
  GET_CHECKOUTS_SUCCESS,
  GET_CHECKOUTS_ERROR,
  SET_USERS_STATUS
} from '../ReduxActions/types';

var initialState = {
  allStores: [],
  allStoreRects: {},
  ComparisonSketchs: [],
  companySelectStores: [],
  fetching: false,
  fetched: false,
  fetching_rects: false,
  fetched_rects: false,
  error_rects: false,
  sketches: {},
  brands: {
    pending: false,
    success: false,
    data: null,
    error: false
  },
  weatherData: {
    pending: false,
    success: false,
    data: [],
    error: false
  },
  PerformanceTableData: {},
  BrandPerformanceTableData: {},
  StoreSuggestions: {
    pending: false,
    success: false,
    data: null
  },
  StoreProductTree: {
    pending: false,
    success: false,
    data: [],
    error: false
  },
  StoreMappedCategories: {
    pending: false,
    success: false,
    data: [],
    error: false
  },
  StoreAllMappedCategoryIds: {
    Configuration: [],
    ConfigurationRectIds: [],
    Settings: [],
    SettingsRectIds: []
  },
  storeUsers: null,
  storeUsersStatus: Status.Empty,
  queryParamSelectedStore: {
    active: false,
    Id: null
  },
  storeCommentPopupSeen: true,
  Checkouts: []
};

export default function (state = null, action) {
  let nextState = { ...state };
  switch (action.type) {
    case INITIALIZE_STATE:
      nextState = {
        ...state,
        ...initialState,
        selectedStores:
          JSON.parse(localStorage.getItem('SelectedStores')) || {},
        selectedBrand: JSON.parse(localStorage.getItem('SelectedBrand'))
          ? JSON.parse(localStorage.getItem('SelectedBrand'))
          : JSON.parse(localStorage.getItem('user'))
          ? {
              globalBrandParam: {
                Id: JSON.parse(localStorage.getItem('user'))['details'][
                  'BrandId'
                ],
                Name: JSON.parse(localStorage.getItem('user'))['details'][
                  'Brand'
                ]
              }
            }
          : { globalBrandParam: {} }
      };
      break;
    case RESET_STATE:
      nextState = null;
      break;
    case GET_STORE_PENDING:
      nextState = {
        ...state,
        fetching: true,
        fetched: false
      };
      break;
    case GET_STORE_SUCCESS:
      let payload = {
        allStores: action.payload,
        fetching: false,
        fetched: true
      };
      nextState = {
        ...state,
        ...payload
      };
      break;
    case STORE_RECTS_PENDING:
      nextState = {
        ...state,
        allStoreRects: {},
        fetching_rects: true,
        fetched_rects: false,
        error_rects: false
      };
      break;
    case STORE_RECTS_SUCCESS:
      nextState = {
        ...state,
        allStoreRects: action.payload,
        fetching_rects: false,
        fetched_rects: true,
        error_rects: false
      };
      break;
    case STORE_RECTS_ERROR:
      nextState = {
        ...state,
        allStoreRects: {},
        fetching_rects: false,
        fetched_rects: false,
        error_rects: action.payload
      };
      break;
    case SELECTED_STORE_CHANGE:
    case SELECTED_COMPARISON_STORE_CHANGE:
      let selectedStores = {
        selectedStores: {
          ...state.selectedStores,
          [action.uniqueName]: action.payload
        }
      };
      if (state.queryParamSelectedStore.active) {
        selectedStores = {
          ...selectedStores,
          queryParamSelectedStore: {
            ...state.queryParamSelectedStore,
            Id: null,
            active: false
          }
        };
      }
      nextState = {
        ...state,
        ...selectedStores
      };
      localStorage.setItem(
        'SelectedStores',
        JSON.stringify(nextState.selectedStores)
      );
      break;
    case SELECTED_STORE_REFRESH:
      nextState = {
        ...state,
        selectedStores: {
          ...state.selectedStores,
          [action.uniqueName]: action.payload
        }
      };
      localStorage.setItem(
        'SelectedStores',
        JSON.stringify(nextState.selectedStores)
      );
      break;
    case SET_COMPANY_SELECT_STORES:
      nextState['companySelectStores'] = action.payload;
      break;
    case GET_SKETCHS_SUCCESS:
      nextState = {
        ...state,
        sketches: {
          ...state.sketches,
          [action.payload.store]: action.payload.data
        }
      };
      break;
    case GET_SKETCHS_ERROR:
      nextState = {
        ...state
      };
      break;
    case GET_BRANDS_PENDING:
      nextState = {
        ...state,
        brands: {
          ...state.brands,
          pending: true,
          success: false,
          data: null
        }
      };
      break;
    case GET_BRANDS_SUCCESS:
      nextState = {
        ...state,
        brands: {
          ...state.brands,
          data: action.payload,
          pending: false,
          success: true
        }
      };
      break;
    case GET_BRANDS_ERROR:
      nextState = {
        ...state,
        brands: {
          ...state.brands,
          pending: false,
          success: false,
          error: action.payload
        }
      };
      break;
    case SELECTED_BRAND_CHANGE:
      nextState = {
        ...state,
        selectedBrand: {
          ...state.selectedBrand,
          [action.uniqueName]: action.payload
        }
      };
      localStorage.setItem(
        'SelectedBrand',
        JSON.stringify(nextState.selectedBrand)
      );
      break;
    case SET_COMPARISON_SKETCH_DATA:
      nextState = {
        ...state,
        [action.uniqueName]: action.payload
      };
      break;
    case GET_PERFORMANCETABLE_PENDING:
      nextState = {
        ...state,
        PerformanceTableData: {
          ...state.PerformanceTableData,
          [action.key]: {
            pending: true,
            success: false,
            data: null
          }
        }
      };
      break;
    case GET_PERFORMANCETABLE_SUCCESS:
      nextState = {
        ...state,
        PerformanceTableData: {
          ...state.PerformanceTableData,
          [action.key]: {
            params: action.params,
            data: action.payload,
            pending: false,
            success: true
          }
        }
      };
      break;
    case GET_PERFORMANCETABLE_ERROR:
      nextState = {
        ...state,
        PerformanceTableData: {
          ...state.PerformanceTableData,
          [action.key]: {
            pending: false,
            success: false,
            error: action.payload
          }
        }
      };
      break;
    case GET_BRAND_PERFORMANCETABLE_PENDING:
      nextState = {
        ...state,
        BrandPerformanceTableData: {
          ...state.BrandPerformanceTableData,
          [action.key]: {
            pending: true,
            success: false,
            data: null
          }
        }
      };
      break;
    case GET_BRAND_PERFORMANCETABLE_SUCCESS:
      nextState = {
        ...state,
        BrandPerformanceTableData: {
          ...state.BrandPerformanceTableData,
          [action.key]: {
            data: action.payload,
            pending: false,
            success: true
          }
        }
      };
      break;
    case GET_BRAND_PERFORMANCETABLE_ERROR:
      nextState = {
        ...state,
        BrandPerformanceTableData: {
          ...state.BrandPerformanceTableData,
          [action.key]: {
            pending: false,
            success: false,
            error: action.payload
          }
        }
      };
      break;
    case PERFORMANCETABLE_DATE_ONCHANGE:
      if (!action.payload) {
        nextState = {
          ...state,
          BrandPerformanceTableData: {},
          PerformanceTableData: {}
        };
      } else
        nextState = {
          ...state,
          BrandPerformanceTableData: {
            ...state.BrandPerformanceTableData,
            [action.payload.dataType]: {}
          },
          PerformanceTableData: {
            ...state.PerformanceTableData,
            [action.payload.dataType]: {}
          }
        };
    case GET_STORE_SUGGESTION_PENDING:
      nextState = {
        ...state,
        StoreSuggestions: {
          ...state.StoreSuggestions,
          pending: true,
          success: false,
          data: null
        }
      };
      break;
    case GET_STORE_SUGGESTION_SUCCESS:
      nextState = {
        ...state,
        StoreSuggestions: {
          ...state.StoreSuggestions,
          pending: false,
          success: true,
          data: action.payload
        }
      };
      break;
    case GET_STORE_SUGGESTION_ERROR:
      nextState = {
        ...state,
        StoreSuggestions: {
          ...state.StoreSuggestions,
          pending: false,
          success: false,
          error: action.payload
        }
      };
      break;
    case CLEAR_SELECTED_STORE:
      nextState = {
        ...state,
        selectedStores: {}
      };
      break;
    case GET_WEATHER_DATA_PENDING:
      nextState = {
        ...state,
        weatherData: {
          ...state.weatherData,
          data: [],
          pending: true,
          success: false,
          data: null
        }
      };
      break;
    case GET_WEATHER_DATA_SUCCESS:
      nextState = {
        ...state,
        weatherData: {
          ...state.weatherData,
          data: action.payload,
          pending: false,
          success: true
        }
      };
      break;
    case GET_WEATHER_DATA_ERROR:
      nextState = {
        ...state,
        weatherData: {
          ...state.weatherData,
          data: [],
          pending: false,
          success: false,
          error: action.payload
        }
      };
      break;
    case SET_USERS_STATUS:
      nextState = {
        ...state,
        storeUsersStatus: action.payload
      };
      break;
    case SET_USERS:
      nextState = {
        ...state,
        storeUsers: action.payload
      };
      break;
    case GET_STOREPRODUCTTREE_PENDING:
      nextState = {
        ...state,
        StoreProductTree: {
          ...state.StoreProductTree,
          pending: true,
          success: false,
          data: []
        }
      };
      break;
    case GET_STOREPRODUCTTREE_SUCCESS:
      nextState = {
        ...state,
        StoreProductTree: {
          ...state.StoreProductTree,
          data: action.payload,
          pending: false,
          success: true
        }
      };
      break;
    case GET_STOREPRODUCTTREE_ERROR:
      nextState = {
        ...state,
        StoreProductTree: {
          ...state.StoreProductTree,
          pending: false,
          success: false,
          error: action.payload
        }
      };
      break;
    case GET_MAPPEDCATEGORIES_PENDING:
      nextState = {
        ...state,
        StoreMappedCategories: {
          ...state.StoreMappedCategories,
          pending: true,
          success: false,
          data: []
        }
      };
      break;
    case GET_MAPPEDCATEGORIES_SUCCESS:
      nextState = {
        ...state,
        StoreMappedCategories: {
          ...state.StoreMappedCategories,
          data: action.payload,
          pending: false,
          success: true
        }
      };
      break;
    case GET_MAPPEDCATEGORIES_ERROR:
      nextState = {
        ...state,
        StoreMappedCategories: {
          ...state.StoreMappedCategories,
          pending: false,
          success: false,
          error: action.payload
        }
      };
      break;
    case SET_STOREALLMAPPEDCATEGORIES:
      nextState = {
        ...state,
        StoreAllMappedCategoryIds: {
          ...state.StoreAllMappedCategoryIds,
          [action.uniqueName]: action.payload,
          [action.uniqNameRect]: action.payloadRect
        }
      };
      break;
    case SET_QUERYSTORESELECTOR:
      nextState = {
        ...state,
        queryParamSelectedStore: {
          ...state.queryParamSelectedStore,
          active: action.active,
          Id: action.Id
        }
      };
      break;
    // case GET_WEATHER_DATA:
    //     const weatherData = action.payload
    //         nextState['weatherData'] = weatherData;
    //     break;
    case GET_STORE_COMMENT_POPUP_SEEN:
      nextState = {
        ...state,
        storeCommentPopupSeen: action.payload
      };
      break;
    case GET_CHECKOUTS_PENDING:
      nextState = {
        ...state,
        pending: true,
        success: false,
        Checkouts: []
      };
      break;
    case GET_CHECKOUTS_SUCCESS:
      nextState = {
        ...state,
        Checkouts: action.payload,
        pending: false,
        success: true
      };
      break;
    case GET_CHECKOUTS_ERROR:
      nextState = {
        ...state,
        pending: false,
        success: false,
        error: action.payload
      };
      break;
    default:
      break;
  }
  return nextState;
}

//GET_NOTRESP_PENDING
