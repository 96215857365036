import suggestionComment from './suggestionComments';
export default {
  en: {
    'customerServices.AverageDwell': 'Customer Services Average Dwell',
    'customerServices.AverageDwellChange':
      'Customer Services Average Dwell Change',

    'customerServices.AverageDwellInfo':
      'Average time spent by total contact detected in Customer Services areas',
    'customerServices.AverageDwellChangeInfo':
      'Change in the average time measured in Customer Service areas compared to the comparison date',
    'customerServices.DensityRatioInfo':
      'Distribution of measured density in Customer Service areas among selected regions/locations. (Density = Number of contacts x Average Duration) (Density Ratio = Region-Location Density / Total Density)',
    'customerServices.DensityRatioChangeInfo':
      'Change in density rates of Customer Services areas according to comparison date',
    'customerServices.PeakHourInfo':
      'Peak hours for Customer Service areas (Comparison date peak hour -> Review date peak hour)',
    'customerServices.NotificationCountInfo':
      'Number of Customer Services density notifications',
    'customerServices.NotificationCountChangeInfo':
      'Change in the number of Customer Services density notifications according to the comparison date',
    'customerServices.CustomerCountInfo':
      'Number of customers spending {value} time in Customer Service areas',
    'customerServices.AverageDailyCustomerCountInfo':
      'Average number of customers spending {value} time in Customer Service areas (Total {value} customers / Number of days)',
    'customerServices.CustomerCountChangeInfo':
      'Change in the number of {value} customers of Customer Service areas according to the comparison date',
    'customerServices.DwellInfo':
      'Average time spent by {value} customers detected in Customer Services areas',
    'customerServices.DwellChangeInfo':
      'Change in the average duration of {value} customers measured in Customer Service areas compared to the comparison date',
    'customerServices.DelayRateInfo':
      'Ratio of {value} customers to total customers ({value} customers / Total customers)',
    'customerServices.DelayRateChangeInfo':
      'Change of delay rate according to comparison date',

    'checkoutSummary.AverageDwellInfo':
      'Average time spent by total contact detected in cash register areas',
    'checkoutSummary.AverageDwellChangeInfo':
      'Change in the average time measured in checkout areas compared to the comparison date',
    'checkoutSummary.DensityRatioInfo':
      'Distribution of density measured in cash register areas among selected regions/locations. (Density = Number of contacts x Average Duration) (Density Ratio = Region-Location Density / Total Density)',
    'checkoutSummary.DensityRatioChangeInfo':
      'Change in the density ratios of safe areas according to the comparison date',
    'checkoutSummary.ChWaitingAverageDwellInfo':
      'Average time spent by total contact detected in cash register waiting areas',
    'checkoutSummary.ChWaitingAverageDwellChangeInfo':
      'Change in the average time measured in checkout waiting areas compared to the comparison date',
    'checkoutSummary.ChWaitingDensityRatioInfo':
      'Distribution of the density measured in checkout waiting areas among selected regions/locations. (Density = Number of contacts x Average Duration) (Density Ratio = Region-Location Density / Total Density)',
    'checkoutSummary.ChWaitingDensityRatioChangeInfo':
      'Change in the density rates of cash register waiting areas according to the comparison date',
    'checkoutSummary.PeakHourInfo':
      'Hours when checkout areas reach their highest density (Comparison date peak hour -> Review date peak hour)',
    'checkoutSummary.NotificationCountInfo':
      'Number of case density notifications',
    'checkoutSummary.NotificationCountChangeInfo':
      'Change in the number of case density notifications according to the comparison date',
    'checkoutSummary.InvoiceCountInfo': 'Number of issued invoices',
    'checkoutSummary.AverageDailyInvoiceCountInfo':
      'Average number of invoices issued per day (Total number of invoices / Number of days)',
    'checkoutSummary.InvoiceCountChangeInfo':
      'Change in the number of invoices according to the comparison date',

    'checkoutSummary.CustomerCountInfo':
      'Number of contacts spending {value} time in checkout areas',
    'checkoutSummary.AverageDailyCustomerCountInfo':
      'Average number of contacts spending {value} time in checkout areas (Total {value} contacts / Number of days)',
    'checkoutSummary.CustomerCountChangeInfo':
      'Change in the number of contacts of cash register areas {value} according to the comparison date',
    'checkoutSummary.DwellInfo':
      'Average time spent by {value} contact detected in cash register areas',
    'checkoutSummary.DwellChangeInfo':
      'Change in the average duration of {value} contact measured in cash register areas according to the comparison date',
    'checkoutSummary.DelayRateInfo':
      'Ratio of {value} contact to total contact ({value} contact / Total contact)',
    'checkoutSummary.DelayRateChangeInfo':
      'Change of delay rate according to comparison date',
    'checkoutSummary.ConversionRateInfo':
      'Ratio of the number of issued invoices to {value} contacts detected at the cash register (Number of invoices / {value} contacts)',
    'checkoutSummary.ConversionRateChangeInfo':
      'Change in conversion rate by comparison date',

    'checkoutSummary.storeRegion': 'Location/Region',
    'checkoutSummary.AverageDwell': 'Checkout Average Dwell',
    'checkoutSummary.AverageDwellChange': 'Checkout Average Dwell Change',
    'checkoutSummary.DensityRatio': 'Density Ratio',
    'checkoutSummary.DensityRatioChange': 'Density Ratio Change',
    'checkoutSummary.ChWaitingAverageDwell': 'Waiting Area Average Dwell',
    'checkoutSummary.ChWaitingAverageDwellChange':
      'Waiting Area Average Dwell Change',
    'checkoutSummary.ChWaitingDensityRatio': 'Waiting Area Density Ratio',
    'checkoutSummary.ChWaitingDensityRatioChange':
      'Waiting Area Density Ratio Change',
    'checkoutSummary.PeakHour': 'Peak Hour',
    'checkoutSummary.NotificationCount': 'Notification Count',
    'checkoutSummary.NotificationCountChange': 'Notification Count Change',
    'checkoutSummary.InvoiceCount': 'Invoice Count',
    'checkoutSummary.AverageDailyInvoiceCount': 'Daily Average Invioce Count',
    'checkoutSummary.InvoiceCountChange': 'Invoice Count Change',
    'checkoutSummary.over15sec': 'Over 15 Seconds',
    'checkoutSummary.CustomerCount': 'Customer Count',
    'checkoutSummary.AverageDailyCustomerCount': 'Daily Average Customer Count',
    'checkoutSummary.CustomerCountChange': 'Customer Count Change',
    'checkoutSummary.Dwell': 'Dwell',
    'checkoutSummary.DwellChange': 'Dwell Change',
    'checkoutSummary.DelayRate': 'Delay Rate',
    'checkoutSummary.DelayRateChange': 'Delay Rate Change',
    'checkoutSummary.ConversionRate': 'Conversion Rate',
    'checkoutSummary.ConversionRateChange': 'Conversion Rate Change',
    'checkoutSummary.btw15and60sec': 'Between 15-60 Seconds',
    'checkoutSummary.over60sec': 'Over 60 Seconds',

    'sketchHistory.blueLegion': 'Contains at least 1 active area.',
    'sketchHistory.grayLegion': 'All areas are archived.',
    'sketchHistory.redLegion': 'All areas have been deleted.',
    'info.submit': 'Submit',
    'info.login': 'Login',
    'info.forgotpassword': 'Forgot Your Password ?',
    'info.username': 'Username',
    'info.name': 'Name',
    'info.password': 'Password',
    'info.changePassword': 'Change Password',
    'info.oldPassword': 'Old Password',
    'info.newPassword': 'New Password',
    'info.repeatNewPassword': 'Repeat New Password',
    'info.successPas': 'The password was changed.',
    'info.errorPassword': "Passwords don't match!",
    'info.badPassword': 'Bad Password',
    'info.blankField': 'Please Fill In The Empty Fields!',
    'info.profile': 'Profile',
    'info.selectUser': 'Select User',
    'info.store.info': 'Location Info',
    'info.selectStore': 'Select Location',
    'info.selectBrand': 'Select Brand',
    'info.categoryName': 'Category Name',
    'info.addCategory': 'Add Category',
    'info.selectCategory': 'Select Category',
    'info.selectSubCategory': 'Select Sub Category',
    'info.RemoveCategory': 'Remove Category',
    'info.RemoveSubCategory': 'Remove Sub Category',
    'info.selectSaleCategory': 'Select Sale Category',
    'info.selectCamera': 'Select Camera',
    'info.selectBoard': 'Select Board',
    'info.selectReceiver': 'Select Receiver',
    'info.selectCamera2': 'Select Camera',
    'info.selectArea': 'Select Area',
    'info.selectSketch': 'Select Sketch',
    'info.selectOperator': 'Select Operator',
    'info.selectDatasource': 'Select Data Source',
    'info.updateSketch': 'Update Floor Plan',
    'info.set': 'Set',
    'info.save': 'Save',
    'info.confirm': 'Confirm',
    'info.cancel': 'Cancel',
    'info.efficiencyScore': 'Efficiency Score',
    'info.sec': 'Sec',
    'info.min': 'Min',
    'info.minute': 'Minute',
    'info.sketchName': 'Sketch Name',
    'info.sketchRect': 'Sketch Rectangle',
    'info.second': 'Seconds',
    'info.saveChanges': 'Save Changes',
    'info.addChart': 'Add Chart',
    'info.add': 'Add',
    'info.always': 'Always',
    'info.remove': 'Remove',
    'info.delete': 'Delete',
    'info.edit': 'Edit',
    'info.archive': 'Archive',
    'info.archived': 'Archived',
    'info.revert': 'Revert',
    'info.addNewSketch': 'Add New Floor Plan',
    'info.notification.edit': 'Edit Notification Rule',
    'info.notification.editComplex': 'Edit Complex Notification Rule',
    'info.notification.visitorCount': 'Person(s)',
    'info.notification.visitorTime': 'Sec',
    'info.notification.passiveStatusActive': 'Active',
    'info.notification.passiveStatusPassive': 'Passive',
    'info.notification.Users': 'Target Users',
    'info.notification.peopleCount': 'Visitor Count',
    'info.excelChart.person': 'Person',
    'info.excelChart.traffic': 'Traffic',
    'info.notification.timeSpent': 'Time Spent',
    'info.notification.timeRange': 'Time Range',
    'info.notification.condition': 'Condition',
    'info.notification.conditionPerson':
      'Notification is not sent when the visitor count detected in less than the rule is 0.',
    'info.word.and': 'and',
    'info.status': 'Status',
    'info.statusOn': 'Active',
    'info.statusOff': 'Deactive',
    'info.statusOnOff': 'Active / Deactive',
    'info.cameraAndArea': 'Camera / Area',
    'info.archivedDate': 'Archived Date',
    'info.toCamera': 'Go To Camera',
    'info.toSetting': 'Go To Settings',
    'info.Entrance': 'Entrance',
    'info.Checkout': 'Checkout',

    'info.department': 'Department',
    'info.newDepartment': 'New Department',
    'info.selectDepertment': 'Select Department',
    'info.newDepartmentType.label': 'Department Type',
    'info.newDepartmentType.shelf': 'Shelf',
    'info.newDepartmentType.checkOutWaitingArea': 'Checkout Waiting Area',
    'info.newDepartmentType.customerServices': 'Customer Services',
    'info.newDepartmentType.correlation': 'Correlation',
    'info.newDepartmentType.checkOut': 'Checkout',
    'info.newDepartmentType.ignore': 'Ignore',
    'info.newDepartmentType.doorIn': 'Door In',
    'info.newDepartmentType.doorOut': 'Door Out',
    'info.newDepartmentType.doorLeft': 'Door Left',
    'info.newDepartmentType.doorRight': 'Door Right',
    'info.newDepartmentType.AreaOfInterest': 'Detection Area',
    'info.newDepartmentType.YellowLineViolation': 'Yellow Line Violation',
    'info.newDepartmentType.firstStand': 'First Stand',
    'info.rectName.placeholder': 'Rectangle Name',
    'info.rectName.label': 'Rectangle Name',
    'info.uploadCategoryFile': 'Upload Category File',
    'info.uploadCategory':
      'File extension must be .csv and columns are category code, category name respectively with no header row.',
    'info.Success': 'Success',
    'info.areas': 'Areas',
    'info.area': 'Area',
    'info.total': 'Total',
    'info.totalCount': 'Total Count',
    'info.change': 'Change',
    'info.amount': 'Daily Amount',
    'info.prevAmount': 'Previous Total',
    'info.metersquare': 'Metersquare',
    'info.metersquarerate': 'Metersquare Rate',
    'info.endorsement': 'Sales Amount',
    'info.endorsement.detail':
      "It is calculated with the formula 'Number of Receipts/Number of Customers' if receipt information is shared in the sales integration, and 'Number of Sales/Number of Customers' if the receipt information is not shared.",
    'info.traffic.detail':
      'Store traffic information is calculated according to working hours.',
    'info.traffic.detailThisWeek':
      'Traffic information is calculated according to the selected time range.',
    'info.trafficTable.detail':
      'It is calculated based on the day the page was viewed.',
    'info.sales.detail':
      'Sales and conversion rate data are calculated based on the filtered date.',
    'info.table.detail': 'Calculated based on the date filtered.',
    'info.sales': 'Sales Quantity',
    'info.mean.time': 'Mean Time',
    'info.startTimeEqualsEndTimeWarning':
      'Start time and end time can not be the same!',
    'info.openingTimeEqualsClosingTimeWarning':
      'Opening time and closing time can not be the same!',
    'info.closeTimeAfterMidnightWarning': 'Closing time is after midnight!',
    'info.endTimeAfterMidnightWarning': 'End time is after midnight!',
    'info.openTime': 'Opening',
    'info.closeTime': 'Closing',
    'info.stimeHour': 'Start',
    'info.etimeHour': 'End',
    'info.stime': 'Start Date',
    'info.etime': 'End Date',
    'info.excel.hour': 'Hour',
    'info.chart': 'Chart',
    'info.table': 'Table',
    'info.department.sales': 'Sales',
    'info.comparisonv2.percent': 'Percent',
    'info.rectangle.archive.accept':
      'Are you sure you want to archive the region ?',
    'info.rectangle.archive.undone': 'This action cannot be undone',
    'info.rectangle.arhive.reminding':
      'The data of the field after this date will be deleted!',
    'info.line.arhive.reminding':
      'The data of the line after this date will be deleted!',
    'info.performancetable.ShopInShopFrontCount':
      'Number of Customers Passing By the Shop-in-Shop.',
    'info.performancetable.ShopInShopEntranceCount':
      'Number of Customers Entering Shop-in-Shop.',
    'info.performancetable.ShopInShopConversionRatio':
      'Number of Shop-in-Shop Sales / Number of Shop-in-Shop Visitors.',
    'info.performancetable.ShopInShopFrontCountChange':
      'Number of Customers Passing By the Shop-in-Shop Change Ratio.',
    'info.performancetable.ShopInShopEntranceCountChange':
      'Number of Customers Entering Shop-in-Shop Change Ratio.',
    'info.performancetable.ShopInShopConversionRatioChange':
      'Number of Shop-in-Shop Sales / Number of Shop-in-Shop Visitors Change.',
    'info.performancetable.change': 'According to the previous date range.',
    'info.performancetable.visitor15sec':
      'The ratio of the customer who spends an average of 15 seconds in the departments to the total customer.',
    'info.performancetable.sale15sec':
      'Sale quantity divided by over 15 seconds customer count .',
    //'info.performancetable.saleamountoverdensity': 'Shows distribution of categories, the sum is 100.',
    'info.Department.Remove':
      'Are you sure you want to delete the selected categories , sub categories and campaigns to which subcategories are linked ?',
    'info.SubDepartment.Remove':
      'Are you sure you want to delete the selected subcategories and the campaigns linked to these categories ?',
    'info.RemoveCategories': 'Delete selected categories',
    'info.Date.Previous': 'Comparison  Date',
    'info.Date.Current': 'Current Date',
    'info.process.date': 'Calculation Start Date of Historical Data',
    'info.notification.delete': ' Notification Rule will be deleted.',
    'info.rotate': 'Rotate',
    'info.limit': 'Limit',
    'info.Exceeded': 'Exceeded',
    'info.exceeded.provided': 'Exceeded Provided',
    'info.SeeAll': 'See All',
    'info.CloseAll': 'Close All',
    'info.group.name': 'Group Name',
    'info.sub.Category.group.name': 'Sub Category Group Name',
    'info.sub.Category.edit': 'Sub Category Edit',
    'info.sub.Category.group': 'Sub Category Group',
    'info.All': 'All',
    'info.title.storeInOut': 'Store In-Out',
    'info.title.storeAvailabilityTime': 'Availability Time In Store',
    'info.title.storeDetailInOut': 'Hour Of In-Out',
    'info.InterestRate': 'Interest Rate',
    'info.Location': 'Location',
    'landingPage.description1': 'Select a product to see details',
    'landingPage.description2': 'See the details by hovering into categories',
    'products.ANALYTIC': 'Instore Analytics',
    'products.ENTRANCE': 'People Counting',
    'products.CHECKOUT': 'Checkout',
    'products.PERSONNEL': 'Staff',
    'products.AGE_GENDER': 'Age Gender',
    'products.categories.BRAND': 'Company',
    'products.categories.STORE': 'Location',
    'products.categories.DENSITY_MAP': 'Density Map',
    'products.categories.PERFORMANCE_TABLE': 'Performance Table',
    'products.categories.TRENDS': 'Trends',
    'products.categories.SUGGESTIONS': 'Suggestions',
    'products.categories.CHECKOUT': 'Checkout',
    'products.categories.PERSONNEL': 'Personnel',
    'products.categories.CAMPAIGN': 'Campaign',
    'products.categories.CAMERA': 'Cameras',
    'products.categories.RECEIVER': 'Receiver',
    'products.categories.AGE_GENDER': 'Age Gender',
    'products.categories.STORE_COMPARISON': 'Store Comparison',
    'products.categories.CATEGORY_COMPARISON': 'Category Comparison',
    'landingPage.searchPlaceholder':
      'Please enter a keyword (Ex: People Counting)',
    'landingPage.backToProducts': 'Back To Products',
    'products.categories.description.DENSITY_MAP':
      '1) Analyze the density, visitor count, mean time and interest rate data on the sketch -/&/- 2) Compare area data with square meter and (if applicable) sales data',
    'products.categories.description.PERFORMANCE_TABLE':
      '1) Analyze the density, visitor count, mean time and interest rate data on the table -/&/- 2) Compare metrics between two dates',
    'products.categories.description.TRENDS':
      '1) Examine the consecutive uptrend or downtrend of the areas',
    'products.categories.description.SUGGESTIONS':
      '1) Display computer-assisted automatic suggestions for areas',
    'products.categories.description.CHECKOUT':
      '1) View checkout data for a store -/&/- 2) Compare data by location and date',
    'products.categories.description.CAMERA':
      '1) Examine the heat map and areas on camera snapshots',
    'products.categories.description.RECEIVER':
      '1) Examine staff-area density relation',
    'products.categories.description.PERSONNEL': '1) Analyze staff efficiency',
    'products.categories.description.BRAND':
      '1) View the data of all locations in the system',
    'products.categories.description.STORE':
      '1) View visitor count data for a store -/&/- 2) View checkout data for a store -/&/- 3) View areas with the best metrics',
    'products.categories.description.AGE_GENDER': '1) View store demography',
    'products.categories.description.STORE_COMPARISON':
      '1) Comparing area metrics of two different locations',
    'products.categories.description.CATEGORY_COMPARISON':
      '1) Compare categories of many locations',
    'products.noSelectedStoreProduct':
      'You need to select a store in order to see your products.',
    'brand.generalGraph.VISITOR_COUNT': 'Traffic',
    'brand.generalGraph.ENDORSEMENT': 'Sales Amount',
    'brand.generalGraph.CONVERSION_RATE': 'Conversion Rate',
    'brand.generalGraph.RECEIPT_COUNT': 'Receipt Count',
    'brand.generalGraph.SALE_COUNT': 'Sales Quantity',
    'brand.generalGraph.ENDORSEMENT_PER_M2': 'Sales Amount/M2',
    'brand.generalGraph.ENTRANCE_RATE': 'Total Customers Rate',
    'info.newSketch.alert':
      'This feature only; to add a different floor in the same store. Use the Update Floor Plan button to update the store.',
    'info.showValues': 'Display Values',

    'routes.uploadReports': 'Upload Reports',
    'routes.floors': 'Floors',
    'routes.reports': 'Reports',
    'routes.ageGender': 'Age<br/>Gender',
    'routes.employeeMonitoring': 'Employee<br/>Monitoring',
    'routes.settings': 'Settings',
    'routes.comparison': 'Location Comparison',
    'routes.sisComparison': 'Shop-in-Shop Comparison',
    'routes.sketchDeparment': 'Density Map',
    'routes.charts': 'Charts',
    'routes.heatmap': 'Heat Map',
    'routes.store': 'Location',
    'routes.company': 'Company',
    'routes.ageGenderComp': 'Age & Gender Comparison',
    'routes.Iframe': 'Custom Reporting',
    'routes.comparisonV2': 'Category Comparison',
    'routes.performance.table': 'Performance Table',
    'routes.Company': 'Company',
    'routes.Suggestion.performanceChange': 'Performance Change',
    'routes.Suggestion.performanceAnalysis': 'Performance Analysis',
    'routes.Suggestion.Request': 'Suggestion Request',
    'routes.personnel': 'Personnel',
    'routes.personnelv2': 'PersonnelV2',
    'routes.trends': 'Trends',
    'routes.analysis': 'Analysis',
    'routes.analysis.request': 'Analysis Request',
    'global.change.request': 'Change Request',
    'routes.heatmap.detail': 'Heat Map Detail',
    'routes.FAQ': 'F.A.Q.',
    'routes.checkout': 'Checkout',
    'routes.HomePage': 'Homepage',
    'global.camera': 'Camera',
    'global.checkoutSummary': 'Checkout Summary',
    'global.regionlessStores': 'Regionless Stores',
    'global.somethingWentWrong': 'Something went wrong.',
    'resetPassword.goToLoginPage': 'Go To Login Page',
    'global.cameras': 'Cameras',
    'global.area': 'Area',
    'global.notifications': 'Notifications',
    'global.add': 'Add',
    'global.edit': 'Edit',
    'global.cancel': 'Cancel',
    'global.save': 'Save',
    'global.remove': 'Remove',
    'global.delete': 'Delete',
    'global.notificationsv2': 'Notifications V2',
    'global.basicNotifications': 'Basic Notifications',
    'global.detailedNotifications': 'Detailed Notifications',
    'global.left': 'Left',
    'global.right': 'Right',
    'global.middle': 'Middle',
    'notificationsV2.startNotificationPrompt':
      'Are you sure that you want to start getting notifications?',
    'notificationsV2.stopNotificationPrompt':
      'Are you sure that you want to stop getting notifications?',
    'notificationsV2.TimeoutThreshold': 'Timeout Threshold (sec)',
    'notificationsV2.viewComplexRule': 'View Complex Rule',
    'notificationsV2.createComplexRule': 'Create Complex Rule',
    'notificationsV2.inactiveChannels':
      'Channels That Are Deactivated By The User',
    'notificationsV2.UserIds': 'Users',
    'notificationsV2.trTemplate': 'Turkish Template',
    'notificationsV2.enTemplate': 'English Template',
    'notificationsV2.RuleTree': 'Rule Tree',
    'notificationsV2.ruleTreeTitle': 'Rule Tree Editor',
    'notificationsV2.channels': 'Channels',
    'notificationsV2.Mail': 'Mail',
    'notificationsV2.Mobile': 'Mobile',
    'notificationsV2.Web': 'Web',
    'notificationsV2.Name': 'Name',
    'notificationsV2.Description': 'Description',
    'notificationsV2.Days': 'Days',
    'notificationsV2.TimeRange': 'TimeRange',
    'notificationsV2.MaxSendFrequencySec': 'Max Send Frequency (sec)',
    'notificationsV2.DataSourceType': 'Data Source Type',
    'notificationsV2.Line': 'Line',
    'notificationsV2.TimeScopeNumber': 'Time Scope Number',
    'notificationsV2.TimeScopePeriod': 'Time Scope Period',
    'notificationsV2.Polygon': 'Polygon',
    'notificationsV2.ObjectType': 'Object',
    'notificationsV2.ComparisonOperator': 'Comparison Operator',
    'notificationsV2.Threshold': 'Threshold',
    'notificationsV2.EqualTo': 'Equal To',
    'notificationsV2.LessThanOrEqual': 'Less Than Or Equal',
    'notificationsV2.GreaterThanOrEqual': 'Greater Than Or Equal',
    'notificationsV2.LessThan': 'Less Than',
    'notificationsV2.GreaterThan': 'Greater Than',
    'notificationsV2.ObjectCount': 'Number Of People Entering',
    'notificationsV2.TimeUnit': 'Time Unit',
    'notificationsV2.linePlaceholder': 'Please select a line...',
    'notificationsV2.polygonPlaceholder': 'Please select a polygon...',
    'notificationsV2.comparisonOperatorPlaceholder':
      'Please select a comparison operator...',
    'notificationsV2.objectPlaceholder': 'Please select an object...',
    'notificationsV2.daysPlaceholder': 'Please select days...',
    'notificationsV2.Second': 'Second',
    'notificationsV2.Minute': 'Minute',
    'notificationsV2.Hour': 'Hour',
    'notificationsV2.Day': 'Day',
    'notificationsV2.Week': 'Week',
    'notificationsV2.Month': 'Month',
    'notificationsV2.Year': 'Year',
    'notificationsV2.Sunday': 'Sunday',
    'notificationsV2.Monday': 'Monday',
    'notificationsV2.Tuesday': 'Tuesday',
    'notificationsV2.Wednesday': 'Wednesday',
    'notificationsV2.Thursday': 'Thursday',
    'notificationsV2.Friday': 'Friday',
    'notificationsV2.Saturday': 'Saturday',
    'notificationsV2.removeRule': 'Remove Rule',
    'notificationsV2.addNewRule': 'Add Rule',
    'notificationsV2.LineNegativeDirection': 'Out',
    'notificationsV2.LinePositiveDirection': 'In',
    'notificationsV2.PolygonDwellTime': 'Dwell Time',
    'notificationsV2.PolygonRepeatedEntrancesCount': 'Entrance Count',
    'notificationsV2.PolygonNonRepeatedEntrancesCount': 'People Count',
    'global.actionButtons': 'Action Buttons',
    'global.back': 'Back',
    'global.next': 'Next',
    'global.submit': 'Submit',
    'global.requiredField': 'This field is required.',
    'global.minLengthError': 'This field needs at least {value} entry.',
    'global.occupancy': 'Occupancy',
    'global.sketch': 'Sketch',
    'global.SaleUploading': 'Sales Uploading',
    'global.CameraSnapshot': 'Camera Snapshot',
    'global.map': 'Map',
    'global.category': 'Category',
    'global.subcategory': 'Sub Category',
    'global.categories': 'Categories',
    'global.departments': 'Departments',
    'global.sale': 'Sale',
    'global.sale.m2': 'Sale(Amount)/M2',
    'global.shelf': 'Shelf',
    'global.WorkingHours': 'Working Hours',
    'global.WorkingHoursWarningMessage': 'Working hours are unset',
    'global.StoreOpen-Close': 'Store Open - Close Hours',
    'global.WorkHoursOpen': 'Open',
    'global.Save': 'Save',
    'global.Search': 'Search',
    'global.logout': 'Logout',
    'global.summarypage': 'Summary ',
    'global.brandPerformanceTable': 'Performance Table',
    'global.Forecast': 'Forecast',
    'global.selectStore': 'You need to select a store.',
    'global.region': 'Region',
    'treeSelect.inlineSearchPlaceholder': 'Search...',
    'treeSelect.noMatches': 'No matches found.',
    'treeSelect.placeholder': 'Choose...',

    'excel.header.averagePerson': 'Average Customer',

    'chart.title.customerDwell': 'Customer Number & Dwell Time',
    'chart.title.mostVisisted': 'Most Visited Areas (number of people)',
    'chart.title.mostTimeSpent': 'Most Time Spent Areas (person sec)',
    'chart.title.conversionRate': 'Conversion Rate',
    'chart.title.averageConversionRate': 'Average Conversion Rate',
    'chart.title.invoices': 'Invoices Quantity',
    'chart.title.averageInvoices': 'Average Invoices Product Quantity',
    'chart.title.customerNumber': 'Customer Number Chart',
    'chart.title.dwellTime': 'Dwell Time Chart',
    'chart.title.departmentAnalysis': 'Department Analysis',
    'chart.title.funnelChart': 'Acquisition Funnel',
    'chart.title.correlations': 'Correlations',
    'chart.title.notifications': 'Notifications',
    'chart.title.weatherAndTraffic': 'Weather And Traffic',
    'chart.title.trafficAndSales': 'Traffic and sales graph',
    'chart.title.conversionRateMarket': 'Conversion Rate (Market Traffic)',
    'chart.title.conversionRateVehicle':
      'Conversion Rate (Total Vehicle Traffic)',
    'chart.title.vehicleNumberAndMarket':
      'Number of Vehicles and Market Traffic',
    'chart.title.customerNumberTable': 'Customer Count Change',
    'chart.title.customerTimeTable': 'Customer Time Change',
    'chart.title.customerDirection': 'Customer Direction In Store',
    'chart.title.totalCustomer': 'Total Customer',
    'chart.title.genderDistr': 'Gender Distribution',
    'chart.title.ageDistr': 'Age Distribution',
    'chart.legend.customer': 'Customer',
    'chart.legend.timeSpent': 'Time Spent',
    'chart.legend.date': 'Date',
    'chart.legend.density': 'Density',
    'chart.legend.density/m2': 'Density/M2',
    'chart.legend.m2': 'Square Meters',
    'chart.legend.customerCount': 'Traffic',
    'chart.legend.dwellTime': 'Dwell Time',
    'chart.legend.productCount': 'Quantity of Product',
    'chart.legend.totalAmount': 'Total Amount',
    'chart.legend.weather': 'Weather',
    'chart.filter.hourly': 'Hourly',
    'chart.filter.daily': 'Daily',
    'chart.info.densityMap':
      'Gives customer density of each section according to the selected time interval.',
    'chart.button.get': 'Get',
    'chart.button.select': 'Select All',
    'chart.button.clear': 'Clear',
    'chart.color.scale': 'Performance Scale',
    'chart.sale.quantity': 'Sale (Quantity)',
    'chart.sale.amount': 'Sale (Amount)',
    'chart.sale.quantityheader': 'Sale Quantity',
    'chart.sale.amountheader': 'Sale Amount',
    'chart.sale.amountheaderCamelCase': 'Sale Amount',
    'chart.entrance': 'Enterance',
    'chart.PassByCount.ToLeft': 'Right To Left ',
    'chart.PassByCount.ToRight': 'Left To Right ',
    'chart.passByCount.header': 'Store Pass By Count',
    'chart.passByCustomerCount.header': 'Store Pass By Customer Count',
    'chart.tooltip.daily': ' daily ',
    'chart.tooltip.status.increase': ' increase  ',
    'chart.tooltip.status.decrease': ' decrease  ',
    'chart.tooltip.observed': ' is observed.',
    'chart.tooltip.time': 'Time',
    'chart.tooltip.time.lowercase': 'mean time',
    'chart.tooltip.person': 'visitor',
    'chart.line.in': 'In',
    'chart.line.out': 'Out',
    'chart.Top': 'Top',
    'chart.Bottom': 'Bottom',
    'chart.CustomerCount':
      'Number of Entries and Average Time Spent in the Area',
    'chart.allCategories': 'All Categories',
    'chart.subcategory': 'Sub Categories',
    'chart.categories': 'Categories',
    'chart.totalCustomers': 'Total Customers',
    'chart.totalCustomersRate': 'Total Customers Rate',
    'performanceTable.tooltip.hasSuggestion':
      'Suggestions are available. Click to view.',

    'chart.dwellTime': 'Dwell Time',
    'Trends.Title': 'Trends',
    'Trends.Title.description':
      'It shows the upward and downward trends in your existing areas.',
    'Trends.sketch.description':
      'It shows your ongoing trends on the selected end date.',

    'Trends.sketch': 'Trend Map',

    'chart.over15sec': 'Number of customers exceeding 15 seconds',
    'chart.over30sec': 'Number of customers exceeding 30 seconds',
    'chart.over60sec': 'Number of customers exceeding 60 seconds',
    'chart.over90sec': 'Number of customers exceeding 90 seconds',
    'chart.over120sec': 'Number of customers exceeding 2 minutes',
    'chart.over180sec': 'Number of customers exceeding 3 minutes',
    'chart.dwell15sec': 'Dwell time over 15 seconds',
    'chart.dwell60sec': 'Dwell time over 60 seconds',
    'chart.dwell120sec': 'Dwell time over 2 minutes',

    'chart.Over15SecDwell': 'Dwell time over 15 seconds (sec)',
    'chart.TotalCount': 'Total Count',
    'chart.AvgDwell': 'Average Dwell(sec)',
    'chart.prevWeek': 'Previous Week',
    'chart.currWeek': 'Current Week',
    'Trends.Suggestion.Info1': 'You have suggestions for ',
    'Trends.Suggestion.Info2': ' categories',
    'trends.time': 'Trend Time: ',
    'trends.reason': 'Reason: ',
    'trends.sale': 'Sale',
    'trends.density': 'Density',
    'trends.interest': 'Interest',
    'trends.visitor': 'Visitor',
    'trends.meantime': 'Mean Time',

    'trends.chartMode': 'Chart mode',
    'trends.mapMode': 'Map mode',
    'trends.daily': 'Daily',
    'trends.weekly': 'Weekly',
    'trends.monthly': 'Monthly',
    'trends.increase': 'Increase',
    'trends.decrease': 'Decrease',
    'trends.tooltip.increaseing.daily': 'Increasing For',
    'trends.tooltip.decreaseing.daily': 'Decreasing For',
    'trends.tooltip.increase.daily': 'Days',
    'trends.tooltip.decrease.daily': 'Days',

    'trends.tooltip.increaseing.weekly': 'Increasing For',
    'trends.tooltip.decreaseing.weekly': 'Decreasing For',
    'trends.tooltip.increase.weekly': 'Weeks',
    'trends.tooltip.decrease.weekly': 'Weeks',
    'trends.tooltip.increaseing.monthly': 'Increasing For',
    'trends.tooltip.decreaseing.monthly': 'Decreasing For',
    'trends.tooltip.increase.monthly': 'Months',
    'trends.tooltip.decrease.monthly': 'Months',

    'trends.excel.daily.increase': 'Daily Increase',
    'trends.excel.weekly.increase': 'Weekly Increase',
    'trends.excel.monthly.increase': 'Monthly Increase',
    'trends.excel.daily.decrease': 'Daily Decrease',
    'trends.excel.weekly.decrease': 'Weekly Decrease',
    'trends.excel.monthly.decrease': 'Monthly Decrease',

    'info.department.over': 'over',
    'info.storecomparison.percentage': 'percentage',
    'info.SaleUploading.extension': 'File extension must be ".xlsx"',
    'info.SaleUploading.Overlay.Sure':
      'Are you sure you want to send the data?',
    'info.SaleUploading.undone': 'This action cannot be undone!',
    'info.Category.File.Upload': 'Upload category file',
    'info.Sketch.Photo.Update':
      'You may not be able to see some cameras and drawings if the image sizes are different.',
    'info.Sale.Ratio': 'Sale Ratio',
    'info.Camera.Exit':
      'You did not save the changes, do you still want to exit ?',
    'info.Camera.Change':
      'You did not save the changes, do you still want to switch cameras?',
    'info.PrivacyPolicy': 'Our Privacy Policy has been updated, ',
    'info.PrivacyPolicy.Link': 'click to view.',

    'error.SaleUploading.SampleExcel': 'Sample Excel',
    'error.SaleUploading.DateFormat': 'Check the date format!',
    'error.SalexUploading.ColumnType': ' Column type error!',
    'error.SaleUploading.fileFormat': 'Send the file in the desired format!',
    'error.SaleUploading.ChangeText': ' Change text to correct.',
    'error.SalesUploading.AddHeader':
      'Header must be added as well as sample excel!',
    'error.SalesUploading.EmptyCell': 'Empty cells will remove! ',
    'error.SalesUploading.EmptycellCount': ' empty row found!',
    'error.Mark.Cam.ToSketch':
      'Mark the camera in sketch to see density ratio.',
    'error.noData': 'No data available.',
    'error.invalidEmail': 'Invalid Email!',
    'error.PolygonCornerDelete': 'A polygon must contain at least 3 corner.',
    'error.notAvailable': 'Data not available!',
    'button.info.showAreas': 'Show Areas',
    'button.info.showCameras': 'Show Cameras',
    'button.info.showPlan': 'Show Plan',
    'button.info.changeGroup': 'Change Group',
    'button.info.setCameraLocation': 'Set Camera Location',
    'button.info.PrivacyPolicy': 'I Agree',
    'button.info.showSuggestions': 'Show Suggestions',
    'button.info.setBoard': 'Set Board',
    'button.info.setReceiver': 'Set Receiver',
    'button.info.Board': 'Board',
    'button.info.Receiver': 'Receiver',
    'personnelv2.AllReceivers': 'All Receivers',
    'button.info.ReceiverType': 'Receiver Type',
    'button.info.goBack': 'Go Back',
    'button.unconnectedAreas': 'Unconnected Areas',
    'button.oldSketches': 'Show Old Sketches',

    'reports.allReports': 'All Reports',
    'reports.startDate': 'Start Date',
    'reports.endDate': 'End Date',
    'reports.reportName': 'Report Name',
    'reports.dateRange': 'Date Range',
    'reports.downloadReport': 'Download Report',
    'reports.deleteReport': 'Delete Report',
    'reports.pleaseSelectReport': 'Please select a report file',
    'reports.selectReport': 'Select Report',
    'reports.uploadReport': 'Upload Report',

    'Date.Today': 'Today',
    'Date.Yesterday': 'Yesterday',
    'Date.ThisWeek': 'This Week',
    'Date.LastWeek': 'Last Week',
    'Date.ThisMonth': 'This Month',
    'Date.LastMonth': 'Last Month',
    'Date.Last15Days': 'Last 15 Days',
    'Date.Last30Days': 'Last 30 Days',
    'Date.DaysUpToToday': 'Days up to today',
    'Date.DaysStartingToday': 'Days starting today',
    'Date.day': ' Day ',
    'Date.weekly': 'Weekly',
    'Date.weekdays': 'Weekdays',
    'Date.weekend': 'Weekend',
    'Date.week': 'Week',
    'Date.month': 'Month',

    'Tour.Info.home.Navigation':
      'Navigate through application by clicking on tabs.',
    'Tour.Info.home.SelectStore': 'Select a store.',
    'Tour.Info.home.SelectDate': 'Select a date range.',
    'Tour.Info.home.SelectDaily':
      'Select whether to display the data in days or hours.',
    'Tour.Info.home.SelectLang': 'Choose website language.',
    'Tour.Info.home.ShowProfile': 'View your profile or log out.',
    'Tour.Info.home.CustomerDwellChart':
      'Displays number of daily visitors and average time spent in the store according to the selected date and time interval.',
    'Tour.Info.home.WeekendColorizerChart':
      'Orange colors are expressed on weekends.',
    'Tour.Info.home.ChartButtons':
      'Examine Customer-Time Spent or Density graph. Download the data used in the graph as Excel file.',
    'Tour.Info.home.MostVisitedTable':
      'Areas that have the largest visitor attraction.',
    'Tour.Info.home.DwellTable': 'Areas that visitors spent time the most.',
    'Tour.Info.home.ConversionRate': 'Returns the conversion rate of density.',
    'Tour.Info.home.TourInfoButton':
      'You can click the button for information about the page.',

    'Tour.Info.company.Summary':
      'Gives a summary of company performance by showing each stores metrics. ',
    'Tour.Info.company.SummaryChart':
      'Click on an arc too see categorical performances on related metric .',

    'Tour.Info.area.densityArea':
      'Shows the field-based distribution of Density data in the store.',
    'Tour.Info.area.quantityArea':
      'Shows the field-based distribution of Sales (Number of Products) data.',
    'Tour.Info.area.amountArea':
      'Shows the field-based distribution of Sales (Total Amount) data.',
    'Tour.Info.area.SketchRect':
      'Move your cursor over an area to examine the density ratio, the m2 ratio, and the density / m2 ratio.',
    'Tour.Info.area.areaHamburgerMenu': 'Show and hide submenu.',
    'Tour.Info.area.camSketch': 'Select your sketch.',
    'Tour.Info.area.CamInfo': 'Show cameras on the plan.',
    'Tour.Info.area.CamHoverInfo':
      'Examine the heat map by clicking on cameras.',
    'Tour.Info.area.CamShowPlan': 'Hide the sections on the sketch.',
    'Tour.Info.area.searchSketch': 'Search your stores.',
    'Tour.Info.area.TopFiveTable':
      'Shows five areas with lowest and highest density  percentages within the selected date range.',

    'Tour.Info.heatmap.Info':
      'Monitoring visitor density distribution through cameras.',
    'Tour.Info.heatmap.showAreas': 'You can click to see areas.',
    'Tour.Info.heatmap.rectShowInfo':
      'Click on the blue areas for further information.',

    'Tour.Info.department.information':
      'It provides detailed information on the density and sales data of the selected area.',
    'Tour.Info.department.densityChart':
      'Shows the daily density and the time spent in the area within specified hours.',
    'Tour.Info.department.downloadExcel':
      'Download the gathered data within selected time interval.',
    'Tour.Info.department.goToCam':
      'Examine the density of the area on the heat map.',
    'Tour.Info.department.SalesChart1':
      'Click on the table to review the category-based sale.',
    'Tour.Info.department.SalesChart2':
      'Displays the daily sales amount and the number of sales in the specified time interval.',
    'Tour.Info.department.correlations':
      'Shows how the visitors circulate in areas.',
    'Tour.Info.department.notification':
      'Shows the push notification distribution sent to the store management according to the parameters that determined in settings.',

    'Tour.Info.PerformanceTable.Table':
      'Allows examination of the category data in the store, and also shows percentage changes according to previous date range.',
    'Tour.Info.PerformanceTable.scale':
      'Performance scale that shows most productive changes as blue and least productive as pink.',

    'Tour.Info.comparison.begin':
      'Shows the performance comparison of stores within the specified date range.',
    'Tour.Info.comparison.select': 'Select a location',
    'Tour.Info.comparison.date': 'Select the time interval ',
    'Tour.Info.comparison.compareStore': 'Select a location to compare',
    'Tour.Info.comparison.compareDate': 'Select the time interval to compare',
    'Tour.Info.comparison.sketchInfo':
      'You can compare the sections in the same department by hovering over the sections in the selected sketch.',
    'Tour.Info.comparison.clickRect':
      'Examine the detail by clicking on Reyona.',
    'Tour.Info.comparison.customerCountTable':
      'Represents areas customer attraction changes compared to previous date range',
    'Tour.Info.comparison.customerCountChart':
      'Represents the total customer number distributions of the areas within specific date range.',
    'Tour.Info.comparison.customerDwellTable':
      'Represents customer average time change fields based on the previous date range.',
    'Tour.Info.comparison.customerDwellChart':
      'Represents the total dwell time distributions of the areas within the specific date range.',

    'Tour.Info.comparisonV2.begin':
      'Allows you to compare data by store and category.',
    'Tour.Info.comparisonV2.beginUsage1':
      'Select the location. Choose a single location, compare categories, select multiple, and examine the performance across stores.',
    'Tour.Info.comparisonV2.beginUsage2':
      'Select your categories. Review the sales performance of the categories of the stores you choose.',
    'Tour.Info.comparisonV2.beginUsage3': 'Select the data source or sources.',
    'Tour.Info.comparisonV2.beginUsage4': "Click on 'Get' to see the results.",

    'Tour.Info.comparisonV2.changeChart':
      'View the chart on a store or category basis.',
    'Tour.Info.comparisonV2.selectStore':
      'Select the location. Either choose a single location and compare categories, or select multiple, and examine the performance across stores.',
    'Tour.Info.comparisonV2.selectCategory': 'Select category(s) to compare.',
    'Tour.Info.comparisonV2.SelectDataSource':
      'Select the data group you want to fetch',
    'Tour.Info.comparisonV2.showChart': 'Click to see the chart.',
    'Tour.Info.comparisonV2.details':
      'You can access detailed information using the bar below.',

    'Tour.Info.settings.camera.begin':
      'You can organize the cameras that the store sees by choosing camera.',

    'Tour.Info.settings.camera.camDetails':
      'Use the mouse to draw the area from where you want to gather data.',
    'Tour.Info.settings.camera.camDetails2':
      'Select the area you want to view.Adjust the position and the size of the area.',
    'Tour.Info.settings.camera.camDetails3':
      'Right-click on the area you want to change. Click archive to store the area, or click  Edit to change area’s details',
    'Tour.Info.settings.camera.submitButton':
      'Click the button under the camera to save your changes.',
    'Tour.Info.settings.camera.Category':
      'Transfer the sales data of your store to the system by matching the categories displayed with the modeled areas.',

    'Tour.Info.settings.notification.info':
      'Specify the notification threshold based on areas or cameras.',
    'Tour.Info.settings.notification.radiobut':
      'Select area if you want to designate notifications based on areas or select camera  if you wish to set the notification based on camera that contains multiple areas.',
    'Tour.Info.settings.notification.dropdown':
      'Click the operator you want to adjust.',
    'Tour.Info.settings.notification.goBack':
      'Press the back button if you want to make changes.',
    'Tour.Info.settings.notification.threshold':
      'Select threshold orientation.',
    'Tour.Info.settings.notification.setThreshhold':
      'Specify the notification threshold based on the number of visitors or the time spent in the region.',
    'Tour.Info.settings.notification.save':
      'Select the time interval for which the notification stays active and click the save button.',
    'Tour.Info.settings.notification.delete':
      'Click to delete the preset notification thresholds.',
    'Tour.Info.settings.notification.arrow': 'Click the arrow to countinue',

    'Tour.Info.setting.departments.newCategory':
      'Add a category that matches with areas displayed in store',

    'Tour.Info.settings.salesUploading.begin':
      'Upload your sales data which is prepared according to sample excel format and analyze store’s sales numbers and density all together.',

    'Tour.Info.settings.sketch.selectSketch': 'Select a sketch.',
    'Tour.Info.settings.sketch.changeSketch':
      'Replace the plan in the system with a plan on your computer.',
    'Tour.Info.settings.sketch.setCam':
      'Mark where the cameras are installed in the store.',
    'Tour.Info.settings.sketch.newSketch':
      'Add a new store plan from your computer.',
    'Tour.Info.settings.sketch.drawRect':
      'Draw the area on store plan matching to area you have already drew on camera settings.',

    'Tour.Info.settings.workingHours.begin':
      'Specify the time interval of the selected store and click the save button.',

    'Tour.Info.BrandPage.BrandPieCharts':
      'Gives a summary of company performance by showing each store’s metrics.',
    'Tour.Info.BrandPage.PieChart':
      'Click on an arc to see categorical performances on related metric.',
    'Tour.Info.BrandPage.BarChart.Excel.Info':
      'Download category data in XLSX format.',
    'Tour.Info.BrandPage.BarCharts':
      'Shows categorical weight for related store.',
    'Tour.Info.BrandPage.BarChart':
      'Click on a bar to see related sub-category data.',
    'Tour.Info.BrandPage.Sub.BarChart.Excel.Info':
      'Download sub-category data in XLSX format.',

    'Total.Customer.Chart': 'Total Customer ',
    'Total.Quantity.Chart': 'Total Sales Quantity : ',
    'Total.TotalAmount.Chart': 'Total Amount : ',
    'Total.Table.Value': 'Total',
    'Total.Summary.Information': 'Summary Information ',
    'Total.Customer.Average': 'Total Customer Average : ',
    'Total.Time.Average': 'Total Time Average : ',
    'Total.Weekday.Customer.Average': 'Weekday Customer Average : ',
    'Total.Weekday.Time.Average': 'Weekday Time Average : ',
    'Total.Weekends.Customer.Average': 'Weekend Customer Average : ',
    'Total.Weekends.Time.Average': 'Weekend Time Average : ',
    'Total.Density.Average': 'Total Density Average : ',
    'Density.Avarage': 'Density Avarage',
    'Total.SQuantity': 'Total Sales',
    'Total.SaleAmount': 'Total Sales Amount',
    'Total.Entrance': 'Total Number of  Entering',
    'Total.EntranceAverega': 'Total Number of  Entering Average',
    'Total.WeekdayEntrance': 'Weekday Average Customer Entering',
    'Total.WeekenddayEntrance': 'Weekend Average Customer Entering',
    'Total.WeekenddayEntranceTotal': 'Weekend Total Customer Entering',

    'info.mean.interestRate': 'Interest Rate',
    'info.mean.conversionRate': 'Sales Conversion Rate',
    'info.15s': '15s/Enterance',
    'info.storeShare': 'Instore',
    'info.storeShareChange': 'Instore Change',
    'info.storeChange': 'Visitor*Time Change',
    'info.dailyAvarage': 'Daily Avarage',
    'info.dailyquantity': 'Daily Quantity',
    'info.peakDensityHour': 'Peak Density Hour',
    'info.instore':
      'The density ratio of the area in the store. (Density = (Average Number of Customers * Average Time Spent) / Total Number of Customers * Total Time), The Ratio in the Density Map',
    'info.instore.change':
      'The change in the density ratio of the area in the store.(Density ratio of the selected 2nd date interval - Density ratio of the selected 1st date interval)',
    'info.instorechange':
      'The change in the density of the area based on the change in average visitor count and mean time.(Person * Time Change = (Average Number of Customers in the 2nd Date Range * Average Time Spent - Average Number of Customers in the 1st Date Range * Average Time Spent)) / Average Number of Customers in the 1st Date Range * Average Average Time)',
    'info.performancetable.peakDensityHour':
      'The hour where the density is highest in the area.',
    'info.performancetable.count':
      'The average number of visitors entering the area.',
    'info.performancetable.totalCount':
      'Total number of visitors entering the area.',
    'info.performancetable.prevchange':
      'The change in the average number of visitors entering the area. (Change = (Average number of customers in the 2nd date range - Average number of customers in the 1st date range) / Average number of customers in the 1st date range)',
    'info.performancetable.time':
      'Average spent time in the area. (Average Time = Total Time / Number of Days)',
    'info.performancetable.timechange':
      'The change in the average spent time. ',
    'info.performancetable.15s':
      'The ratio of the average number of customers who spend 15 sec or more in the area to the average number of customers entering the area.(15s or more customers / Total number of customers)',
    'info.performancetable.15schange':
      'The change in the ratio of the average number of customers who spend 15 sec or more in the area to the total number of customers entering the area.(Total number of customers) / Number of customers spend 15sec and above in the 1st date / Total number of customers) ',
    'info.performancetable.conversionRate':
      'The ratio of the sales quantity to the number of customers who spend 15 sec or more.(Number of sales / Number of customers who spend 15s or more)',
    'info.performancetable.conversionChange':
      'The change in the ratio of the sales quantity to the number of customers who spend 15 sec or more.(Number of sales in the 1st date range / Number of customers passing 15s or more)',
    'info.performancetable.prevsalestotal':
      'Total number of sales on comparison date.',
    'info.performancetable.salestotal':
      'Total number of sales on the date compared.',
    'info.performancetable.salestotalchange':
      'The change in the total number of sales.',
    'info.performancetable.sale':
      'The average number of sales on the date compared.(Average sales = Total sales / Number of days)',
    'info.performancetable.saledensity':
      'Conversion rate of the density in the area to the number of sales. Sales quantity per unit density.(Number of Sales / Density = (Average sales / Average density ratio)',
    'info.performancetable.saledensitychange':
      'The change in the sales quantity per unit density.(Average number of sales / Average density ratio)',
    'info.performancetable.prevtotalsaleamount':
      'Total sales amount on the comparison date.',
    'info.performancetable.totalsaleamount':
      'Total sales amount on the date compared.',
    'info.performancetable.totalchangesaleamount':
      'The change in the total sales amount.',
    'info.performancetable.saleamount': 'The average sales amount in the area.',
    'info.performancetable.endorsement':
      'Conversion rate of the density in the area to the sales amount. Sales amount per unit density.(Sales Amount / Density = (amount / density)',
    'info.performancetable.prevsale':
      'The change in the sales amount per unit density.',
    'info.performancetable.metersquarerate': 'The square meter of the area.',
    'info.performancetable.saleamountoverdensity':
      'The share of the square meter of the area in the store.',

    'info.Suggestion.HasSuggestion1':
      'The categories that has suggestion are indicated by the',
    'info.Suggestion.HasSuggestion2': 'icon.',

    'Notifications.less': 'less than',
    'Notifications.greater': 'greater than',

    'HomeBrand.Info.emptyValues': 'No data available.',
    'brand.generalGraph': 'General - Graph',
    'brand.generalGraph.value': 'Value',
    downloadAsImage: 'Download As Image',
    'brand.metricSelectorPlaceholder': 'Select a metric...',
    'brand.storeSelectorError': 'Max 10 stores can be selected.',
    'brand.storeSelectorPlaceholder': 'You can select around 1-10 stores...',
    'dayIndexConversion.0': 'Sunday',
    'dayIndexConversion.1': 'Monday',
    'dayIndexConversion.2': 'Tuesday',
    'dayIndexConversion.3': 'Wednesday',
    'dayIndexConversion.4': 'Thursday',
    'dayIndexConversion.5': 'Friday',
    'dayIndexConversion.6': 'Saturday',

    'Company.CompanySummary': 'Company Summary',
    CheckoutSummary: 'Checkout Summary',

    'Polygon.MoveBehind': 'Send to back',
    'Heatmap.Total.Data.Count': 'Total Data Count',
    'Heatmap.Data.Count': 'Data Count',

    'tooltip.totalCustomer': 'Total Customer',
    'Customer.Male': 'Male',
    'Customer.Female': 'Female',
    'Customer.Total': 'Total',
    'Customer.TotalTraffic': 'Total Traffic',
    'Customer.prevTotal': 'Previous Total',
    'Customer.prevTotalChange': 'Previous Total Change',
    'Customer.AgeGender': 'Age-Gender',
    'Customer.traffic': 'Traffic',
    'Customer.TotalCustomer': 'Total Customer',
    'Customer.TotalMales': 'Total Males',
    'Customer.TotalFemales': 'Total Females',
    'Customer.GenderClassification': 'Gender Classification',
    'Customer.AgeClassification': 'Traffic',
    'Customer.young': 'Number of young people',
    'Customer.adult': 'Number of adults',
    'Customer.old': 'Number of olds',
    'Customer.AgeGender.Title': 'Age & Gender',

    'storeSummary.TodayCount': 'Todays Traffic',
    'storeSummary.YesterdayCount': 'Yesterdays Traffic',
    'storeSummary.ThisWeekCount': 'Weekly Traffic',
    'storeSummary.ThisMonthCount': 'Monthly Traffic',
    'storeSummary.ThisYearCount': 'Yearly Traffic',
    'storeSummary.SalesAmount': 'Sales Amount',
    'storeSummary.SalesQuantity': 'Sales Quantity',
    'storeSummary.ConversionRatio': 'Conversion Rate',

    'weekDay.0': 'Sunday',
    'weekDay.1': 'Monday',
    'weekDay.2': 'Tuesday',
    'weekDay.3': 'Wednesday',
    'weekDay.4': 'Thursday',
    'weekDay.5': 'Friday',
    'weekDay.6': 'Saturday',

    'Polygon.hsitory.existing': 'Existing',
    'Polygon.recalculation': 'Recalculation',
    'Polygon.setFirstEntrance': 'Set first entrance',

    Line: 'Line',
    'floors.types.Total': 'Total',
    'floors.types.Staircase': 'Staircase',
    'floors.types.Elevator': 'Elevator',
    'floors.floorComparison': 'Floor Comparison',
    'floors.floorOrientation': 'In Floor Orientation',
    'Line.Type': 'Line Type',
    'Line.Type': 'Line Type',
    'Line.Direction.Entrance': 'Entrance Direction',
    'Line.Direction.name': 'Direction Name',
    'Line.Direction.type': 'Direction Type',
    'Line.PassingShopInShop': 'Passing by the Shop-in-Shop',
    'Line.newShopInShopMatch': 'New Shop-in-Shop Match',
    'Line.ShopInShopEntrance': 'Shop-in-Shop Entrance',
    'Line.PassingStore': 'Passing by the store',
    'Line.InStore.orientation': 'Orientation In Store',
    'Line.Stairs': 'Floor Stairs',
    'Line.Elevator': 'Floor Elevator',
    'Line.InFloorOrientation': 'In Floor Orientation',

    'filter.columns': 'Filter Columns',
    'modal.close': 'close',
    'modal.deparment.history': 'Department history',
    'modal.sketch.history': 'Sketch history',
    'navigation.Suggestions': 'Suggestions',

    'Suggestion.Comment.one':
      'The footfall and time parameters of the density should be examined. The effect of the footfall in the density is higher than time. It means that visitors who came to this area have spent little time. Staff allocation, product price balance, and in-store allocation may be reviewed. It is expected that increasing the number of visitors makes the area more efficient and increases sales. Also, a new campaign is likely to increase the number of people. To be able to decide on the reduction in of area, the space can be shrunk gradually and its effect on sales can be examined in the meantime. If sales do not tend to decrease while space is shrinking, space should be shrunk.',
    'Suggestion.Comment.two':
      'The footfall and time parameters of the density should be examined. The effect of the time in the density is higher than the footfall. First of all, the interest rate should be considered to determine whether the percentage is sufficient. If it is believed that the interest rate is adequate; If the meantime is too long, there is a big possibility that the visitor can not find what he is looking for and leaves after a long waiting time. To reduce this time, the complexity of the section or product should come under review. If the visitor spends a long time in the related area, it may create an inadequacy in the personnel. As a solution, product information cards can be detailed to give information to the customer to decrease the need for calling personnel. When compared with other areas since the percentage of the area is larger than the percentage of the density, shrinkage in the area may be considered. To be able to decide on the reduction in of area, the space can be shrunk gradually and its effect on sales can be examined in the meantime. If sales do not tend to decrease while space is shrinking, space should be shrunk.',
    'Suggestion.Comment.three':
      'The footfall and time parameters of the density should be examined. The effect of the time in the density is almost equal to footfall. So, if it is believed that the meantime is sufficient, the main focus should be increasing the number of visitors while keeping the time parameter constant. Making a campaign, making a design change in the area, setting new prices are some of the options to achieve this goal. If the number of people increases and the duration does not decrease at the same time, sales are expected to increase. Since the percentage of the area is larger than its density it can be gradually reduced if sales do not tend to decrease while space is shrinking, space should be shrunk.',
    'Suggestion.Comment.four':
      'When the footfall and time parameters of the density are examined, it is observed that the effect of the footfall in the density is higher than time. The reason for the sales being less than the density may be the inability to hold the visitor in the area sufficiently. The staff relevancy can be increased to increase the time that a visitor spends in the area. The store plan can be reviewed to increase the time that is spent. Since the density/square meter efficiency is larger than the sales/square meter, the sales average is expected to increase with increasing the meantime. The area is used efficiently.',
    'Suggestion.Comment.five':
      'When the footfall and time parameters of the density are examined, it is observed that the effect of the time in the density is higher than the footfall. First of all, it is necessary to examine the interest rate within the relevant area to determine whether the rate is sufficient. If the proportion of interested customers in the area is sufficient, the reasons for the excess time should be examined. If the time that is spent in the area is long, there’s a possibility that the customer cannot find what he is looking for and may be leaving after a long wait. This may be due to the very large product range or shortage of personnel. The number of customers who spend time longer than 15 seconds in the area and the number of vouchers should be checked mutually to determine the percentage of the interested customers left the shop without buying an item from the related area. Since the density/square meter ratio is 1 and main factor that affects the density is time, it may be considered to reduce or increase the square meter of the area to increase efficiency.',
    'Suggestion.Comment.six':
      'When the footfall and time parameters of the density are examined, it is observed that the effect of the time in the density is almost equal to the footfall. First of all, if it is believed that the mean time that is spent in the related area is sufficient to decide on buying a product, the main focus should be increasing the number of footfall while keeping the time parameter constant. If the number of the visitor increases and the time does not decrease at the same time, sales are expected to increase. Campaigns can be made to increase the number of visitors, and the density as well. Low sales may be due to lack of product diversity. According to density/square meter parameter, space is used efficiently. Since the sales/square meter parameter is less than 1, the area can be reduced gradually and its effect on sales can be observed in the meantime. On the other hand, the number of people entering the area and the time should be increased concordantly.',
    'Suggestion.Comment.seven':
      'When the footfall and time parameters of the density are examined, it is observed that the effect of the footfall in the density is higher than the footfall. 1) When the interest rate in the area is considered sufficient, a campaign can be made to increase the number of people and thus sales, and personnel support can be provided to be able to achieve this goal. 2) If the interest rate is low, the store plan can be reviewed. The product range can be increased. The price balance can be reviewed. The number of staff can be increased. The area is efficient when evaluated on a density/square meter basis. It is necessary to look fort the number of sales the area receives in units. It may be considered to enlarge the square meter of the area according to the product type/price sold.',
    'Suggestion.Comment.eight':
      'When the footfall and time parameters of the density are examined, it is observed that the effect of the time in the density is higher than the footfall. First of all, it is necessary to examine the interest rate within the relevant area to determine whether the rate is sufficient. If the proportion of interested customers in the area is sufficient, the reasons for the excess time should be examined. The reason for the long time that is spent in the area may be due to the shortage of personnel. In this situation, the number of visitors who spent time longer than 15 seconds in the area and the number of vouchers should be checked mutually to determine the percentage of the interested customers left the shop without buying an item from the related area. If the number of vouchers is small, the reason for the customer loss may be the lack of personnel. Increasing the number of staff is likely to increase sales. Density/square meter efficiency shows that the area is used efficiently. It is necessary to see how many sales the area receives in units. It may be considered to enlarge the square meter of the area according to the product type/price sold.',
    'Suggestion.Comment.nine':
      'When the footfall and time parameters of the density are examined, it is observed that the effect of the time in the density is almost equal to the footfall. First of all, if it is believed that the meantime that is spent in the related area is sufficient to decide on buying a product, the main focus should be increasing the number of footfall while keeping the time parameter constant to be able to increase sales. If the interest rate is low, the number of the voucher and the number of the interested customer should be checked mutually and if the number of the voucher is high enough, the area should be enlarged.',

    'Suggestion.Comment.Oneone':
      'The footfall and time parameters of the density should be examined. The density is footfall-weighted rather than time.',
    'Suggestion.Comment.Onetwo':
      'To be able to increase the share of the time on the density, spent time by each visitor should be increased as well.',
    'Suggestion.Comment.Onethree':
      'Staff allocation, product price balance, and in-store allocation may be reviewed to be able to increase both of the parameters: spent time and footfall.',
    'Suggestion.Comment.Onefour':
      'Also, a new campaign or a change in store plan is likely to increase the footfall.',
    'Suggestion.Comment.Onefive':
      'Since the percentage of the sales of the area is higher than the density, sales performance of the area is efficient.',
    'Suggestion.Comment.Onesix':
      'Since the percentage of the squaremeter of the area is higher than the density, reduction in of area may be considered.',
    'Suggestion.Comment.Oneseven':
      'To be able to decide on the reduction, the space can be tightened gradually and its effect on sales can be examined in the meantime. ',
    'Suggestion.Comment.Oneeight':
      'If sales do not tend to decrease while space is tightining, space should be scaled down.',
    'Suggestion.Comment.Onenine': ' ',

    'Suggestion.Comment.Twoone':
      'The footfall and time parameters of the density should be examined. The density is time-weighted rather than the footfall.',
    'Suggestion.Comment.Twotwo':
      'To be able to increase the share of the footfall on the density, number of the people who visit the area should be increased.',
    'Suggestion.Comment.Twothree':
      'A new campaign or a change in store plan is likely to increase the footfall.',
    'Suggestion.Comment.Twofour':
      'Also, staff allocation, product price balance, and in-store allocation may be reviewed to be able to increase both of the parameters: spent time and footfall.',
    'Suggestion.Comment.Twofive':
      'Since the percentage of the sales of the area is higher than the density, sales performance of the area is efficient.',
    'Suggestion.Comment.Twosix':
      'Since the percentage of the squaremeter of the area is higher than the density, reduction in of area may be considered.',
    'Suggestion.Comment.Twoseven':
      'To be able to decide on the reduction, the space can be tightened gradually and its effect on sales can be examined in the meantime. ',
    'Suggestion.Comment.Twoeight':
      'If sales do not tend to decrease while space is tightining, space should be scaled down. ',

    'Suggestion.Comment.Threeone':
      'The footfall and time parameters of the density should be examined. The density is weighted equally by the time and the footfall.',
    'Suggestion.Comment.Threetwo':
      'To be able to increase the share of the footfall on the density, number of the people who visit the area should be increased while keeping the time parameter constant.',
    'Suggestion.Comment.Threethree':
      'A new campaign or a change in store plan is likely to increase the footfall.',
    'Suggestion.Comment.Threefour':
      'Also, staff allocation, product price balance, and in-store allocation may be reviewed to be able to increase both of the parameters: spent time and footfall.',
    'Suggestion.Comment.Threefive':
      'Since the percentage of the sales of the area is higher than the density, sales performance of the area is efficient.',
    'Suggestion.Comment.Threesix':
      'Since the percentage of the squaremeter of the area is higher than the density, reduction in of area may be considered.',
    'Suggestion.Comment.Threeseven':
      'To be able to decide on the reduction, the space can be tightened gradually and its effect on sales can be examined in the meantime.',
    'Suggestion.Comment.Threeeight':
      'If sales do not tend to decrease while space is tightining, space should be scaled down.',
    'Suggestion.Comment.Threenine': ' ',

    'Suggestion.Comment.Fourone':
      'The footfall and time parameters of the density should be examined. The density is footfall-weighted rather than time.',
    'Suggestion.Comment.Fourtwo':
      'To be able to increase the share of the time on the density, spent time by each visitor should be increased as well.',
    'Suggestion.Comment.Fourthree':
      'Staff allocation, product price balance, and in-store allocation may be reviewed to be able to increase both of the parameters: spent time and footfall.',
    'Suggestion.Comment.Fourfour':
      'Also, a new campaign or a change in store plan is likely to increase the footfall.',
    'Suggestion.Comment.Fourfive':
      'Since the percentage of the density of the area is higher than the sales, the area has a sales potential but isn’t able to use it’s potential.',
    'Suggestion.Comment.Foursix':
      'To be able to increase the sales performance of the area and in order to not to lose customer interest, it is urgent to take an action. Recommendations to increase the density can be applied.',
    'Suggestion.Comment.Fourseven':
      'The area is used efficiently since the percentage of the density is equal to percentage of the squaremeter.',
    'Suggestion.Comment.Foureight':
      'Since the percentage of the density/squaremeter of the area is lower than the sales/squaremeter, reduction in of area may be considered.',
    'Suggestion.Comment.Fournine':
      'To be able to decide on the reduction, the space can be tightened gradually and its effect on sales can be examined in the meantime. ',
    'Suggestion.Comment.Fourten':
      ' If sales do not tend to decrease while space is tightining, space should be scaled down.',

    'Suggestion.Comment.Fiveone':
      'The footfall and time parameters of the density should be examined. The density is time-weighted rather than the footfall.',
    'Suggestion.Comment.Fivetwo':
      'To be able to increase the share of the footfall on the density, number of the people who visit the area should be increased.',
    'Suggestion.Comment.Fivethree':
      'A new campaign or a change in store plan is likely to increase the footfall.',
    'Suggestion.Comment.Fivefour':
      'Also, staff allocation, product price balance, and in-store allocation may be reviewed to be able to increase both of the parameters: spent time and footfall.',
    'Suggestion.Comment.Fivefive':
      'If it’s believed that the time that is spent in the area is too long, there’s a possibility that the customer cannot find what he is looking for and may be leaving after a long wait. This may be due to the very large product range or shortage of personnel.',
    'Suggestion.Comment.Fivesix':
      'Since the percentage of the density of the area is higher than the sales, the area has a sales potential but isn’t able to use it’s potential.',
    'Suggestion.Comment.Fiveseven':
      'To be able to increase the sales performance of the area and in order to not to lose customer interest, it is urgent to take an action. Recommendations to increase the density can be applied.',
    'Suggestion.Comment.Fiveeight':
      'Since the percentage of the squaremeter of the area is higher than the density, reduction in of area may be considered.',
    'Suggestion.Comment.Fivenine':
      'To be able to decide on the reduction, the space can be tightened gradually and its effect on sales can be examined in the meantime.',
    'Suggestion.Comment.Fiveten':
      'If sales do not tend to decrease while space is tightining, space should be scaled down.',

    'Suggestion.Comment.Sixone':
      'The footfall and time parameters of the density should be examined. The density is weighted equally by the time and the footfall.',
    'Suggestion.Comment.Sixtwo':
      'To be able to increase the share of the footfall on the density, number of the people who visit the area should be increased while keeping the time parameter constant.',
    'Suggestion.Comment.Sixthree':
      'A new campaign or a change in store plan is likely to increase the footfall.',
    'Suggestion.Comment.Sixfour':
      'Also, staff allocation, product price balance, and in-store allocation may be reviewed to be able to increase both of the parameters: spent time and footfall.',
    'Suggestion.Comment.Sixfive':
      'Since the percentage of the density of the area is higher than the sales, the area has a sales potential but isn’t able to use it’s potential.',
    'Suggestion.Comment.Sixsix':
      'To be able to increase the sales performance of the area and in order to not to lose customer interest, it is urgent to take an action. Recommendations to increase the density can be applied.',
    'Suggestion.Comment.Sixseven':
      'Since the percentage of the squaremeter of the area is higher than the density, reduction in of area may be considered.',
    'Suggestion.Comment.Sixeight':
      'To be able to decide on the reduction, the space can be tightened gradually and its effect on sales can be examined in the meantime.',
    'Suggestion.Comment.Sixnine':
      'If sales do not tend to decrease while space is tightining, space should be scaled down.',
    'Suggestion.Comment.Sixten':
      'On the other hand, the number of people entering the area and the time should be increased concordantly.',

    'Suggestion.Comment.Sevenone':
      'The footfall and time parameters of the density should be examined. The density is footfall-weighted rather than time. ',
    'Suggestion.Comment.Seventwo':
      'To be able to increase the share of the time on the density, spent time by each visitor should be increased as well.',
    'Suggestion.Comment.Seventhree':
      'Staff allocation, product price balance, and in-store allocation may be reviewed to be able to increase both of the parameters: spent time and footfall.',
    'Suggestion.Comment.Sevenfour':
      'Also, a new campaign or a change in store plan is likely to increase the footfall.',
    'Suggestion.Comment.Sevenfive':
      'Since the percentage of the density of the area is equal to the the sales, recommendations to increase the density can be applied to be able to increase sales/density efficiency.',
    'Suggestion.Comment.Sevensix':
      'The area is efficient when evaluated on a density/square meter basis. It is necessary to look for the number of sales the area receives in units. ',
    'Suggestion.Comment.Sevenseven':
      'It may be considered to enlarge the square meter of the area according to the product type/price sold.',
    'Suggestion.Comment.Seveneight': ' ',

    'Suggestion.Comment.Eightone':
      'The footfall and time parameters of the density should be examined. The density is time-weighted rather than the footfall.',
    'Suggestion.Comment.Eighttwo':
      'To be able to increase the share of the footfall on the density, number of the people who visit the area should be increased.',
    'Suggestion.Comment.Eightthree':
      'A new campaign or a change in store plan is likely to increase the footfall',
    'Suggestion.Comment.Eightfour':
      'Also, staff allocation, product price balance, and in-store allocation may be reviewed to be able to increase both of the parameters: spent time and footfall.',
    'Suggestion.Comment.Eightfive':
      'If it’s believed that the time that is spent in the area is too long, there’s a possibility that the customer cannot find what he is looking for and may be leaving after a long wait. This may be due to the very large product range or shortage of personnel.',
    'Suggestion.Comment.Eightsix':
      'Since the percentage of the density of the area is equal to the the sales, recommendations to increase the density can be applied to be able to increase sales/density efficiency.',
    'Suggestion.Comment.Eightseven':
      'The area is efficient when evaluated on a density/square meter basis. It is necessary to look for the number of sales the area receives in units.',
    'Suggestion.Comment.Eighteight':
      'It may be considered to enlarge the square meter of the area according to the product type/price sold.',

    'Suggestion.Comment.Nineone':
      'The footfall and time parameters of the density should be examined. The density is weighted equally by the time and the footfall.',
    'Suggestion.Comment.Ninetwo':
      'The main focus should be increasing the number of footfall while keeping the time parameter constant to be able to increase sales.',
    'Suggestion.Comment.Ninethree':
      'A new campaign or a change in store plan is likely to increase the footfall.',
    'Suggestion.Comment.Ninefour':
      'Also, staff allocation, product price balance, and in-store allocation may be reviewed to be able to increase both of the parameters: spent time and footfall.',
    'Suggestion.Comment.Ninefive':
      'Since the percentage of the density of the area is equal to the the sales, recommendations to increase the density can be applied to be able to increase sales/density efficiency.',
    'Suggestion.Comment.Ninesix':
      'The area is efficient when evaluated on a density/square meter basis. It is necessary to look for the number of sales the area receives in units. ',
    'Suggestion.Comment.Nineseven':
      'It may be considered to enlarge the square meter of the area according to the product type/price sold.',
    'Suggestion.Comment.Nineeight': ' ',

    'Suggestion.Comment.Description':
      'This recommendation is based on the following data. This data represents the distribution within the store.',
    'Suggestion.select.Adate': 'Select a date.',
    'Suggestion.select.Adepartment':
      'Select a department that you want to look up the recommendation.',
    'Suggestion.Area.Percent': 'Area',
    'Suggestion.Density.Percent': 'Density',
    'Suggestion.DwellTime.Percent': 'DwellTime',
    'Suggestion.PersonCount.Percent': 'Person count',
    'Suggestion.Sales.Percent': 'Sales',

    'Suggestion.Feedback.palceHolder':
      'Write your feedback and share with us...',
    'Suggestion.Feedback.approve': 'Approve',
    'Suggestion.Feedback.Report': 'Send a feedback',
    'Suggestion.Feedback.Wrong': 'Wrong comment',
    'CustomerEntrance.Dwell': 'Customer Entrance Count & Dwell Time',
    'CheckoutCount.Dwell': 'Customer Count at Checkout & Dwell Time (sec)',
    'Suggestion.interestCount': 'Interest Count',
    'Suggestion.densityDistribution': 'Density Distribution',
    'Suggestion.salesDistribution': 'Sales Distribution',
    'suggestionRequest.waiting': 'Waiting',
    'suggestionRequest.preparing': 'Preparing',
    'suggestionRequest.Completed': 'Completed',
    'suggestionRequest.Department': 'Department',
    'entranceCheckout.v2': 'In - Out Count',
    'entrance.v2': 'In Count',
    'entrance.peopleCount': 'Number Of People Entering',
    'suggestion.top5.sale': 'FIRST 5 - SALES',

    'Personnel.employee': 'Employee',
    'Personnel.InTime': 'In Time',
    'Personnel.OutTime': 'Out Time',
    'Personnel.Dwell': 'Dwell Time',
    'Personnel.Entrances': 'In Out Count',
    'Personnel.TotalEntrances': 'Total In Out',
    'Personnel.EntrancesDetail': 'In Out Detail',
    'Personnel.Aisle': 'Aisle',
    'Personnel.Aisle/Depot': 'Aisle/Depot',
    'Personnel.StoreLimit': 'Store Border',
    'Personnel.Break': 'Break',
    'Personnel.Depot': 'Depot',
    'PersonnelModal.selectPersonnel': 'Select personnel',
    'PersonnelModal.header': 'Select a personnel to bind',
    'PersonnelModal.selectPersonnels': 'Select personnels',
    'PersonnelModal.header2': 'Select a personnels to bind',

    'personnelv2.ActiveTime': 'Active Instore Time',
    'personnelv2.AvarageOfActiveWorkingTime': 'Average Instore Time',
    'personnelv2.AvarageTimeSpentInAisle': 'Average Aisle Time',
    'personnelv2.AvarageTimeSpentInBreak': 'Average Break Time',
    'personnelv2.AvarageTimeSpentInDepot': 'Average Depot Time',
    'personnelv2.BeaconName': 'Personnel',
    'personnelv2.BeaconTargets': 'Responsible Areas',
    'personnelv2.Date': 'Date',
    'personnelv2.StoreEnterTime': 'Entering Time to Store',
    'personnelv2.StoreExitTime': 'Exiting Time from Store',
    'personnelv2.MostEnteredReceiver': 'Most Entered Area',
    'personnelv2.MostSeenReceiver': 'Most Time Spent Area',
    'personnelv2.MostSpentArea': 'Most Time Spent Area',
    'personnelv2.MostSpentTimeInArea': 'Most Spent Time In Area',
    'personnelv2.MostSpentTimeAvarageInArea': 'Most Spent Time In Area',
    'personnelv2.Name': 'Name',
    'personnelv2.NumberOfEntries': 'Number Of Entries',
    'personnelv2.ReceiverName': 'Receiver Name',
    'personnelv2.TimeSpentInAisle': 'Time Spent in Aisle',
    'personnelv2.TimeSpentInDepot': 'Time Spent in Depot',
    'personnelv2.TimeSpentOnTargetReceivers': 'Time Spent on Target Areas',
    'personnelv2.UnseenReceivers': 'Non-Entered Areas',
    'personnelv2.WorkingDay': 'Working Day',
    'personnelv2.WorkingTime': 'Working Time',
    'personnelv2.allPersonnels': 'All Personnels',
    'personnelv2.detailedExcel': 'Download Detailed Excel',
    'personnelv2.Receiver': 'Receiver',
    'personnelv2.HighestDensityDate': 'Most Dense Day/Hour',
    'personnelv2.HighestDensityHour': 'Average Most Dense Hour',
    'personnelv2.AverageEntranceCount': 'Daily Average Personnel Entrance',
    'personnelv2.AverageDwellMin': 'Daily Average Personnel Dwell',
    'personnelv2.LongestBeaconName': 'The Personnel That Spent Most Time',
    'personnelv2.AvarageOfDwellMin': 'Average Dwell Of That Personnel',
    'personnelv2.BeaconCount': 'Personnel Count',
    'personnelv2.CustomerCountOver15Sec': 'Customer Count Over 15s',
    'personnelv2.SaleQuantity': 'Sale Quantity',
    'personnelv2.Dwell': 'Dwell',
    'personnelv2.EntranceCount': 'Entrance Count',
    'personnelv2.EntranceToAreaTime': 'Area Entrance Time (min)',
    'personnelv2.EntranceToAreaCount': 'Area Entrance Count',
    'personnelv2.Start': 'Start',
    'personnelv2.End': 'End',
    'personnelv2.Time': 'Time',

    'personnelv2.AverageWorkingTime': 'Average Working Time',
    'personnelv2.AverageActiveWorkingTime': 'In-Store / Active Average Time',
    'personnelv2.AverageTimeOutOfStore': 'Average Time Out of Store',
    'personnelv2.AverageNumberOfOutOfStoreExits':
      'Average Number of Out-of-Store Exits',
    'personnelv2.AverageTimeSpentInBeaconTargets':
      'Average Time Spent in Areas of Responsibility',
    'personnelv2.AverageTimeSpentInBreak': 'Average Time in Break',
    'personnelv2.MostEnteredArea': 'Most Entered Area',
    'personnelv2.AverageNumberOfEntries': 'Average Number of Entries',
    'personnelv2.AverageTimeInMostSeenReceiver': 'Average Time Spent',
    'personnelv2.ClickToDayNote': 'Please click to a day for personnel detail.',

    'personnelv2.cards.TotalBeaconCount': 'Total Beacon Count',
    'personnelv2.cards.TotalWorkingTime': 'Total Working Time',
    'personnelv2.cards.AverageWorkingTime': 'Average Working Time',
    'personnelv2.cards.TotalActiveTime': 'Total Active Time',
    'personnelv2.cards.AverageActiveTime': 'Average Active Time',
    'personnelv2.cards.EstimatedWorkforceLoss': 'Estimated Workforce Loss',
    'personnelv2.cards.AverageEstimatedWorkforceLoss':
      'Average Estimated Workforce Loss',
    'personnelv2.cards.DayWithMostWorkforceLoss':
      'Day With Most Workforce Loss',
    'personnelv2.cards.shortHour': 'h',
    'personnelv2.cards.showMoreDetail': 'Show Detail',

    'personnelv2.EstimatedWorkforceLoss': 'Estimated Workforce Loss',
    'personnelv2.TotalActiveTime': 'Total Active Time',
    'personnelv2.TotalBeaconCount': 'Total Beacon Count',
    'personnelv2.TotalWorkingTime': 'Total Working Time',
    'personnelv2.Day': 'Day',

    'personnelv2.TimeOutOfStore': 'Out of Store Time (Out of Service Time)',
    'personnelv2.BeaconExits': 'Exiting Times From Store',
    'personnelv2.TimeSpentInBreak': 'Time in the Break Area',
    'personnelv2.TimeOfSeen': 'Time Of Seen',

    'demography.County': 'County',
    'demography.Population': 'Population Total',
    'demography.Age': 'Average Age',
    'demography.HouseHold': 'House Hold',
    'demography.Marital': 'Marital',
    'demography.Ses': 'S.E.S',
    'demography.TotalSale': 'Total Sale',
    'demography.TotalEstate': 'Total Estate',
    'demography.Education': 'Education',
    'demography.HouseIncome': 'House Income',
    'demography.HouseIncomeTotal': 'House Income Total',
    'demography.ECommerce': 'E Commerce',
    'demography.CountyId': 'County Id',
    'demography.A Grubu': 'Group A',
    'demography.B Grubu': 'Group B',
    'demography.C Grubu': 'Group C',
    'demography.D Grubu': 'Group D',
    'demography.Lisans': 'Undergraduate',
    'demography.Ilkokul': 'Primary School ',
    'demography.Lise': 'High school',
    'demography.Ortaokul': 'Middle School',
    'demography.Okuryazar Egitimsiz': 'Uneducated',
    'demography.Married': 'Married',
    'demography.MarriedNever': 'Married Never',

    'sketch.layer': 'Layer ',
    'sketch.selectLayer': 'Select Layer',
    'sketch.addLayer': 'Add Layer',
    'sketch.removeLastLayer': 'Remove Last Layer',
    'sketch.removeLastLayerPrompt': 'Are you sure to remove last layer?',
    'sketch.removeLastLayerError': 'Layer has one or more rectangles.',

    'LeavePage.Error':
      'Are you sure you want to leave the page without saving the changes?',
    'placeHolder.targetUser': 'Target Users',

    'live.storeCapacity': 'AREA CAPACITY: ',
    'live.currentPeople': 'CURRENT CUSTOMER:',
    'live.currentCapacity': 'Current Capacity: ',
    'live.customerChart': 'Number of Visitor',
    'live.exceededCapacity': 'exceeded capacity!',
    'live.least': 'Least',
    'live.most': 'Most',
    'live.storeCapacityEdit': 'Enter Area Capacity',
    'live.capacity': 'Area Capacity',
    'live.liveOccupancyGraph': 'Live Occupancy Graph',
    'live.location.capacity': 'Location Capacity',
    'live.peopleData': 'Live People Data',
    'live.LiveAreas': 'Live Area Counts',
    'info.Forecast.Cam': 'Camera Areas',
    'info.prompt.Forecast':
      'Are you sure you want to delete the selected group?',
    'Browser.Does.not.Support': 'Browser does not support for this content.',

    'notification.response': 'Notification Response : ',
    'notification.reply': 'Reply ',
    'notification.timedOut': 'Timed Out',
    'notification.emptyResponse': 'No Notification Response.',
    'notification.otherResponse': 'Other ',
    'Polygon.recalculationInfo':
      'Select the date you want your departmant data recalculated.',
    'Polygon.changeCamera': 'Change Camera',

    'suggestion.month': 'Month',
    'suggestion.topcategory': 'Top Category',
    'suggestion.show': 'Show',
    'suggestion.densityChange': 'Density Change',
    'suggestion.saleChange': 'Sale Change',
    'suggestion.personChange': 'Visitor Change',
    'suggestion.interestChange': 'Interest Change',
    'suggestion.timeChange': 'Time Change',
    'suggestion.store': 'Store',
    'suggestion.visitorCount': 'Visitor Count',
    'suggestion.campaign': 'Campaign',
    'suggestion.productVariety': 'Product Variety',
    'suggestion.price': 'Price',
    'suggestion.storePlan': 'Store Plan',
    'suggestion.stock': 'Stock',
    //NewSuggestions
    //  'suggestion.commentOne':'In order to increase the density in this area, the number of visitors to the area should be increased. The increase in density is expected to increase sales as well.',
    //  'suggestion.commentTwo': 'High increase for visitor count. If there is a campaign, it indicates that it works.',
    //  'suggestion.commentThree': 'The mean time per visitor in the area should be increased to increase the density in this area. The increase in density is expected to increase sales as well.',
    //  'suggestion.commentFour': 'Since the time spent per customer in this area is low and sales are not enough, if there is a staff member assigned to this area, the staff can be switched to different areas.',
    //  'suggestion.commentFive': 'Since the time spent in this area is high and sales are low, the customer may need to get more information from the staff. Action should be taken to turn interest growth into sales.',
    //  'suggestion.commentSix': 'The customer may not be able to find what she/he is looking for. Staff consideration may be needed.',
    //  'suggestion.commentSeven': 'The customer did not purchase because he/she could not find what he/she was looking for, and the customer may need personnel care.',
    //  'suggestion.commentEight': 'Increasing the number and interest of staff may be considered in order to meet the increasing visitor count and interest of customers.',
    //  'suggestion.commentNine': 'The number of visitors to this area gradually decreases. Campaign may be considered to increase customer interest.',
    //  'suggestion.commentTen' : 'The number of visitors to this area gradually decreases. It is insufficient to stop customers and direct them to sales in this area. Campaigns may be considered to increase the number and interest of customers.',
    //  'suggestion.commentEleven' :"Since the visitor count and sales increased, the campaign was successful.",
    //  'suggestion.commentTwelve': 'The customer does not make a purchase because he/she cannot find what he is looking for. The product range may be inadequate.',
    //  'suggestion.commentThirteen': "Many visitors to the store bypass the area. The product-price balance can be revised.",
    // 'suggestion.commentFourteen': "The time spent in the area is gradually decreasing. It can have a price effect.",
    // 'suggestion.commentFiveteen':"The area may be located at a blind spot in the store. The location of the product group should be reviewed.",
    // 'suggestion.commentSixteen':  "The area loses both customers and interest and sales. The store layout can be reviewed.",
    // 'suggestion.commentSeventeen':"The area loses both customers and interest and sales. Daily store plan changes such as basket, action area, pallet should be checked.",
    // 'suggestion.commentEighteen': "Area inventory should be checked frequently.",

    'suggestion.infoDaily':
      'You can see the most increasing and decreasing categories of the specified day according to the previous day and the selected variable.',
    'suggestion.infoWeekly':
      'You can see the most increasing and decreasing categories of this week based on the specified day range and the selected variable. Weekly changes are calculated from the beginning of the week. Ex: On the 4th day of the week, the first three days of this week are compared with the first three days of the past week. ',
    'suggestion.infoMonthly':
      'You can see the most increasing and decreasing categories of this month based on the specified day range and the selected variable. Monthly changes are calculated from the beginning of the month. Ex: On the 21st day of the month, the first 20 days of this month are compared with the first 20 days of the last month.',

    'suggestion.title': 'Change tracking ',
    'suggestion.detailTitle': 'Suggestions ',
    'suggestion.detailPageDes':
      'On this page, you can see the changes in the category you have chosen. You can review them daily, weekly and monthly processes and compare them with the higher category and store changes. The problems and suggestions calculated from the changes in the selected date range of the category are specified in the related title.',
    'suggestion.pageDescription':
      'On this page, you can see the categories that have undergone the most changes. You can examine their changes in daily, weekly and monthly processes and compare the change of your chosen parameter with the sales change.',
    'suggestion.infoDetailDaily':
      'You can see the changes in the specified day in the category you selected compared to the previous day.',
    'suggestion.infoDetailWeekly':
      "In the category you selected, you can see the week's changes within the specified day compared to last week. Weekly changes are calculated from the beginning of the week. Ex: On the 4th day of the week, the first three days of this week are compared with the first three days of the past week.",
    'suggestion.infoDetailMonthly':
      'You can see the changes this month in the specified day range in the category you selected. Monthly changes are calculated from the beginning of the month. Ex: On the 21st day of the month, the first 20 days of this month are compared with the first 20 days of last month.',
    'suggestion.infoDetailStore':
      'You can compare the changes in the category you selected with the changes in the entire store for the same date range. ',
    'suggestion.infoDetailDep':
      'You can compare the changes in the category you selected with the upper category changes for the same date range.',

    'trends.detailTitle': 'Trends ',
    'trends.detailPageDes':
      "On this page, you can see the category trends' increase and decrease in the daily, weekly, and monthly periods and compare them with the sales trends. You can examine the data by switching to map mode according to their location in the store.",
    'trends.infoDaily':
      'You can see the increase and decrease trends of the categories as the number of days according to the selected variable and sales. Ex: An increasing trend for five days.',
    'trends.infoWeekly':
      'You can see the increase and decrease trends of the categories as the number of weeks according to the selected variable and sales. Ex: 4-week downward trend.',
    'trends.infoMonthly':
      ' You can see the increase and decrease trends of categories in terms of the number of months according to the selected variable and sales. Ex: The downward trend for three months.',

    'live.route': 'Live',
    'live.customerCountChart': 'Number Of Visitor Chart',
    'live.visitor': 'Live Visitor Count',
    'login.Register': 'Register',
    'login.MemberShipResponse':
      'Your membership request has been received. Response will be made as soon as possible.',
    'membership.Name': 'Name',
    'membership.Surname': 'Surname',
    'membership.email': 'Email',
    'membership.Brand': 'Brand',
    'all.Brands': 'All Locations',

    'average.values': 'Average Values',
    'notification.sketchWarning': 'There are unscratched sketchs!',
    'notification.skectAdd': 'Click to add sketch',
    'notification.brandWarning': 'Categories have not been added to brand yet!',
    'notification.brandAdd': 'Click to add categories',
    'settings.daily': 'Daily',
    'settings.weekly': 'Weekly',
    'settings.cameraImage': 'Camera Image',
    'settings.noCameraImage': 'No camera view',
    'user.Mail': 'User Email',
    'settings.removeCam': 'Are you sure  that you want to delete selecteds ?',
    'created.userAndDate': 'Creation Date - Creator User',
    'suggestion.finding': 'Findings',
    'heatmap.value': 'Heat Map Value: ',
    'correlations.from': 'From',
    'correlations.to': 'To',
    'info.age': 'Age',
    'suggestion.warning': 'No Suggestions!',
    'area.inArea': 'Time in the area',
    'area.outTime': 'Time to leave the area',
    'area.inTime': 'Time to enter the area',
    'personnel.tracking': 'personnel tracking',
    'personnel.route': 'Route',
    'checkOut.region/store': 'Region/Store',
    'checkOut.general': 'General',
    'checkout.Comparison': 'Comparison',
    'checkout.density': 'Checkout Density',
    'checkout.ReceiptError': 'Receipt Integration Required',
    'checkout.customer': 'Customer',
    'checkout.potentialLoss': 'Potential Loss',
    'checkout.numberCheckout': 'Number of open checkout',
    'checkout.instantCus': 'Number of Instant Customers',
    'checkout.instantCusInfo': 'Number of customers currently in all checkouts',
    'checkout.instantCusWaitingAreaInfo':
      'Number of customers currently in all checkout waiting areas',
    'checkout.checkout': 'Checkout',
    'checkout.checkoutWaitingArea': 'Waiting Area',
    'checkout.maxCus': 'Max number of customers',
    'checkout.numberCus': 'Number of customers',
    'checkout.queue': 'Queue Waiting Time',
    'checkout.process': 'Checkout Process Time',
    'checkout.integrationReq': 'Integration Required',
    'suggestion.numberOfSuggestions': 'Number Of Suggestions',
    'suggestions.see': 'See Suggestions',
    'department.waiting.limit': 'Waiting time limit',
    'number.customers.passed': 'Number Of Customers Passed',
    'info.update': 'Update',
    'info.conversionSale': 'Conversion Time To Sale',
    'current.people': 'Current Number of People',
    'rotate.degrees': 'Rotate drawings',
    'rotate.degreesConfirmation': 'Are you sure you want to rotate?',
    Under13Female: 'Age (0-13) Female',
    B14and23Female: 'Age (14-23) Female',
    B24and35Female: 'Age (24-35) Female',
    B36and46Female: 'Age (36-46) Female',
    Over47Female: 'Age (47-) Female',
    Under13Male: 'Age (0-13) Male',
    B14and23Male: 'Age (14-23) Male',
    B24and35Male: 'Age (24-35) Male',
    B36and46Male: 'Age (36-46) Male',
    Over47Male: 'Age (47-) Male',
    'ageGender.moveCountsHeader': 'People Passed by the Store',
    'shopInShop.moveCountsHeader': 'People Passed by the Shop-in-Shop',

    'global.campaingn': 'Campaingn Create',
    'global.loading': 'Loading...',
    'global.empty': 'No data found.',
    'campaingn.name': 'Campaingn Name',

    ...suggestionComment.en,

    'socket.error': 'Connection Error!',
    'socket.disconnected': 'Socket connection error!',
    'total.demography': 'Total Demographics',
    'total.change': 'Total Change',
    'campaign.category.change': 'Campaign  Categories  Change',
    'trends.yearly': 'Yearly',
    'waiting.time': 'Waiting Time',
    'seconds.over': 'Seconds and Over',
    'names.visible': 'Show Names',
    'short.names.visible': 'Show Short Names',
    'values.visible': 'Show Values',
    'campaign.analysis': 'Campaign Analysis',
    'info.prompt.campaign':
      'Are you sure you want to delete the selected campaign?',
    'Total.Weekday.Customer': 'Weekday Total Customer Entering  ',
    'info.showMore': 'Show More',
    'info.showLess': 'Show Less',
    'info.summaryTable': 'General-Table',
    'info.StoreName': 'Store Name',
    'Endorsement/m2': 'Sales Amount/M2',
    InvoicesCount: 'Invoices Count',
    'hourly.avarage': 'Hourly Average',
    'draw.door': 'Draw Door',
    'draw.entry.direction': 'Draw Entry Direction',
    'draw.count.line': 'Draw Count Line',
    Metric: 'Metric',
    'Peak.DensityHour': 'Peak Density Hours',
    'selected.area': 'Selected Area',
    ProductNotAllowed:
      'This product is not used in the selected location. You can review the products of this location on the {link}.',
    ProductNotAllowedLink: 'home page',
    'Avarage.Dwell': 'Average Dwell(sec)',
    'Over.15secAvarage': 'Average time over 15 seconds',
    'Invoice.Count': 'Invoice Count',
    'DailyCustomers.Over15': 'Daily number of customers over 15 seconds',
    'Numberof.Receipts': 'Number of Receipts',
    'PotentialLost.Customer': 'Potential Lost Customer',
    'data.recalculated': 'Datas recalculated...',
    'data.tobe.calculated': 'Datas waiting to be calculated in line',
    'Unsubscribe.error': 'An Error occurred while unsubscribe!',
    'Unsubscribe.success': 'Successfully unsubscribed!',
    'current.version': 'Current Version',
    'select.atleastOneStore': 'You need to select at least 1 location.',
    personnels: 'Person(s)',
    Days: 'Days',
    'time.limit': 'Time Limit',
    PersonnelOver15Sec: 'Customer Count Over 15s',
    PersonnelOver30Sec: 'Customer Count Over 30s',
    PersonnelOver60Sec: 'Customer Count Over 60s',
    PersonnelOver90Sec: 'Customer Count Over 90s',
    PersonnelOver120Sec: 'Customer Count Over 120s',
    PersonnelOver180Sec: 'Customer Count Over 180s',
    'report.type': 'Report Type',
    subscribe: 'Subscribe',
    'workforce.change': 'Workforce Change',
    'number.employees.working': 'Number of employees working',
    'bottom.shift.time': 'Bottom shift time',
    'top.shift.time': 'Top shift time',
    'workforce.loss.change': 'Workforce loss change',
    'employee.conversion.rate': 'Employee conversion rate',
    'employee.efficiency': 'Employee efficiency',
    'store.least.employeeefficiency': 'Store with least employee efficiency',
    'store.most.employeeefficiency': 'Store with most employee efficiency',
    'estimated.workforce.loss': 'Estimated workforce loss',
    'total.workforce.loss': 'Total workforce loss',
    'total.shift.time': 'Total shift time (hour:min)',
    'total.breaks': 'Total breaks',
    'total.customer': 'Total customer',
    'working.time.change': 'Working Time Change',
    'total.active.time': 'Total Active Time (hour:min)',
    'breaks.change': 'Breaks Change',
    'store.count': 'Store Count',
    'personnel.performance': 'Personnel Performance',
    'brand.summary': 'Brand Summary',
    'personnel.summary': 'Personnel Summary',
    'customer.change': 'Customer Change',
    'personnel.count.total': 'Personnel Count Total',
    'personnel.count.change': 'Personnel Count Change',
    'invoice.count.change': 'Invioce Count Change',
    'sale.quantity.change': 'Sale Quantity Change',
    'sale.amount.change': 'Sale Amount Change',
    'employee.conversion.rate.total': 'Employee conversion rate total',
    'employee.conversion.rate.change': 'Employee conversion rate change',
    'employeeefficiency.change':
      'Employee Efficiency Change(Active Time / Shift Time)',
    'employeeefficiency.total':
      'Employee Efficiency Total (Active Time / Shift Time)',
    'download.excel': 'Download Excel',
    'checkout.traffic': 'Checkout Traffic',
    'checkout.topStores': 'Checkout (Top Stores)',
    'checkout.bottomStores': 'Checkout (Bottom Stores)',
    'Meantime.over15sec': 'Mean time over 15sec',
    'numberof.notifications': 'Number of Notifications',
    '15sec.checkout.visitor': 'Checkout Visitor Number  Over 15sec',
    'visitor.number.over15sec': 'Visitor number over 15sec',
    'visitor.number.change.over15sec': 'Visitor number change over 15sec',
    'visitor.number.over60sec': 'Visitor number over 60sec',
    'visitor.number.change.over60sec': 'Visitor number change over 60sec',
    'mean.time.change.over15sec': 'Mean time change over 15sec',
    'mean.time.over15sec': 'Mean time over 15sec',
    'meantime.over15sec': 'Mean Time Over 15 sec',
    'meantime.changeover15sec': 'Mean Time Change Over 15 sec',
    'hourly.traffic.chart': 'Hourly Traffic Chart',
    'daily.traffic.chart': 'Daily Traffic Chart',
    'door.person.count': 'People Counting',
    'daily.amount': 'Daily Sale Amount',
    'daily.traffic': 'Daily Traffic',
    'daily.invoice': 'Daily Invoice',
    'daily.quantity': 'Daily Quantity',
    'monthly.amount': 'Monthly Sale Amount',
    'monthly.traffic': 'Monthly Traffic',
    'monthly.invoice': 'Monthly Invoice',
    'monthly.quantity': 'Monthly Sale Quantity',
    'previous.daily.amount': 'Previous Daily Amount',
    'previous.daily.traffic': 'Previous Daily Traffic',
    'previous.daily.invoice': 'Previous Daily Invoice',
    'previous.daily.quantity': 'Previous Daily Sale Quantity',
    'previous.monthly.amount': 'Previous Monthly Sale Amount',
    'previous.monthly.traffic': 'Previous Monthly Traffic',
    'previous.monthly.invoice': 'Previous Monthly Weekly',
    'previous.monthly.quantity': 'Previous Monthly Sale Quantity',
    'previous.monthly.amount': 'Previous Monthly Sale Amount',
    'previous.weekly.traffic': 'Previous Weekly Traffic',
    'previous.weekly.invoice': 'Previous Weekly Invoice',
    'previous.weekly.quantity': 'Previous Weekly Sale Quantity',
    'weekly.amount': 'Previous Sale Amount',
    'weekly.traffic': 'Previous Traffic',
    'weekly.invoice': 'Previous Invoice',
    'weekly.quantity': 'Previous Sale Quantity',
    'category.summary': 'Category Summary',
    'InStore.Analytics.TopStores': 'In-Store Analytics (Top Stores)',
    'InStore.Analytics.BottomStores': 'In-Store Analytics (Bottom Stores)',
    'InStore.Analytics.TopCategories': 'In-Store Analytics (Top Categories)',
    'InStore.Analytics.BottomCategories':
      'In-Store Analytics (Bottom Categories)',
    'store.based.traffic.perf': 'Store Based Traffic Performance',
    'traffic.change': 'Traffic Change',
    'invoicecount.change': 'Invoices Count Change',
    'top.ten.stores': 'Top 10 Stores',
    'InStore.Density': 'In-Store Density',
    'InStore.Density.Change': 'In-Store Density Change',
    'meantime.change': 'Mean Time Change',
    'conversionRate.change': 'Conversion Rate Change',
    'Visitor.Count.Over.15sec.Change': 'Change Visitor Count Over 15sec',
    'Visitor.Count.Over.15sec': 'Visitor Count Over 15sec',
    'Top.Categories': 'Top Categories',
    'Bottom.Categories': 'Bottom Categories',
    Days: 'Days',
    'store-occupancy':
      'I want to receive a notification when a store is {value}% occupied.',
    'checkoutSummary.storeRegion': 'Store/Region',
    'checkoutSummary.AverageDwell': 'Average Dwell',
    'checkoutSummary.AverageDwellChange': 'Average Dwell Change',
    'checkoutSummary.DensityRatio': 'Density Ratio',
    'checkoutSummary.DensityRatioChange': 'Density Ratio Change',

    'checkoutSummary.ChWaitingAverageDwell': 'Waiting Area Average Dwell',
    'checkoutSummary.ChWaitingAverageDwellChange':
      'Waiting Area Average Dwell Change',
    'checkoutSummary.ChWaitingDensityRatio': 'Waiting Area Density Ratio',
    'checkoutSummary.ChWaitingDensityRatioChange':
      'Waiting Area Density Ratio Change',

    'checkoutSummary.PeakHour': 'Peak Hour',
    'checkoutSummary.NotificationCount': 'Notification Count',
    'checkoutSummary.NotificationCountChange': 'Notification Count Change',
    'checkoutSummary.InvoiceCount': 'Invoice Count',
    'checkoutSummary.AverageDailyInvoiceCount': 'Average Daily Invoice Count',
    'checkoutSummary.InvoiceCountChange': 'Invoice Count Change',
    'checkoutSummary.over15sec': 'Over 15 Seconds',
    'checkoutSummary.CustomerCount': 'Customer Count',
    'checkoutSummary.AverageDailyCustomerCount': 'Average Daily Customer Count',
    'checkoutSummary.CustomerCountChange': 'Customer Count Change',
    'checkoutSummary.Dwell': 'Dwell',
    'checkoutSummary.DwellChange': 'Dwell Change',
    'checkoutSummary.DelayRate': 'Delay Rate',
    'checkoutSummary.DelayRateChange': 'Delay Rate Change',
    'checkoutSummary.ConversionRate': 'Conversion Rate',
    'checkoutSummary.ConversionRateChange': 'Conversion Rate Change',
    'checkoutSummary.btw15and60sec': 'Between 15-60 Seconds',
    'checkoutSummary.over60sec': 'Over 60 Seconds',
    'sketches.sketchHistoryTitle': 'Update Sketch History',
    'sketches.sketchSavedWarning2': 'here.',
    'sketches.sketchSavedWarning1':
      'In order to name the saved sketch and set a starting date for it please click ',
    'sketches.updateSketchHistoryDate': 'Update Sketch History Date',
    'info.currentSketch': 'Current Sketch',
    'info.sketchHistory': 'Sketch History',
    ShopInShopFrontLineCount: 'Shop-in-Shop Front Traffic',
    ShopInShopEntranceLineCount: 'Shop-in-Shop Entrance',
    ShopInShopFrontAndEntranceGraphTitle:
      'Shop-in-Shop Front Traffic and Entrance',
    compare: 'Compare',
    firstArea: 'First area',
    secondArea: 'Second area',
    'info.ShopInShopFrontCount': 'SiS Front Count',
    'info.ShopInShopEntranceCount': 'SiS Entrance Count',
    'info.ShopInShopConversionRatio': 'SiS Conversion Rate',
    'notificationFilter.seenFilter': 'Seen Filter',
    'notificationFilter.seen': 'Seen',
    'notificationFilter.nonSeen': 'Unseen',
    'notificationFilter.all': 'All',
    'notificationFilter.respondedFilter': 'Responded Filter',
    'notificationFilter.responded': 'Responded',
    'notificationFilter.nonResponded': 'Unresponded',
    'notificationFilter.responseRate': 'Response Rate',
    'notificationFilter.total': 'Total',
    single: 'Singular',
    group: 'Group',
    lineEntranceGroupValue12578: 'Group of {value}',
    lineEntranceGroupValue34: 'Group of {value}',
    lineEntranceGroupValue6: 'Group of {value}',
    lineEntranceGroupValue9: 'Group of {value}',
    areYouSureDeletePrompt: 'Are you sure you want to delete this?',
    doorLine: 'Door Line',
    countingLine: 'Count Line',
    'drawingToolsInfos.close': 'Zoom in camera: W',
    'drawingToolsInfos.far': 'Zoom out camera: S',
    'drawingToolsInfos.reset': 'Reset zoom: R',
    'drawingToolsInfos.move': 'Move camera: hold Q',
    'drawingToolsInfos.polygonActions': 'Polygon actions: Right click',
    'drawingToolsInfos.polygonDrag': 'Drag polygon: Hold Shift',
    'drawingToolsInfos.addCorner':
      'Add corner: Hold Shift and left click on corner',
    'drawingToolsInfos.deleteCorner':
      'Delete corner: Hold Alt and left click on corner',
    lines: 'Lines',
    polygons: 'Polygons',
    noPolygons: 'No polygons found.',
    noLines: 'No lines found.',
    drawRectangle: 'Draw rectangle'
  },
  tr: {
    'sketchHistory.blueLegion': 'En az 1 aktif alan içerir.',
    'sketchHistory.grayLegion': 'Tüm alanları arşivlenmiştir.',
    'sketchHistory.redLegion': 'Tüm alanları silinmiştir.',
    'info.submit': 'Gönder',
    'info.login': 'Giriş',
    'info.forgotpassword': 'Şifreni mi unuttun ?',
    'info.username': 'Kullanıcı Adı',
    'info.name': 'İsim',
    'info.password': 'Şifre',
    'info.changePassword': 'Parola Değiştir',
    'info.oldPassword': 'Eski Parola',
    'info.newPassword': 'Yeni Parola',
    'info.repeatNewPassword': 'Yeni Parola Tekrarla',
    'info.successPas': 'Parolanız Değiştirilmiştir.',
    'info.errorPassword': 'Parolalar Eşleşmiyor!',
    'info.badPassword': 'Hatalı Parola',
    'info.blankField': 'Lütfen Boş Alanları Doldurunuz!',
    'info.profile': 'Profil',
    'info.selectUser': 'Kullanıcı Seçin',
    'info.store.info': 'Lokasyon Bilgisi',
    'info.selectStore': 'Lokasyon Seçin',
    'info.selectBrand': 'Firma Seçin',
    'info.categoryName': 'Kategori Adı',
    'info.addCategory': 'Kategori Ekle',
    'info.selectCategory': 'Kategori Seçin',
    'info.selectSubCategory': 'Alt Kategori Seçin',
    'info.RemoveCategory': 'Kategori Sil',
    'info.RemoveSubCategory': 'Alt Kategori Sil',
    'info.selectSaleCategory': 'Satış Kategorisi Seçin',
    'info.selectCamera': 'Kamera Seçin',
    'info.selectCamera2': 'Kamera Seç',
    'info.selectBoard': 'Kart Seçin',
    'info.selectReceiver': 'Alıcı Seçin',
    'info.selectArea': 'Bölge Seçin',
    'info.selectSketch': 'Kroki Seçin',
    'info.selectOperator': 'Operatör Seçin',
    'info.selectDatasource': 'Veri Kaynağı Seçin',
    'info.updateSketch': 'Krokiyi Güncelle',
    'info.set': 'Uygula',
    'info.save': 'Kaydet',
    'info.confirm': 'Onayla',
    'info.cancel': 'Vazgeç',
    'info.efficiencyScore': 'Verimlilik Skoru',
    'info.sec': 'Sn',
    'info.min': 'Dk',
    'info.minute': 'Dakika',
    'info.second': 'Saniye',
    'info.saveChanges': 'Değişiklikleri Kaydet',
    'info.addChart': 'Grafik Ekle',
    'info.always': 'Her Zaman',
    'info.remove': 'Kaldır',
    'info.delete': 'Sil',
    'info.edit': 'Düzenle',
    'info.archive': 'Arşivle',
    'info.archived': 'Arşivli',
    'info.revert': 'Geri Al',
    'info.addNewSketch': 'Yeni Kroki Ekle',
    'info.notification.edit': 'Bildirim Kuralını Düzenle',
    'info.notification.editComplex': 'Kapsamlı Bildirim Kuralını Düzenle',
    'info.notification.visitorCount': 'Temas(lar)',
    'info.notification.visitorTime': 'Sn',
    'info.notification.passiveStatusActive': 'Aktif',
    'info.notification.passiveStatusPassive': 'Pasif',
    'info.notification.Users': 'Hedef Kullanıcılar',
    'info.notification.peopleCount': 'Temas Sayısı',
    'info.excelChart.person': 'Temas',
    'info.excelChart.traffic': 'Trafik',
    'info.notification.timeSpent': 'Geçirilen Süre',
    'info.notification.timeRange': 'Zaman Aralığı',
    'info.notification.condition': 'Koşul',
    'info.notification.conditionPerson':
      'Küçüktür kuralında tespit edilen temas sayısı 0 olduğunda bildirim gönderilmez.',
    'info.word.and': 've',
    'info.status': 'Durum',
    'info.archivedDate': 'Arşivlenme Tarihi',
    'info.toCamera': 'Kameraya Git',
    'info.toSetting': 'Ayarlara Git',
    'info.Entrance': 'Giren',
    'info.Checkout': 'Çıkan',
    'info.sketchName': 'Kroki İsmi',
    'info.sketchRect': 'Kroki Çizimi',
    'info.cameraAndArea': 'Kamera / Bölge',
    'info.department': 'Alan',
    'info.newDepartment': 'Yeni Alan',
    'info.selectDepertment': 'Alan Seçiniz',
    'info.newDepartmentType.label': 'Alan Tipi',
    'info.newDepartmentType.checkOutWaitingArea': 'Kasa Bekleme Alanı',
    'info.newDepartmentType.customerServices': 'Müşteri Hizmetleri',
    'info.newDepartmentType.correlation': 'Korelasyon',
    'info.newDepartmentType.shelf': 'Reyon',
    'info.newDepartmentType.checkOut': 'Kasa',
    'info.newDepartmentType.ignore': 'Yoksay',
    'info.newDepartmentType.doorIn': 'Kapı İç',
    'info.newDepartmentType.doorOut': 'Kapı Dış',
    'info.newDepartmentType.doorLeft': 'Kapı Sol',
    'info.newDepartmentType.doorRight': 'Kapı Sağ',
    'info.newDepartmentType.AreaOfInterest': 'Takip Alanı',
    'info.newDepartmentType.YellowLineViolation': 'Sarı Çizgi İhlali',
    'info.newDepartmentType.firstStand': 'Giriş Standı',
    'info.rectName.placeholder': 'Alan adı',
    'info.rectName.label': 'Alan adı',
    'info.uploadCategoryFile': 'Kategori Dosyası Yükle',
    'info.uploadCategory':
      ' Dosya uzantısı .csv ve sütunlar sırasıyla kategori kodu, kategori adı olmalı.Ilk satırda sütun isimleri olmamalı.',
    'info.Success': 'Başarılı',
    'info.areas': 'Alanlar',
    'info.area': 'Alan',
    'info.total': 'Adet',
    'info.totalCount': 'Toplam Adet',
    'info.change': 'Değişim',
    'info.amount': 'Günlük Tutar',
    'info.prevAmount': 'Geçmiş Toplam',
    'info.metersquare': 'Metrekare',
    'info.metersquarerate': 'Metrekare Oranı',
    'info.endorsement': 'Ciro',
    'info.endorsement.detail':
      "Satış entegrasyonunda fiş bilgisi paylaşılıyorsa 'Fiş Sayısı/Müşteri Sayısı', fiş bilgisi paylaşılmıyorsa 'Satış Adedi/Müşteri Sayısı' formülü ile hesaplanmaktadır.",
    'info.traffic.detail':
      'Lokasyon trafik bilgisi çalışma saatlerine göre hesaplanmaktadır.',
    'info.traffic.detailThisWeek':
      'Trafik bilgisi seçilen saat aralığına göre hesaplanmaktadır.',
    'info.sales.detail':
      'Satış ve dönüşüm oranı verisi filtrelenen tarihe göre hesaplanmaktadır.',
    'info.sales': 'Satış Adedi',
    'info.table.detail': 'Tarih filtresinden etkilenerek hesaplanmaktadır.',
    'info.trafficTable.detail':
      'Sayfanın görüntülendiği güne göre hesaplanmaktadır.',
    'info.mean.time': 'Ortalama Süre',
    'info.startTimeEqualsEndTimeWarning':
      'Başlangıç ve bitiş zamanları aynı olamaz!',
    'info.openingTimeEqualsClosingTimeWarning':
      'Açılış ve kapanış zamanları aynı olamaz!',
    'info.closeTimeAfterMidnightWarning':
      'Kapanış zamanı gece yarısından sonradır!',
    'info.endTimeAfterMidnightWarning':
      'Bitiş zamanı gece yarısından sonradır!',
    'info.openTime': 'Açılış',
    'info.closeTime': 'Kapanış',
    'info.stimeHour': 'Başlangıç',
    'info.etimeHour': 'Bitiş',
    'info.stime': 'Başlangıç Tarihi',
    'info.etime': 'Bitiş Tarihi',
    'info.excel.hour': 'Saat',
    'info.chart': 'Grafik',
    'info.table': 'Tablo',
    'info.department.sales': 'Satışlar',
    'info.comparisonv2.percent': 'Yüzde',
    'info.rectangle.archive.accept':
      'Bölgeyi arşivlemek istediğinize emin misiniz ?',
    'info.rectangle.archive.undone': 'Bu işlem geri alınamaz.',
    'info.rectangle.arhive.reminding':
      'Alanın bu tarihten sonraki verileri silinecektir!',
    'info.line.arhive.reminding':
      'Çizginin bu tarihten sonraki verileri silinecektir!',
    'info.Department.Remove':
      'Seçili kategorileri, alt kategorileri ve alt kategorilerin bağlı olduğu kampanyaları silmek istediğinize emin misiniz ?',
    'info.SubDepartment.Remove':
      'Seçilen alt kategorileri ve bu kategorilere bağlı kampanyaları silmek istediğinizden emin misiniz ?',
    'info.RemoveCategories': 'Seçili kategorileri sil',
    'info.Date.Previous': 'Karşılaştırma Tarihi',
    'info.Date.Current': 'Geçerli Tarih',
    'info.process.date': 'Geçmiş Verilerin Hesaplama Başlangıç Tarihi',
    'info.notification.delete': 'Bildirim silinecektir.',
    'info.rotate': 'döndür',
    'info.limit': 'Sınır',
    'info.Exceeded': 'Aşıldı',
    'info.exceeded.provided': 'Aşılamadı',
    'info.group.name': 'Grup Adı',
    'info.sub.Category.group.name': 'Alt Kategori Grup Adı',
    'info.sub.Category.group': 'Alt Kategori Grup',
    'info.sub.Category.edit': 'Alt Kategori Düzenle',
    'info.title.storeInOut': 'Lokasyon Giriş-Çıkış',
    'info.title.storeAvailabilityTime': 'Lokasyonda Bulunma Süresi',
    'info.title.storeDetailInOut': 'Giriş-Çıkış Saati',
    'info.newSketch.alert':
      'Bu özellik sadece; aynı lokasyonda farklı bir kat eklemek içindir. Lokasyon güncellemesi için Krokiyi Güncelle butonunu kullanınız.',
    'info.showValues': 'Değerleri Göster',

    'info.mean.interestRate': 'İlgi Oranı',
    'info.mean.conversionRate': 'Satışa Dönüşüm Oranı',
    'info.15s': '15s/Giren',
    'info.storeShare': 'Lokasyon İçi',
    'info.storeShareChange': 'Lokasyon İçi Değişimi',
    'info.storeChange': 'Kişi*Süre Değişim',
    'info.dailyAvarage': 'Günlük Ortalama',
    'info.dailyquantity': 'Günlük Adet',
    'info.peakDensityHour': 'En Yoğun Saat',
    'info.instore':
      'Bölgenin lokasyon içerisinde yoğunluktan aldığı pay.(Yoğunluk = (Ortalama Temas Sayısı * Geçirilen Ortalama Süre )/ Toplam Temas Sayısı * Toplam Süre), Yoğunluk Haritasında Görünen Oran',
    'info.instore.change':
      'Bölgenin lokasyon içerisinde yoğunluktan aldığı payın karşılaştırma tarihine göre değişimi.(Seçilen 2.tarih aralığının yoğunluk oranı - Seçilen 1.tarih aralığının yoğunluk oranı) ',
    'info.instorechange':
      'Bölgedeki kişi ve süre değişimine bağlı olarak yoğunlukta gözlenen değişim.(Kişi* Süre Değişimi = (2.tarih aralığındaki Ortalama Ziyaretçi Sayısı*Geçirilen Ortalama Süre - 1.tarih aralığındaki Ortalama Ziyaretçi Sayısı*Geçirilen Ortalama Süre)/ 1.tarih aralığındaki Ortalama Ziyaretçi Sayısı*Geçirilen Ortalama Süre)',
    'info.performancetable.peakDensityHour':
      'Bölgedeki yoğunluğun zirve yaptığı saat.',
    'info.performancetable.count': 'Bölgedeki günlük ortalama temas sayısı.',
    'info.performancetable.totalCount': 'Bölgedeki toplam temas sayısı.',
    'info.performancetable.ShopInShopFrontCount':
      'Shop-in-Shop Önünden Geçen Müşteri Sayısı.',
    'info.performancetable.ShopInShopEntranceCount':
      'Shop-in-Shop’a Giriş Yapan Müşteri Sayısı.',
    'info.performancetable.ShopInShopConversionRatio':
      'Shop-in-Shop Satış Adedi / Shop-in-Shop Ziyaretçi Sayısı.',
    'info.performancetable.ShopInShopFrontCountChange':
      'Shop-in-Shop Önünden Geçen Müşteri Sayısı Değişimi.',
    'info.performancetable.ShopInShopEntranceCountChange':
      'Shop-in-Shop’a Giriş Yapan Müşteri Sayısı Değişimi.',
    'info.performancetable.ShopInShopConversionRatioChange':
      'Shop-in-Shop Satış Adedi / Shop-in-Shop Ziyaretçi Sayısı Değişimi.',
    'info.performancetable.change': 'Bir önceki tarih aralığına göre.',
    'info.performancetable.prevchange':
      'Bölgedeki ortalama temas sayısındaki değişim.(Değişim=(2.tarih aralığındaki ortalama temas sayısı - 1.tarih aralığındaki ortalama temas sayısı) / 1.tarih aralığındaki ortalama temas sayısı)',
    'info.performancetable.time':
      'Bölgedeki giren, geçen ortalama temas sayısının bölgede geçirdiği ortalama süre.(Ortalama Süre = Toplam süre / Gün Sayısı)',
    'info.performancetable.timechange':
      'Bölgedeki giren, geçen ortalama temas sayısının bölgede geçirdiği ortalama sürenin değişimi.(Değişim=(2.tarih aralığında bölgede geçirilen ortalama süre - 1.tarih aralığında bölgede geçirilen ortalama süre )/1.tarih aralığında bölgede geçirilen ortalama süre değişimi)',
    'info.performancetable.15s':
      'Bölgede 15sn ve üzeri geçiren ziyaretçilerin, bölgedeki ziyaretçi sayısına oranı.(toplam 15sn ve üzeri geçiren ziyaretçi / toplam ziyaretçi sayısı)',
    'info.performancetable.15schange':
      'Bölgede 15sn ve üzeri geçiren ziyaretçilerin, bölgedeki ziyaretçi sayısına oranının değişimi.(Değişim=(2.tarih aralığındaki 15.sn ve üzeri geçiren ziyaretçi sayısı / toplam ziyaretçi sayısı - 1.tarih aralığındaki 15.sn ve üzeri geçiren ziyaretçi sayısı / toplam ziyaretçi sayısı)/1.tarih aralığındaki 15.sn ve üzeri geçiren ziyaretçi sayısı / toplam ziyaretçi sayısı)',
    'info.performancetable.conversionRate':
      'Bölgede gerçekleşen toplam satış adedinin, bölgede 15sn ve üzeri geçiren toplam ziyaretçi sayısına oranı .(toplam satış adedi / toplam 15sn ve üzeri geçiren ziyaretçi sayısı)',
    'info.performancetable.conversionChange':
      'Bölgede gerçekleşen toplam satış adedinin, bölgede 15sn ve üzeri geçiren toplam ziyaretçi sayısına oranının değişimi. (Değişim=(2.tarih aralığındaki toplam satış adedi / toplam 15.sn ve üzeri geçiren ziyaretçi sayısı - 1.tarih aralığındaki toplam satış adedi / toplam 15.sn ve üzeri geçiren ziyaretçi sayısı) / 1.tarih aralığındaki toplam satış adedi / toplam 15.sn ve üzeri geçiren ziyaretçi sayısı)',
    'info.performancetable.prevsalestotal':
      'Karşılaştırma tarihindeki toplam satış adedi.',
    'info.performancetable.salestotal':
      'Karşılaştırılan tarihteki toplam satış adedi.',
    'info.performancetable.salestotalchange':
      'Toplam satış adedindeki değişim.',
    'info.performancetable.sale':
      'Bölgedeki günlük ortalama satış adedi.(Ortalama satış adedi = Toplam satış adedi / Gün Sayısı)',
    'info.performancetable.saledensity':
      'Bölgedeki yoğunluğun satış adedine dönüşüm oranı. Birim yoğunluk başına düşen satış adedi.(Satış Adedi/Yoğunluk = (Ortalama satış adedi/Ortalama yoğunluk oranı)',
    'info.performancetable.saledensitychange':
      'Birim yoğunluk başına düşen satış adedindeki değişim.(Değişim = 2.tarih aralığındaki Ortalama satış adedi/Ortalama yoğunluk oranı-(1.tarih aralığındaki Ortalama satış adedi/Ortalama yoğunluk oranı)/1.tarih aralığındaki Ortalama satış adedi/Ortalama yoğunluk oranı)',
    'info.performancetable.prevtotalsaleamount':
      'Karşılaştırma tarihindeki toplam satış tutarı.',
    'info.performancetable.totalsaleamount':
      'Karşılaştırılan tarihteki toplam satış tutarı.',
    'info.performancetable.totalchangesaleamount':
      'Toplam satış tutarındaki değişim.',
    'info.performancetable.saleamount':
      'Bölgedeki günlük ortalama satış tutarı.',
    'info.performancetable.endorsement':
      'Bölgedeki yoğunluğun satış tutarına dönüşüm oranı. Birim yoğunluk başına düşen satış tutarı.(Satış Tutarı/Yoğunluk =( satış tutarı/ yoğunluk)',
    'info.performancetable.prevsale':
      'Birim yoğunluk başına düşen satış tutarındaki değişim.(Değişim=(2.tarih aralığındaki Ortalama satış tutarı/ yoğunluk-1.tarih aralığındaki satış tutarı/ yoğunluk)/1.tarih aralığındaki satış tutarı/ yoğunluk)',
    'info.performancetable.visitor15sec':
      'Reyonlarda ortalama 15 saniye geçiren ziyaretçinin, toplam ziyaretçiye oranı. ',
    'info.performancetable.sale15sec':
      'Şatış adedinin, 15 saniye vakit geçiren ziyaretçilere oranı.',
    'info.performancetable.metersquarerate':
      'Bölgenin lokasyon içerisindeki metre karesi.',
    'info.performancetable.saleamountoverdensity':
      'Bölgenin lokasyon içerisinde metre kareden aldığı pay.',

    'info.Suggestion.HasSuggestion1': 'Öneri çıkan kategoriler',
    'info.Suggestion.HasSuggestion2': 'ikonu ile belirtilmektedir.',

    'routes.uploadReports': 'Rapor Yükle',
    'routes.floors': 'Katlar',
    'routes.reports': 'Raporlar',
    'routes.ageGender': 'Yaş<br/>Cinsiyet',
    'routes.employeeMonitoring': 'Personel<br/>Takibi',
    'routes.settings': 'Ayarlar',
    'routes.comparison': 'Lokasyon Karşılaştırma',
    'routes.sisComparison': 'Shop-in-Shop Kıyaslama',
    'routes.sketchDeparment': 'Yoğunluk Haritası',
    'routes.charts': 'Grafikler',
    'routes.heatmap': 'Isı Haritası',
    'routes.store': 'Lokasyon',
    'routes.company': 'Şirket',
    'routes.comparisonV2': 'Kategori Karşılaştırma',
    'routes.performance.table': 'Performans Tablosu',
    'routes.Company': 'Şirket',
    'routes.Suggestion.performanceAnalysis': 'Performans Analizi',
    'routes.Suggestion.performanceChange': 'Performans Değişimi',
    'routes.Suggestion.Request': 'Öneri Talebi',
    'routes.personnelv2': 'PersonelV2',
    'routes.personnel': 'Personel',
    'routes.trends': 'Trendler',
    'routes.analysis': 'Analiz',
    'routes.analysis.request': 'Analiz Talebi',
    'routes.ageGenderComp': 'Yaş & Cinsiyet Karşılaştırma',
    'routes.Iframe': 'Özel Raporlama',
    'global.change.request': 'Değişim Talebi',
    'routes.heatmap.detail': 'Isı Haritası Detay',
    'routes.FAQ': 'S.S.S.',
    'routes.checkout': 'Kasa',
    'routes.HomePage': 'Anasayfa',
    'global.camera': 'Kamera',
    'global.checkoutSummary': 'Kasa Özet',
    'treeSelect.inlineSearchPlaceholder': 'Arayınız...',
    'treeSelect.noMatches': 'Eşleşme bulunamadı.',
    'treeSelect.placeholder': 'Seçiniz...',
    'global.cameras': 'Kameralar',
    'global.area': 'Bölge',
    'global.notifications': 'Bildirimler',
    'global.add': 'Ekle',
    'global.edit': 'Düzenle',
    'global.cancel': 'Kapat',
    'global.save': 'Kaydet',
    'global.remove': 'Kaldır',
    'global.delete': 'Sil',
    'global.notificationsv2': 'Bildirimler V2',
    'global.basicNotifications': 'Basit Bildirimler',
    'global.detailedNotifications': 'Kapsamlı Bildirimler',
    'global.left': 'Sol',
    'global.right': 'Sağ',
    'global.middle': 'Orta',
    'notificationsV2.startNotificationPrompt':
      'Bildirim gönderimini başlatmak istediğinize emin misiniz?',
    'notificationsV2.stopNotificationPrompt':
      'Bildirim gönderimini durdurmak istediğinize emin misiniz?',
    'notificationsV2.TimeoutThreshold': 'Zaman Aşımı (sn)',
    'notificationsV2.viewComplexRule': 'Kapsamlı Kural Görüntüle',
    'notificationsV2.createComplexRule': 'Kapsamlı Kural Oluştur',
    'notificationsV2.inactiveChannels':
      'Kullanıcı Tarafından Devre Dışı Bırakılmış Kanallar',
    'notificationsV2.UserIds': 'Kullanıcılar',
    'notificationsV2.trTemplate': 'Türkçe Şablon',
    'notificationsV2.enTemplate': 'İngilizce Şablon',
    'notificationsV2.RuleTree': 'Kural Ağacı',
    'notificationsV2.ruleTreeTitle': 'Kural Ağacı Düzenleme',
    'notificationsV2.channels': 'Kanallar',
    'notificationsV2.Mail': 'Mail',
    'notificationsV2.Mobile': 'Mobil',
    'notificationsV2.Web': 'Web',
    'notificationsV2.Name': 'İsim',
    'notificationsV2.Description': 'Açıklama',
    'notificationsV2.Days': 'Günler',
    'notificationsV2.TimeRange': 'Zaman Aralığı',
    'notificationsV2.MaxSendFrequencySec': 'Gönderim Sıklığı (sn)',
    'notificationsV2.DataSourceType': 'Veri Kaynağı Tip',
    'notificationsV2.Line': 'Çizgi',
    'notificationsV2.TimeScopeNumber': 'Zaman Kapsamı Değeri',
    'notificationsV2.TimeScopePeriod': 'Zaman Kapsamı Birimi',
    'notificationsV2.Polygon': 'Poligon',
    'notificationsV2.ObjectType': 'Nesne',
    'notificationsV2.ComparisonOperator': 'Koşul',
    'notificationsV2.Threshold': 'Eşik Değeri',
    'notificationsV2.EqualTo': 'Eşittir',
    'notificationsV2.LessThanOrEqual': 'Az veya Eşittir',
    'notificationsV2.GreaterThanOrEqual': 'Fazla veya Eşittir',
    'notificationsV2.LessThan': 'Azdır',
    'notificationsV2.GreaterThan': 'Fazladır',
    'notificationsV2.ObjectCount': 'Kişi Sayısı',
    'notificationsV2.TimeUnit': 'Zaman Birimi',
    'notificationsV2.linePlaceholder': 'Çizgi seçiniz...',
    'notificationsV2.polygonPlaceholder': 'Poligon seçiniz...',
    'notificationsV2.comparisonOperatorPlaceholder': 'Koşul seçiniz...',
    'notificationsV2.objectPlaceholder': 'Obje seçiniz...',
    'notificationsV2.daysPlaceholder': 'Günleri seçiniz...',
    'notificationsV2.Second': 'Saniye',
    'notificationsV2.Minute': 'Dakika',
    'notificationsV2.Hour': 'Saat',
    'notificationsV2.Day': 'Gün',
    'notificationsV2.Week': 'Hafta',
    'notificationsV2.Month': 'Ay',
    'notificationsV2.Year': 'Yıl',
    'notificationsV2.Sunday': 'Pazar',
    'notificationsV2.Monday': 'Pazartesi',
    'notificationsV2.Tuesday': 'Salı',
    'notificationsV2.Wednesday': 'Çarşamba',
    'notificationsV2.Thursday': 'Perşembe',
    'notificationsV2.Friday': 'Cuma',
    'notificationsV2.Saturday': 'Cumartesi',
    'notificationsV2.removeRule': 'Kuralı Kaldır',
    'notificationsV2.addNewRule': 'Kural Ekle',
    'notificationsV2.LineNegativeDirection': 'Çıkış',
    'notificationsV2.LinePositiveDirection': 'Giriş',
    'notificationsV2.PolygonDwellTime': 'Bulunma Süresi',
    'notificationsV2.PolygonRepeatedEntrancesCount': 'Giriş Sayısı',
    'notificationsV2.PolygonNonRepeatedEntrancesCount': 'İnsan Sayısı',
    'global.actionButtons': 'Aksiyon Tuşları',
    'global.back': 'Geri',
    'global.next': 'İleri',
    'global.submit': 'Gönder',
    'global.requiredField': 'Bu alanı doldurmak zorunludur.',
    'global.minLengthError': 'Bu alana en az {value} giriş yapılmalıdır.',
    'global.occupancy': 'Doluluk',
    'global.sketch': 'Kroki',
    'global.SaleUploading': 'Satış Yükleme',
    'global.CameraSnapshot': 'Uygulama Kontrol',
    'global.map': 'Harita',
    'global.category': 'Kategori',
    'global.subcategory': 'Alt Kategori',
    'global.categories': 'Kategoriler',
    'global.departments': 'Alanlar',
    'global.sale': 'Satış',
    'global.sale.m2': 'Satış(Tutar)/M2',
    'global.shelf': 'Reyon',
    'global.WorkingHours': 'Çalışma Saatleri',
    'global.WorkingHoursWarningMessage': 'Çalışma saatleri ayarlanmamıştır.',
    'global.StoreOpen-Close': 'Lokasyon Açılış - Kapanış Saatleri',
    'global.WorkHoursOpen': 'Açık',
    'global.Save': 'Kaydet',
    'global.Search': 'Ara',
    'global.logout': 'Çıkış',
    'global.summarypage': 'Özet',
    'global.brandPerformanceTable': 'Performans Tablosu',
    'global.Forecast': 'Forecast',
    'global.selectStore': 'Bir lokasyon seçmelisiniz.',
    'global.region': 'Bölge',
    'global.regionlessStores': 'Bölgesiz Lokasyonlar',
    'global.somethingWentWrong': 'Bir şeyler yanlış gitti.',

    'resetPassword.goToLoginPage': 'Giriş Sayfasına Git',

    'excel.header.averagePerson': 'Ortalama Kişi',

    'chart.title.customerDwell': 'Kişi Süre Grafiği',
    'chart.title.mostVisisted': 'En Çok Ziyaret Edilen Alanlar (temas adet)',
    'chart.title.mostTimeSpent': 'En Çok Vakit Geçirilen Alanlar (kişi sn)',
    'chart.title.conversionRate': 'Dönüşüm Oranı',
    'chart.title.averageConversionRate': 'Ortalama Dönüşüm Oranı',
    'chart.title.invoices': 'Fatura Adedi',
    'chart.title.averageInvoices': 'Ortalama Fatura Ürün Sayısı',
    'chart.title.customerNumber': 'Ziyaretçi Sayısı Grafiği',
    'chart.title.customerNumberTable': 'Ziyaretçi Sayı Değişimi',
    'chart.title.customerTimeTable': 'Ziyaretçi Süre Değişimi',
    'chart.title.dwellTime': 'Ortalama Süre Grafiği',
    'chart.title.departmentAnalysis': 'Bölge Analizi',
    'chart.title.funnelChart': 'Edinme Hunisi',
    'chart.title.correlations': 'Korelasyonlar',
    'chart.title.notifications': 'Bildirimler',
    'chart.title.weatherAndTraffic': 'Hava Durumu Ve Trafik',
    'chart.title.trafficAndSales': 'Trafik ve Satış Grafiği',
    'chart.title.conversionRateMarket': 'Dönüşüm Oranı (Market Trafiği)',
    'chart.title.conversionRateVehicle': 'Dönüşüm Oranı (Toplam Araç Trafiği)',
    'chart.title.vehicleNumberAndMarket': 'Araç Sayısı ve Market Trafiği',
    'chart.title.customerDirection': 'Lokasyon İçi Ziyaretçi Yönelimi',
    'chart.title.totalCustomer': 'Toplam Ziyaretçi',
    'chart.title.genderDistr': 'Cinsiyet Dağılımı',
    'chart.title.ageDistr': 'Yaş Dağılımı',
    'chart.legend.customer': 'Temas',
    'chart.legend.timeSpent': 'Süre',
    'chart.legend.date': 'Tarih',
    'chart.legend.density': 'Yoğunluk',
    'chart.legend.density/m2': 'Yoğunluk/M2',
    'chart.legend.m2': 'Metrekare',
    'chart.legend.customerCount': 'Ziyaretçi Sayısı',
    'chart.legend.dwellTime': 'Geçirilen Süre',
    'chart.legend.productCount': 'Ürün Sayısı',
    'chart.legend.totalAmount': 'Toplam Tutar',
    'chart.legend.weather': 'Hava Durumu',
    'chart.filter.hourly': 'Saatlik',
    'chart.filter.daily': 'Günlük',
    'chart.prevWeek': 'Önceki Hafta',
    'chart.currWeek': 'Şimdiki Hafta',
    'landingPage.description1':
      'Analiz detaylarına ulaşmak istediğiniz ürünü seçiniz',
    'landingPage.description2':
      'İmleci kategorilerin üstüne getirerek hangi işlemleri yapabileceğinizi görebilirsiniz',
    'products.ANALYTIC': 'Mağaza İçi Analitik',
    'products.ENTRANCE': 'Kapı Sayım',
    'products.CHECKOUT': 'Kasa',
    'products.PERSONNEL': 'Personel Takip',
    'products.AGE_GENDER': 'Yaş Cinsiyet',
    'products.categories.BRAND': 'Şirket',
    'products.categories.STORE': 'Lokasyon',
    'products.categories.DENSITY_MAP': 'Yoğunluk Haritası',
    'products.categories.PERFORMANCE_TABLE': 'Performans Tablosu',
    'products.categories.TRENDS': 'Trendler',
    'products.categories.SUGGESTIONS': 'Öneriler',
    'products.categories.CHECKOUT': 'Kasa',
    'products.categories.PERSONNEL': 'Personel',
    'products.categories.CAMPAIGN': 'Kampanya',
    'products.categories.CAMERA': 'Kamera',
    'products.categories.RECEIVER': 'Alıcılar',
    'products.categories.AGE_GENDER': 'Yaş Cinsiyet',
    'products.categories.STORE_COMPARISON': 'Lokasyon Karşılaştırma',
    'products.categories.CATEGORY_COMPARISON': 'Kategori Karşılaştırma',
    'landingPage.searchPlaceholder':
      'Anahtar Kelime Giriniz (Örn: Kişi Sayısı)',
    'landingPage.backToProducts': 'Ürünlere Dön',
    'products.categories.description.DENSITY_MAP':
      '1) Kroki üzerinde yoğunluk, temas, süre ve ilgi oranı verilerini inceleme -/&/- 2) Alan verilerini metrekare ve (varsa) satış verileriyle karşılaştırma',
    'products.categories.description.PERFORMANCE_TABLE':
      '1) Alanlara ait metrikleri tablo halinde inceleme -/&/- 2) İki tarih arasındaki metrikleri karşılaştırma',
    'products.categories.description.TRENDS':
      '1) Alanların ardışık olarak yükseliş veya düşüş trendini inceleme',
    'products.categories.description.SUGGESTIONS':
      '1) Alanlar özelinde bilgisayar destekli otomatik önerileri görüntüleme',
    'products.categories.description.CHECKOUT':
      '1) Kasa performansını inceleme -/&/- 2) Lokasyon ve tarihe göre verileri kıyaslama',
    'products.categories.description.CAMERA':
      '1) Isı haritasını ve alan tanımlamalarını kamera görüntüleri üzerinde inceleme',
    'products.categories.description.RECEIVER':
      '1) Personel-yoğunluk ilişkisi inceleme',
    'products.categories.description.PERSONNEL':
      '1) Personel verimini kontrol etme',
    'products.categories.description.AGE_GENDER':
      '1) Lokasyon demografisini inceleme',
    'products.categories.description.BRAND':
      '1) Sistemdeki tüm lokasyonlara ait verileri bir arada görüntüleme',
    'products.categories.description.STORE':
      '1) Bir lokasyonya ait giriş bilgilerini görüntüleme -/&/- 2) Kasa performansını özet olarak inceleme -/&/- 3) En iyi metriklere sahip alanları görüntüleme',
    'products.categories.description.STORE_COMPARISON':
      '1) İki farklı lokasyonun alan metriklerini karşılaştırma',
    'products.categories.description.CATEGORY_COMPARISON':
      '1) Birçok lokasyonun kategorilerini karşılaştırma',
    'products.noSelectedStoreProduct':
      'Ürünleri görebilmek için bir lokasyon seçmelisiniz.',
    'brand.generalGraph.VISITOR_COUNT': 'Ziyaretçi Sayısı',
    'brand.generalGraph.ENDORSEMENT': 'Ciro',
    'brand.generalGraph.CONVERSION_RATE': 'Dönüşüm Oranı',
    'brand.generalGraph.RECEIPT_COUNT': 'Fiş Adedi',
    'brand.generalGraph.SALE_COUNT': 'Satış Adedi',
    'brand.generalGraph.ENDORSEMENT_PER_M2': 'Ciro/M2',
    'brand.generalGraph.ENTRANCE_RATE': 'İçeri Çekilme Oranı',
    'chart.info.densityMap':
      'Seçilen zaman aralığında ziyaretçi yoğunluğunun reyon kırılımını verir.',
    'chart.button.get': 'Getir',
    'chart.button.select': 'Tümünü Seç',
    'chart.button.clear': 'Temizle',
    'chart.color.scale': 'Performans Göstergesi',
    'chart.sale.quantity': 'Satış (Adet)',
    'chart.sale.amount': 'Satış (tutar)',
    'chart.sale.quantityheader': 'Satış adedi',
    'chart.sale.amountheader': 'Satış tutarı',
    'chart.sale.amountheaderCamelCase': 'Satış Tutarı',
    'chart.entrance': 'Giren',
    'chart.PassByCount.ToLeft': 'Sağdan Sola ',
    'chart.PassByCount.ToRight': 'Soldan Sağa ',
    'chart.passByCount.header': 'Lokasyon Önünden Geçen Adedi',
    'chart.passByCustomerCount.header':
      'Lokasyon Önünden Geçen Ziyaretçi Sayısı',
    'chart.over15sec': '15 sn Aşan Kişi',
    'chart.over30sec': '30 sn Aşan Kişi',
    'chart.over60sec': '60 sn Aşan Kişi',
    'chart.over90sec': '90 sn Aşan Kişi',
    'chart.over120sec': '2 dk Aşan Kişi',
    'chart.over180sec': '3 dk Aşan Kişi',
    'chart.dwell15sec': '15 sn Üzeri Ortalama Süre',
    'chart.dwell60sec': '60 sn Üzeri Ortalama Süre',
    'chart.dwell120sec': '2 dk Üzeri Ortalama Süre',
    'chart.Over15SecDwell': '15 sn üzeri geçiren ortalama süre (sn)',
    'chart.TotalCount': 'Toplam Kişi',
    'chart.AvgDwell': 'Ortalama bekleme süresi(sn)',
    'chart.tooltip.daily': ' günlük ',
    'chart.tooltip.status.increase': 'den kaynaklı artış ',
    'chart.tooltip.status.decrease': 'den kaynaklı azalış ',
    'chart.tooltip.observed': ' gözlemlenmektedir.',
    'chart.tooltip.time': 'Süre',
    'chart.tooltip.time.lowercase': 'süre',
    'chart.tooltip.person': 'Temas',
    'chart.line.in': 'Giren',
    'chart.line.out': 'Çıkan',
    'chart.Top': 'En Yüksek',
    'chart.Bottom': 'En Düşük',
    'chart.CustomerCount': 'Alana Giriş Sayısı & Ortalama Geçirilen Süre',
    'chart.allCategories': 'Tüm Kategoriler',
    'chart.subcategory': 'Alt Kategori',
    'chart.categories': 'Kategoriler',
    'chart.totalCustomers': 'İçeri Girenler',
    'chart.totalCustomersRate': 'İçeri Çekilme Oranı',
    'performanceTable.tooltip.hasSuggestion':
      'Öneri mevcuttur. Görüntülemek için tıklayınız.',

    'chart.dwellTime': 'Süre',
    'Trends.Title': 'Trendler',
    'Trends.Title.description':
      'Mevcut alanlarınızda bulunan yükseliş ve azalış eğilimlerini gösterir.',
    'Trends.sketch': 'Trend Haritası',
    'Trends.sketch.description':
      'Seçilen son günde devam eden trendlerinizi gösterir.',
    'Trends.Suggestion.Info1': ' ',
    'Trends.Suggestion.Info2': ' kategori için öneriniz mevcuttur.',
    'trends.time': 'Trend Süresi: ',
    'trends.reason': 'Kaynak: ',

    'trends.sale': 'Satış',
    'trends.density': 'Yoğunluk',
    'trends.interest': 'İlgi',
    'trends.visitor': 'Temas',
    'trends.meantime': 'Süre',

    'trends.chartMode': 'Grafik modu',
    'trends.mapMode': 'Harita modu',
    'trends.daily': 'Günlük',
    'trends.weekly': 'Haftalık',
    'trends.monthly': 'Aylık',
    'trends.increase': 'Artan',
    'trends.decrease': 'Azalan',
    'trends.tooltip.increaseing.daily': ' ',
    'trends.tooltip.decreaseing.daily': ' ',
    'trends.tooltip.increase.daily': 'Gündür Artmaktadır',
    'trends.tooltip.decrease.daily': 'Gündür Azalmaktadır',
    'trends.tooltip.increaseing.weekly': ' ',
    'trends.tooltip.decreaseing.weekly': ' ',
    'trends.tooltip.increase.weekly': 'Haftadır Artmaktadır',
    'trends.tooltip.decrease.weekly': 'Haftadır Azalmaktadır',
    'trends.tooltip.increaseing.monthly': ' ',
    'trends.tooltip.decreaseing.monthly': ' ',
    'trends.tooltip.increase.monthly': 'Aydır Artmaktadır',
    'trends.tooltip.decrease.monthly': 'Aydır Azalmaktadır',

    'trends.excel.daily.increase': 'Günlük Artış',
    'trends.excel.weekly.increase': 'Haftalık Artış',
    'trends.excel.monthly.increase': 'Aylık Artış',
    'trends.excel.daily.decrease': 'Günlük Düşüş',
    'trends.excel.weekly.decrease': 'Haftalık Düşüş',
    'trends.excel.monthly.decrease': 'Aylık Düşüş',

    'info.department.over': 'üzeri',
    'info.storecomparison.percentage': 'oranı',
    'info.SaleUploading.extension': 'Dosya uzantısı ".xlsx" olmalı.',
    'info.SaleUploading.Overlay.Sure':
      'Verileri göndermek istediğinize emin misiniz?',
    'info.SaleUploading.undone': 'Bu işlem geri alınamaz!',
    'info.Category.File.Upload': 'Kategori dosyası yükle',
    'info.Sketch.Photo.Update':
      'Resim boyutları farklı ise bazı kameraları ve çizimleri göremeyebilirsiniz.',
    'info.Sale.Ratio': 'Satış Oranı',
    'info.SeeAll': 'Tümünü Gör',
    'info.CloseAll': 'Tümünü Kapat',
    'info.All': 'Tümü',
    'info.Camera.Exit':
      'Değişiklikleri kaydetmediniz, yine de çıkış yapmak istiyor musunuz ?',
    'info.Camera.Change':
      'Değişiklikleri kaydetmediniz, yine de kamera değiştirmek istiyor musunuz ?',
    'info.PrivacyPolicy':
      'Gizlilik sözleşmemiz güncellenmiştir, görüntülemek için ',
    'info.PrivacyPolicy.Link': 'tıklayınız.',
    'info.InterestRate': 'İlgi Oranı',
    'info.Location': 'Lokasyon',

    'error.SaleUploading.SampleExcel': 'Örnek Excel',
    'error.SaleUploading.DateFormat': 'Tarih formatını kontrol ediniz! ',
    'error.SalexUploading.ColumnType': ' Kolon tipi hatası',
    'error.SaleUploading.fileFormat': 'Dosyayı istenilen formatta gönderiniz!',
    'error.SaleUploading.ChangeText': ' olarak değiştiriniz.',
    'error.SalesUploading.AddHeader':
      'Başlık örnek exceldeki gibi eklenmelidir.',
    'error.SalesUploading.EmptyCell': 'Boş hücreler Silinecektir! ',
    'error.SalesUploading.EmptycellCount': ' tane boş satır bulunundu!',
    'error.Mark.Cam.ToSketch':
      'Yoğunluk verilerini görmek için kamerayı kroki üzerinde işaretleyiniz.',
    'error.noData': 'Veri bulunmamaktadır.',
    'error.invalidEmail': 'Geçersiz E-Posta!',
    'error.PolygonCornerDelete': 'Bir polygon, en az 3 köşe içermelidir',
    'error.notAvailable': 'Veri bulunmuyor!',

    'button.info.showAreas': 'Alanları Göster',
    'button.info.showCameras': 'Kameraları Göster',
    'button.info.showPlan': 'Planı Göster',
    'button.info.changeGroup': 'Grup Değiştir',
    'button.info.setCameraLocation': 'Kamera İşaretle',
    'button.info.PrivacyPolicy': 'Kabul Ediyorum',
    'button.info.showSuggestions': 'Önerileri Göster',
    'button.info.setBoard': 'Kart İşaretle',
    'button.info.setReceiver': 'Alıcı İşaretle',
    'button.info.Board': 'Kart',
    'button.info.Receiver': ' Alıcı',
    'button.info.ReceiverType': 'Alıcı Tipi',
    'button.info.goBack': 'Geri Dön',
    'button.unconnectedAreas': 'Bağlanmamış Alanlar',
    'button.oldSketches': 'Eski Krokileri göster',

    'reports.allReports': 'Tüm Raporlar',
    'reports.startDate': 'Başlangıç Tarihi',
    'reports.endDate': 'Bitiş Tarihi',
    'reports.reportName': 'Rapor İsmi',
    'reports.dateRange': 'Tarih Aralığı',
    'reports.downloadReport': 'Raporu İndir',
    'reports.deleteReport': 'Raporu Sil',
    'reports.pleaseSelectReport': 'Yüklemek için bir rapor dosyası seçiniz',
    'reports.selectReport': 'Rapor Seçiniz',
    'reports.uploadReport': 'Rapor Yükle',

    'Date.Today': 'Bugün',
    'Date.Yesterday': 'Dün',
    'Date.ThisWeek': 'Bu Hafta',
    'Date.LastWeek': 'Geçen Hafta',
    'Date.ThisMonth': 'Bu Ay',
    'Date.LastMonth': 'Geçen Ay',
    'Date.Last15Days': 'Son 15 Gün',
    'Date.Last30Days': 'Son 30 Gün',
    'Date.DaysUpToToday': 'Gün öncesi',
    'Date.DaysStartingToday': 'Gün sonrasından bugüne',
    'Date.weekly': 'Haftalık',
    'Date.weekdays': 'Hafta içi',
    'Date.weekend': 'Hafta sonu',
    'Date.day': ' Gün ',
    'Date.week': 'Hafta',
    'Date.month': 'Ay',

    'Tour.Info.home.Navigation':
      'Panelde incelemek istediğiniz başlığa tıklayın.',
    'Tour.Info.home.SelectStore': 'İncelemek istediğiniz lokasyonu seçin.',
    'Tour.Info.home.SelectDate': 'Tarih aralığını seçin.',
    'Tour.Info.home.SelectDaily':
      'Veriyi saatlik veya günlük görüntüleyeceğinizi seçin.',
    'Tour.Info.home.SelectLang': 'Dili seçin.',
    'Tour.Info.home.ShowProfile': 'Profilinizi görüntüleyin veya çıkış yapın.',
    'Tour.Info.home.CustomerDwellChart':
      'Seçilen tarih ve zaman aralığına göre lokasyondaki günlük ziyaretçi sayısını ve geçirilen ortalama süreyi verir.',
    'Tour.Info.home.WeekendColorizerChart':
      'Hafta sonlarını turuncu renkler ifade etmektedir.',
    'Tour.Info.home.ChartButtons':
      'Kişi-Süre veya Yoğunluk grafiğini inceleyin. Tabloda kullanılan verileri Excel olarak indirin.',
    'Tour.Info.home.MostVisitedTable':
      'Lokasyonda en çok ziyaret edilen departmanları gösterir. Detaylı bilgi almak için üstlerine tıklayın.',
    'Tour.Info.home.DwellTable':
      'Lokasyon içinde geçirilen vaktin en fazla olduğu departmanları gösterir. Detaylı bilgi almak için üstlerine tıklayın.',
    'Tour.Info.home.ConversionRate': 'Yoğunluğun satışa dönüşüm oranını verir.',
    'Tour.Info.home.TourInfoButton':
      'Sayfa yeniden bilgi edinmek için buraya tıklayabilirsiniz.',

    'Tour.Info.area.densityArea':
      'Lokasyondaki Yoğunluk verilerinin alan bazlı dağılımını gösterir.',
    'Tour.Info.area.quantityArea':
      'Satış(Ürün Sayısı) verilerinin alan bazlı dağılımını gösterir.',
    'Tour.Info.area.amountArea':
      'Satış(Toplam Tutar) verilerinin alan bazlı dağılımını gösterir.',
    'Tour.Info.area.SketchRect':
      'İmlecinizi bir alanın üzerine getirerek lokasyon içindeki yoğunluk oranını, kapladığı m2 oranını ve yoğunluk/m2 oranını inceleyin.',
    'Tour.Info.area.areaHamburgerMenu':
      'Alt menüleri gösterebilir ve gizleyebilirsin.',
    'Tour.Info.area.camSketch': 'Krokinizi seçebilirsiniz.',
    'Tour.Info.area.CamInfo':
      'Kameraları kroki üzerinde gösterebilir ve gizleyebilirsin.',
    'Tour.Info.area.CamHoverInfo':
      'Kameralara tıklayarak ısı haritasını inceleyin.',
    'Tour.Info.area.CamShowPlan': 'Kroki üzerindeki reyonları gizleyin.',
    'Tour.Info.area.searchSketch':
      'Reyonlarınız arasında arama yapabilirsiniz.',
    'Tour.Info.area.TopFiveTable':
      'Seçilen tarih aralığındaki yoğunluğu en yüksek ve en düşük beş alanın yüzdelerini gösterir.',

    'Tour.Info.heatmap.Info':
      'Ziyaretçi yoğunluk dağılımını kamera üzerinden gözlemlemeyi sağlar.',
    'Tour.Info.heatmap.showAreas':
      'Alanların detayını görmek için alana tıklayabilirsiniz',
    'Tour.Info.heatmap.rectShowInfo':
      'Detaylı bilgi almak için harita üzerinden maviyle işaretli bölgeye tıklayın.',

    'Tour.Info.department.information':
      'Seçilen alan hakkında ayrıntılı bilgi sağlar. ',
    'Tour.Info.department.densityChart':
      'Reyonun belirtilen saatler arasındaki günlük yoğunluğunu ve reyonda geçirilen süreyi gösterir.',
    'Tour.Info.department.downloadExcel':
      'Kategorinin seçilen zaman aralığına dair verilerini indirin.',
    'Tour.Info.department.goToCam':
      'Kategorinin yoğunluğunu  ısı haritası üzerinde inceleyin.',
    'Tour.Info.department.SalesChart1':
      'Kategori bazlı satışı incelemek için tabloya tıklayın.',
    'Tour.Info.department.SalesChart2':
      'Reyonun belirtilen saat aralığındaki günlük satış tutarını ve satış adedini sergiler.',
    'Tour.Info.department.correlations':
      'Ziyaretçilerin ilgili reyona hangi reyondan geldiğini ve sonrasında hangisine yöneldiklerini gösterir.',
    'Tour.Info.department.notification':
      'Ayarlar kısmından belirlenen parametrelere göre lokasyon yönetimine gönderilen bildirimlerin günlere göre dağılımını gösterir.',

    'Tour.Info.PerformanceTable.Table':
      'Lokasyondaki kategori verilerini tablo halinde incelemenizi sağlarken bir önceki tarih aralığına göre gerçekleşen değişimi gösterir.',
    'Tour.Info.PerformanceTable.scale':
      'En verimli değişiklikleri mavi, en az pembe olarak veren performans ölçeğini gösterir.',

    'Tour.Info.comparison.begin':
      'Lokasyonlar arası değişimleri buradan takip edebilirsiniz.',
    'Tour.Info.comparison.select': 'Lokasyon Seçiniz',
    'Tour.Info.comparison.date': 'Tarih aralığı seçiniz.',
    'Tour.Info.comparison.compareStore':
      'Karşılaştırmak için  bir lokasyon seçiniz.',
    'Tour.Info.comparison.compareDate':
      'Karşılaştırılacak zaman aralığını seçiniz.',
    'Tour.Info.comparison.sketchInfo':
      ' Seçilen krokide, reyonların üzerine gelerek aynı departmanda bulunan reyonları karşılaştırabilirsiniz.',
    'Tour.Info.comparison.clickRect':
      'Reyona tıklayarak detaylı inceleyebilirsiniz.',
    'Tour.Info.comparison.customerCountTable':
      'Alanların toplam ziyaretçi sayısı dağılımını önceki haftaya göre temsil eder.',
    'Tour.Info.comparison.customerCountChart':
      'Belirli bir tarih aralığındaki alanların toplam ziyaretçi sayısı dağılımını temsil eder.',

    'Tour.Info.comparison.customerDwellTable':
      'Önceki tarih aralığına göre ortalama ziyaretçi süresi değişikliği alanlarını temsil eder.',
    'Tour.Info.comparison.customerDwellChart':
      ' Belirli bir tarih aralığındaki ziyaretçi alanlarda ortalama kalma süresi dağılımını temsil eder.',

    'Tour.Info.comparisonV2.begin':
      'Lokasyon ve kategori bazında veri kıyaslaması yapmanızı sağlar.',
    'Tour.Info.comparisonV2.beginUsage1':
      'Lokasyon seçin. İster tek lokasyon seçip kategorileri karşılaştırın ister birden fazla seçip lokasyonlar arası performansı inceleyin.',
    'Tour.Info.comparisonV2.beginUsage2':
      'Kategorilerinizi seçiniz. Seçtiğiniz lokasyonların kategorilerin satış performanslarını inceleyin. ',
    'Tour.Info.comparisonV2.beginUsage3':
      'Veri kaynağınızı yada kaynaklarınızı seçiniz.',
    'Tour.Info.comparisonV2.beginUsage4':
      "'Getir' butonuna tıklayarak sonuçları görün.",

    'Tour.Info.comparisonV2.changeChart':
      'Grafiği lokasyon bazında veya kategori bazında görüntüleyin.',
    'Tour.Info.comparisonV2.selectStore':
      'Lokasyon seçin. Tek bir lokasyon seçin ve kategorileri karşılaştırın veya birden fazla seçin ve lokasyonlar arasındaki performansı inceleyin.',
    'Tour.Info.comparisonV2.selectCategory':
      'Karşılaştırılabilir kategoriyi / kategorileri seçin.',
    'Tour.Info.comparisonV2.SelectDataSource':
      'Almak istediğiniz veri grubunu seçiniz.',
    'Tour.Info.comparisonV2.showChart': 'Grafiği görmek için tıklayınız.',
    'Tour.Info.comparisonV2.details':
      'Aşağıdaki çubuğu kullanarak ayrıntılı bilgilere erişebilirsiniz.',

    'Tour.Info.settings.camera.begin':
      'Lokasyonunuzda bulunan kameralardan seçerek kameranızın gördüğü reyonları düzenleyebilirisiniz.',

    'Tour.Info.settings.camera.camDetails':
      'Fare yardımıyla verisini görmek istediğiniz bölgeyi çizin.',
    'Tour.Info.settings.camera.camDetails2':
      'Modellenen bölgenin konumunu ve boyutunu ayarlayın.',
    'Tour.Info.settings.camera.camDetails3':
      'İşlem yapmak istediğiniz alanın üzerine sağ tıklayın. Alanı arşivde saklamak için Arşivle’ye, alan bilgilerini değiştirmek için Düzenle’ye tıklayın. ',
    'Tour.Info.settings.camera.submitButton':
      'Yaptığınız değişiklikleri kaydetmek için kamera altında butona tıklayın.',
    'Tour.Info.settings.camera.Category':
      'Modellenen bölgelerle sergilenen kategorileri eşleyerek lokasyonunuzun satış verisini sisteme aktarın.',

    'Tour.Info.settings.notification.info':
      'Bölgelere veya kameralara göre bildirim eşiğini belirleyin.',
    'Tour.Info.settings.notification.radiobut':
      'Bölge üzerinde ayar yapmak istiyorsanız bölgeyi, birden fazla bölgeyi içeren kamera üzerinde bildirim ayarı yapmak istiyorsanız kamerayı seçin.',
    'Tour.Info.settings.notification.dropdown':
      'Ayar yapmak istediğiniz seçeneğe tıklayın. ',
    'Tour.Info.settings.notification.goBack':
      'Değişiklik yapmak istiyorsanız geri tuşuna basın.',
    'Tour.Info.settings.notification.threshold':
      'Seçilen parametrenin belirlediğiniz eşikten küçük veya büyük olmasına göre gelecek bildirimi seçin.',
    'Tour.Info.settings.notification.setThreshhold':
      'Bildirim eşiğini ziyaretçi sayısı veya bölgede geçirilen süreye göre belirleyin.',
    'Tour.Info.settings.notification.save':
      'Bildirimin aktif kalacağı zaman aralığını seçin ve kaydet düğmesine tıklayın.',
    'Tour.Info.settings.notification.delete':
      'Önceden ayarlanmış bildirim eşiklerini silmek için tıklayın.',
    'Tour.Info.settings.notification.arrow': 'Devam etmek için oka tıklayın.',

    'Tour.Info.setting.departments.newCategory':
      'Lokasyonda sergilenen ürünlerin üst başlığıyla uyuşan bir alan ekleyin.',

    'Tour.Info.settings.salesUploading.begin':
      'Örnek excel formatına göre hazırlanan satış verilerinizi yükleyin ve lokasyonun satış numaralarını ve yoğunluğunu birlikte analiz edin.',

    'Tour.Info.settings.sketch.selectSketch': 'Kroki seçin.',
    'Tour.Info.settings.sketch.changeSketch':
      'Sistemdeki krokiyi bilgisayarınızda bulunan bir krokiyle değiştirin.',
    'Tour.Info.settings.sketch.setCam':
      'Harita üzerinde kamera yerlerini işaretleyin.',
    'Tour.Info.settings.sketch.newSketch':
      'Bilgisayarınızdan yeni bir kroki ekleyin.',
    'Tour.Info.settings.sketch.drawRect':
      'Alanı, daha önce kamera ayarlarına çizdiğiniz alanla eşleştirmek için lokasyon planı üzerine çizin.',

    'Tour.Info.settings.workingHours.begin':
      'Lokasyonunuzun açık olduğu saatleri belirleyin ve kaydet tuşuna tıklayın. ',

    'Tour.Info.BrandPage.BrandPieCharts':
      'Her lokasyonun şirket performansına olan etkisini gösterir.',
    'Tour.Info.BrandPage.PieChart':
      'İlgili metrikteki kategori performanslarını görmek için tıklayın.',
    'Tour.Info.BrandPage.BarChart.Excel.Info':
      'Kategori dağılım verisini XLSX formatında indirin.',
    'Tour.Info.BrandPage.BarCharts':
      'Kategorilerin lokasyon içindeki performans dağılımlarını gösterir.',
    'Tour.Info.BrandPage.BarChart':
      'İlgili alt kategorileri görmek için tıklayın.',
    'Tour.Info.BrandPage.Sub.BarChart.Excel.Info':
      'Alt kategori dağılım verisini XLSX formatında indirin.',

    'Total.Customer.Chart': 'Ziyaretçi Sayısı ',
    'Total.Quantity.Chart': 'Toplam Satış Miktarı : ',
    'Total.TotalAmount.Chart': 'Toplam Tutar : ',
    'Total.Table.Value': 'Toplam',
    'Total.Summary.Information': 'Özet Bilgi',
    'Total.Customer.Average': 'Toplam Kişi Ortalaması : ',
    'Total.Customer.Deviation': 'Toplam Kişilerin Standart Sapması : ',
    'Total.Time.Average': 'Toplam Süre Ortalaması : ',
    'Total.Time.Deviation': 'Toplam Sürelerin Standart Sapması : ',
    'Total.Weekday.Customer': 'Hafta İçi Giren Toplam Kişi Sayısı  ',
    'Total.Weekday.Customer.Average': 'Hafta İçi Kişi Ortalaması : ',
    'Total.Weekday.Time.Average': 'Hafta İçi Süre Ortalaması : ',
    'Total.Weekends.Customer.Average': 'Hafta Sonu Kişi Ortalaması : ',
    'Total.Weekends.Time.Average': 'Hafta Sonu Süre Ortalaması : ',
    'Total.Density.Average': 'Toplam Yoğunluk Ortalaması :',
    'Density.Avarage': 'Yoğunluk Ortalaması',
    'Total.SQuantity': 'Toplam Satış Adedi',
    'Total.SaleAmount': 'Toplam Satış Tutarı',
    'Total.Entrance': 'Toplam Giren Sayısı',
    'Total.EntranceAverega': 'Toplam Giren Ortalaması ',
    'Total.WeekdayEntrance': 'Hafta İçi Giren Kişi Ortalaması',
    'Total.WeekenddayEntrance': 'Hafta Sonu Giren Kişi Ortalaması',
    'Total.WeekenddayEntranceTotal': 'Hafta Sonu Giren Kişi Toplamı',

    'Notifications.less': 'küçüktür',
    'Notifications.greater': 'büyüktür',

    'HomeBrand.Info.emptyValues': 'Veri bulunmamaktadır.',
    'brand.generalGraph': 'Genel - Grafik',
    'brand.generalGraph.value': 'Değer',
    downloadAsImage: 'Fotoğraf Olarak İndir',
    'brand.metricSelectorPlaceholder': 'Metrik seçiniz...',
    'brand.storeSelectorPlaceholder': '1-10 arası lokasyon seçebilirsiniz...',
    'brand.storeSelectorError': 'En fazla 10 adet lokasyon seçebilirsiniz.',
    'dayIndexConversion.0': 'Pazar',
    'dayIndexConversion.1': 'Pazartesi',
    'dayIndexConversion.2': 'Salı',
    'dayIndexConversion.3': 'Çarşamba',
    'dayIndexConversion.4': 'Perşembe',
    'dayIndexConversion.5': 'Cuma',
    'dayIndexConversion.6': 'Cumartesi',
    'Company.CompanySummary': 'Şirket Özeti',
    CheckoutSummary: 'Kasa özeti',

    'Polygon.MoveBehind': 'Arkaya gönder',
    'Heatmap.Total.Data.Count': 'Toplam Veri Sayısı',
    'Heatmap.Data.Count': 'Veri sayısı',
    'Customer.traffic': 'Trafik',
    'Customer.TotalCustomer': 'Toplam Ziyaretçi',
    'Customer.TotalMales': 'Toplam Erkek',
    'Customer.TotalFemales': 'Toplam Kadın',
    'Customer.AgeGender': 'Yaş - Cinsiyet',
    'Customer.GenderClassification': 'Cinsiyet Sınıflandırması',
    'Customer.AgeClassification': 'Trafik',
    'Customer.Male': 'Erkek',
    'Customer.Female': 'Kadın',
    'Customer.Total': 'Toplam',
    'Customer.TotalTraffic': 'Toplam Trafik',
    'Customer.prevTotal': 'Geçmiş Toplam',
    'Customer.prevTotalChange': 'Toplam Değişimi',
    'Customer.young': 'Genç Sayısı',
    'Customer.adult': 'Yetişkin Sayısı',
    'Customer.old': 'Yaşlı Sayısı',
    'Customer.AgeGender.Title': 'Yaş & Cinsiyet',

    'storeSummary.TodayCount': 'Bugünkü Trafik',
    'storeSummary.YesterdayCount': 'Dünkü Trafik',
    'storeSummary.ThisWeekCount': 'Haftalık Trafik',
    'storeSummary.ThisMonthCount': 'Aylık Trafik',
    'storeSummary.ThisYearCount': 'Yıllık Trafik',
    'storeSummary.SalesAmount': 'Toplam Ciro',
    'storeSummary.SalesQuantity': 'Satış Adedi',
    'storeSummary.ConversionRatio': 'Dönüşüm Oranı',

    'tooltip.totalCustomer': 'Toplam kişi',

    'weekDay.0': 'Pazar',
    'weekDay.1': 'Pazartesi',
    'weekDay.2': 'Salı',
    'weekDay.3': 'Çarşamba',
    'weekDay.4': 'Perşembe',
    'weekDay.5': 'Cuma',
    'weekDay.6': 'Cumartesi',

    'Polygon.hsitory.existing': 'Güncel',
    'Polygon.recalculation': 'Yeniden Hesaplat',
    'Polygon.setFirstEntrance': 'İlk giriş yap',

    Line: 'Çizgi',
    'floors.types.Total': 'Tümü',
    'floors.types.Staircase': 'Merdiven',
    'floors.types.Elevator': 'Asansör',
    'floors.floorComparison': 'Kat Kıyas',
    'floors.floorOrientation': 'Kat İçi Yönelim',
    'Line.Type': 'Çizgi Tipi',
    'Line.Direction.Entrance': 'Giriş Yönü',
    'Line.Direction.name': 'Yön Adı',
    'Line.Direction.type': 'Yön Tipi',
    'Line.PassingStore': 'Lokasyon Önünden Geçen',
    'Line.PassingShopInShop': 'Shop-in-Shop Önünden Geçen',
    'Line.newShopInShopMatch': 'Yeni Shop-in-Shop Eşleşmesi',
    'Line.ShopInShopEntrance': 'Shop-in-Shop Girişi',
    'Line.InStore.orientation': 'Lokasyon İçi Ziyaretçi Yönelimi',
    'Line.Stairs': 'Kat Merdiven Girişi',
    'Line.Elevator': 'Kat Asansör Girişi',
    'Line.InFloorOrientation': 'Kat İçi Yönelimi',

    'Polygon.recalculationInfo':
      'Alanınızın verilerinin yeniden hesaplanmasını istediğiniz tarihi seçiniz.',
    'Polygon.changeCamera': 'Kamera Değiştir',
    'Polygon.recalculationInfoLine':
      'Çizginizin verilerinin yeniden hesaplanmasını istediğiniz tarihi seçiniz.',

    'filter.columns': 'Sütunları Filtrele',
    'modal.close': 'kapat',
    'modal.deparment.history': 'Alan geçmişi',
    'modal.sketch.history': 'Kroki Geçmişi',
    'navigation.Suggestions': 'Öneriler',

    'Suggestion.Comment.one':
      'Yoğunluğu oluşturan kişi ve süre parametrelerinin incelenmesi gerekir. Detayına bakıldığında kişinin yoğunluğa olan etkisi süreden daha yüksektir. Alana ziyaretçi gelmiş az zaman geçirmiştir. Personel, ürün fiyat dengesi, lokasyon planı gözden geçirilebilir. Hizmet verilen temas sayısının arttırılması hem alanı daha verimli hale getirir hem de satışı arttırır. Temas sayısını arttırmak için kampanya yapılabilir. Alan, kademeli olarak küçültülüp satışa etkisi incelenebilir. Alan küçülürken satış azalmıyorsa alan küçültülmelidir.',
    'Suggestion.Comment.two':
      'Yoğunluğu oluşturan kişi ve süre parametreleri incelendiğinde sürenin temas sayısından daha etkili olduğu görülmektedir. Öncelikle, sürenin yeterli olup olmadığını tespit etmek için süre ortalamasına ve ilgi oranına bakmak gerekir. Süre ortalaması ve ilgi oranı fazlaysa; 1) Süre uzunsa ziyaretçi aradığını bulamıyor ve uzun süre bekledikten sonra gidiyor olabilir. Süreyi azaltmak için reyonun/ürünün karmaşıklığı gözden geçirilebilir. 2) Ziyaretçinin alanda çok fazla zaman harcaması personel yetersizliği yaratabilir. Ziyaretçi bilgi vermek amacıyla konulan ürün bilgi kartları detaylandırılabilir. Alan, yoğunluktan büyük olduğu için kademeli olarak küçültülüp satışa etkisi incelenebilir. Alan küçülürken satış azalmıyorsa alan küçültülmelidir.',
    'Suggestion.Comment.three':
      'Sürenin yeterli olduğu düşünülüyorsa yoğunluğu arttırmak için ilk olarak temas sayısını arttırmaya dikkat etmek ve bunu yaparken sürenin azalmamasına özen göstermek gerekir. Temas sayısı artarken süre azalmıyorsa, satışın da artması beklenir. Alan, yoğunluktan büyük olduğu için kademeli olarak küçültülüp satışa etkisi incelenebilir. Alanı küçültmek düşünülebilir.',
    'Suggestion.Comment.four':
      "Yoğunluğu oluşturan kişi ve süre parametreleri incelendiğinde temas sayısının süreden büyük olduğu görülmektedir. Satışın yoğunluktan az olmasının sebebi, ziyaretçiyi alanda yeteri kadar tutamamak olabilir. Süreyi arttırmak için personelin ilgi oranı arttırılabilir. Lokasyon planı süreyi arttırmak için gözden geçirilebilir. Yoğunluk/metrekare, satış/metrekare'den büyük olduğu için sürenin arttırılmasıyla birlikte satış ortalamasının yükselmesi beklenir. Alan, verimli kullanılmaktadır.",
    'Suggestion.Comment.five':
      'Yoğunluğu oluşturan kişi ve süre parametreleri incelendiğinde sürenin temas sayısından daha etkili olduğu görülmektedir. Yoğunluğu oluşturan kişi ve süre parametreleri incelendiğinde sürenin temas sayısından daha etkili olduğu görülmektedir. Öncelikle, sürenin yeterli olup olmadığını tespit etmek için ilgili alan içerisindeki ilgi oranına bakmak gerekir. Alan içerisindeki ilgilenen ziyaretçi oranı yeterliyse, sürenin fazla olma sebepleri incelenmelidir. Süre fazlaysa, ziyaretçi aradığını bulamıyor ve uzun süre bekledikten sonra gidiyor olabilir. Bunun sebebi ürün gamının çok geniş olması veya personel yetersizliği olabilir. İlgi göstermesine rağmen kaybettiğimiz ziyaretçi oranını tespit etmek için alanda süre geçiren ziyaretçi sayısı ile fiş sayısı karşılıklı olarak kontrol edilmelidir. Yoğunluk/metrekare oranı 1 olduğu için yoğunluğu oluşturan ana faktör süre olduğuna göre, verimliliği arttırmak için metrekarenin küçültülmesi veya yoğunluğun arttırılması düşünülebilir.',
    'Suggestion.Comment.six':
      "Sürenin yeterli olduğu düşünülüyorsa yoğunluğu arttırmak için ilk olarak temas sayısını arttırmaya dikkat etmek ve bunu yaparken sürenin azalmamasına özen göstermek gerekir. Temas sayısı artarken süre azalmıyorsa, satışın da artması beklenir. Temas sayısını yani dolayısıyla yoğunluğu arttırmak için kampanya yapılabilir. Satışın az olmasının sebebi ürün çeşitliliğindeki yetersizlik olabilir. Yoğunluk/metrekare parametresine göre alan verimli kullanılmaktadır. Satış/metrekare parametresi ise 1'den küçük olduğuna göre, kademeli olarak alan küçültülüp bunun satışa etkisi gözlenebilir. Diğer bir yandan, alana giren temas sayısı ve süre de birbirine paralel olarak arttırılmalıdır.",
    'Suggestion.Comment.seven':
      'Yoğunluğu oluşturan kişi ve süre parametreleri incelendiğinde temas sayısının süreden büyük olduğu görülmektedir. 1) İlgili alan içerisindeki ilgi oranına bakıldığında yeterli olduğu düşünülüyorsa, temas sayısını dolayısıyla da satışı arttırmak için kampanya yapılabilir, personel desteği sağlanabilir. 2) İlgi oranı az ise, lokasyon planı gözden geçirilebilir. Ürün çeşitliliği arttırılabilir. Fiyat dengesi gözden geçirilebilir. Personel ilgisi arttırılabilir. Yoğunluk/metrekare bazında değerlendirildiğinde alan verimlidir. Alanın adet olarak ne kadarlık satış aldığına bakılmalıdır. Satılan ürün türüne/fiyatına göre metrekarenin büyütülmesi düşünülebilir.',
    'Suggestion.Comment.eight':
      'Yoğunluğu oluşturan kişi ve süre parametreleri incelendiğinde sürenin temas sayısından daha etkili olduğu görülmektedir. Öncelikle, sürenin yeterli olup olmadığını tespit etmek için ilgili alan içerisindeki süre ortalamasına ve ilgi oranına bakmak gerekir. Alan içerisindeki süre ortalaması ve ilgi oranı yeterliyse, sürenin büyük olma sebepleri incelenmelidir. Sürenin büyük olmasının sebebi personel yetersizliği olabilir. Bu durumda, fiş sayısı ve alana gelen temas sayısı kıyaslanmalıdır, fiş sayısı düşükse ziyaretçi kaybının sebebi personel yetersizliği olabilir. Personel sayısını arttırmanın satışları da arttırması muhtemeldir. Yoğunluk/metrekare bazında alan verimli kullanılmıştır. Alanın adet olarak ne kadarlık satış aldığına bakılmalıdır. Satılan ürün türüne/fiyatına göre metrekarenin büyütülmesi düşünülebilir.',
    'Suggestion.Comment.nine':
      'Alanı ziyaret eden temas sayısı arttırılırsa satış da artar. İlgi oranı yüksekse ve satış ilgi oranına ulaşamıyorsa fiş sayısı ve alana gelen temas sayısı kontrol edilmeli, fiş sayısı yüksekse alan büyültülmelidir. ',

    'Suggestion.Comment.Oneone':
      'İlgili kategorinin yoğunluğunun oluşmasında gelen temas sayısının payı geçirilen süreden daha büyüktür. ',
    'Suggestion.Comment.Onetwo':
      'Alanın yoğunluğunda sürenin payını arttırmak için alana gelen ziyaretçilerin alanda daha fazla süre geçirmesi sağlanmalıdır.',
    'Suggestion.Comment.Onethree':
      'Bunun için; personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'Suggestion.Comment.Onefour':
      'Temas sayısını arttırmak içinse; ilgili kategoride kampanya yapılabilir veya lokasyon planı gözden geçirilebilir.',
    'Suggestion.Comment.Onefive':
      'Temas sayısını ve geçirilen süreyi arttırmanın, satışı da arttırması beklenir.',
    'Suggestion.Comment.Onesix':
      'İlgili kategorinin satış/yoğunluk verimliliği incelendiğinde lokasyon içerisindeki satış payının yoğunluktan aldığı paydan büyük olduğu görülür. Kategorinin satış performansı yüksektir.',
    'Suggestion.Comment.Oneseven':
      ' Bu verimliliği daha da arttırmak için yoğunluk arttırma önerileri uygulanıp bunların satışa etkisi incelenmelidir.',
    'Suggestion.Comment.Oneeight':
      'İlgili kategorinin yoğunluk/metrekare verimliliği incelendiğinde lokasyon içerisindeki metrekare payının yoğunluktan aldığı paydan büyük olduğu görülür.',
    'Suggestion.Comment.Onenine':
      'Bu verimliliği arttırmak için yoğunluk arttırma önerilerine ek olarak alan kademeli olarak küçültülüp satışa etkisi incelenebilir. Alan küçülürken satış azalmıyorsa alan küçültülmelidir.',

    'Suggestion.Comment.Twoone':
      'İlgili kategorinin yoğunluğunun oluşmasında ortalama sürenin payı giren temas sayısından daha büyüktür.',
    'Suggestion.Comment.Twotwo':
      'Alanın yoğunluğunda giren temas sayısının payını arttırmak için ilgili kategoride kampanya yapılabilir veya lokasyon planı gözden geçirilebilir. ',
    'Suggestion.Comment.Twothree':
      'Geçirilen süreyi arttırmak içinse; personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'Suggestion.Comment.Twofour':
      'Geçirilen ortalama süreyi ve temas sayısını arttırmanın, satışı da arttırması beklenir.',
    'Suggestion.Comment.Twofive':
      'İlgili kategorinin satış/yoğunluk verimliliği incelendiğinde lokasyon içerisindeki satış payının yoğunluktan aldığı paydan büyük olduğu görülür. Kategorinin satış performansı yüksektir.',
    'Suggestion.Comment.Twosix':
      'Bu verimliliği daha da arttırmak için yoğunluk arttırma önerileri uygulanıp bunların satışa etkisi incelenmelidir.',
    'Suggestion.Comment.Twoseven':
      'İlgili kategorinin yoğunluk/metrekare verimliliği incelendiğinde lokasyon içerisindeki metrekare payının yoğunluktan aldığı paydan büyük olduğu görülür.',
    'Suggestion.Comment.Twoeight':
      'Bu verimliliği arttırmak için yoğunluk arttırma önerilerine ek olarak alan kademeli olarak küçültülüp satışa etkisi incelenebilir. Alan küçülürken satış azalmıyorsa alan küçültülmelidir.',

    'Suggestion.Comment.Threeone':
      'İlgili kategorinin yoğunluğunun oluşmasında ortalama sürenin payı giren temas sayısının payına eşittir.',
    'Suggestion.Comment.Threetwo':
      'Alanın yoğunluğunu arttırmak için ilk olarak temas sayısını arttırmaya dikkat etmek ve bunu yaparken sürenin azalmamasına özen göstermek gerekir.',
    'Suggestion.Comment.Threethree':
      'Alanın yoğunluğunda giren temas sayısının payını arttırmak için ilgili kategoride kampanya yapılabilir veya lokasyon planı gözden geçirilebilir.',
    'Suggestion.Comment.Threefour':
      'Geçirilen süreyi arttırmak içinse; personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'Suggestion.Comment.Threefive':
      'Geçirilen ortalama süreyi ve temas sayısını arttırmanın, satışı da arttırması beklenir.',
    'Suggestion.Comment.Threesix':
      'İlgili kategorinin satış/yoğunluk verimliliği incelendiğinde lokasyon içerisindeki satış payının yoğunluktan aldığı paydan büyük olduğu görülür. Kategorinin satış performansı yüksektir.',
    'Suggestion.Comment.Threeseven':
      'Bu verimliliği daha da arttırmak için yoğunluk arttırma önerileri uygulanıp bunların satışa etkisi incelenmelidir.',
    'Suggestion.Comment.Threeeight':
      'İlgili kategorinin yoğunluk/metrekare verimliliği incelendiğinde lokasyon içerisindeki metrekare payının yoğunluktan aldığı paydan büyük olduğu görülür.',
    'Suggestion.Comment.Threenine':
      'Bu verimliliği arttırmak için yoğunluk arttırma önerilerine ek olarak alan kademeli olarak küçültülüp satışa etkisi incelenebilir. Alan küçülürken satış azalmıyorsa alan küçültülmelidir.',

    'Suggestion.Comment.Fourone':
      'İlgili kategorinin yoğunluğunun oluşmasında gelen temas sayısının payı geçirilen süreden daha büyüktür.',
    'Suggestion.Comment.Fourtwo':
      'Alanın yoğunluğunda sürenin payını arttırmak için alana gelen ziyaretçilerin alanda daha fazla süre geçirmesi sağlanmalıdır.',
    'Suggestion.Comment.Fourthree':
      'Bunun için; personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'Suggestion.Comment.Fourfour':
      'Temas sayısını arttırmak içinse; ilgili kategoride kampanya yapılabilir veya lokasyon planı gözden geçirilebilir.',
    'Suggestion.Comment.Fourfive':
      'Temas sayısını ve geçirilen süreyi arttırmanın, satışı da arttırması beklenir.',
    'Suggestion.Comment.Foursix':
      'İlgili kategorinin satış/yoğunluk verimliliği incelendiğinde lokasyon içerisindeki yoğunluktan aldığı payın satıştan aldığı paydan büyük olduğu görülür.',
    'Suggestion.Comment.Fourseven':
      'Satış potansiyeli olan bir alandır. Ziyaretçi ilgisini kaybetmemek için acilen müdahale etmek gerekir. Yoğunluk arttırma önerileri uygulanıp bunların satışa etkisi incelenmelidir.',
    'Suggestion.Comment.Foureight':
      'İlgili kategorinin yoğunluk/metrekare verimliliği incelendiğinde lokasyon içerisindeki metrekare payının yoğunluktan aldığı paya eşit olduğu görülür. Alan verimlidir.',
    'Suggestion.Comment.Fournine':
      'Bu verimliliği daha da arttırmak için yoğunluk arttırma önerilerine ek olarak alan kademeli olarak küçültülüp satışa etkisi incelenebilir. Alan küçülürken satış azalmıyorsa alan küçültülmelidir.',
    'Suggestion.Comment.Fourten': ' ',

    'Suggestion.Comment.Fiveone':
      'İlgili kategorinin yoğunluğunun oluşmasında gelen temas sayısının payı geçirilen süreden daha büyüktür.',
    'Suggestion.Comment.Fivetwo':
      'Alanın yoğunluğunda sürenin payını arttırmak için alana gelen ziyaretçilerin alanda daha fazla süre geçirmesi sağlanmalıdır.',
    'Suggestion.Comment.Fivethree':
      'Bunun için; personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'Suggestion.Comment.Fivefour':
      'Temas sayısını arttırmak içinse; ilgili kategoride kampanya yapılabilir veya lokasyon planı gözden geçirilebilir.',
    'Suggestion.Comment.Fivefive':
      'Temas sayısını ve geçirilen süreyi arttırmanın, satışı da arttırması beklenir.',
    'Suggestion.Comment.Fivesix':
      'İlgili kategorinin satış/yoğunluk verimliliği incelendiğinde lokasyon içerisindeki yoğunluktan aldığı payın satıştan aldığı paydan büyük olduğu görülür.',
    'Suggestion.Comment.Fiveseven':
      'Satış potansiyeli olan bir alandır. Ziyaretçi ilgisini kaybetmemek için acilen müdahale etmek gerekir. Yoğunluk arttırma önerileri uygulanıp bunların satışa etkisi incelenmelidir.',
    'Suggestion.Comment.Fiveeight':
      'İlgili kategorinin yoğunluk/metrekare verimliliği incelendiğinde lokasyon içerisindeki metrekare payının yoğunluktan aldığı paya eşit olduğu görülür. Alan verimlidir.',
    'Suggestion.Comment.Fivenine':
      'Bu verimliliği daha da arttırmak için yoğunluk arttırma önerilerine ek olarak alan kademeli olarak küçültülüp satışa etkisi incelenebilir. Alan küçülürken satış azalmıyorsa alan küçültülmelidir.',
    'Suggestion.Comment.Fiveten': ' ',

    'Suggestion.Comment.Sixone':
      'İlgili kategorinin yoğunluğunun oluşmasında ortalama sürenin payı giren temas sayısının payına eşittir.',
    'Suggestion.Comment.Sixtwo':
      'Alanın yoğunluğunu arttırmak için ilk olarak temas sayısını arttırmaya dikkat etmek ve bunu yaparken sürenin azalmamasına özen göstermek gerekir.',
    'Suggestion.Comment.Sixthree':
      'Alanın yoğunluğunda giren temas sayısının payını arttırmak için ilgili kategoride kampanya yapılabilir veya lokasyon planı gözden geçirilebilir.',
    'Suggestion.Comment.Sixfour':
      'Geçirilen süreyi arttırmak içinse; personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'Suggestion.Comment.Sixfive':
      'Geçirilen ortalama süreyi ve temas sayısını arttırmanın, satışı da arttırması beklenir.',
    'Suggestion.Comment.Sixsix':
      'İlgili kategorinin satış/yoğunluk verimliliği incelendiğinde lokasyon içerisindeki yoğunluktan aldığı payın satıştan aldığı paydan büyük olduğu görülür.',
    'Suggestion.Comment.Sixseven':
      'Satış potansiyeli olan bir alandır. Ziyaretçi ilgisini kaybetmemek için acilen müdahale etmek gerekir. Yoğunluk arttırma önerileri uygulanıp bunların satışa etkisi incelenmelidir.',
    'Suggestion.Comment.Sixeight':
      'İlgili kategorinin yoğunluk/metrekare verimliliği incelendiğinde lokasyon içerisindeki metrekare payının yoğunluktan aldığı paya eşit olduğu görülür. Alan verimlidir.',
    'Suggestion.Comment.Sixnine':
      'Bu verimliliği daha da arttırmak için yoğunluk arttırma önerilerine ek olarak alan kademeli olarak küçültülüp satışa etkisi incelenebilir. Alan küçülürken satış azalmıyorsa alan küçültülmelidir.',
    'Suggestion.Comment.Sixten': ' ',

    'Suggestion.Comment.Sevenone':
      'İlgili kategorinin yoğunluğunun oluşmasında gelen temas sayısının payı geçirilen süreden daha büyüktür.',
    'Suggestion.Comment.Seventwo':
      'Alanın yoğunluğunda sürenin payını arttırmak için alana gelen ziyaretçilerin alanda daha fazla süre geçirmesi sağlanmalıdır.',
    'Suggestion.Comment.Seventhree':
      'Bunun için; personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'Suggestion.Comment.Sevenfour':
      'Temas sayısını arttırmak içinse; ilgili kategoride kampanya yapılabilir veya lokasyon planı gözden geçirilebilir.',
    'Suggestion.Comment.Sevenfive':
      'Temas sayısını ve geçirilen süreyi arttırmanın, satışı da arttırması beklenir.',
    'Suggestion.Comment.Sevensix':
      'İlgili kategorinin satış/yoğunluk verimliliği incelendiğinde lokasyon içerisindeki yoğunluktan aldığı payın satıştan aldığı paya eşit olduğu görülür.',
    'Suggestion.Comment.Sevenseven':
      'Bu verimliliği arttırmak için yoğunluk arttırma önerileri uygulanıp bunların satışa etkisi incelenmelidir.',
    'Suggestion.Comment.Seveneight':
      'Yoğunluk/metrekare bazında değerlendirildiğinde alan verimlidir. Alanın adet olarak ne kadarlık satış aldığına bakılmalıdır. Satılan ürün türüne/fiyatına göre metrekarenin büyütülmesi düşünülebilir.',

    'Suggestion.Comment.Eightone':
      'İlgili kategorinin yoğunluğunun oluşmasında ortalama sürenin payı giren temas sayısından daha büyüktür.',
    'Suggestion.Comment.Eighttwo':
      'Alanın yoğunluğunda giren temas sayısının payını arttırmak için ilgili kategoride kampanya yapılabilir veya lokasyon planı gözden geçirilebilir.',
    'Suggestion.Comment.Eightthree':
      'Geçirilen süreyi arttırmak içinse; personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'Suggestion.Comment.Eightfour':
      'Geçirilen ortalama süreyi ve temas sayısını arttırmanın, satışı da arttırması beklenir.',
    'Suggestion.Comment.Eightfive':
      'Sürenin fazla olduğu düşünülüyorsa, ziyaretçi aradığını bulamıyor ve uzun süre bekledikten sonra gidiyor olabilir. Bunun sebebi ürün gamının çok geniş olması veya personel yetersizliği olabilir.',
    'Suggestion.Comment.Eightsix':
      'İlgili kategorinin satış/yoğunluk verimliliği incelendiğinde lokasyon içerisindeki yoğunluktan aldığı payın satıştan aldığı paya eşit olduğu görülür.',
    'Suggestion.Comment.Eightseven':
      'Bu verimliliği arttırmak için yoğunluk arttırma önerileri uygulanıp bunların satışa etkisi incelenmelidir.',
    'Suggestion.Comment.Eighteight':
      'Yoğunluk/metrekare bazında değerlendirildiğinde alan verimlidir. Alanın adet olarak ne kadarlık satış aldığına bakılmalıdır. Satılan ürün türüne/fiyatına göre metrekarenin büyütülmesi düşünülebilir.',

    'Suggestion.Comment.Nineone':
      'İlgili kategorinin yoğunluğunun oluşmasında ortalama sürenin payı giren temas sayısının payına eşittir.',
    'Suggestion.Comment.Ninetwo':
      'Alanın yoğunluğunu arttırmak için ilk olarak temas sayısını arttırmaya dikkat etmek ve bunu yaparken sürenin azalmamasına özen göstermek gerekir.',
    'Suggestion.Comment.Ninethree':
      'Alanın yoğunluğunda giren temas sayısının payını arttırmak için ilgili kategoride kampanya yapılabilir veya lokasyon planı gözden geçirilebilir. ',
    'Suggestion.Comment.Ninefour':
      'Geçirilen süreyi arttırmak içinse; personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'Suggestion.Comment.Ninefive':
      'Geçirilen ortalama süreyi ve temas sayısını arttırmanın, satışı da arttırması beklenir.',
    'Suggestion.Comment.Ninesix':
      'İlgili kategorinin satış/yoğunluk verimliliği incelendiğinde lokasyon içerisindeki yoğunluktan aldığı payın satıştan aldığı paya eşit olduğu görülür.',
    'Suggestion.Comment.Nineseven':
      'Bu verimliliği arttırmak için yoğunluk arttırma önerileri uygulanıp bunların satışa etkisi incelenmelidir.',
    'Suggestion.Comment.Nineeight':
      'Yoğunluk/metrekare bazında değerlendirildiğinde alan verimlidir. Alanın adet olarak ne kadarlık satış aldığına bakılmalıdır. Satılan ürün türüne/fiyatına göre metrekarenin büyütülmesi düşünülebilir.',

    'Suggestion.select.Adate': 'Bir tarih seçiniz.',
    'Suggestion.select.Adepartment':
      'Önerisini görmek istediğiniz departmanınızı seçiniz.',

    'Suggestion.Comment.Description':
      'Bu öneri, aşağıdaki verilere bakılarak çıkarılmıştır.Bu veriler lokasyon içerisindeki dağılımı ifade etmektedir.',
    'Suggestion.Area.Percent': 'Alan',
    'Suggestion.Density.Percent': 'Yoğunluk',
    'Suggestion.DwellTime.Percent': 'Ortalama süre',
    'Suggestion.PersonCount.Percent': 'Temas sayısı',
    'Suggestion.Sales.Percent': 'Satış',

    'Suggestion.Feedback.palceHolder':
      'Geri bildiriminizi yazın ve bizimle paylaşın...',

    'Suggestion.Feedback.approve': 'Onaylıyorum',
    'Suggestion.Feedback.Report': 'Geri bildirim',
    'Suggestion.Feedback.Wrong': 'Yanlış yorum',
    'CustomerEntrance.Dwell': 'Lokasyon Özet Grafiği',
    'CheckoutCount.Dwell':
      'Kasadaki Ziyaretçi Sayısı & Ortalama Geçirilen Süre (sn)',
    'Suggestion.interestCount': 'İlgi',
    'Suggestion.densityDistribution': 'Yoğunluk Dağılımı',
    'Suggestion.salesDistribution': 'Satış Dağılımı',
    'suggestionRequest.waiting': 'Bekliyor',
    'suggestionRequest.preparing': 'Hazırlanıyor',
    'suggestionRequest.Completed': 'Tamamlandı',
    'suggestionRequest.Department': 'Departman',
    'entranceCheckout.v2': 'Giren - Çıkan Adet',
    'entrance.v2': 'Giren Adet',
    'entrance.peopleCount': 'Giren Kişi Sayısı',
    'suggestion.top5.sale': 'İLK 5 - SATIŞ',

    'Personnel.employee': 'Çalışan',
    'Personnel.InTime': 'Giriş',
    'Personnel.OutTime': 'Çıkış',
    'Personnel.Dwell': 'Lokasyonda Bulunma Süresi',
    'Personnel.Entrances': 'Giriş Çıkış Sayısı',
    'Personnel.EntrancesDetail': 'Giriş Çıkış Detayı',
    'Personnel.TotalEntrances': 'Toplam Giriş Çıkış',
    'Personnel.Aisle': 'Reyon',
    'Personnel.Aisle/Depot': 'Reyon/Depo',
    'Personnel.StoreLimit': 'Lokasyon Sınırı',
    'Personnel.Break': 'Mola',
    'Personnel.Depot': 'Depo',
    'PersonnelModal.selectPersonnel': 'Personel seçiniz',
    'PersonnelModal.header': 'Karta atamak için bir personel seçiniz',
    'PersonnelModal.selectPersonnels': 'Personelleri seçiniz',
    'PersonnelModal.header2': 'Karta atamak için personelleri seçiniz',

    'personnelv2.ActiveTime': 'Lokasyon İçi Aktif Süre',
    'personnelv2.AvarageOfActiveWorkingTime': 'Lokasyon İçi Ortalama Süre',
    'personnelv2.AvarageTimeSpentInAisle': 'Reyon İçi Ortalama Süre',
    'personnelv2.AvarageTimeSpentInBreak': 'Ortalama Mola Süresi',
    'personnelv2.AvarageTimeSpentInDepot': 'Depo İçi Aktif Süre',
    'personnelv2.BeaconName': 'Personel',
    'personnelv2.BeaconTargets': 'Sorumlu Olduğu Alanlar',
    'personnelv2.Date': 'Tarih',
    'personnelv2.StoreEnterTime': 'Lokasyona Giriş Saati',
    'personnelv2.StoreExitTime': 'Lokasyondan Çıkış Saati',
    'personnelv2.MostEnteredReceiver': 'En Çok Giriş Yapılan Alan',
    'personnelv2.MostSeenReceiver': 'En Uzun Süre Geçirilen Alan',
    'personnelv2.MostSpentArea': 'En Uzun Süre Geçirilen Alan',
    'personnelv2.MostSpentTimeInArea': 'Alanda Bulunma Süresi',
    'personnelv2.MostSpentTimeAvarageInArea': 'Alanda Ortalama Bulunma Süresi',
    'personnelv2.Name': 'Adı',
    'personnelv2.NumberOfEntries': 'Giriş Sayısı',
    'personnelv2.ReceiverName': 'Alıcı',
    'personnelv2.TimeSpentInAisle': 'Reyon İçi Süre',
    'personnelv2.TimeSpentInDepot': 'Depo İçi Süre',
    'personnelv2.TimeSpentOnTargetReceivers':
      'Sorumlu Alanlarda Geçirilen Süre',
    'personnelv2.UnseenReceivers': 'Hiç Girilmeyen Alanlar',
    'personnelv2.WorkingDay': 'Çalışılan Gün',
    'personnelv2.WorkingTime': 'Mesai Süresi',
    'personnelv2.allPersonnels': 'Tüm Personeller',
    'personnelv2.detailedExcel': 'Detaylı Excel İndir',

    'personnelv2.AverageWorkingTime': 'Ortalama Mesai Süresi',
    'personnelv2.AverageActiveWorkingTime':
      'Lokasyon İçi / Aktif Ortalama Süre',
    'personnelv2.AverageTimeOutOfStore': 'Lokasyon Dışı Ortalama Süre',
    'personnelv2.AverageNumberOfOutOfStoreExits':
      'Lokasyon Dışına Çıkış Sayısı',
    'personnelv2.AverageTimeSpentInBeaconTargets':
      'Sorumlu Olduğu Alanlarda Geçirilen Ortalama Süre',
    'personnelv2.AverageTimeSpentInBreak': 'Ortalama Mola Alanında Geçen Süre',
    'personnelv2.MostEnteredArea': 'En Çok Giriş Yapılan Alan',
    'personnelv2.AverageNumberOfEntries': 'Ortalama Giriş Sayısı',
    'personnelv2.AverageTimeInMostSeenReceiver': 'Ortalama Geçirilen Süre',
    'personnelv2.ClickToDayNote':
      'Günlük personel detayı için güne tıklayınız.',

    'personnelv2.cards.TotalBeaconCount': 'Toplam Personel Sayısı',
    'personnelv2.cards.TotalWorkingTime': 'Toplam Mesai Süresi',
    'personnelv2.cards.AverageWorkingTime': 'Günlük Ortalama Mesai Süresi',
    'personnelv2.cards.TotalActiveTime': 'Toplam Aktif Süre',
    'personnelv2.cards.AverageActiveTime': 'Günlük Ortalama Aktif Süre',
    'personnelv2.cards.EstimatedWorkforceLoss': 'Tahmini İş Gücü Kaybı',
    'personnelv2.cards.AverageEstimatedWorkforceLoss':
      'Günlük Ortalama Tahmini İş Gücü Kaybı',
    'personnelv2.cards.DayWithMostWorkforceLoss':
      'En Yüksek İş Gücü Kaybı Yaşanan Gün',
    'personnelv2.cards.shortHour': 's',
    'personnelv2.cards.showMoreDetail': 'Detay Göster',

    'personnelv2.EstimatedWorkforceLoss': 'Tahmini İş Gücü Kaybı',
    'personnelv2.TotalActiveTime': 'Toplam Aktif Süre',
    'personnelv2.TotalBeaconCount': 'Toplam Personel Sayısı',
    'personnelv2.TotalWorkingTime': 'Toplam Mesai Süresi',
    'personnelv2.Day': 'Gün',

    'personnelv2.TimeOutOfStore': 'Lokasyon Dışı Süre (Hizmet Dışı Süre)',
    'personnelv2.BeaconExits': 'Lokasyondan Çıkış Sayısı',
    'personnelv2.TimeSpentInBreak': 'Mola Alanında Geçen Süre',
    'personnelv2.TimeOfSeen': 'Geçirilen Süre',
    'personnelv2.Start': 'Başlangıç',
    'personnelv2.End': 'Bitiş',
    'personnelv2.Time': 'Süre',

    'personnelv2.Receiver': 'Alıcı',
    'personnelv2.AllReceivers': 'Tüm Alıcılar',
    'personnelv2.HighestDensityDate': 'En Yoğun Gün/Saat',
    'personnelv2.HighestDensityHour': 'Ortalama En Yoğun Saat',
    'personnelv2.AverageEntranceCount': 'Günlük Ortalama Personel Giriş Sayısı',
    'personnelv2.AverageDwellMin': 'Günlük Ortalama Personel Hizmet Süresi',
    'personnelv2.LongestBeaconName': 'En Uzun Vakit Geçiren Personel',
    'personnelv2.AvarageOfDwellMin': 'Personelin Günlük Ortalama Süresi',
    'personnelv2.BeaconCount': 'Personel Sayısı',
    'personnelv2.CustomerCountOver15Sec': '15sn Üzeri Müşteri',
    'personnelv2.SaleQuantity': 'Satış Adedi',
    'personnelv2.Dwell': 'Hizmet Süresi',
    'personnelv2.EntranceCount': 'Giriş Sayısı',
    'personnelv2.EntranceToAreaTime': 'Alanda Durma Süresi (dk)',
    'personnelv2.EntranceToAreaCount': 'Alana Giriş Sayısı',

    'demography.County': 'İlçe',
    'demography.Population': 'Toplam Nufüs',
    'demography.Age': 'Ortalama Yaş',
    'demography.HouseHold': 'Hane Başı temas sayısı',
    'demography.Marital': 'Medeni Durum',
    'demography.Ses': 'S.E.S',
    'demography.TotalSale': 'Toplam Satış',
    'demography.TotalEstate': 'Toplam Emlak',
    'demography.Education': 'Eğitim Durumu',
    'demography.HouseIncome': 'Konut Geliri',
    'demography.HouseIncomeTotal': 'Toplam Konut Geliri',
    'demography.ECommerce': 'E-Ticaret',
    'demography.CountyId': 'İlçe Kimliği',
    'demography.A Grubu': 'A Grubu',
    'demography.B Grubu': 'B Grubu',
    'demography.C Grubu': 'C Grubu',
    'demography.D Grubu': 'D Grubu',
    'demography.Lisans': 'Lisans',
    'demography.Ilkokul': 'İlkokul',
    'demography.Lise': 'Lise',
    'demography.Ortaokul': 'Ortaokul',
    'demography.Okuryazar Egitimsiz': 'Okuryazar Eğitimsiz',
    'demography.Married': 'Evli',
    'demography.MarriedNever': 'Bekar',

    'sketch.layer': 'Katman ',
    'sketch.selectLayer': 'Katman Seç',
    'sketch.addLayer': 'Katman Ekle',
    'sketch.removeLastLayer': 'Son Katmanı Sil',
    'sketch.removeLastLayerPrompt':
      'Son katmanı silmek istediğinize emin misiniz?',
    'sketch.removeLastLayerError':
      'Katmanda bir yada daha fazla çizim bulunmakta.',

    'LeavePage.Error':
      'Değişiklikleri Kaydetmeden sayfayı terk etmek istediğinize emin misiniz?',
    'placeHolder.targetUser': 'Hedef Kullanıcılar',

    'live.storeCapacity': 'ALAN KAPASİTESİ: ',
    'live.currentPeople': 'GÜNCEL KİŞİ SAYISI: ',
    'live.currentCapacity': 'Mevcut Kapasite: ',
    'live.customerChart': 'Ziyaretçi Sayısı Grafiği',
    'live.exceededCapacity': 'kapasite aşıldı!',
    'live.least': 'En az',
    'live.most': 'En çok',
    'live.liveOccupancyGraph': 'Anlık Ziyaretçi Grafiği',
    'live.storeCapacityEdit': 'Alan Kapasitesi Giriniz',
    'live.location.capacity': 'Lokasyon Kapasitesi',
    'live.peopleData': 'Canlı Trafik Verileri',
    'live.capacity': 'Alan Kapasitesi',
    'live.LiveAreas': 'Canlı Alan Sayımları',
    'info.Forecast.Cam': 'Kamera Alanları',
    'info.prompt.Forecast': 'Seçili grubu silmek istediğinizden emin misiniz?',
    'Browser.Does.not.Support':
      'Bu içerik için tarayıcı desteği bulunmamaktadır.',

    'notification.response': 'Bildirim Cevabı : ',
    'notification.timedOut': 'Zaman Aşımına Uğradı',
    'notification.reply': 'Cevapla ',
    'notification.emptyResponse': 'Bildirim Cevabı Yoktur.',
    'notification.otherResponse': 'Diğer ',

    'suggestion.month': 'Ay',
    'suggestion.topcategory': 'Üst Kategori',
    'suggestion.show': 'Göster',
    'suggestion.densityChange': 'Yoğunluk Değişimi',
    'suggestion.saleChange': 'Satış Değişimi',
    'suggestion.interestChange': 'İlgi Değişimi',
    'suggestion.personChange': 'Temas Değişimi',
    'suggestion.timeChange': 'Süre Değişimi',
    'suggestion.store': 'Lokasyon',
    'suggestion.visitorCount': 'Ziyaretçi Sayısı',
    'suggestion.campaign': 'Kampanya',
    'suggestion.productVariety': 'Ürün Çeşitliliği',
    'suggestion.price': 'Fiyat',
    'suggestion.storePlan': 'Lokasyon Plan',
    'suggestion.stock': 'Stok',

    'user.Mail': 'Kullanıcı Maili',

    'suggestion.infoDaily':
      'Belirtilen günün bir önceki güne ve seçilen değişkene göre en çok artan ve en çok azalan kategorilerini görmektesiniz.',
    'suggestion.infoWeekly':
      'Bu haftanın geçen haftaya göre belirtilen gün aralığı ve seçilen değişken baz alınarak en çok artan ve en çok azalan kategorilerini görmektesiniz. Haftalık değişimler hafta başından itibaren hesaplanmaktadır. Ör: Haftanın 4. gününde bu haftanın ilk 3 günü ile geçen haftanın ilk 3 günü kıyaslanmaktadır. ',
    'suggestion.infoMonthly':
      'Bu ayın geçen aya göre belirtilen gün aralığı ve seçilen değişken baz alınarak en çok artan ve en çok azalan kategorilerini görmektesiniz. Aylık değişimler ay başından itibaren hesaplanmaktadır. Ör: Ayın 21. gününde bu ayın ilk 20 günü ile geçen ayın ilk 20 günü kıyaslanmaktadır.',

    'suggestion.title': 'Değişim Takip ',
    'suggestion.detailTitle': 'Öneriler ',
    'suggestion.detailPageDes':
      'Bu sayfada seçtiğiniz kategorinin değişimlerini görebilir, günlük, haftalık veaylık süreçlerde inceleyebilir, ait olunan üst kategori ve lokasyon değişimleriile kıyaslayabilirsiniz. Kategorinin seçilen tarih aralığındaki değişimlerinden hesaplanan problemler ve öneriler ilgili başlıkta belirtilmiştir.',
    'suggestion.pageDescription':
      'Bu sayfada en çok değişim geçiren kategorileri görebilir, değişimlerini günlük, haftalık ve aylık süreçlerde inceleyebilir ve seçtiğiniz parametrenin değişimini satış değişimi ile karşılaştırabilirsiniz.',
    'suggestion.infoDetailDaily':
      'Seçtiğiniz kategoride, belirtilen günün bir önceki güne göre değişimlerini görmektesiniz.',
    'suggestion.infoDetailWeekly':
      'Seçtiğiniz kategoride bu haftanın geçen haftaya göre belirtilen gün aralığında değişimlerini görmektesiniz. Haftalık değişimler hafta başından itibaren hesaplanmaktadır. Ör: Haftanın 4. gününde bu haftanın ilk 3 günü ile geçen haftanın ilk 3 günü kıyaslanmaktadır.',
    'suggestion.infoDetailMonthly':
      'Seçtiğiniz kategoride bu ayın geçen aya göre belirtilen gün aralığında değişimlerini görmektesiniz. Aylık değişimler ay başından itibaren hesaplanmaktadır. Ör: Ayın 21. gününde bu ayın ilk 20 günü ile geçen ayın ilk 20 günü kıyaslanmaktadır.',
    'suggestion.infoDetailStore':
      'Seçtiğiniz kategorinin değişimlerini aynı tarih aralığı için lokasyon toplamındaki değişimler ile kıyaslayabilirsiniz. ',
    'suggestion.infoDetailDep':
      'Seçtiğiniz kategorinin değişimlerini aynı tarih aralığı için bir üst kategori değişimleri ile kıyaslayabilirsiniz.',

    'trends.detailTitle': 'Trend ',
    'trends.detailPageDes':
      'Bu sayfada kategorilerin günlük, haftalık ve aylık dönemlerde seçilen değişkene göre artış ve azalış trendlerini görebilir, satış trendleri ile karşılaştırabilirsiniz. Verileri harita moduna geçiş yaparak lokasyon içerisindeki konumlarına göre inceleyebilirsiniz.',
    'trends.infoDaily':
      'Gün sayısı olarak kategorilerin artış ve azalış trendlerini seçilen değişkene ve satışa göre görmektesiniz. Ör: 5 gündür artış trendi.',
    'trends.infoWeekly':
      'Hafta sayısı olarak kategorilerin artış ve azalış trendlerini seçilen değişkeneve satışa göre görmektesiniz. Ör: 4 haftadır azalış trendi.',
    'trends.infoMonthly':
      'Ay sayısı olarak kategorilerin artış ve azalış trendlerini seçilen değişkene vesatışa göre görmektesiniz. Ör: 3 aydır azalış trendi.',

    'forecast.editInfo':
      'Düzenleme seçeneği ile kamera alanlarından yeni alan ekleyebilir, grupta var olan alanı silebilir, grup isimini veya bilgilerini değiştirip kaydedebilirsiniz.',
    'live.customerCountChart': 'Ziyaretçi Sayısı Grafiği',
    'live.route': 'Canlı',
    'live.visitor': 'Canlı Ziyaretçi Sayısı',
    'login.Register': 'Üye Ol',
    'login.MemberShipResponse':
      'Üyelik talebiniz alınmıştır. En kısa sürede geri dönüş yapılacaktır.',
    'membership.Name': 'İsim',
    'membership.Surname': 'Soyisim',
    'membership.email': 'E-posta',
    'membership.Brand': 'Firma',
    'all.Brands': 'Tüm Lokasyonlar',

    'average.values': 'Ortalama Değerler',
    'notification.sketchWarning': 'Çizilmemiş krokiler bulunmaktadır!',
    'notification.skectAdd': 'Kroki eklemek için tıklayınız',

    'notification.brandWarning': 'Firmaya henüz kategori eklenmemiştir!',
    'notification.brandAdd': 'Kategori eklemek için tıklayınız',
    'settings.daily': 'Günlük',
    'settings.weekly': 'Haftalık',
    'settings.cameraImage': 'Kamera Görüntüsü',
    'settings.noCameraImage': 'Kamera görüntüsü yok.',
    'settings.removeCam': 'Seçileni silmek istediğinize emin misiniz ?',
    'created.userAndDate': 'Oluşturulan Tarih - Oluşturan Kullanıcı',
    'suggestion.finding': 'Bulgular',
    'heatmap.value': 'Isı Haritası Değeri: ',
    'correlations.from': 'Çıkılan',
    'correlations.to': 'Girilen',
    'info.age': 'Yaş',
    'suggestion.warning': 'Öneri Bulunmamaktadır!',
    'personnel.tracking': 'Personel Takip',
    'area.inTime': 'Alana Giriş Zamanı',
    'area.outTime': 'Alandan Çıkış Zamanı',
    'area.inArea': 'Alanda Durulan Süre',
    'personnel.route': 'Rota',
    'personnel.otherPerson': 'Alandaki Diğer Kişiler',
    'personnel.Area': 'Alan Sorumlusu',
    'personnel.Entrance': 'Giriş Sayısı',
    'checkOut.region/store': 'Bölge/Lokasyon',
    'checkOut.general': 'Genel',
    'checkout.Comparison': 'Kıyaslama',
    'checkout.density': 'Kasa Yoğunluğu',
    'checkout.ReceiptError': 'Fiş Entegrasyonu Gereklidir',
    'checkout.customer': 'Ziyaretçi',
    'checkout.potentialLoss': 'Potansiyel Kayıp',
    'checkout.numberCheckout': 'Açık Kasa Sayısı',
    'checkout.instantCus': 'Anlık Ziyaretçi Sayısı',
    'checkout.instantCusInfo':
      'Anlık olarak tüm kasalarda bulunan müşteri sayısı',
    'checkout.instantCusWaitingAreaInfo':
      'Anlık olarak tüm kasa bekleme alanlarında bulunan müşteri sayısı',
    'checkout.maxCusInfo':
      'Seçili tarih aralığında tüm kasalarda eş zamanlı bulunan müşteri sayısı',
    'checkout.maxCusWaitingAreaInfo':
      'Seçili tarih aralığında tüm kasa bekleme alanlarında eş zamanlı bulunan müşteri sayısı',
    'checkout.checkout': 'Kasa',
    'checkout.checkoutWaitingArea': 'Bekleme Alanı',
    'checkout.maxCus': 'Max Ziyaretçi Sayısı',
    'checkout.numberCus': 'Ziyaretçi Sayısı',
    'checkout.queue': 'Sıra Bekleme Süresi',
    'checkout.process': 'Kasa İşlem Süresi',
    'checkout.integrationReq': 'Entegrasyon Gereklidir',
    'suggestion.numberOfSuggestions': 'Öneri Sayılarınız',
    'suggestions.see': 'Önerileri Gör',
    'department.waiting.limit': 'Sınır bekleme süresi',
    'number.customers.passed': 'Geçen Ziyaretçi Sayısı',
    'info.update': 'Güncelle',
    'info.add': 'Ekle',
    'info.conversionSale': 'Satışa Dönüşüm Süresi',
    'current.people': 'Güncel Ziyaretçi Sayısı',
    'rotate.degrees': 'Çizimleri döndür',
    'rotate.degreesConfirmation':
      'Çizimleri döndürmek istediğinze emin misiniz?',
    Under13Female: 'Yaş (0-13) Kadın',
    B14and23Female: 'Yaş (14-23) Kadın',
    B24and35Female: 'Yaş (24-35) Kadın',
    B36and46Female: 'Yaş (36-46) Kadın',
    Over47Female: 'Yaş (47-) Kadın',

    Under13Male: 'Yaş (0-13) Erkek',
    B14and23Male: 'Yaş (14-23) Erkek',
    B24and35Male: 'Yaş (24-35) Erkek',
    B36and46Male: 'Yaş (36-46) Erkek',
    Over47Male: 'Yaş (47-) Erkek',
    'ageGender.moveCountsHeader': 'Lokasyon Önünden Geçenler',
    'shopInShop.moveCountsHeader': 'Shop-in-Shop Önünden Geçenler',

    'global.campaingn': 'Kampanya Oluşturma',
    'global.loading': 'Yükleniyor...',
    'global.empty': 'Veri bulunamadı.',
    'campaingn.name': 'Kampanya İsmi',
    ...suggestionComment.tr,

    'socket.error': 'Bağlantı hatası!',
    'socket.disconnected': 'Soket bağlantı hatası!',
    'total.demography': 'Toplam Demografi',
    'total.change': 'Toplam Değişimler',
    'campaign.category.change': 'Kampanya  Kategorilerinin  Değişimi',
    'trends.yearly': 'Yıllık',
    'waiting.time': 'Bekleme Süresi',
    'seconds.over': 'Saniye ve Üzeri',
    'names.visible': 'İsimleri Göster',
    'short.names.visible': 'Kısa İsimleri Göster',
    'values.visible': 'Değerleri Göster',
    'campaign.analysis': 'Kampanya Analiz',
    'info.prompt.campaign':
      'Seçili kampanyayı silmek istediğinizden emin misiniz?',
    'info.showMore': 'Daha Fazla Göster',
    'info.showLess': 'Daha Az Göster',
    'info.summaryTable': 'Genel-Tablo',
    'info.StoreName': 'Lokasyon Adı',
    'Endorsement/m2': 'Ciro/M2',
    InvoicesCount: 'Fiş Adedi',
    'hourly.avarage': 'Saatlik Ortalama',
    'draw.door': 'Kapı Çiz',
    'draw.entry.direction': 'Giriş Yönünü Çiz',
    'draw.count.line': 'Sayım Çizgisi Çiz',
    Metric: 'Metrik',
    'Peak.DensityHour': 'En Yoğun Saatler',
    'selected.area': 'Seçili Kroki',
    ProductNotAllowed:
      'Seçili lokasyonda bu ürün kullanılmamaktadır. {link} bu lokasyona ait ürünleri inceleyebilirsiniz.',
    ProductNotAllowedLink: 'Ana sayfadan',
    'Avarage.Dwell': 'Ortalama süre (sn)',
    'Over.15secAvarage': '15 sn Üzeri Ortalama Süre',
    'Invoice.Count': 'Fatura Sayısı',
    'DailyCustomers.Over15': 'Günlük 15 sn Üzeri Müşteri Sayısı',
    'Numberof.Receipts': 'Fiş Sayısı',
    'PotentialLost.Customer': 'Potansiyel Kayıp Müşteri',
    'data.recalculated': 'Veriler yeniden hesaplanıyor...',
    'data.tobe.calculated': 'Veriler yeniden hesaplama için sırada bekliyor',
    'Unsubscribe.error': 'Abonelikten çıkarken bir hata oluştu!',
    'Unsubscribe.success': 'Başarıyla abonelik bitirildi!',
    'current.version': 'Mevcut Sürüm',
    'select.atleastOneStore': 'En az 1 lokasyon seçmelisiniz.',
    personnels: 'Personel(ler)',
    Days: 'Günler',
    'time.limit': 'Süre Limiti',
    PersonnelOver15Sec: '15sn aşan temas sayısı',
    PersonnelOver30Sec: '30sn aşan temas sayısı',
    PersonnelOver60Sec: '60sn aşan temas sayısı',
    PersonnelOver90Sec: '90sn aşan temas sayısı',
    PersonnelOver120Sec: '120sn aşan temas sayısı',
    PersonnelOver180Sec: '180sn aşan temas sayısı',
    'report.type': 'Rapor Tipi',
    subscribe: 'Abonelik',
    // '15sec.meanTime.change': '15 sn üzeri ortalama süre değişim',
    '15sec.checkout.visitor': '15sn Üzeri Kasa Temas',
    'workforce.change': 'Aktif Süre Değişimi',
    'number.employees.working': 'Çalışan Personel Sayısı',
    'bottom.shift.time': 'En Düşük Mesai Süresi',
    'top.shift.time': 'En Yüksek Mesai Süresi',
    'workforce.loss.change': 'İş Gücü Kaybı Değişimi',
    'employee.conversion.rate': 'Personel Dönüşüm Oranı',
    'employee.efficiency': 'Personel Verimi',
    'store.least.employeeefficiency': 'Personel Verimi En Düşük Lokasyon',
    'store.most.employeeefficiency': 'Personel Verimi En Yüksek Lokasyon',
    'estimated.workforce.loss': 'Tahmini iş gücü kaybı',
    'total.workforce.loss': 'Toplam İş Gücü Kaybı (saat:dk)',
    'total.shift.time': 'Toplam Mesai Süresi (saat:dk)',
    'total.breaks': 'Toplam Mola Sayısı',
    'breaks.change': 'Mola Sayısı Değişimi',
    'total.customer': 'Ziyaretçi Sayısı Toplam',
    'working.time.change': 'Mesai Süresi Değişimi',
    'total.active.time': 'Toplam Aktif Süre (saat:dk)',
    'store.count': 'Lokasyon Sayısı',
    'personnel.performance': 'Personel Performansı',
    'brand.summary': 'Firma Özeti',
    'personnel.summary': 'Personel Özeti',
    'customer.change': 'Ziyaretçi Sayısı Değişim',
    'personnel.count.total': 'Personel Sayısı Toplam',
    'personnel.count.change': 'Personel Sayısı Değişim',
    'invoice.count.change': 'Fiş Adedi Değişim',
    'sale.quantity.change': 'Satış Adedi Değişim',
    'sale.amount.change': 'Satış Tutarı Değişim',
    'employee.conversion.rate.total': 'Personel Dönüşüm Oranı Toplam',
    'employee.conversion.rate.change': 'Personel Dönüşüm Oranı Değişimi',
    'employeeefficiency.change':
      'Personel Verimi Değişim (Aktif Süre / Mesai Süre)',
    'employeeefficiency.total':
      'Personel Verimi Toplam (Aktif Süre / Mesai Süre)',
    'download.excel': 'Excel İndir',
    'checkout.traffic': 'Kasa Trafiği',
    'checkout.topStores': 'Kasa (En Yüksek Lokasyonlar)',
    'checkout.bottomStores': 'Kasa (En Düşük Lokasyonlar)',
    'Meantime.over15sec': '15sn Üzeri Ortalama Kasa Süre',
    'numberof.notifications': 'Bildirim Sayısı',
    'visitor.number.over15sec': '15sn üzeri temas',
    'visitor.number.change.over15sec': '15sn üzeri temas değişim',
    'visitor.number.over60sec': '60sn üzeri temas',
    'visitor.number.change.over60sec': '60sn üzeri temas değişim',
    'mean.time.change.over15sec': '15sn üzeri temas değişim',
    'mean.time.over15sec': '15sn üzeri temas',
    'meantime.over15sec': '15sn üzeri ortalama süre',
    'meantime.changeover15sec': '15sn üzeri ortalama süre değişim',
    'hourly.traffic.chart': 'Saatlik Trafik Grafiği',
    'daily.traffic.chart': 'Günlük Trafik Grafiği',
    'door.person.count': 'Kapı Kişi Sayım',
    'daily.amount': 'Günlük Satış Tutarı',
    'daily.traffic': 'Günlük Trafik',
    'daily.invoice': 'Günlük Fiş Sayısı',
    'daily.quantity': 'Günlük Satış Adedi',
    'monthly.amount': 'Aylık Satış Tutarı',
    'monthly.traffic': 'Aylık Trafik',
    'monthly.invoice': 'Aylık Fiş Sayısı',
    'monthly.quantity': 'Aylık Satış Adedi',
    'previous.daily.amount': 'Önceki Günlük Satış Tutarı',
    'previous.daily.traffic': 'Önceki Günlük Trafik',
    'previous.daily.invoice': 'Önceki Günlük Fiş Adedi',
    'previous.daily.quantity': 'Önceki Günlük Satış Adedi',
    'previous.daily.amount': 'Önceki Ay Satış Tutarı',
    'previous.monthly.traffic': 'Önceki Ay Trafik',
    'previous.monthly.invoice': 'Önceki Ay Fiş Adedi',
    'previous.monthly.quantity': 'Önceki Ay Satış Adedi',
    'previous.monthly.amount': 'Önceki Ay Satış Tutarı',
    'previous.weekly.traffic': 'Önceki Hafta Trafik',
    'previous.weekly.invoice': 'Önceki Hafta Fiş Adedi',
    'previous.weekly.quantity': 'Önceki Hafta Satış Adedi',
    'weekly.amount': 'Haftalık Satış Tutarı',
    'weekly.traffic': 'Haftalık Trafik',
    'weekly.invoice': 'Haftalık Fiş Adedi',
    'weekly.quantity': 'Haftalık Satış Adedi',
    'category.summary': 'Kategori Özeti',
    'InStore.Analytics.BottomStores':
      'Mağaza İçi Analitik (En Düşük Mağazalar)',
    'InStore.Analytics.TopStores': 'Mağaza İçi Analitik (En Yüksek Mağazalar)',
    'InStore.Analytics.TopCategories':
      'Mağaza İçi Analitik (En Yüksek Kategoriler)',
    'InStore.Analytics.BottomCategories':
      'Mağaza İçi Analitik (En Düşük Kategoriler)',
    'store.based.traffic.perf': 'Mağaza Bazlı Trafik Performansı',
    'traffic.change': 'Trafik Değişimi',
    'invoicecount.change': 'Fiş Adedi Değişimi',
    'top.ten.stores': 'En Yüksek 10 Lokasyon',
    'InStore.Density': 'Lokasyon İçi Yoğunluk',
    'InStore.Density.Change': 'Lokasyon İçi Yoğunluk Değişim',
    'meantime.change': 'Ortalama Süre Değişim',
    'conversionRate.change': 'Dönüşüm Oranı Değişim',
    'Visitor.Count.Over.15sec.Change': '15 sn üzeri temas değişim',
    'Visitor.Count.Over.15sec': '15 sn üzeri temas',
    'Top.Categories': 'En Yüksek Kategoriler',
    'Bottom.Categories': 'En Düşük Kategoriler',
    Days: 'Günler',
    'store-occupancy':
      'Lokasyon %{value} üzeri doluluğa ulaştığında bildirim almak istiyorum',

    'customerServices.AverageDwell': 'Müşteri Hizmetleri Ortalama Süre',
    'customerServices.AverageDwellChange':
      'Müşteri Hizmetleri Ortalama Süre Değişimi',

    'customerServices.AverageDwellInfo':
      'Müşteri Hizmetleri alanlarında tespit edilen toplam temasın geçirdiği ortalama süre',
    'customerServices.AverageDwellChangeInfo':
      'Müşteri Hizmetleri alanlarında ölçülen ortalama sürenin karşılaştırma tarihine göre değişimi',
    'customerServices.DensityRatioInfo':
      'Müşteri Hizmetleri alanlarında ölçülen yoğunluğun seçilen bölgeler/lokasyonlar arasındaki dağılımı. (Yoğunluk = Temas sayısı x Ortalama Süre) (Yoğunluk Oranı = Bölge-Lokasyon Yoğunluğu / Toplam Yoğunluk)',
    'customerServices.DensityRatioChangeInfo':
      'Müşteri Hizmetleri alanlarının yoğunluk oranlarının karşılaştırma tarihine göre değişimi',
    'customerServices.PeakHourInfo':
      'Müşteri Hizmetleri alanlarının en yüksek yoğunluğa ulaştığı saatler (Karşılaştırma tarihi en yoğun saat -> İnceleme tarihi en yoğun saat)',
    'customerServices.NotificationCountInfo':
      'Müşteri Hizmetleri yoğunluk bildirimi sayısı',
    'customerServices.NotificationCountChangeInfo':
      'Müşteri Hizmetleri yoğunluk bildirimi sayısının karşılaştırma tarihine göre değişimi',
    'customerServices.CustomerCountInfo':
      'Müşteri Hizmetleri alanlarında {value} vakit geçiren temas sayısı',
    'customerServices.AverageDailyCustomerCountInfo':
      'Müşteri Hizmetleri alanlarında {value} vakit geçiren ortalama temas sayısı (Toplam {value} temas / Gün sayısı)',
    'customerServices.CustomerCountChangeInfo':
      'Müşteri Hizmetleri alanlarının {value} temas sayısının karşılaştırma tarihine göre değişimi',
    'customerServices.DwellInfo':
      'Müşteri Hizmetleri alanlarında tespit edilen {value} temasın geçirdiği ortalama süre',
    'customerServices.DwellChangeInfo':
      'Müşteri Hizmetleri alanlarında ölçülen {value} temasın ortalama süresinin karşılaştırma tarihine göre değişimi',
    'customerServices.DelayRateInfo':
      '{value} temasın toplam temasa oranı ({value} temas / Toplam temas)',
    'customerServices.DelayRateChangeInfo':
      'Gecikme oranının karşılaştırma tarihine göre değişimi',

    'checkoutSummary.AverageDwellInfo':
      'Kasa alanlarında tespit edilen toplam temasın geçirdiği ortalama süre',
    'checkoutSummary.AverageDwellChangeInfo':
      'Kasa alanlarında ölçülen ortalama sürenin karşılaştırma tarihine göre değişimi',
    'checkoutSummary.DensityRatioInfo':
      'Kasa alanlarında ölçülen yoğunluğun seçilen bölgeler/lokasyonlar arasındaki dağılımı. (Yoğunluk = Temas sayısı x Ortalama Süre) (Yoğunluk Oranı = Bölge-Lokasyon Yoğunluğu / Toplam Yoğunluk)',
    'checkoutSummary.DensityRatioChangeInfo':
      'Kasa alanlarının yoğunluk oranlarının karşılaştırma tarihine göre değişimi',
    'checkoutSummary.ChWaitingAverageDwellInfo':
      'Kasa bekleme alanlarında tespit edilen toplam temasın geçirdiği ortalama süre',
    'checkoutSummary.ChWaitingAverageDwellChangeInfo':
      'Kasa bekleme alanlarında ölçülen ortalama sürenin karşılaştırma tarihine göre değişimi',
    'checkoutSummary.ChWaitingDensityRatioInfo':
      'Kasa bekleme alanlarında ölçülen yoğunluğun seçilen bölgeler/lokasyonlar arasındaki dağılımı. (Yoğunluk = Temas sayısı x Ortalama Süre) (Yoğunluk Oranı = Bölge-Lokasyon Yoğunluğu / Toplam Yoğunluk)',
    'checkoutSummary.ChWaitingDensityRatioChangeInfo':
      'Kasa bekleme alanlarının yoğunluk oranlarının karşılaştırma tarihine göre değişimi',
    'checkoutSummary.PeakHourInfo':
      'Kasa alanlarının en yüksek yoğunluğa ulaştığı saatler (Karşılaştırma tarihi en yoğun saat -> İnceleme tarihi en yoğun saat)',
    'checkoutSummary.NotificationCountInfo': 'Kasa yoğunluk bildirimi sayısı',
    'checkoutSummary.NotificationCountChangeInfo':
      'Kasa yoğunluk bildirimi sayısının karşılaştırma tarihine göre değişimi',
    'checkoutSummary.InvoiceCountInfo': 'Kesilmiş fatura sayısı',
    'checkoutSummary.AverageDailyInvoiceCountInfo':
      'Günlük ortalama kesilen fatura sayısı (Toplam fatura sayısı / Gün Sayısı)',
    'checkoutSummary.InvoiceCountChangeInfo':
      'Karşılaştırma tarihine göre fatura sayısında yaşanan değişim',

    'checkoutSummary.CustomerCountInfo':
      'Kasa alanlarında {value} vakit geçiren temas sayısı',
    'checkoutSummary.AverageDailyCustomerCountInfo':
      'Kasa alanlarında {value} vakit geçiren ortalama temas sayısı (Toplam {value} temas / Gün sayısı)',
    'checkoutSummary.CustomerCountChangeInfo':
      'Kasa alanlarının {value} temas sayısının karşılaştırma tarihine göre değişimi',
    'checkoutSummary.DwellInfo':
      'Kasa alanlarında tespit edilen {value} temasın geçirdiği ortalama süre',
    'checkoutSummary.DwellChangeInfo':
      'Kasa alanlarında ölçülen {value} temasın ortalama süresinin karşılaştırma tarihine göre değişimi',
    'checkoutSummary.DelayRateInfo':
      '{value} temasın toplam temasa oranı ({value} temas / Toplam temas)',
    'checkoutSummary.DelayRateChangeInfo':
      'Gecikme oranının karşılaştırma tarihine göre değişimi',
    'checkoutSummary.ConversionRateInfo':
      'Kesilmiş fatura sayısının kasada tespit edilen {value} temasa oranı ( Fatura sayısı / {value} temas)',
    'checkoutSummary.ConversionRateChangeInfo':
      'Dönüşüm oranının karşılaştırma tarihine göre değişimi',

    'checkoutSummary.storeRegion': 'Lokasyon/Bölge',
    'checkoutSummary.AverageDwell': 'Kasa Ortalama Süre',
    'checkoutSummary.AverageDwellChange': 'Kasa Ortalama Süre Değişimi',
    'checkoutSummary.DensityRatio': 'Yoğunluk Oranı',
    'checkoutSummary.DensityRatioChange': 'Yoğunluk Oranı Değişimi',
    'checkoutSummary.ChWaitingAverageDwell': 'Bekleme Alanı Ortalama Süre',
    'checkoutSummary.ChWaitingAverageDwellChange':
      'Bekleme Alanı Ortalama Süre Değişimi',
    'checkoutSummary.ChWaitingDensityRatio': 'Bekleme Alanı Yoğunluk Oranı',
    'checkoutSummary.ChWaitingDensityRatioChange':
      'Bekleme Alanı Yoğunluk Oranı Değişimi',
    'checkoutSummary.PeakHour': 'En Yoğun Saat',
    'checkoutSummary.NotificationCount': 'Bildirim Sayısı',
    'checkoutSummary.NotificationCountChange': 'Bildirim Sayısı Değişimi',
    'checkoutSummary.InvoiceCount': 'Fatura Sayısı',
    'checkoutSummary.AverageDailyInvoiceCount': 'Günlük Ortalama Fatura Sayısı',
    'checkoutSummary.InvoiceCountChange': 'Fatura Sayısı Değişimi',
    'checkoutSummary.over15sec': '15 Saniye Üzeri',
    'checkoutSummary.CustomerCount': 'Temas Sayısı',
    'checkoutSummary.AverageDailyCustomerCount': 'Günlük Ortalama Temas Sayısı',
    'checkoutSummary.CustomerCountChange': 'Temas Değişimi',
    'checkoutSummary.Dwell': 'Ortalama Süre',
    'checkoutSummary.DwellChange': 'Ortalama Süre Değişimi',
    'checkoutSummary.DelayRate': 'Gecikme Oranı',
    'checkoutSummary.DelayRateChange': 'Gecikme Oranı Değişimi',
    'checkoutSummary.ConversionRate': 'Dönüşüm Oranı',
    'checkoutSummary.ConversionRateChange': 'Dönüşüm Oranı Değişimi',
    'checkoutSummary.btw15and60sec': '15-60 Saniye Arası',
    'checkoutSummary.over60sec': '60 Saniye Üzeri',
    'sketches.sketchSavedWarning1':
      'Eski krokiyi isimlendirmek ve güncel krokinin başlangıç tarihini güncellemek için ',
    'sketches.sketchSavedWarning2': 'tıklayın.',
    'sketches.sketchHistoryTitle': 'Kroki Geçmişi Güncelleme',
    'sketches.updateSketchHistoryDate': 'Kroki Geçmişi Tarihini Güncelleme',
    'info.currentSketch': 'Güncel Kroki',
    'info.sketchHistory': 'Geçmiş Kroki',
    ShopInShopFrontLineCount: 'Shop-in-Shop Önü Trafiği',
    ShopInShopEntranceLineCount: 'Shop-in-Shop Girişi',
    ShopInShopFrontAndEntranceGraphTitle: 'Shop-in-Shop Önü Trafiği ve Girişi',
    ShopInShopLineCountsGraphTitle: 'Shop-in-Shop Line Counts',
    compare: 'Kıyasla',
    firstArea: 'İlk alan',
    secondArea: 'İkinci alan',
    'info.ShopInShopFrontCount': 'SiS Önü Trafiği',
    'info.ShopInShopEntranceCount': 'SiS Ziyaretçi Sayısı',
    'info.ShopInShopConversionRatio': 'SiS Dönüşüm Oranı',
    'notificationFilter.seenFilter': 'Görüldü Filtresi',
    'notificationFilter.seen': 'Görüldü',
    'notificationFilter.nonSeen': 'Görülmedi',
    'notificationFilter.all': 'Tümü',
    'notificationFilter.respondedFilter': 'Cevaplandı Filtresi',
    'notificationFilter.responded': 'Cevaplandı',
    'notificationFilter.nonResponded': 'Cevaplanmadı',
    'notificationFilter.responseRate': 'Cevaplama Oranı',
    'notificationFilter.total': 'Toplam',
    single: 'Tekil',
    group: 'Grup',
    lineEntranceGroupValue12578: "{value}'li Grup Sayısı",
    lineEntranceGroupValue34: "{value}'lü Grup Sayısı",
    lineEntranceGroupValue6: "{value}'lı Grup Sayısı",
    lineEntranceGroupValue9: "{value}'lu Grup Sayısı",
    areYouSureDeletePrompt: 'Silmek istediğinize emin misiniz?',
    doorLine: 'Kapı Çizgisi',
    countingLine: 'Sayım Çizgisi',
    'drawingToolsInfos.close': 'Kamera yakınlaştır: W',
    'drawingToolsInfos.far': 'Kameradan uzaklaştır: S',
    'drawingToolsInfos.reset': 'Yakınlaştırma sıfırla: R',
    'drawingToolsInfos.move': 'Kamera kaydır: Q basılı tut',
    'drawingToolsInfos.polygonActions': 'Poligon eylemleri: Sağ tık',
    'drawingToolsInfos.polygonDrag': 'Poligon sürükleme: Shift basılı tut',
    'drawingToolsInfos.addCorner':
      'Köşe ekleme: Shift basılıyken köşeye sol tık',
    'drawingToolsInfos.deleteCorner':
      'Köşe silme: Alt basılıyken köşeye sol tık',
    lines: 'Çizgiler',
    polygons: 'Poligonlar',
    noPolygons: 'Hiçbir poligon bulunamadı.',
    noLines: 'Hiçbir çizgi bulunamadı.',
    drawRectangle: 'Dikdörtgen çiz'
  },
  de: {
    'customerServices.AverageDwell': 'Customer Services Average Dwell',
    'customerServices.AverageDwellChange':
      'Customer Services Average Dwell Change',

    'customerServices.AverageDwellInfo':
      'Average time spent by total contact detected in Customer Services areas',
    'customerServices.AverageDwellChangeInfo':
      'Change in the average time measured in Customer Service areas compared to the comparison date',
    'customerServices.DensityRatioInfo':
      'Distribution of measured density in Customer Service areas among selected regions/locations. (Density = Number of contacts x Average Duration) (Density Ratio = Region-Location Density / Total Density)',
    'customerServices.DensityRatioChangeInfo':
      'Change in density rates of Customer Services areas according to comparison date',
    'customerServices.PeakHourInfo':
      'Peak hours for Customer Service areas (Comparison date peak hour -> Review date peak hour)',
    'customerServices.NotificationCountInfo':
      'Number of Customer Services density notifications',
    'customerServices.NotificationCountChangeInfo':
      'Change in the number of Customer Services density notifications according to the comparison date',
    'customerServices.CustomerCountInfo':
      'Number of customers spending {value} time in Customer Service areas',
    'customerServices.AverageDailyCustomerCountInfo':
      'Average number of customers spending {value} time in Customer Service areas (Total {value} customers / Number of days)',
    'customerServices.CustomerCountChangeInfo':
      'Change in the number of {value} customers of Customer Service areas according to the comparison date',
    'customerServices.DwellInfo':
      'Average time spent by {value} customers detected in Customer Services areas',
    'customerServices.DwellChangeInfo':
      'Change in the average duration of {value} customers measured in Customer Service areas compared to the comparison date',
    'customerServices.DelayRateInfo':
      'Ratio of {value} customers to total customers ({value} customers / Total customers)',
    'customerServices.DelayRateChangeInfo':
      'Change of delay rate according to comparison date',

    'checkoutSummary.AverageDwellInfo':
      'Average time spent by total contact detected in cash register areas',
    'checkoutSummary.AverageDwellChangeInfo':
      'Change in the average time measured in checkout areas compared to the comparison date',
    'checkoutSummary.DensityRatioInfo':
      'Distribution of density measured in cash register areas among selected regions/locations. (Density = Number of contacts x Average Duration) (Density Ratio = Region-Location Density / Total Density)',
    'checkoutSummary.DensityRatioChangeInfo':
      'Change in the density ratios of safe areas according to the comparison date',
    'checkoutSummary.ChWaitingAverageDwellInfo':
      'Average time spent by total contact detected in cash register waiting areas',
    'checkoutSummary.ChWaitingAverageDwellChangeInfo':
      'Change in the average time measured in checkout waiting areas compared to the comparison date',
    'checkoutSummary.ChWaitingDensityRatioInfo':
      'Distribution of the density measured in checkout waiting areas among selected regions/locations. (Density = Number of contacts x Average Duration) (Density Ratio = Region-Location Density / Total Density)',
    'checkoutSummary.ChWaitingDensityRatioChangeInfo':
      'Change in the density rates of cash register waiting areas according to the comparison date',
    'checkoutSummary.PeakHourInfo':
      'Hours when checkout areas reach their highest density (Comparison date peak hour -> Review date peak hour)',
    'checkoutSummary.NotificationCountInfo':
      'Number of case density notifications',
    'checkoutSummary.NotificationCountChangeInfo':
      'Change in the number of case density notifications according to the comparison date',
    'checkoutSummary.InvoiceCountInfo': 'Number of issued invoices',
    'checkoutSummary.AverageDailyInvoiceCountInfo':
      'Average number of invoices issued per day (Total number of invoices / Number of days)',
    'checkoutSummary.InvoiceCountChangeInfo':
      'Change in the number of invoices according to the comparison date',

    'checkoutSummary.CustomerCountInfo':
      'Number of contacts spending {value} time in checkout areas',
    'checkoutSummary.AverageDailyCustomerCountInfo':
      'Average number of contacts spending {value} time in checkout areas (Total {value} contacts / Number of days)',
    'checkoutSummary.CustomerCountChangeInfo':
      'Change in the number of contacts of cash register areas {value} according to the comparison date',
    'checkoutSummary.DwellInfo':
      'Average time spent by {value} contact detected in cash register areas',
    'checkoutSummary.DwellChangeInfo':
      'Change in the average duration of {value} contact measured in cash register areas according to the comparison date',
    'checkoutSummary.DelayRateInfo':
      'Ratio of {value} contact to total contact ({value} contact / Total contact)',
    'checkoutSummary.DelayRateChangeInfo':
      'Change of delay rate according to comparison date',
    'checkoutSummary.ConversionRateInfo':
      'Ratio of the number of issued invoices to {value} contacts detected at the cash register (Number of invoices / {value} contacts)',
    'checkoutSummary.ConversionRateChangeInfo':
      'Change in conversion rate by comparison date',

    'checkoutSummary.storeRegion': 'Location/Region',
    'checkoutSummary.AverageDwell': 'Checkout Average Dwell',
    'checkoutSummary.AverageDwellChange': 'Checkout Average Dwell Change',
    'checkoutSummary.DensityRatio': 'Density Ratio',
    'checkoutSummary.DensityRatioChange': 'Density Ratio Change',
    'checkoutSummary.ChWaitingAverageDwell': 'Waiting Area Average Dwell',
    'checkoutSummary.ChWaitingAverageDwellChange':
      'Waiting Area Average Dwell Change',
    'checkoutSummary.ChWaitingDensityRatio': 'Waiting Area Density Ratio',
    'checkoutSummary.ChWaitingDensityRatioChange':
      'Waiting Area Density Ratio Change',
    'checkoutSummary.PeakHour': 'Peak Hour',
    'checkoutSummary.NotificationCount': 'Notification Count',
    'checkoutSummary.NotificationCountChange': 'Notification Count Change',
    'checkoutSummary.InvoiceCount': 'Invoice Count',
    'checkoutSummary.AverageDailyInvoiceCount': 'Daily Average Invioce Count',
    'checkoutSummary.InvoiceCountChange': 'Invoice Count Change',
    'checkoutSummary.over15sec': 'Over 15 Seconds',
    'checkoutSummary.CustomerCount': 'Customer Count',
    'checkoutSummary.AverageDailyCustomerCount': 'Daily Average Customer Count',
    'checkoutSummary.CustomerCountChange': 'Customer Count Change',
    'checkoutSummary.Dwell': 'Dwell',
    'checkoutSummary.DwellChange': 'Dwell Change',
    'checkoutSummary.DelayRate': 'Delay Rate',
    'checkoutSummary.DelayRateChange': 'Delay Rate Change',
    'checkoutSummary.ConversionRate': 'Conversion Rate',
    'checkoutSummary.ConversionRateChange': 'Conversion Rate Change',
    'checkoutSummary.btw15and60sec': 'Between 15-60 Seconds',
    'checkoutSummary.over60sec': 'Over 60 Seconds',

    'sketchHistory.blueLegion': 'Contains at least 1 active area.',
    'sketchHistory.grayLegion': 'All areas are archived.',
    'sketchHistory.redLegion': 'All areas have been deleted.',
    'info.submit': 'Abschicken',
    'info.login': 'Anmelden',
    'info.forgotpassword': 'Passwort vergessen?',
    'info.username': 'Benutzername',
    'info.name': 'Name',
    'info.password': 'Passwort',
    'info.changePassword': 'Passwort ändern',
    'info.oldPassword': 'Altes Passwort',
    'info.newPassword': 'Neues Passwort',
    'info.repeatNewPassword': 'Neues Passwort wiederholen',
    'info.successPas': 'Das Passwort wurde erfolgreich geändert.',
    'info.errorPassword': 'Das Passwort ist nicht korrekt.',
    'info.badPassword': 'Bad Password',
    'info.blankField': 'Please Fill In The Empty Fields!',
    'info.profile': 'Mein Profil',
    'info.selectUser': 'Benutzer auswählen',
    'info.store.info': 'Geschäftinformationen',
    'info.selectStore': 'Geschäftauswahl',
    'info.selectBrand': 'Select Brand',
    'info.categoryName': 'Kategoriename',
    'info.addCategory': 'Kategorie hinzufügen',
    'info.selectCategory': 'Kategorie auswählen',
    'info.selectSubCategory': 'Unterkategorie auswählen',
    'info.RemoveCategory': 'Kategorie entfernen',
    'info.selectSaleCategory': 'Kategorie zur Veräuß  erung auswählen',
    'info.selectCamera': 'Kamera auswählen',
    'info.selectCamera2': 'Kamera auswählen',
    'info.selectArea': 'Bereich auswählen',
    'info.selectSketch': 'Skizze auswählen',
    'info.selectOperator': 'Operator auswählen',
    'info.selectDatasource': 'Datenquelle auswählen',
    'info.updateSketch': 'Skizze aktualisieren',
    'info.set': 'Einstellen',
    'info.save': 'Speichern',
    'info.confirm': 'Bestätigen',
    'info.cancel': 'Wiederrufen',
    'info.efficiencyScore': 'Effizienzwert',
    'info.sec': 'Sek',
    'info.min': 'Min',
    'info.minute': 'Minute',
    'info.second': 'Sekunde',
    'info.saveChanges': 'Änderungen speichern',
    'info.addChart': 'Chart hinzufügen',
    'info.add': 'Hinzufügen',
    'info.always': 'Immer',
    'info.remove': 'Entfernen',
    'info.delete': 'Löschen',
    'info.edit': 'Bearbeiten',
    'info.archive': 'Archivieren',
    'info.archived': 'Archived',
    'info.revert': 'Revert',
    'info.addNewSketch': 'Neue Skizze hinzufügen',
    'info.notification.edit': 'Edit Notification Rule',
    'info.notification.editComplex': 'Edit Complex Notification Rule',
    'info.notification.visitorCount': 'Person(en)',
    'info.notification.visitorTime': 'Sek',
    'info.notification.passiveStatusActive': 'Aktiv',
    'info.notification.passiveStatusPassive': 'Passiv',
    'info.notification.Users': 'Target Users',
    'info.notification.peopleCount': 'Besucherzahl',
    'info.excelChart.person': 'Person',
    'info.excelChart.traffic': 'Traffic',
    'info.notification.timeSpent': 'Verbrachte Zeit',
    'info.notification.timeRange': 'Zeitspanne',
    'info.notification.condition': 'Bedingung',
    'info.notification.conditionPerson':
      'Notification is not sent when the visitor count detected in less than the rule is 0',
    'info.word.and': 'und',
    'info.status': 'Status',
    'info.archivedDate': 'archiviertes Datum',
    'info.toCamera': 'zur Kamera verbinden',
    'info.toSetting': 'Gehe zu den Einstellungen',
    'info.Entrance': 'Eingang',
    'info.Checkout': 'Auschecken',
    'info.cameraAndArea': 'Kamera / Bereich',
    'info.department': 'Abteilung',
    'info.newDepartment': 'neue Abteilung',
    'info.selectDepertment': 'Abteilung auswählen',
    'info.newDepartmentType.label': 'Abteilungstyp',
    'info.newDepartmentType.shelf': 'Regal',
    'info.newDepartmentType.checkOutWaitingArea': 'Checkout Waiting Area',
    'info.newDepartmentType.customerServices': 'Customer Services',
    'info.newDepartmentType.correlation': 'Correlation',
    'info.newDepartmentType.checkOut': 'Kasse',
    'info.newDepartmentType.ignore': 'Ignorieren',
    'info.newDepartmentType.doorIn': 'Tür herein',
    'info.newDepartmentType.doorOut': 'Tür Außen',
    'info.newDepartmentType.doorLeft': 'Tür Links',
    'info.newDepartmentType.doorRight': 'Tür Rechts',
    'info.newDepartmentType.AreaOfInterest': 'Detection Area',
    'info.newDepartmentType.YellowLineViolation': 'Yellow Line Violation',
    'info.newDepartmentType.firstStand': 'First Stand',
    'info.rectName.placeholder': 'Rechteckname',
    'info.rectName.label': 'Rechteckname',
    'info.uploadCategoryFile': 'Kategoriedatei hochladen',
    'info.uploadCategory':
      'Die Dateierweiterung muss .csv sein, die Spalten enthalten den Kategoriecode bzw. den Kategorienamen ohne Titelzeile.',
    'info.Success': 'Erfolg',
    'info.areas': 'Bereiche',
    'info.area': 'Area',
    'info.total': 'Total',
    'info.totalCount': 'Total Count',
    'info.change': 'Änderung',
    'info.amount': 'Tägliche Menge',
    'info.prevAmount': 'Vorherige Summe',
    'info.metersquare': 'Quadratmeter',
    'info.metersquarerate': 'Quadratmeterrate',
    'info.endorsement': 'Billigung',
    'info.endorsement.detail':
      'Sie wird mit der Formel "Anzahl der Belege/Anzahl der Kunden" berechnet, wenn Beleginformationen in der Verkaufsintegration geteilt werden, und "Anzahl der Verkäufe/Anzahl der Kunden", wenn die Beleginformationen nicht geteilt werden.',
    'info.traffic.detail': 'Winkelverkeer wordt berekend op basis van vandaag.',
    'info.traffic.detailThisWeek':
      'Traffic information is calculated according to the selected time range.',
    'info.trafficTable.detail':
      'Store traffic information is calculated according to working hours.',
    'info.sales.detail':
      'verkoop- en conversieratiogegevens worden berekend op basis van de gefilterde datum',
    'info.table.detail':
      'Berekend door beïnvloed te worden door het datumfilter',
    'info.sales': 'Umsatz',
    'info.mean.time': 'durchschnittliche Dauer',
    'info.startTimeEqualsEndTimeWarning':
      'Start time and end time can not be the same!',
    'info.openingTimeEqualsClosingTimeWarning':
      'Opening time and closing time can not be the same!',
    'info.closeTimeAfterMidnightWarning': 'Closing time is after midnight!',
    'info.endTimeAfterMidnightWarning': 'End time is after midnight!',
    'info.openTime': 'Opening',
    'info.closeTime': 'Closing',
    'info.stimeHour': 'Start',
    'info.etimeHour': 'End',
    'info.stime': 'Startdatum',
    'info.etime': 'Enddatum',
    'info.excel.hour': 'Stunde',
    'info.chart': 'Diagramm',
    'info.table': 'Tabelle',
    'info.department.sales': 'Verkäufe',
    'info.comparisonv2.percent': 'Prozent',
    'info.rectangle.archive.accept':
      'Sind Sie sicher die Region zu archivieren.',
    'info.rectangle.archive.undone':
      'Diese Aktion ist nicht rückgängig zu machen',
    'info.rectangle.arhive.reminding':
      'Die Daten des Feldes nach diesem Datum werden gelöscht!',
    'info.line.arhive.reminding':
      'Liniendaten nach diesem Datum werden gelöscht!',
    'info.Department.Remove':
      'Are you sure you want to delete the selected categories , sub categories and campaigns to which subcategories are linked ?',
    'info.RemoveCategories': 'Ausgewählte Kategorien löschen',
    'info.Date.Previous': 'Vergleichsdatum',
    'info.Date.Current': 'aktuelles Datum',
    'info.process.date': 'Calculation Start Date of Historical Data',
    'info.notification.delete': 'Benachrichtigungsregel wird gelöscht.',
    'info.rotate': 'Rotieren',
    'info.limit': 'Limit',
    'info.Exceeded': 'Überschritten',
    'info.exceeded.provided': 'Überschritten vorausgesetzt',
    'info.group.name': 'Gruppenname',
    'info.sub.Category.group.name': 'Gruppenname der Unterkategorie',
    'info.sub.Category.group': 'Unterkategorie Gruppe',
    'info.title.storeInOut': 'In-Out speichern',
    'info.title.storeAvailabilityTime': 'Verfügbarkeitszeit im Geschäft',
    'info.title.storeDetailInOut': 'Stunde des In-Out',
    'info.newSketch.alert':
      'Nur diese Funktion; eine andere Etage im selben Geschäft hinzufügen. Verwenden Sie die Schaltfläche Grundriss aktualisieren, um das Geschäft zu aktualisieren.',
    'info.mean.interestRate': 'Interressesatz',
    'info.mean.conversionRate': 'Umsatz-Conversion-Rate',
    'info.15s': '15s / Eingang',
    'info.storeShare': 'im Geschäft',
    'info.storeShareChange': 'Instore Change',
    'info.storeChange': 'Besucher * Zeitumstellung',
    'info.dailyAvarage': 'Täglicher Durchschnitt',
    'info.dailyquantity': 'Tägliche Menge',
    'info.peakDensityHour': 'Peak Density Hour',
    'info.instore':
      'Das Dichteverhältnis der Fläche im Geschäft. (Dichte = (Durchschnittliche Anzahl der Kunden * Durchschnittliche Zeit) / Gesamtzahl der Kunden * Gesamtzeit), Das Verhältnis in der Dichtekarte',
    'info.instore.change':
      'Die Änderung des Dichteverhältnisses der Fläche im Geschäft. (Dichteverhältnis des ausgewählten 2. Datumsintervalls - Dichteverhältnis des ausgewählten 1. Datumsintervalls)',
    'info.instorechange':
      'Die Änderung der Dichte des Bereichs basierend auf der Änderung der durchschnittlichen Besucherzahl und der mittleren Zeit. (Person * Zeitänderung = (Durchschnittliche Anzahl der Kunden im 2. Datumsbereich * Durchschnittliche Zeitaufwand - Durchschnittliche Anzahl der Kunden im 1. Datumsbereich * Durchschnittliche Zeitaufwand)) / Durchschnittliche Anzahl der Kunden im 1. Datumsbereich * Durchschnittliche Durchschnittszeit)',
    'info.performancetable.peakDensityHour':
      'The hour where the density is highest in the area.',
    'info.performancetable.count':
      'Die durchschnittliche Anzahl der Besucher, die das Gebiet betreten.',
    'info.performancetable.totalCount':
      'Gesamtzahl der Besucher, die das Gebiet betreten.',
    'info.performancetable.ShopInShopFrontCount':
      'Number of Customers Passing By the Shop-in-Shop.',
    'info.performancetable.ShopInShopEntranceCount':
      'Number of Customers Entering Shop-in-Shop.',
    'info.performancetable.ShopInShopConversionRatio':
      'Number of Shop-in-Shop Sales / Number of Shop-in-Shop Visitors.',
    'info.performancetable.ShopInShopFrontCountChange':
      'Number of Customers Passing By the Shop-in-Shop Change Ratio.',
    'info.performancetable.ShopInShopEntranceCountChange':
      'Number of Customers Entering Shop-in-Shop Change Ratio.',
    'info.performancetable.ShopInShopConversionRatioChange':
      'Number of Shop-in-Shop Sales / Number of Shop-in-Shop Visitors Change.',
    'info.performancetable.change': 'Enthalten dem des Datenbereichs',
    'info.performancetable.prevchange':
      'Die Änderung der durchschnittlichen Anzahl von Besuchern, die den Bereich betreten. (Änderung = (Durchschnittliche Anzahl von Kunden im 2. Datumsbereich - Durchschnittliche Anzahl von Kunden im 1. Datumsbereich) / Durchschnittliche Anzahl von Kunden im 1. Datumsbereich)',
    'info.performancetable.time':
      'Durchschnittliche Aufenthaltsdauer in der Region. (Durchschnittliche Zeit = Gesamtzeit / Anzahl der Tage)',
    'info.performancetable.timechange':
      'Die Veränderung der durchschnittlich verbrachten Zeit.',
    'info.performancetable.15s':
      'Das Verhältnis der durchschnittlichen Anzahl von Kunden, die 15 Sekunden oder länger in der Region verbringen, zur durchschnittlichen Anzahl von Kunden, die die Region betreten (15 Sekunden oder mehr Kunden / Durchschnittliche Anzahl von Kunden)',
    'info.performancetable.15schange':
      'Die Änderung des Verhältnisses der durchschnittlichen Anzahl von Kunden, die 15 Sekunden oder mehr in der Region verbringen, zur durchschnittlichen Anzahl von Kunden, die die Region betreten. (Durchschnittliche Anzahl der Kunden) / Anzahl der Kunden, die 15 Sekunden und mehr am 1. Datum / Durchschnitt verbrachte Anzahl der Kunden)',
    'info.performancetable.conversionRate':
      'Das Verhältnis der Verkaufsmenge zur Anzahl der Kunden, die 15 Sekunden oder länger verbringen. (Anzahl der Verkäufe / Anzahl der Kunden, die sich 15s oder mehr aufhalten)',
    'info.performancetable.conversionChange':
      'Die Änderung des Verhältnisses der Verkaufsmenge zur Anzahl der Kunden, die 15 Sekunden oder mehr verbringen. (Anzahl der Verkäufe im 1. Datumsbereich / Anzahl der Kunden, die 15 Sekunden oder mehr überschreiten)',
    'info.performancetable.prevsalestotal':
      'Gesamtzahl der Verkäufe am Vergleichstermin.',
    'info.performancetable.salestotal':
      'Gesamtzahl der Verkäufe zum Vergleichstermin.',
    'info.performancetable.salestotalchange':
      'Die Änderung der Gesamtzahl der Verkäufe.',
    'info.performancetable.sale':
      'Die durchschnittliche Anzahl der Verkäufe am verglichenen Datum. (Durchschnittliche Verkäufe = Gesamtverkäufe / Anzahl der Tage)',
    'info.performancetable.saledensity':
      'Umrechnungskurs der Dichte in der Region in die Anzahl der Verkäufe. Verkaufsmenge pro Dichteeinheit. (Anzahl der Verkäufe / Dichte = (Verhältnis von durchschnittlichem Umsatz zu durchschnittlicher Dichte)',
    'info.performancetable.saledensitychange':
      'Die Änderung der Verkaufsmenge pro Dichteeinheit (Durchschnittliche Anzahl der Verkäufe / Durchschnittliche Dichte)',
    'info.performancetable.prevtotalsaleamount':
      'Gesamtumsatz am Vergleichstag.',
    'info.performancetable.totalsaleamount': 'Gesamtumsatz am Vergleichstag.',
    'info.performancetable.totalchangesaleamount':
      'Die Änderung des Gesamtumsatzes.',
    'info.performancetable.saleamount':
      'Der durchschnittliche Umsatz in der Region.',
    'info.performancetable.endorsement':
      'Umrechnungskurs der Dichte im Gebiet zum Verkaufsbetrag. Verkaufsmenge pro Dichteeinheit. (Verkaufsmenge / Dichte = (Menge / Dichte)',
    'info.performancetable.prevsale':
      'Die Änderung der Verkaufsmenge pro Einheitsdichte.',
    'info.performancetable.visitor15sec':
      'Das VerhÃ§ltnis der Kunden, die 15 Sekunden in den Abteilungen verbrachten, zu Gesamtkunden.',
    'info.performancetable.sale15sec':
      'Verkaufsmenge geführt durch Kundenzahl, verbringen über 15 Sekunden in den Abteilungen.',
    'info.performancetable.metersquarerate': 'Die Quadratmeter der Fläche.',
    'info.performancetable.saleamountoverdensity':
      'Der Anteil der Quadratmeter der Fläche im Laden.',
    'info.Suggestion.HasSuggestion1':
      'Die Kategorien, die Vorschläge haben, werden durch die angezeigt',
    'info.Suggestion.HasSuggestion2': 'icon.',
    'info.showValues': 'Werte Anzeigen',
    'routes.uploadReports': 'Upload Reports',
    'routes.floors': 'Floors',
    'routes.reports': 'Reports',
    'routes.ageGender': 'Alter<br/>Geschlecht',
    'routes.employeeMonitoring': 'Mitarbeiter<br/>√úberwachung',
    'routes.settings': 'Einstellungen',
    'routes.comparison': 'Vergleich von Geschäften',
    'routes.sisComparison': 'Shop-in-Shop Comparison',
    'routes.sketchDeparment': 'Abbildung der Kundendichte',
    'routes.charts': 'Diagramm',
    'routes.heatmap': 'Wärmebildkarte',
    'routes.store': 'Geschäft',
    'routes.company': 'Unternehmen',
    'routes.comparisonV2': 'Vergleich von Kategorien',
    'routes.performance.table': 'Leistungstabelle',
    'routes.Company': 'Unternehmen',
    'routes.Suggestion.performanceAnalysis': 'Performance-Analyse',
    'routes.Suggestion.performanceChange': 'Leistungsänderung',
    'routes.Suggestion.Request': ' Vorschlagsanfrage',
    'routes.personnelv2': 'PersonalV2',
    'routes.personnel': ' Personal',
    'routes.trends': ' Trends',
    'routes.analysis': ' Analyse',
    'routes.analysis.request': ' Analyseanforderung',
    'routes.HomePage': 'Homepage',
    'global.change.request': ' Änderungsanforderung',
    'routes.heatmap.detail': 'Wärmebildkartendetails',
    'routes.FAQ': 'F.A.Q.',
    'routes.checkout': 'Checkout',
    'global.camera': 'Camera',
    'global.checkoutSummary': 'Checkout Summary',
    'treeSelect.inlineSearchPlaceholder': 'Search...',
    'treeSelect.noMatches': 'No matches found.',
    'treeSelect.placeholder': 'Choose...',
    'global.cameras': 'Cameras',
    'global.area': 'Bereich',
    'global.notifications': 'Benachrichtigungen',
    'global.add': 'Add',
    'global.edit': 'Edit',
    'global.cancel': 'Cancel',
    'global.save': 'Save',
    'global.remove': 'Remove',
    'global.delete': 'Delete',
    'global.notificationsv2': 'Benachrichtigungen V2',
    'global.basicNotifications': 'Basic Notifications',
    'global.detailedNotifications': 'Detailed Notifications',
    'global.left': 'Left',
    'global.right': 'Right',
    'global.middle': 'Middle',
    'notificationsV2.startNotificationPrompt':
      'Are you sure that you want to start getting notifications?',
    'notificationsV2.stopNotificationPrompt':
      'Are you sure that you want to stop getting notifications?',
    'notificationsV2.createComplexRule': 'Create Complex Rule',
    'notificationsV2.TimeoutThreshold': 'Timeout Threshold (sec)',
    'notificationsV2.viewComplexRule': 'View Complex Rule',
    'notificationsV2.inactiveChannels':
      'Channels That Are Deactivated By The User',
    'notificationsV2.UserIds': 'Users',
    'notificationsV2.trTemplate': 'Turkish Template',
    'notificationsV2.enTemplate': 'English Template',
    'notificationsV2.RuleTree': 'Rule Tree',
    'notificationsV2.ruleTreeTitle': 'Rule Tree Editor',
    'notificationsV2.channels': 'Channels',
    'notificationsV2.Mail': 'Mail',
    'notificationsV2.Mobile': 'Mobile',
    'notificationsV2.Web': 'Web',
    'notificationsV2.Name': 'Name',
    'notificationsV2.Description': 'Description',
    'notificationsV2.Days': 'Days',
    'notificationsV2.TimeRange': 'TimeRange',
    'notificationsV2.MaxSendFrequencySec': 'Max Send Frequency (sec)',
    'notificationsV2.DataSourceType': 'Data Source Type',
    'notificationsV2.Line': 'Line',
    'notificationsV2.TimeScopeNumber': 'Time Scope Number',
    'notificationsV2.TimeScopePeriod': 'Time Scope Period',
    'notificationsV2.Polygon': 'Polygon',
    'notificationsV2.ObjectType': 'Object',
    'notificationsV2.ComparisonOperator': 'Comparison Operator',
    'notificationsV2.Threshold': 'Threshold',
    'notificationsV2.EqualTo': 'Equal To',
    'notificationsV2.LessThanOrEqual': 'Less Than Or Equal',
    'notificationsV2.GreaterThanOrEqual': 'Greater Than Or Equal',
    'notificationsV2.LessThan': 'Less Than',
    'notificationsV2.GreaterThan': 'Greater Than',
    'notificationsV2.ObjectCount': 'Number Of People Entering',
    'notificationsV2.TimeUnit': 'Time Unit',
    'notificationsV2.linePlaceholder': 'Please select a line...',
    'notificationsV2.polygonPlaceholder': 'Please select a polygon...',
    'notificationsV2.comparisonOperatorPlaceholder':
      'Please select a comparison operator...',
    'notificationsV2.objectPlaceholder': 'Please select an object...',
    'notificationsV2.daysPlaceholder': 'Please select days...',
    'notificationsV2.Second': 'Second',
    'notificationsV2.Minute': 'Minute',
    'notificationsV2.Hour': 'Hour',
    'notificationsV2.Day': 'Day',
    'notificationsV2.Week': 'Week',
    'notificationsV2.Month': 'Month',
    'notificationsV2.Year': 'Year',
    'notificationsV2.Sunday': 'Sunday',
    'notificationsV2.Monday': 'Monday',
    'notificationsV2.Tuesday': 'Tuesday',
    'notificationsV2.Wednesday': 'Wednesday',
    'notificationsV2.Thursday': 'Thursday',
    'notificationsV2.Friday': 'Friday',
    'notificationsV2.Saturday': 'Saturday',
    'notificationsV2.removeRule': 'Remove Rule',
    'notificationsV2.addNewRule': 'Add Rule',
    'notificationsV2.LineNegativeDirection': 'Out',
    'notificationsV2.LinePositiveDirection': 'In',
    'notificationsV2.PolygonDwellTime': 'Dwell Time',
    'notificationsV2.PolygonRepeatedEntrancesCount': 'Entrance Count',
    'notificationsV2.PolygonNonRepeatedEntrancesCount': 'People Count',
    'global.actionButtons': 'Action Buttons',
    'global.back': 'Back',
    'global.next': 'Next',
    'global.submit': 'Submit',
    'global.requiredField': 'This field is required.',
    'global.minLengthError': 'This field needs at least {value} entry.',
    'global.occupancy': 'Occupancy',
    'global.sketch': 'Skizze',
    'global.SaleUploading': 'Verkauf Hochladen',
    'global.map': 'Lageplan',
    'global.category': 'Kategorie',
    'global.subcategory': 'Unterkategorie',
    'global.categories': 'Kategorien',
    'global.departments': 'Abteilungen',
    'global.sale': 'Umsatz',
    'global.sale.m2': 'Umsatz/M2',
    'global.shelf': 'Regal',
    'global.WorkingHours': 'Arbeitsstunden',
    'global.WorkingHoursWarningMessage': 'werktijden zijn niet ingesteld',
    'global.StoreOpen-Close': 'Ladenöffnungszeiten',
    'global.WorkHoursOpen': 'Offen',
    'global.Save': 'Speichern',
    'global.Search': 'Suchen',
    'global.logout': 'Abmelden',
    'global.summarypage': 'Zusammenfassung',
    'global.brandPerformanceTable': 'Leistungstabelle',
    'global.Forecast': 'Vorhersage',
    'global.selectStore': 'You need to select a store.',
    'global.region': 'Region',
    'global.regionlessStores': 'Regionless Stores',
    'global.somethingWentWrong': 'Something went wrong.',

    'resetPassword.goToLoginPage': 'Go To Login Page',
    'excel.header.averagePerson': 'durchschnittlicher Kunde',
    'chart.title.customerDwell': 'Kundennummer & Haltezeit',
    'chart.title.mostVisisted': 'meistbesucht(number of people)',
    'chart.title.mostTimeSpent': 'am meisten verbrachte Zeit(person sec)',
    'chart.title.conversionRate': 'Umwandlungsrate',
    'chart.title.averageConversionRate': 'Durchschnitt Umwandlungsrate',
    'chart.title.invoices': 'Anzahl der Rechnungen',
    'chart.title.averageInvoices': 'Durchschnittliche Rechnungen Produktmenge',
    'chart.title.customerNumber': 'Kundennummer Tabelle',
    'chart.title.customerNumberTable': 'Änderung der Kundenanzahl',
    'chart.title.customerTimeTable': 'Änderung der Kundenzeit',
    'chart.title.dwellTime': 'Haltezeitchart',
    'chart.title.departmentAnalysis': 'Abteilungsanalyse',
    'chart.title.funnelChart': 'Erfassungstrichter',
    'chart.title.correlations': 'Korellationen',
    'chart.title.notifications': 'Benachrichtigungen',
    'chart.title.weatherAndTraffic': 'Wetter und Verkehr',
    'chart.title.trafficAndSales': 'Kundenverkehr und Umsatzgraph',
    'chart.title.conversionRateMarket': 'Umsetzungsrate (Marktverkehr)',
    'chart.title.conversionRateVehicle': 'Umsetzungsrate (Fahrzeugverkehr)',
    'chart.title.vehicleNumberAndMarket':
      'Number of Vehicles and Market Traffic',
    'chart.title.customerDirection': 'Customer Direction In Store',
    'chart.title.totalCustomer': 'Total Customer',
    'chart.title.genderDistr': 'Gender Distribution',
    'info.sketchRect': 'Sketch Rectangle',
    'chart.title.ageDistr': 'Age Distribution',
    'chart.legend.customer': 'Kunde',
    'chart.legend.timeSpent': 'Verweildauer',
    'chart.legend.date': 'Datum',
    'chart.legend.density': 'Kundendichte',
    'chart.legend.density/m2': 'Kundendichte/M2',
    'chart.legend.m2': 'Quadratmeter',
    'chart.legend.customerCount': 'Kundenzahl',
    'chart.legend.dwellTime': 'Verweildauer',
    'chart.legend.productCount': 'Produktzahl',
    'chart.legend.totalAmount': 'Gesamtbeitrag',
    'chart.legend.weather': 'Wetter',
    'chart.filter.hourly': 'stündlich',
    'chart.filter.daily': 'täglich',
    'chart.info.densityMap':
      'Die Kundendichte jedes Regalabschnitts gemäß   dem ausgewählten Zeitintervall angeben.',
    'chart.button.get': 'erhalten',
    'chart.button.select': 'Alles auswählen',
    'chart.button.clear': 'löschen',
    'chart.color.scale': 'Leistungsskala',
    'chart.sale.quantity': 'Verkauf Quantität',
    'chart.sale.amount': 'Verkaufserlös',
    'chart.sale.quantityheader': 'Verkauf Quantität',
    'chart.sale.amountheader': 'Verkaufserlös',
    'chart.sale.amountheaderCamelCase': 'Verkaufserlös',
    'chart.entrance': 'Eingang',
    'chart.PassByCount.ToLeft': 'von Rechts nach Links',
    'chart.PassByCount.ToRight': 'von Links nach Rechts',
    'chart.passByCount.header': 'an dem Laden vorbei',
    'chart.passByCustomerCount.header': 'Store Pass By Customer Count',
    'chart.Over15SecDwell': 'Verweilzeit über 15 Sekunden (sek)',
    'chart.TotalCount': 'Komplette Anzahl',
    'chart.AvgDwell': 'Durchschnittliche Verweilzeit (Sek.)',
    'chart.over15sec': 'Number of customers exceeding 15 seconds',
    'chart.over30sec': 'Number of customers exceeding 30 seconds',
    'chart.over60sec': 'Number of customers exceeding 60 seconds',
    'chart.over90sec': 'Number of customers exceeding 90 seconds',
    'chart.over120sec': 'Number of customers exceeding 2 minutes',
    'chart.over180sec': 'Number of customers exceeding 3 minutes',
    'chart.dwell15sec': 'Dwell time over 15 seconds',
    'chart.dwell60sec': 'Dwell time over 60 seconds',
    'chart.dwell120sec': 'Dwell time over 2 minutes',

    'chart.tooltip.daily': 'Täglich',
    'chart.tooltip.status.increase': 'erhöhen, ansteigen',
    'chart.tooltip.status.decrease': 'verringern',
    'chart.tooltip.observed': 'wird beobachtet.',
    'chart.tooltip.time': 'Zeit',
    'chart.tooltip.time.lowercase': 'Zwischenzeit',
    'chart.tooltip.person': 'Besucher',
    'chart.line.in': 'Im',
    'chart.line.out': 'aus',
    'chart.Top': 'oben',
    'chart.Bottom': 'Unterseite',
    'chart.CustomerCount':
      'Anzahl der Einträge und durchschnittliche Zeit in der Region',
    'chart.allCategories': 'All Categories',
    'chart.categories': 'Categories',
    'chart.totalCustomers': 'Total Customers',
    'chart.totalCustomersRate': 'Total Customers Rate',
    'performanceTable.tooltip.hasSuggestion':
      ' Vorschläge sind verfügbar. Klicke zum Zeigen.',
    'chart.dwellTime': ' Verweilzeit',
    'chart.prevWeek': 'Previous Week',
    'chart.currWeek': 'Current Week',
    'landingPage.description1': 'Select a product to see details',
    'landingPage.description2': 'See the details by hovering into categories',
    'products.ANALYTIC': 'Instore Analytics',
    'products.ENTRANCE': 'People Counting',
    'products.CHECKOUT': 'Checkout',
    'products.PERSONNEL': 'Staff',
    'products.AGE_GENDER': 'Age Gender',
    'products.categories.BRAND': 'Brand',
    'products.categories.STORE': 'Store',
    'products.categories.DENSITY_MAP': 'Density Map',
    'products.categories.PERFORMANCE_TABLE': 'Performance Table',
    'products.categories.TRENDS': 'Trends',
    'products.categories.SUGGESTIONS': 'Suggestions',
    'products.categories.CHECKOUT': 'Checkout',
    'products.categories.PERSONNEL': 'Personnel',
    'products.categories.CAMPAIGN': 'Campaign',
    'products.categories.CAMERA': 'Camera',
    'products.categories.RECEIVER': 'Receiver',
    'products.categories.AGE_GENDER': 'Age Gender',
    'products.categories.STORE_COMPARISON': 'Store Comparison',
    'products.categories.CATEGORY_COMPARISON': 'Category Comparison',
    'landingPage.searchPlaceholder':
      'Please enter a keyword (Ex: People Counting)',
    'landingPage.backToProducts': 'Back To Products',
    'products.categories.description.DENSITY_MAP':
      '1) Analyze the density, visitor count, mean time and interest rate data on the sketch -/&/- 2) Compare area data with square meter and (if applicable) sales data',
    'products.categories.description.PERFORMANCE_TABLE':
      '1) Analyze the density, visitor count, mean time and interest rate data on the table -/&/- 2) Compare metrics between two dates',
    'products.categories.description.TRENDS':
      '1) Examine the consecutive uptrend or downtrend of the areas',
    'products.categories.description.SUGGESTIONS':
      '1) Display computer-assisted automatic suggestions for areas',
    'products.categories.description.CHECKOUT':
      '1) View checkout data for a store -/&/- 2) Compare data by location and date',
    'products.categories.description.CAMERA':
      '1) Examine the heat map and areas on camera snapshots',
    'products.categories.description.RECEIVER':
      '1) Examine staff-area density relation',
    'products.categories.description.PERSONNEL': '1) Analyze staff efficiency',
    'products.categories.description.BRAND':
      '1) View the data of all locations in the system',
    'products.categories.description.STORE':
      '1) View visitor count data for a store -/&/- 2) View checkout data for a store -/&/- 3) View areas with the best metrics',
    'products.categories.description.AGE_GENDER': '1) View store demography',
    'products.categories.description.STORE_COMPARISON':
      '1) Comparing area metrics of two different locations',
    'products.categories.description.CATEGORY_COMPARISON':
      '1) Compare categories of many locations',
    'products.noSelectedStoreProduct':
      'You need to select a store in order to see your products.',
    'brand.generalGraph.VISITOR_COUNT': 'Traffic',
    'brand.generalGraph.ENDORSEMENT': 'Sales Amount',
    'brand.generalGraph.CONVERSION_RATE': 'Conversion Rate',
    'brand.generalGraph.RECEIPT_COUNT': 'Receipt Count',
    'brand.generalGraph.SALE_COUNT': 'Sales Quantity',
    'brand.generalGraph.ENDORSEMENT_PER_M2': 'Sales Amount/M2',
    'brand.generalGraph.ENTRANCE_RATE': 'Total Customers Rate',
    'Trends.Title': ' Trends',
    'Trends.Title.description':
      ' Es zeigt die Auf- und Abwärtstrends in Ihren bestehenden Bereichen.',
    'Trends.sketch': ' Trendkarte',
    'Trends.sketch.description':
      ' Es zeigt Ihre aktuellen Trends zum ausgewählten Enddatum.',
    'Trends.Suggestion.Info1': ' Sie haben Vorschläge für',
    'Trends.Suggestion.Info2': '  Kategorien',
    'trends.time': ' Trendzeit:',
    'trends.reason': ' Grund:',
    'trends.sale': ' Verkauf',
    'trends.density': ' Dichte',
    'trends.interest': ' Interesse',
    'trends.visitor': ' Besucher',
    'trends.meantime': ' Zwischenzeit',
    'trends.chartMode': ' Diagrammmodus',
    'trends.mapMode': ' Kartenmodus',
    'trends.daily': ' Täglich',
    'trends.weekly': ' Wöchentlich',
    'trends.monthly': ' Monatlich',
    'trends.increase': ' Erhöhen, ansteigen',
    'trends.decrease': ' Verringern',
    'trends.excel.daily.decrease': 'Daily Decrease',
    'trends.excel.weekly.decrease': 'Weekly Decrease',
    'trends.excel.monthly.decrease': 'Monthly Decrease',
    'trends.excel.daily.increase': 'Daily Increase',
    'trends.excel.weekly.increase': 'Weekly Increase',
    'trends.excel.monthly.increase': 'Monthly Increase',
    'trends.tooltip.increaseing.daily': ' Zunehmend für',
    'trends.tooltip.decreaseing.daily': ' Abnehmend für',
    'trends.tooltip.increase.daily': ' Tage',
    'trends.tooltip.decrease.daily': ' Tage',
    'trends.tooltip.increaseing.weekly': 'Zunehmend für',
    'trends.tooltip.decreaseing.weekly': 'Abnehmend für',
    'trends.tooltip.increase.weekly': 'Wochen',
    'trends.tooltip.decrease.weekly': 'Wochen',
    'trends.tooltip.increaseing.monthly': 'Zunehmend für',
    'trends.tooltip.decreaseing.monthly': 'Abnehmend für',
    'trends.tooltip.increase.monthly': 'Monate',
    'trends.tooltip.decrease.monthly': 'Monate',
    'info.department.over': 'Über',
    'info.storecomparison.percentage': 'Prozent.',
    'info.SaleUploading.extension': "Dateierweiterung muss' .xlsx sein.",
    'info.SaleUploading.Overlay.Sure': 'Sind Sie sicher, die Daten zu senden?',
    'info.SaleUploading.undone': 'Diese Aktion ist nicht rückgängig zu machen.',
    'info.Category.File.Upload': 'Kategoriedatei hochladen',
    'info.Sketch.Photo.Update':
      'You may not be able to see some cameras and drawings if the image sizes are different.',
    'info.Sale.Ratio': 'Verkaufsverhältnis',
    'info.SeeAll': 'Alles sehen',
    'info.CloseAll': 'Alle schließen',
    'info.All': 'Alle',
    'info.Camera.Exit':
      'Sie haben die Änderungen nicht gespeichert. Möchten Sie trotzdem beenden?',
    'info.Camera.Change':
      'Sie haben die Änderungen nicht gespeichert. Möchten Sie trotzdem die Kamera wechseln?',
    'info.PrivacyPolicy': 'Unsere Datenschutzrichtlinie wurde aktualisiert.',
    'info.PrivacyPolicy.Link': 'klicke zum Zeigen.',
    'info.InterestRate': 'Interressesatz',
    'info.Location': 'Ort',
    'error.SaleUploading.SampleExcel': 'Beispiel Excel.',
    'error.SaleUploading.DateFormat': 'Überprüfen Sie das Datumsformat!',
    'error.SalexUploading.ColumnType': 'Spaltentypfehler!',
    'error.SaleUploading.fileFormat':
      'Senden Sie die Datei im gewünschten Format!',
    'error.SaleUploading.ChangeText': 'Den Text für Korrigieren ändern.',
    'error.SalesUploading.AddHeader':
      'Kopfzeile sowie Beispiel Excel müssen hinzugefügt werden!',
    'error.SalesUploading.EmptyCell': 'Leere Spalte gefunden!',
    'error.SalesUploading.EmptycellCount': 'Leere Zeile gefunden!',
    'error.Mark.Cam.ToSketch':
      'Markieren Sie die Kamera in der Skizze, um das Verhältnis der Kundendichte zu sehen.',
    'error.noData': 'Keine Daten verfügbar.',
    'error.invalidEmail': 'Ungültige E-Mail.',
    'error.PolygonCornerDelete': 'A polygon must contain at least 3 corner.',
    'error.notAvailable': 'Data not available!',
    'button.info.showAreas': 'Abteilungen anzeigen.',
    'button.info.showCameras': 'Kameras anzeigen.',
    'button.info.showPlan': 'Den Plan anzeigen.',
    'button.info.changeGroup': 'Gruppe verändern.',
    'button.info.setCameraLocation': 'Kamerastandort festlegen',
    'button.info.PrivacyPolicy': 'Genau',
    'button.info.showSuggestions': 'Zeige Vorschläge',
    'Date.Today': 'Heute',
    'Date.Yesterday': 'Gestern',
    'Date.ThisWeek': 'diese Woche',
    'Date.LastWeek': 'letzte Woche',
    'Date.ThisMonth': 'diesen Monat',
    'Date.LastMonth': 'im vergangenen Monat',
    'Date.Last15Days': 'Letzte 15 Tage',
    'Date.Last30Days': 'Letzte 30 Tage',
    'Date.DaysUpToToday': 'bis heute',
    'Date.DaysStartingToday': 'von heute',
    'Date.day': 'Tage',
    'Date.weekly': 'Wöchentlich',
    'Date.weekdays': 'Wochentags',
    'Date.weekend': 'Wochenende',
    'Date.week': 'Woche',
    'Tour.Info.home.Navigation':
      'Navigieren Sie mit Hilfe der Applikation um auf die Registerkarten zu klicken.',
    'Tour.Info.home.SelectStore': 'Geschäft auswählen',
    'Tour.Info.home.SelectDate': 'Zeitintervall auswählen',
    'Tour.Info.home.SelectDaily': 'Daten täglich oder stündlich anzeigen.',
    'Tour.Info.home.SelectLang': 'Sprache der Webseite auswählen.',
    'Tour.Info.home.ShowProfile': 'Profil ansehen oder abmelden.',
    'Tour.Info.home.CustomerDwellChart':
      'Die Anzahl der Kunden und die durchschnittliche Verweildauer im Geschäft täglich gemäß   dem ausgewählten Datum und Zeitintervall anzeigen.',
    'Tour.Info.home.WeekendColorizerChart':
      'Orange Farben werden am Wochenende angezeigt.',
    'Tour.Info.home.ChartButtons':
      'Überprüfen Sie das Diagramm "Verweildauer"oder "Kundendichte". Laden Sie die in der Grafik verwendeten Daten als Excel-Datei herunter.',
    'Tour.Info.home.MostVisitedTable':
      'Regalabschnitte, die meistes Interesse geweckt haben.',
    'Tour.Info.home.DwellTable':
      'Regalabschnitte, an denen Kunden meist verweilt haben.',
    'Tour.Info.home.ConversionRate':
      'Die Umsetzungsrate der Kundendichte zurückgeben.',
    'Tour.Info.home.TourInfoButton':
      'Klicken Sie auf die Schaltfläche, um Informationen zu der Seite zu erhalten',
    'Tour.Info.area.densityArea':
      'Die abteilungsbasierte Verteilung der Kundendichtedaten im Geschäft anzeigen.',
    'Tour.Info.area.quantityArea':
      'Die abteilungsbasierte Verteilung der Verkaufsdaten (Anzahl der Produkte) anzeigen.',
    'Tour.Info.area.amountArea':
      'Die abteilungbasierte Verteilung der Verkaufsdaten (Gesamtbetrag) anzeigen.',
    'Tour.Info.area.SketchRect':
      'Bewegen Sie den Mauszeiger über einen Regalabschnitt um das Verhältnis der Kundendichte, das Verhältnis m2 und das Verhältnis Kundendichte / m2 zu überprüfen.',
    'Tour.Info.area.areaHamburgerMenu': 'Untermenü ein- und ausblenden',
    'Tour.Info.area.camSketch': 'Skizze auswählen.',
    'Tour.Info.area.CamInfo': 'Zeigen Sie Kameras auf dem Plan.',
    'Tour.Info.area.CamHoverInfo':
      'Untersuchen Sie die Heatmap durch Klicken auf die Kameras.',
    'Tour.Info.area.CamShowPlan': 'Regalabschnitte auf der Skizze ausblenden.',
    'Tour.Info.area.searchSketch': 'Laden durchsuchen',
    'Tour.Info.area.TopFiveTable':
      'Fünf Regalabschnitte mit den niedrigsten und höchsten Prozenten der Kundendichte innerhalb des ausgewählten Datumsbereichs anzeigen.',
    'Tour.Info.heatmap.Info':
      'Überwachung der Kundendichteverteilung durch Kameras',
    'Tour.Info.heatmap.showAreas': 'Klicken Sie hier, um Bereiche anzuzeigen',
    'Tour.Info.heatmap.rectShowInfo':
      'Klicken Sie auf die blauen Bereiche, um weitere Informationen zu erhalten.',
    'Tour.Info.department.information':
      'Es stellt detaillierte Informationen über Kundendichte und Verkaufsdaten für den ausgewählten Bereich zur Verfügung.',
    'Tour.Info.department.densityChart':
      'Die tägliche Kundendichte und die Verweildauer innerhalb der angegebenen Stunden in der Abteilung anzeigen.',
    'Tour.Info.department.downloadExcel':
      'Laden Sie die gesammelten Daten innerhalb des ausgewählten Zeitintervalls herunter.',
    'Tour.Info.department.goToCam':
      'Untersuchen Sie die Kundendichte des Bereichs auf der Heatmap',
    'Tour.Info.department.SalesChart1':
      'Klicken Sie auf die Tabelle, um den kategorienbasierten Verkauf zu überprüfen.',
    'Tour.Info.department.SalesChart2':
      'Den täglichen Verkaufsbetrag und die Anzahl der Verkäufe im angegebenen Zeitintervall anzeigen.',
    'Tour.Info.department.correlations':
      'Kundenverhalten in Abteilungen zeigen.',
    'Tour.Info.department.notification':
      'Die Verteilung der Push-Benachrichtigungen, die an die Geschäftsleitung gesendet wurde, gemäß   den in den Einstellungen festgelegten Parametern anzeigen.',
    'Tour.Info.PerformanceTable.Table':
      'Ermöglicht dieÜberprüfung der Kategoriendaten im Geschäft und zeigt auch prozentuale Änderungen gemäß   dem vorherigen Datumsbereich an.',
    'Tour.Info.PerformanceTable.scale':
      'Leistungsskala, die die produktivsten Änderungen als blau und die am wenigsten produktiven Änderungen als pink anzeigt.',
    'Tour.Info.comparison.begin':
      'Den Leistungsvergleich von Geschäften innerhalb des angegebenen Datumsbereichs anzeigen.',
    'Tour.Info.comparison.select': 'Wählen Sie ein Geschäft aus',
    'Tour.Info.comparison.date': 'Wählen Sie das Zeitintervall',
    'Tour.Info.comparison.compareStore':
      'Wählen Sie ein Geschäft zum Vergleichen aus',
    'Tour.Info.comparison.compareDate':
      'Wählen Sie das zu vergleichende Zeitintervall aus',
    'Tour.Info.comparison.sketchInfo':
      'Sie können die Regalabschnitte in derselben Abteilung vergleichen durch das Bewegen des Mauszeigers über die Regalabschnitte in der ausgewählten Skizze.',
    'Tour.Info.comparison.clickRect':
      'Untersuchen Sie das Detail durch auf ‚ÄúReyona‚Äú klicken.',
    'Tour.Info.comparison.customerCountTable':
      'Stellt die Bereichsattraktivität im Vegleich zum vorherigen Datenbereich dar. ',
    'Tour.Info.comparison.customerCountChart':
      'Stellt die totale Kundenverteilung der Bereiche innerhalb spezifischen Datenbereich dar. ',
    'Tour.Info.comparison.customerDwellTable':
      'Represents customer average time change fields based on the previous date range.',
    'Tour.Info.comparison.customerDwellChart':
      'Stellt die totale Verweilzeitverteilung der Bereiche innerhalb spezifischen Datenbereichs dar.',
    'Tour.Info.comparisonV2.begin':
      'Ermöglicht den Vergleich von Daten nach Geschäft und Kategorie.',
    'Tour.Info.comparisonV2.beginUsage1':
      'Wählen Sie das Geschäft aus. Wählen Sie ein einzelnes Geschäft aus, vergleichen Sie Kategorien, wählen Sie mehrere aus und überprüfen Sie die Leistung der Geschäfte untereinander.',
    'Tour.Info.comparisonV2.beginUsage2':
      'Wählen Sie Ihre Kategorien aus.Überprüfen Sie die Umsatzentwicklung der Kategorien der von Ihnen ausgewählten Geschäfte.',
    'Tour.Info.comparisonV2.beginUsage3': 'Wählen Sie die Datenquelle aus.',
    'Tour.Info.comparisonV2.beginUsage4':
      'Klicken Sie auf "Erhalten", um die Ergebnisse anzuzeigen.',
    'Tour.Info.comparisonV2.changeChart':
      'Sehen Sie sich das Diagramm nach Geschäft oder Kategorie an.',
    'Tour.Info.comparisonV2.selectStore':
      'Wählen Sie das Geschäft aus. Wählen Sie entweder ein einzelnes Geschäft aus und vergleichen Sie Kategorien, oder wählen Sie mehrere aus und überprüfen Sie die Leistung zwischen den Geschäften.',
    'Tour.Info.comparisonV2.selectCategory':
      'Wählen Sie die zu vergleichenden Kategorien aus.',
    'Tour.Info.comparisonV2.SelectDataSource':
      'Wählen Sie die Datengruppe aus, die Sie abrufen möchten',
    'Tour.Info.comparisonV2.showChart':
      'Klicken Sie hier, um das Diagramm anzuzeigen.',
    'Tour.Info.comparisonV2.details':
      'Über die unten stehende Leiste können Sie auf detaillierte Informationen zugreifen.',
    'Tour.Info.settings.camera.begin':
      'Durch Auswahl der Kamera können Sie die Kameras bearbeiten, die in Ihrem Geschäft angezeigt werden.',
    'Tour.Info.settings.camera.camDetails':
      'Zeichnen Sie mit der Maus den Bereich, in dem Sie Daten erfassen möchten.',
    'Tour.Info.settings.camera.camDetails2':
      'Wählen Sie den Bereich aus, den Sie anzeigen möchten. Passen Sie die Position und die Größ  e des Bereichs an.',
    'Tour.Info.settings.camera.camDetails3':
      'Klicken Sie mit dem rechten Mausklick auf den Bereich, den Sie ändern möchten. Klicken Sie auf "Archivieren", um den Bereich zu speichern, oder auf "Bearbeiten", um die Details des Bereichs zu ändern.',
    'Tour.Info.settings.camera.submitButton':
      'Klicken Sie auf die Schaltfläche unter der Kamera, um Ihre Änderungen zu speichern.',
    'Tour.Info.settings.camera.Category':
      'Übertragen Sie die Verkaufsdaten Ihres Geschäfts in das System durch die Anpassung der Kategorien mit den modellierten Bereichen.',
    'Tour.Info.settings.notification.info':
      'Geben Sie den Benachrichtigungsschwellenwert basierend auf Bereichen oder Kameras an.',
    'Tour.Info.settings.notification.radiobut':
      'Bereich auswählen, wenn Sie Benachrichtigungen basierend auf Bereichen festlegen möchten, oder Kamera auswählen, wenn Sie die Benachrichtigung basierend auf einer Kamera festlegen möchten, die mehrere Bereiche enthält.',
    'Tour.Info.settings.notification.dropdown':
      'Klicken Sie auf den Operator, den Sie anpassen möchten.',
    'Tour.Info.settings.notification.goBack':
      'Klicken Sie auf Zurück-Taste um Änderungen zu machen.',
    'Tour.Info.settings.notification.threshold':
      'Schwellenorientierung auswählen',
    'Tour.Info.settings.notification.setThreshhold':
      'Geben Sie den Benachrichtigungsschwellenwert basierend auf der Anzahl der Kunden oder der an dem Regalabschnitt verbrachteten Zeit an.',
    'Tour.Info.settings.notification.save':
      'Wählen Sie den Zeitabschnitt aus, für den die Benachricchtigungen aktiv bleiben und klicken Sie auf speichern. ',
    'Tour.Info.settings.notification.delete':
      'Klicken Sie um die voreingestellte Benachrichrtigungsschwelle zu löschen.',
    'Tour.Info.settings.notification.arrow':
      'Klicken Sie auf den Pfeil um fortzufahren.',
    'Tour.Info.setting.departments.newCategory':
      'Fügen Sie eine Kategorie hinzu, die mit den angezeigten Bereichen im Geschäft übereinstimmt.',
    'Tour.Info.settings.salesUploading.begin':
      'Laden Sie Ihre Verkaufsdaten hoch, die im Excel-Beispielformat erstellt wurden, und analysieren Sie die Verkaufszahlen und die Verkaufsdichte des Geschäfts zusammen.',
    'Tour.Info.settings.sketch.selectSketch': 'Skizze auswählen',
    'Tour.Info.settings.sketch.changeSketch':
      'Ersetzen Sie den Plan im System durch einen Plan auf Ihrem Computer.',
    'Tour.Info.settings.sketch.setCam':
      'Markieren Sie, wo die Kameras im Geschäft installiert sind.',
    'Tour.Info.settings.sketch.newSketch':
      'Fügen Sie einen neuen Store-Plan von Ihrem Computer aus hinzu.',
    'Tour.Info.settings.sketch.drawRect':
      'Zeichnen Sie den Bereich im Geschäftsplan entsprechend dem Bereich, den Sie bereits in den Kameraeinstellungen festgelegt haben.',
    'Tour.Info.settings.workingHours.begin':
      'Geben Sie das Zeitintervall des ausgewählten Geschäfts an und klicken Sie auf die Schaltfläche Speichern.',
    'Tour.Info.BrandPage.BrandPieCharts':
      'EinenÜberblick über die Unternehmensleistung, in dem die Metriken der einzelnen Geschäfte angezeigt werden, angeben.',
    'Tour.Info.BrandPage.PieChart':
      'Klicken Sie auf einen Bogen, um Kategorieleistungen für verwandte Metriken anzuzeigen.',
    'Tour.Info.BrandPage.BarChart.Excel.Info':
      'Kategoriedaten im XLSX-Format herunterladen',
    'Tour.Info.BrandPage.BarCharts':
      'Den Einsatz der Kategorien des zugehörigen Geschäfts anzeigen.',
    'Tour.Info.BrandPage.BarChart':
      'Klicken Sie auf eine Leiste, um zugehörige Unterkategoriedaten anzuzeigen.',
    'Tour.Info.BrandPage.Sub.BarChart.Excel.Info':
      'Unterkategoriedaten im XLSX-Format herunterladen.',
    'Total.Customer.Chart': 'Total Kunden',
    'Total.Quantity.Chart': 'Gesamtabsatzmenge',
    'Total.TotalAmount.Chart': 'Gesamtbetrag',
    'Total.Table.Value': 'Total',
    'Total.Summary.Information': 'Zusammenfassungsinformationen',
    'Total.Customer.Average': 'Durchschnittliche Gesamtkunden: ',
    'Total.Customer.Deviation': 'Totale Kundenabweichung',
    'Total.Time.Average': 'Durchschnittliche Gesamtverweildauer: ',
    'Total.Time.Deviation': 'Totale Zeitabweichung',
    'Total.Weekday.Customer.Average': 'Kundendurchschnitt an Wochentagen: ',
    'Total.Weekday.Time.Average': 'Durchschnittszeit des Wochentages: ',
    'Total.Weekends.Customer.Average': 'Kundendurchschnitt am Wochenende: ',
    'Total.Weekends.Time.Average': 'Durchschnittszeit des Wochenendes: ',
    'Total.Density.Average': 'Durchschnittliche Gesamtdichte: ',
    'Density.Avarage': 'Dichte Durchschnitt',
    'Total.SQuantity': 'Gesamtumsatz',
    'Total.SaleAmount': 'Gesamtumsatz',
    'Notifications.less': 'weniger als',
    'Notifications.greater': 'mehr als',
    'HomeBrand.Info.emptyValues': 'Keine Daten vorhanden',
    'brand.generalGraph': 'General - Graph',
    'brand.generalGraph.value': 'Value',
    downloadAsImage: 'Download As Image',
    'brand.metricSelectorPlaceholder': 'Select a metric...',
    'brand.storeSelectorPlaceholder': 'You can select around 1-10 stores...',
    'brand.storeSelectorError': 'Max 10 stores can be selected.',
    'dayIndexConversion.0': 'Sunday',
    'dayIndexConversion.1': 'Monday',
    'dayIndexConversion.2': 'Tuesday',
    'dayIndexConversion.3': 'Wednesday',
    'dayIndexConversion.4': 'Thursday',
    'dayIndexConversion.5': 'Friday',
    'dayIndexConversion.6': 'Saturday',
    'Company.CompanySummary': 'Zusammenfassung des Unternehmens',
    CheckoutSummary: 'Checkout Zusammenfassung',
    'Polygon.MoveBehind': 'Zürückbringen',
    'Heatmap.Total.Data.Count': 'Total Datenzahl',
    'Heatmap.Data.Count': 'Datenanzahl',
    'Customer.traffic': 'Traffic',
    'Customer.TotalCustomer': 'Total Kunden',
    'Customer.TotalMales': 'Total Männer',
    'Customer.TotalFemales': 'Total Frauen',
    'Customer.AgeGender': 'Alter-Geschlecht',
    'Customer.GenderClassification': 'Geschlechtsklassifikation',
    'Customer.AgeClassification': 'Traffic',
    'Customer.Male': 'Mann',
    'Customer.Female': 'Frau',
    'Customer.Total': 'Total',
    'Customer.TotalTraffic': 'Total Traffic',
    'Customer.prevTotal': 'Vorherige Summe',
    'Customer.prevTotalChange': 'Vorherige Gesamtänderung',
    'tooltip.totalCustomer': 'Total Kunden',
    'Polygon.hsitory.existing': 'bestehend',
    'Polygon.recalculation': 'Rekalkulation',
    'Polygon.setFirstEntrance': 'Set first entrance',
    Line: 'Line',
    'floors.types.Total': 'Total',
    'floors.types.Staircase': 'Staircase',
    'floors.types.Elevator': 'Elevator',
    'floors.floorComparison': 'Floor Comparison',
    'floors.floorOrientation': 'In Floor Orientation',
    'Line.Type': 'Line Type',
    'Line.Direction.Entrance': 'Entrance Direction',
    'Line.Direction.name': 'Direction Name',
    'Line.Direction.type': 'Direction Type',
    'Line.PassingStore': 'Passing by the store',
    'Line.PassingShopInShop': 'Passing by the Shop-in-Shop',
    'Line.newShopInShopMatch': 'New Shop-in-Shop Match',
    'Line.ShopInShopEntrance': 'Shop-in-Shop Entrance',
    'Line.InStore.orientation': 'In-store orientation',
    'Line.Stairs': 'Floor Stairs',
    'Line.Elevator': 'Floor Elevator',
    'Line.InFloorOrientation': 'In Floor Orientation',
    'Polygon.recalculationInfo':
      'Select the date you want your departmant data recalculated.',
    'Polygon.changeCamera': 'Change Camera',
    'modal.close': 'schließ  en',
    'filter.columns': 'Filter Columns',
    'modal.deparment.history': 'Abteilung Verlauf',
    'modal.sketch.history': 'Sketch history',
    'navigation.Suggestions': 'Vorschläge',
    'Suggestion.Comment.one':
      'Die Kundenfrequenz- und Zeitparameter der Kundendichte sollten untersucht werden. Der Effekt der Kundenfrequenz in der Kundendichte ist höher als die Zeit. Dies bedeutet, dass Besucher, die in diese Zone gekommen sind, hier wenig Zeit verbracht haben. Mitarbeiterverteilung, Produktpreisausgleich und In-Store-Verteilung können überprüft werden. Es wird erwartet, dass die Erhöhung der Besucherzahl die Fläche effizienter macht und den Umsatz steigert. Eine neue Aktion wird wahrscheinlich auch die Anzahl der Personen erhöhen. Um über die Flächenreduzierung entscheiden zu können, kann die Fläche schrittweise verkleinert und deren Auswirkung auf den Umsatz zwischenzeitlich untersucht werden. Wenn die Verkäufe nicht negativ beeinflusst werden, während der Raum schrumpft, sollte der Raum verkleinert werden.',
    'Suggestion.Comment.two':
      'Die Kundenfrequenz- und Zeitparameter der Kundendichte sollten untersucht werden. Der Effekt der Zeit in der Kundendichte ist höher als die Kundenfrequenz. Zunächst sollte das Interesse am jeweiligen Regalabschnitt berücksichtigt werden, um festzustellen, ob der Prozentsatz ausreicht. Angenommen wird, dass die Zeit des Kunden zum Finden eines Produktes angemessen ist. Wenn die Produktsuche zu lange dauert, besteht eine groß  e Möglichkeit, dass der Kunde nicht findet, wonach er sucht, und nach einer längeren Warte- oder Suchzeit geht. Um diese Zeit zu verkürzen, sollte die Komplexität des Abschnitts oder Produkts überprüft werden. Wenn sich der Kunde längere Zeit in dem betreffenden Bereich aufhält, kann dies zu einer Unzulänglichkeit des Personals führen. Als Lösung können Produktinformationskarten detaillierter sein, um dem Kunden Informationen zu geben und den Bedarf an Personal zu verringern. Im Vergleich zu anderen Bereichen kann eine Schrumpfung eines Bereichs betrachtet werden, weil der Prozentsatz des Bereichs größ  er als der Prozentsatz der Kundendichte ist. Um über die Flächenreduzierung entscheiden zu können, kann die Fläche schrittweise verkleinert und deren Auswirkung auf den Umsatz zwischenzeitlich untersucht werden. Wenn die Verkäufe nicht negativ beeinflusst werden, während der Raum schrumpft, sollte der Raum verkleinert werden.",',
    'Suggestion.Comment.three':
      'Die Kundenfrequenz- und Zeitparameter der Kundendichte sollten untersucht werden. Der Effekt der Zeit auf die Kundendichte ist fast gleich der Kundenfrequenz. Geht man davon aus, dass die Verweildauer ausreichend ist, sollte man den Schwerpunkt auf die Erhöhung der Besucherzahl bei konstantem Zeitparameter legen. Eine Rabattaktion durchzuführen, das Design an dem Regalabschnitt zu ändern und neue Preise festzulegen, sind einige der Optionen, um dieses Ziel zu erreichen. Wenn die Anzahl der Kunden zunimmt und die Verweildauer nicht gleichzeitig abnimmt, wird ein Umsatzanstieg erwartet. Da der Prozentsatz der Fläche größ  er als ihre Kundendichte ist, kann Fläche schrittweise verringert werden. Wenn die Verkäufe nicht negativ beeinflusst werden, während der Raum schrumpft, sollte der Raum verkleinert werden.",',
    'Suggestion.Comment.four':
      'Bei der Untersuchung der Kundenfrequenz- und Zeitparameter der Kundendichte wird beobachtet, dass der Effekt der Kundenfrequenz auf die Kundendichte höher als die Zeit ist. Der Grund dafür, dass der Umsatz geringer als die Kundendichte ist, kann die Unfähigkeit sein, den Besucher ausreichend lange vor dem jeweiligen Regalabschnitt zu halten. Das Personal kann gezielt geschult werden, um Kunden länger in der gewünschten Abteilung verweilen zu lassen. Der Geschäftsplan kann überprüft werden, um die Verweildauer zu erhöhen. Da die Kundendichte / Quadratmeter-Effizienz größ  er als der Umsatz / Quadratmeter ist, wird erwartet, dass der Umsatzdurchschnitt mit zunehmender Verweilzeit steigt. Die Fläche wird somit effizienter genutzt. ",',
    'Suggestion.Comment.five':
      'Bei der Untersuchung der Kundenfrequenz- und Zeitparameter der Kundendichte wird beobachtet, dass der Effekt der Zeit auf die Kundendichte höher als die Kundenfrequenz ist. Zunächst ist zu prüfen, ob das Interesse am relevanten Bereich ausreichend ist. Wenn die Zahl der interessierten Kunden in der Abteilung ausreichend ist, sollten die Gründe für die überdurchschnittliche Verweildauer untersucht werden. Wenn die Verweildauer in der Abteilung länger als die überdurchschnittliche Verweildauer ist, besteht die Möglichkeit, dass der Kunde nicht finden kann, wonach er sucht, und nach einer langen Wartezeit abbricht. Der Grund dafür kann eine zu groß  e Produktvielfalt oder Personalmangel sein. Die Anzahl der Kunden, die sich länger als 15 Sekunden in der Abteilung aufhalten, und die Anzahl der Gutscheine sollten gemeinsam überprüft werden, um den Prozentsatz der interessierten Kunden zu ermitteln, die das Geschäft verlassen haben, ohne ein Produkt aus der jeweiligen Abteilung zu kaufen. Da das Verhältnis Dichte / Quadratmeter 1 ist und der Hauptfaktor, der die Dichte beeinflusst, die Zeit ist, kann man davon ausgehen, die Quadratmeter der Fläche verringern oder  vermehren zu müssen, um die Effizienz zu erhöhen.',
    'Suggestion.Comment.six':
      'Bei der Untersuchung der Kundenfrequenz- und Zeitparameter der Kundendichte wird beobachtet, dass der Effekt der Zeit auf die Kundendichte nahezu gleich der Kundenfrequenz ist. Geht man davon aus, dass die Verweildauer in der jeweiligen Abteilung um über den Kauf eines Produkts zu entscheiden ausreichend ist, sollte man den Schwerpunkt auf die Erhöhung der Besucherzahl bei konstantem Zeitparameter legen. Wenn die Kundenzahl ansteigt und die Verweildauer nicht gleichzeitig abnimmt, wird ein Umsatzanstieg erwartet. Es können Rabattaktionen durchgeführt werden, um die Anzahl der Kunden und auch die Kundendichte zu erhöhen. Niedrige Umsätze können wegen mangelnder Produktvielfalt entstehen. Je nach Kundendichte / Quadratmeter-Parameter wird der Raum effizienter genutzt. Da der Parameter Umsatz / Quadratmeter kleiner als 1 ist, kann die Fläche schrittweise reduziert und die Auswirkung auf den Umsatz in der Verweilzeit beobachtet werden. Andererseits sollten die Anzahl der Kunden, die die Abteilung betreten, und die Zeit entsprechend erhöht werden. ',
    'Suggestion.Comment.seven':
      '"Bei der Untersuchung der Kundenfrequenz- und Zeitparameter der Kundendichte wird beobachtet, dass der Effekt der Kundenfrequenz auf die Kunden',
    'Suggestion.Comment.eight':
      'Wenn die Fußgänger- und Zeitparameter der Dichte untersucht werden, wird beobachtet, dass der Effekt der Zeit in der Dichte höher ist als der der  Fußgänger. Zunächst muss der Interressensatz innerhalb des relevanten Bereichs untersucht werden, um festzustellen, ob der Interressensatz ausreichend ist. Wenn der Anteil interessierter Kunden in der Region ausreicht, sollten die Gründe für die Überzeit untersucht werden. Der Grund für die lange Zeit, die in der Region verbracht wird, kann der Personalmangel sein. In dieser Situation sollten die Anzahl der Besucher, die länger als 15 Sekunden in der Region verbracht haben, und die Anzahl der Gutscheine gegenseitig überprüft werden, um festzustellen, wie viel Prozent der interessierten Kunden den Shop verlassen haben, ohne einen Artikel aus der entsprechenden Region zu kaufen. Wenn die Anzahl der Gutscheine gering ist, kann der Grund für den Kundenverlust der Personalmangel sein. Eine Erhöhung der Mitarbeiterzahl dürfte den Umsatz steigern. Dichte / Quadratmeter Effizienz zeigt, dass die Fläche effizient genutzt wird. Es ist notwendig zu sehen, wie viele Verkäufe der Bereich in Einheiten erhält. Es kann erwogen werden, die Quadratmeter der Fläche entsprechend dem Produkttyp / Preis zu vergrößern.',
    'Suggestion.Comment.nine':
      'Wenn die Eintrittfrequenz- und Zeitparameter der Dichte untersucht werden, wird beobachtet, dass der Effekt der Zeit in der Dichte fast gleich der Eintrittfrequenz ist. Wenn davon ausgegangen wird, dass die in dem entsprechenden Bereich verbrachte Zeit ausreicht, um über den Kauf eines Produkts zu entscheiden, sollte der Schwerpunkt zunächst auf der Erhöhung der Anzahl der Besucher liegen, während der Zeitparameter konstant gehalten wird, um den Umsatz steigern zu können. Wenn der Interressensatz niedrig ist, sollten die Nummer des Gutscheins und die Nummer des interessierten Kunden gegenseitig überprüft werden, und wenn die Nummer des Gutscheins hoch genug ist, sollte der Bereich vergrößert werden.',
    'Suggestion.Comment.Oneone':
      'Die Schritt- und Zeitparameter der Dichte sollten untersucht werden. Die Dichte ist eher nach Betreten gewichtet als nach Zeit gewichtet.',
    'Suggestion.Comment.Onetwo':
      'Um den Anteil der Zeit an der Dichte erhöhen zu können, sollte auch die von jedem Besucher verbrachte Zeit erhöht werden.',
    'Suggestion.Comment.Onethree':
      'Die Personalzuweisung, das Produktpreisgleichgewicht und die Zuweisung im Geschäft können überprüft werden, um beide Parameter zu erhöhen: Zeitaufwand und Kundenfrequenz.',
    'Suggestion.Comment.Onefour':
      'Eine neue Kampagne oder eine Änderung des Geschäftsplans erhöhen wahrscheinlich die Besucherfrequenz.',
    'Suggestion.Comment.Onefive':
      'Da der Prozentsatz des Umsatzes des Gebiets höher ist als die Dichte, ist die Verkaufsleistung des Gebiets effizient.',
    'Suggestion.Comment.Onesix':
      'Da der Prozentsatz der Quadratmeter der Fläche höher als die Dichte ist, kann eine Verringerung der Fläche in Betracht gezogen werden.',
    'Suggestion.Comment.Oneseven':
      'Um über die Reduzierung entscheiden zu können, kann der Raum schrittweise verkleinert und in der Zwischenzeit die Auswirkung auf den Umsatz untersucht werden.',
    'Suggestion.Comment.Oneeight':
      'Wenn der Umsatz bei beengten Platzverhältnissen nicht tendenziell sinkt, sollte der Platz verkleinert werden.',
    'Suggestion.Comment.Onenine':
      'Um diese Effizienz zu steigern, kann zusätzlich zu den Empfehlungen zur Erhöhung der Dichte die Fläche schrittweise reduziert und die Auswirkungen auf den Umsatz untersucht werden. Wenn der Umsatz nicht abnimmt, während die Fläche schrumpft, sollte die Fläche reduziert werden.',
    'Suggestion.Comment.Twoone':
      'Die Schritt- und Zeitparameter der Dichte sollten untersucht werden. Die Dichte ist eher zeitlich gewichtet als der Schritt.',
    'Suggestion.Comment.Twotwo':
      'Um den Anteil der Besucher an der Dichte erhöhen zu können, sollte die Anzahl der Personen, die das Gebiet besuchen, erhöht werden.',
    'Suggestion.Comment.Twothree':
      'Eine neue Kampagne oder eine Änderung des Geschäftsplans erhöht wahrscheinlich die Besucherfrequenz.',
    'Suggestion.Comment.Twofour':
      'Außerdem können die Personalzuweisung, das Produktpreisgleichgewicht und die Zuweisung im Geschäft überprüft werden, um beide Parameter zu erhöhen: Zeitaufwand und Kundenfrequenz.',
    'Suggestion.Comment.Twofive':
      'Da der Prozentsatz des Umsatzes des Gebiets höher ist als die Dichte, ist die Verkaufsleistung des Gebiets effizient.',
    'Suggestion.Comment.Twosix':
      'Da der Prozentsatz des Quadratmeters der Fläche höher als die Dichte ist, kann eine Verringerung der Fläche in Betracht gezogen werden.',
    'Suggestion.Comment.Twoseven':
      'Um über die Reduzierung entscheiden zu können, kann der Raum schrittweise verkleinert und in der Zwischenzeit die Auswirkung auf den Umsatz untersucht werden.',
    'Suggestion.Comment.Twoeight':
      'Wenn der Umsatz bei beengten Platzverhältnissen nicht tendenziell sinkt, sollte der Platz verkleinert werden.',
    'Suggestion.Comment.Threeone':
      'Die Schritt- und Zeitparameter der Dichte sollten untersucht werden. Die Dichte wird gleichermaßen durch die Zeit und den Schritt gewichtet.',
    'Suggestion.Comment.Threetwo':
      'Um den Anteil der Besucher an der Dichte erhöhen zu können, sollte die Anzahl der Personen, die das Gebiet besuchen, erhöht werden, während der Zeitparameter konstant gehalten wird.',
    'Suggestion.Comment.Threethree':
      'Eine neue Kampagne oder eine Änderung des Geschäftsplans erhöht wahrscheinlich die Besucherfrequenz.',
    'Suggestion.Comment.Threefour':
      'Außerdem können die Personalzuweisung, das Produktpreisgleichgewicht und die Zuweisung im Geschäft überprüft werden, um beide Parameter zu erhöhen: Zeitaufwand und Kundenfrequenz.',
    'Suggestion.Comment.Threefive':
      'Da der Prozentsatz des Umsatzes des Gebiets höher ist als die Dichte, ist die Verkaufsleistung des Gebiets effizient.',
    'Suggestion.Comment.Threesix':
      'Da der Prozentsatz des Quadratmeters der Fläche höher als die Dichte ist, kann eine Verringerung der Fläche in Betracht gezogen werden.',
    'Suggestion.Comment.Threeseven':
      'Um über die Reduzierung entscheiden zu können, kann der Raum schrittweise verkleinert und in der Zwischenzeit die Auswirkung auf den Umsatz untersucht werden.',
    'Suggestion.Comment.Threeeight':
      'Wenn der Umsatz bei beengten Platzverhältnissen nicht tendenziell sinkt, sollte der Platz verkleinert werden.',
    'Suggestion.Comment.Threenine':
      'Um diese Effizienz zu steigern, kann zusätzlich zu den Empfehlungen zur Erhöhung der Dichte die Fläche schrittweise reduziert und die Auswirkungen auf den Umsatz untersucht werden. Wenn der Umsatz nicht abnimmt, während die Fläche schrumpft, sollte die Fläche reduziert werden.',
    'Suggestion.Comment.Fourone':
      'Die Schritt- und Zeitparameter der Dichte sollten untersucht werden. Die Dichte ist eher nach Betreten gewichten als nach Zeit gewichtet.',
    'Suggestion.Comment.Fourtwo':
      'Um den Anteil der Zeit an der Dichte erhöhen zu können, sollte auch die von jedem Besucher verbrachte Zeit erhöht werden.',
    'Suggestion.Comment.Fourthree':
      'Die Personalzuweisung, das Produktpreisgleichgewicht und die Zuweisung im Geschäft können überprüft werden, um beide Parameter zu erhöhen: Zeitaufwand und Kundenfrequenz.',
    'Suggestion.Comment.Fourfour':
      'Eine neue Kampagne oder eine Änderung des Geschäftsplans erhöhen wahrscheinlich die Besucherfrequenz.',
    'Suggestion.Comment.Fourfive':
      'Da der Prozentsatz der Flächendichte höher ist als der Umsatz, hat das Gebiet ein Verkaufspotenzial, kann es jedoch nicht nutzen.',
    'Suggestion.Comment.Foursix':
      'Um die Verkaufsleistung des Gebiets steigern zu können und das Kundeninteresse nicht zu verlieren, ist es dringend erforderlich, Maßnahmen zu ergreifen. Empfehlungen zur Erhöhung der Dichte können angewendet werden.',
    'Suggestion.Comment.Fourseven':
      'Die Fläche wird effizient genutzt, da der Prozentsatz der Dichte gleich dem Prozentsatz der Quadratmeter ist.',
    'Suggestion.Comment.Foureight':
      'Da der Prozentsatz der Dichte / Quadratmeter der Fläche niedriger ist als der Umsatz / Quadratmeter, kann eine Verringerung der Fläche in Betracht gezogen werden.',
    'Suggestion.Comment.Fournine':
      'Um über die Reduzierung entscheiden zu können, kann der Platz schrittweise verkleinert und in der Zwischenzeit die Auswirkung auf den Umsatz untersucht werden.',
    'Suggestion.Comment.Fourten':
      ' Wenn der Umsatz während der Platzverknappung nicht tendenziell sinkt, sollte der Platz verkleinert werden.',
    'Suggestion.Comment.Fiveone':
      'Die Schritt- und Zeitparameter der Dichte sollten untersucht werden. Die Dichte ist eher zeitlich gewichtet als der Schritt.',
    'Suggestion.Comment.Fivetwo':
      'Um den Anteil der Besucher an der Dichte erhöhen zu können, sollte die Anzahl der Personen, die das Gebiet besuchen, erhöht werden.',
    'Suggestion.Comment.Fivethree':
      'Eine neue Kampagne oder eine Änderung des Geschäftsplans erhöht wahrscheinlich die Besucherfrequenz.',
    'Suggestion.Comment.Fivefour':
      'Außerdem können die Personalzuweisung, das Produktpreisgleichgewicht und die Zuweisung im Geschäft überprüft werden, um beide Parameter zu erhöhen: Zeitaufwand und Kundenfrequenz.',
    'Suggestion.Comment.Fivefive':
      'Wenn der Eindruck entsteht, dass die Zeit, die in der Gegend verbracht wird, zu lang ist, besteht die Möglichkeit, dass der Kunde nicht findet, wonach er sucht, und möglicherweise nach langem Warten abbricht. Dies kann auf die sehr große Produktpalette oder den Personalmangel zurückzuführen sein.',
    'Suggestion.Comment.Fivesix':
      'Da der Prozentsatz der Flächendichte höher ist als der Umsatz, hat das Gebiet ein Verkaufspotenzial, kann es jedoch nicht nutzen.',
    'Suggestion.Comment.Fiveseven':
      'Um die Verkaufsleistung des Gebiets steigern zu können und das Kundeninteresse nicht zu verlieren, ist es dringend erforderlich, Maßnahmen zu ergreifen. Empfehlungen zur Erhöhung der Dichte können angewendet werden.',
    'Suggestion.Comment.Fiveeight':
      'Da der Prozentsatz der Quadratmetes der Fläche höher als die Dichte ist, kann eine Verringerung der Fläche in Betracht gezogen werden.',
    'Suggestion.Comment.Fivenine':
      'Um über die Reduzierung entscheiden zu können, kann der Platz schrittweise verkleinert und in der Zwischenzeit die Auswirkung auf den Umsatz untersucht werden.',
    'Suggestion.Comment.Fiveten':
      'Wenn der Umsatz während der Platzverknappung nicht tendenziell sinkt, sollte der Platz verkleinert werden.',
    'Suggestion.Comment.Sixone':
      'Die Schritt- und Zeitparameter der Dichte sollten untersucht werden. Die Dichte wird gleichermaßen durch die Zeit und den Schritt gewichtet.',
    'Suggestion.Comment.Sixtwo':
      'Um den Anteil der Besucher an der Dichte erhöhen zu können, sollte die Anzahl der Personen, die das Gebiet besuchen, erhöht werden, während der Zeitparameter konstant gehalten wird.',
    'Suggestion.Comment.Sixthree':
      'Eine neue Kampagne oder eine Änderung des Geschäftsplans erhöht wahrscheinlich die Besucherfrequenz.',
    'Suggestion.Comment.Sixfour':
      'Außerdem können die Personalzuweisung, das Produktpreisgleichgewicht und die Zuweisung im Geschäft überprüft werden, um beide Parameter zu erhöhen: Zeitaufwand und Kundenfrequenz.',
    'Suggestion.Comment.Sixfive':
      'Da der Prozentsatz der Flächendichte höher ist als der Umsatz, hat das Gebiet ein Verkaufspotenzial, kann es jedoch nicht nutzen.',
    'Suggestion.Comment.Sixsix':
      'Um die Verkaufsleistung des Gebiets steigern zu können und das Kundeninteresse nicht zu verlieren, ist es dringend erforderlich, Maßnahmen zu ergreifen. Empfehlungen zur Erhöhung der Dichte können angewendet werden.',
    'Suggestion.Comment.Sixseven':
      'Da der Prozentsatz der Quadratmeter der Fläche höher als die Dichte ist, kann eine Verringerung der Fläche in Betracht gezogen werden.',
    'Suggestion.Comment.Sixeight':
      'Um über die Reduzierung entscheiden zu können, kann der Platz schrittweise verkleinert und in der Zwischenzeit die Auswirkung auf den Umsatz untersucht werden.',
    'Suggestion.Comment.Sixnine':
      'Wenn der Umsatz während der Platzverknappung nicht tendenziell sinkt, sollte der Platz verkleinert werden.',
    'Suggestion.Comment.Sixten':
      'Andererseits sollten die Anzahl der Personen, die das Gebiet betreten, und die Zeit entsprechend erhöht werden.',
    'Suggestion.Comment.Sevenone':
      'Die Schritt- und Zeitparameter der Dichte sollten untersucht werden. Die Dichte ist eher nach Betreten gewichtet als nach Zeit gewichtet.',
    'Suggestion.Comment.Seventwo':
      'Um den Anteil der Zeit an der Dichte erhöhen zu können, sollte auch die von jedem Besucher verbrachte Zeit erhöht werden.',
    'Suggestion.Comment.Seventhree':
      'Die Personalzuweisung, das Produktpreisgleichgewicht und die Zuweisung im Geschäft können überprüft werden, um beide Parameter zu erhöhen: Zeitaufwand und Kundenfrequenz.',
    'Suggestion.Comment.Sevenfour':
      'Eine neue Kampagne oder eine Änderung des Geschäftsplans erhöhen wahrscheinlich die Besucherfrequenz.',
    'Suggestion.Comment.Sevenfive':
      'Da der Prozentsatz der Flächendichte gleich dem Umsatz ist, können Empfehlungen zur Erhöhung der Dichte angewendet werden, um die Umsatz- / Dichteeffizienz zu steigern.',
    'Suggestion.Comment.Sevensix':
      'Die Fläche ist effizient, wenn sie auf Dichte- / Quadratmeterbasis bewertet wird. Es ist notwendig, nach der Anzahl der Verkäufe zu suchen, die der Bereich in Einheiten erhält.',
    'Suggestion.Comment.Sevenseven':
      'Es kann erwogen werden, die Quadratmeter der Fläche entsprechend dem Produkttyp / Preis zu vergrößern.',
    'Suggestion.Comment.Seveneight':
      'Ist die Dichte / Quadratmeter hoch, kann die Flaeche als effizient bezeichnet werden. Die Höhe der Verkäufe, die der Bereich erhält, sollte überprüft werden. Es kann erwogen werden, die Quadratmeter je nach Art / Preis des verkauften Produkts zu vergrößern.',
    'Suggestion.Comment.Eightone':
      'Die Schritt- und Zeitparameter der Dichte sollten untersucht werden. Die Dichte ist eher zeitlich gewichtet als der Schritt.',
    'Suggestion.Comment.Eighttwo':
      'Um den Anteil der Besucher an der Dichte erhöhen zu können, sollte die Anzahl der Personen, die das Gebiet besuchen, erhöht werden.',
    'Suggestion.Comment.Eightthree':
      'Eine neue Kampagne oder eine Änderung des Geschäftsplans erhöht wahrscheinlich die Besucherfrequenz',
    'Suggestion.Comment.Eightfour':
      'Außerdem können die Personalzuweisung, das Produktpreisgleichgewicht und die Zuweisung im Geschäft überprüft werden, um beide Parameter zu erhöhen: Zeitaufwand und Kundenfrequenz.',
    'Suggestion.Comment.Eightfive':
      'Wenn der Eindruck entsteht, dass die Zeit, die in der Gegend verbracht wird, zu lang ist, besteht die Möglichkeit, dass der Kunde nicht findet, wonach er sucht, und möglicherweise nach langem Warten abbricht. Dies kann auf die sehr große Produktpalette oder den Personalmangel zurückzuführen sein.',
    'Suggestion.Comment.Eightsix':
      'Da der Prozentsatz der Flächendichte gleich dem Umsatz ist, können Empfehlungen zur Erhöhung der Dichte angewendet werden, um die Umsatz- / Dichteeffizienz zu steigern.',
    'Suggestion.Comment.Eightseven':
      'Die Fläche ist effizient, wenn sie auf Dichte- / Quadratmeterbasis bewertet wird. Es ist notwendig, nach der Anzahl der Verkäufe zu suchen, die der Bereich in Einheiten erhält.',
    'Suggestion.Comment.Eighteight':
      'Es kann erwogen werden, die Quadratmeter der Fläche entsprechend dem Produkttyp / Preis zu vergrößern.',
    'Suggestion.Comment.Nineone':
      'Die Schritt- und Zeitparameter der Dichte sollten untersucht werden. Die Dichte wird gleichermaßen durch die Zeit und den Schritt gewichtet.',
    'Suggestion.Comment.Ninetwo':
      'Das Hauptaugenmerk sollte darauf liegen, die Anzahl der Besucher zu erhöhen und gleichzeitig den Zeitparameter konstant zu halten, um den Umsatz steigern zu können.',
    'Suggestion.Comment.Ninethree':
      'Eine neue Kampagne oder eine Änderung des Geschäftsplans erhöht wahrscheinlich die Besucherfrequenz.',
    'Suggestion.Comment.Ninefour':
      'Außerdem können die Personalzuweisung, das Produktpreisgleichgewicht und die Zuweisung im Geschäft überprüft werden, um beide Parameter zu erhöhen: Zeitaufwand und Kundenfrequenz.',
    'Suggestion.Comment.Ninefive':
      'Da der Prozentsatz der Flächendichte gleich dem Umsatz ist, können Empfehlungen zur Erhöhung der Dichte angewendet werden, um die Umsatz- / Dichteeffizienz zu steigern.',
    'Suggestion.Comment.Ninesix':
      'Die Fläche ist effizient, wenn sie auf Dichte- / Quadratmeterbasis bewertet wird. Es ist notwendig, nach der Anzahl der Verkäufe zu suchen, die der Bereich in Einheiten erhält.',
    'Suggestion.Comment.Nineseven':
      'Es kann erwogen werden, die Quadratmeter der Fläche entsprechend dem Produkttyp / Preis zu vergrößern.',
    'Suggestion.Comment.Nineeight':
      'Ist die Dichte / Quadratmeter hoch, kann die Flaeche als effizient bezeichnet werden. Die Höhe der Verkäufe, die der Bereich erhält, sollte überprüft werden. Es kann erwogen werden, die Quadratmeter je nach Art / Preis des verkauften Produkts zu vergrößern.',
    'Suggestion.select.Adate': 'Wählen Sie ein Datum.',
    'Suggestion.select.Adepartment':
      'Wählen Sie eine Abteilung aus, in der Sie die Empfehlung nachschlagen möchten.',
    'Suggestion.Comment.Description':
      'Diese Empfehlung basiert auf den folgenden Daten. Diese Daten repräsentieren die Verteilung innerhalb des Geschäfts.',
    'Suggestion.Area.Percent': 'Bereich',
    'Suggestion.Density.Percent': 'Dichte',
    'Suggestion.DwellTime.Percent': 'Verweilzeit',
    'Suggestion.PersonCount.Percent': 'Anzahl der Personen',
    'Suggestion.Sales.Percent': 'Der Umsatz',
    'Suggestion.Feedback.palceHolder':
      'Schreiben Sie Ihr Feedback und teilen Sie es uns mit ...',
    'Suggestion.Feedback.approve': 'Genehmigen',
    'Suggestion.Feedback.Report': 'Senden Sie ein Feedback',
    'Suggestion.Feedback.Wrong': 'Falscher Kommentar',
    'CustomerEntrance.Dwell': 'Übersichtstabelle speichern',
    'CheckoutCount.Dwell': 'Kundenanzahl an der Kasse & Verweilzeit (Sek.)',
    'Suggestion.interestCount': 'İnteressenzahlung',
    'Suggestion.densityDistribution': 'Dichteverteilung',
    'Suggestion.salesDistribution': 'Umsatzverteilung',
    'suggestionRequest.waiting': 'Warten',
    'suggestionRequest.preparing': 'Vorbereiten',
    'suggestionRequest.Completed': 'Abgeschlossen',
    'suggestionRequest.Department': 'Abteilung',
    'entranceCheckout.v2': 'In - Out Count',
    'entrance.v2': 'In Count',
    'entrance.peopleCount': 'Number Of People Entering',
    'suggestion.top5.sale': 'ERSTE 5 - VERKAUF',
    'Personnel.employee': 'Mitarbeiter',
    'Personnel.InTime': 'Rechtzeitig',
    'Personnel.OutTime': 'Out Time',
    'Personnel.Dwell': 'Verweilzeit',
    'Personnel.Entrances': 'In Out Count',
    'Personnel.EntrancesDetail': 'Im Detail',
    'Personnel.TotalEntrances': 'Total In Out',
    'Personnel.Aisle': 'Aisle',
    'Personnel.Aisle/Depot': 'Aisle/Depot',
    'Personnel.StoreLimit': 'Store Border',
    'Personnel.Break': 'Break',
    'Personnel.Depot': 'Depot',
    'PersonnelModal.selectPersonnel': 'Select personnel',
    'PersonnelModal.header': 'Select a personnel to bind',
    'PersonnelModal.selectPersonnels': 'Select personnels',
    'PersonnelModal.header2': 'Select a personnels to bind',

    'personnelv2.ActiveTime': 'Active Instore Time',
    'personnelv2.AvarageOfActiveWorkingTime': 'Average Instore Time',
    'personnelv2.AvarageTimeSpentInAisle': 'Average Aisle Time',
    'personnelv2.AvarageTimeSpentInBreak': 'Average Break Time',
    'personnelv2.AvarageTimeSpentInDepot': 'Average Depot Time',
    'personnelv2.BeaconName': 'Personnel',
    'personnelv2.BeaconTargets': 'Responsible Areas',
    'personnelv2.Date': 'Date',
    'personnelv2.StoreEnterTime': 'Entering Time to Store',
    'personnelv2.StoreExitTime': 'Exiting Time from Store',
    'personnelv2.MostEnteredReceiver': 'Most Entered Area',
    'personnelv2.MostSeenReceiver': 'Most Time Spent Area',
    'personnelv2.MostSpentArea': 'Most Time Spent Area',
    'personnelv2.MostSpentTimeInArea': 'Most Spent Time In Area',
    'personnelv2.MostSpentTimeAvarageInArea': 'Most Spent Time In Area',
    'personnelv2.Name': 'Name',
    'personnelv2.NumberOfEntries': 'Number Of Entries',
    'personnelv2.ReceiverName': 'Receiver Name',
    'personnelv2.TimeSpentInAisle': 'Time Spent in Aisle',
    'personnelv2.TimeSpentInDepot': 'Time Spent in Depot',
    'personnelv2.TimeSpentOnTargetReceivers': 'Time Spent on Target Areas',
    'personnelv2.UnseenReceivers': 'Non-Entered Areas',
    'personnelv2.WorkingDay': 'Working Day',
    'personnelv2.WorkingTime': 'Working Time',
    'personnelv2.allPersonnels': 'All Personnels',
    'personnelv2.detailedExcel': 'Download Detailed Excel',
    'personnelv2.AllReceivers': 'All Receivers',
    'personnelv2.Receiver': 'Receiver',
    'personnelv2.HighestDensityDate': 'Most Dense Day/Hour',
    'personnelv2.HighestDensityHour': 'Average Most Dense Hour',
    'personnelv2.AverageEntranceCount': 'Daily Average Personnel Entrance',
    'personnelv2.AverageDwellMin': 'Daily Average Personnel Dwell',
    'personnelv2.LongestBeaconName': 'The Personnel That Spent Most Time',
    'personnelv2.AvarageOfDwellMin': 'Average Dwell Of That Personnel',
    'personnelv2.BeaconCount': 'Personnel Count',
    'personnelv2.CustomerCountOver15Sec': 'Customer Count Over 15s',
    'personnelv2.SaleQuantity': 'Sale Quantity',
    'personnelv2.Dwell': 'Dwell',
    'personnelv2.EntranceCount': 'Entrance Count',
    'personnelv2.EntranceToAreaTime': 'Area Entrance Time (min)',
    'personnelv2.EntranceToAreaCount': 'Area Entrance Count',

    'personnelv2.AverageWorkingTime': 'Average Working Time',
    'personnelv2.AverageActiveWorkingTime': 'In-Store / Active Average Time',
    'personnelv2.AverageTimeOutOfStore': 'Average Time Out of Store',
    'personnelv2.AverageNumberOfOutOfStoreExits':
      'Average Number of Out-of-Store Exits',
    'personnelv2.AverageTimeSpentInBeaconTargets':
      'Average Time Spent in Areas of Responsibility',
    'personnelv2.AverageTimeSpentInBreak': 'Average Time in Break',
    'personnelv2.MostEnteredArea': 'Most Entered Area',
    'personnelv2.AverageNumberOfEntries': 'Average Number of Entries',
    'personnelv2.AverageTimeInMostSeenReceiver': 'Average Time Spent',
    'personnelv2.ClickToDayNote': 'Please click to a day for personnel detail.',

    'personnelv2.cards.TotalBeaconCount': 'Total Beacon Count',
    'personnelv2.cards.TotalWorkingTime': 'Total Working Time',
    'personnelv2.cards.AverageWorkingTime': 'Average Working Time',
    'personnelv2.cards.TotalActiveTime': 'Total Active Time',
    'personnelv2.cards.AverageActiveTime': 'Average Active Time',
    'personnelv2.cards.EstimatedWorkforceLoss': 'Estimated Workforce Loss',
    'personnelv2.cards.AverageEstimatedWorkforceLoss':
      'Average Estimated Workforce Loss',
    'personnelv2.cards.DayWithMostWorkforceLoss':
      'Day With Most Workforce Loss',
    'personnelv2.cards.shortHour': 'h',
    'personnelv2.cards.showMoreDetail': 'Show Detail',

    'personnelv2.EstimatedWorkforceLoss': 'Estimated Workforce Loss',
    'personnelv2.TotalActiveTime': 'Total Active Time',
    'personnelv2.TotalBeaconCount': 'Total Beacon Count',
    'personnelv2.TotalWorkingTime': 'Total Working Time',
    'personnelv2.Day': 'Day',

    'personnelv2.TimeOutOfStore': 'Out of Store Time (Out of Service Time)',
    'personnelv2.BeaconExits': 'Exiting Times From Store',
    'personnelv2.TimeSpentInBreak': 'Time in the Break Area',
    'personnelv2.TimeOfSeen': 'Time Of Seen',
    'personnelv2.Start': 'Start',
    'personnelv2.End': 'End',
    'personnelv2.Time': 'Time',

    'demography.County': 'Bezirk',
    'demography.Population': 'Gesamtbevölkerung',
    'demography.Age': 'Durchschnittsalter',
    'demography.HouseHold': 'Haushalt halten',
    'demography.Marital': 'Ehe',
    'demography.Ses': 'S.E.S.',
    'demography.TotalSale': 'Ausverkauf',
    'demography.TotalEstate': 'Total Estate',
    'demography.Education': 'Bildung',
    'demography.HouseIncome': 'Hauseinkommen',
    'demography.HouseIncomeTotal': 'Hauseinkommen insgesamt',
    'demography.ECommerce': 'E-Commerce',
    'demography.CountyId': 'County Id',
    'demography.A Grubu': 'Gruppe A',
    'demography.B Grubu': 'Gruppe B.',
    'demography.C Grubu': 'Gruppe C.',
    'demography.D Grubu': 'Gruppe D.',
    'demography.Lisans': 'Undergraduate',
    'demography.Ilkokul': 'Grundschule',
    'demography.Lise': 'Weiterführende Schule',
    'demography.Ortaokul': 'Mittelschule',
    'demography.Okuryazar Egitimsiz': 'Ungebildet',
    'demography.Married': 'Verheiratet',
    'demography.MarriedNever': 'Niemals verheiratet',
    'sketch.layer': 'Schicht',
    'sketch.selectLayer': 'Wählen Sie Ebene',
    'sketch.addLayer': 'Ebene hinzufügen',
    'sketch.removeLastLayer': 'Remove Last Layer',
    'sketch.removeLastLayerPrompt': 'Are you sure to remove last layer?',
    'sketch.removeLastLayerError': 'Layer has one or more rectangles.',
    'LeavePage.Error':
      'Möchten Sie die Seite wirklich verlassen, ohne die Änderungen zu speichern?',
    'placeHolder.targetUser': 'Zielbenutzer',
    'live.storeCapacity': 'Gebietskapazität:',
    'live.currentPeople': 'AKTUELLE KUNDEN:',
    'live.currentCapacity': 'Aktuelle Kapazität:',
    'live.customerChart': 'Anzahl der Besucher',
    'live.exceededCapacity': 'Kapazität überschritten!',
    'live.least': 'am wenigsten',
    'live.most': 'Die meisten',
    'live.storeCapacityEdit': 'Bereichskapazität eingeben',
    'live.LiveAreas': 'Live Area Counts',
    'info.Forecast.Cam': 'Kamerabereiche',
    'info.prompt.Forecast':
      'Möchten Sie die ausgewählte Gruppe wirklich löschen?',
    'Browser.Does.not.Support': 'Der Browser unterstützt diesen Inhalt nicht.',
    'notification.response': 'Benachrichtigungsantwort:',
    'notification.reply': 'Antworten',
    'notification.timedOut': 'Timed Out',
    'notification.emptyResponse': 'Keine Benachrichtigungsantwort.',
    'notification.otherResponse': 'Andere',
    'suggestion.month': 'Monat',
    'suggestion.topcategory': 'Top Kategorie',
    'suggestion.show': 'Show',
    'suggestion.densityChange': 'Dichteänderung',
    'suggestion.saleChange': 'Verkaufsänderung',
    'suggestion.interestChange': 'İnteressenänderung',
    'suggestion.personChange': 'Besucherwechsel',
    'suggestion.timeChange': 'Zeitumstellung',
    'suggestion.store': 'GESCHÄFT',
    'suggestion.visitorCount': 'Besucherzahl',
    'suggestion.campaign': 'Kampagne',
    'suggestion.productVariety': 'Produktvielfalt',
    'suggestion.price': 'Preis',
    'suggestion.storePlan': 'Ladenplan',
    'suggestion.stock': 'Lager',
    'suggestion.commentOne':
      'Um die Dichte in diesem Bereich zu erhöhen, sollte die Anzahl der Besucher des Bereichs erhöht werden. Die Zunahme der Dichte wird voraussichtlich auch den Umsatz steigern.',
    'suggestion.commentTwo':
      'Hoher Anstieg der Besucherzahl. Wenn es eine Kampagne gibt, zeigt dies an, dass sie funktioniert.',
    'suggestion.commentThree':
      'Die durchschnittliche Zeit pro Besucher in der Region sollte erhöht werden, um die Dichte in dieser Region zu erhöhen. Die Zunahme der Dichte wird voraussichtlich auch den Umsatz steigern.',
    'suggestion.commentFour':
      'Da der Zeitaufwand pro Kunde in diesem Bereich gering ist und der Umsatz nicht ausreicht, kann das Personal in verschiedene Bereiche versetzt werden, wenn diesem Bereich ein Mitarbeiter zugeordnet ist.',
    'suggestion.commentFive':
      'Da die in diesem Bereich verbrachte Zeit hoch und der Umsatz niedrig ist, muss der Kunde möglicherweise weitere Informationen vom Personal erhalten. Es sollten Maßnahmen ergriffen werden, um das Interressenwachstum in Umsatz umzuwandeln.',
    'suggestion.commentSix':
      'Der Kunde kann möglicherweise nicht finden, wonach er sucht. Möglicherweise ist eine Berücksichtigung durch das Personal erforderlich.',
    'suggestion.commentSeven':
      'Der Kunde hat nicht gekauft, weil er nicht finden konnte, wonach er gesucht hat, und der Kunde möglicherweise Personalpflege benötigt.',
    'suggestion.commentEight':
      'Eine Erhöhung der Anzahl und des Interesses der Mitarbeiter kann in Betracht gezogen werden, um der steigenden Besucherzahl und dem steigenden Interesse der Kunden gerecht zu werden.',
    'suggestion.commentNine':
      'Die Zahl der Besucher in diesem Bereich nimmt allmählich ab. Eine Kampagne kann in Betracht gezogen werden, um das Kundeninteresse zu erhöhen.',
    'suggestion.commentTen':
      'Die Zahl der Besucher in diesem Bereich nimmt allmählich ab. Es reicht nicht aus, Kunden zu stoppen und sie an den Vertrieb in diesem Bereich weiterzuleiten. Kampagnen können in Betracht gezogen werden, um die Anzahl und das Interesse der Kunden zu erhöhen.',
    'suggestion.commentEleven':
      'Da die Besucherzahl und der Umsatz zunahmen, war die Kampagne erfolgreich.',
    'suggestion.commentTwelve':
      'Der Kunde tätigt keinen Kauf, weil er nicht finden kann, wonach er sucht. Die Produktpalette ist möglicherweise unzureichend.',
    'suggestion.commentThirteen':
      'Viele Besucher des Ladens umgehen die Gegend. Das Produkt-Preis-Verhältnis kann überarbeitet werden.',
    'suggestion.commentFourteen':
      'Die in der Region verbrachte Zeit nimmt allmählich ab. Dies kann einen Preiseffekt haben.',
    'suggestion.commentFiveteen':
      'Der Bereich befindet sich möglicherweise in einem toten Winkel im Geschäft. Der Standort der Produktgruppe sollte überprüft werden.',
    'suggestion.commentSixteen':
      'Das Gebiet verliert sowohl Kunden als auch Interesse und Umsatz. Das Ladenlayout kann überprüft werden.',
    'suggestion.commentSeventeen':
      'Das Gebiet verliert sowohl Kunden als auch Interesse und Umsatz. Tägliche Änderungen des Geschäftsplans wie Warenkorb, Aktionsbereich und Palette sollten überprüft werden.',
    'suggestion.commentEighteen':
      'Das Gebietsinventar sollte häufig überprüft werden.',
    'suggestion.infoDaily':
      'Sie können die am stärksten ansteigenden und abnehmenden Kategorien des angegebenen Tages entsprechend dem vorherigen Tag und der ausgewählten Variablen anzeigen.',
    'suggestion.infoWeekly':
      'Sie können die am stärksten steigenden und abnehmenden Kategorien dieser Woche basierend auf dem angegebenen Tagesbereich und der ausgewählten Variablen sehen. Wöchentliche Änderungen werden ab Wochenbeginn berechnet. Beispiel: Am 4. Tag der Woche werden die ersten drei Tage dieser Woche mit den ersten drei Tagen der letzten Woche verglichen.',
    'suggestion.infoMonthly':
      'Sie können die am stärksten ansteigenden und abnehmenden Kategorien dieses Monats basierend auf dem angegebenen Tagesbereich und der ausgewählten Variablen anzeigen. Monatliche Änderungen werden ab Monatsanfang berechnet. Beispiel: Am 21. Tag des Monats werden die ersten 20 Tage dieses Monats mit den ersten 20 Tagen des letzten Monats verglichen.',
    'suggestion.title': 'Tracking ändern',
    'suggestion.detailTitle': 'Vorschläge',
    'suggestion.detailPageDes':
      'Auf dieser Seite sehen Sie die Änderungen in der von Ihnen ausgewählten Kategorie. Sie können sie täglich, wöchentlich und monatlich überprüfen und mit der höheren Kategorie vergleichen und Änderungen speichern. Die Probleme und Vorschläge, die aus den Änderungen im ausgewählten Datumsbereich der Kategorie berechnet wurden, sind im zugehörigen Titel angegeben.',
    'suggestion.pageDescription':
      'Auf dieser Seite sehen Sie die Kategorien, die die meisten Änderungen erfahren haben. Sie können ihre Änderungen in täglichen, wöchentlichen und monatlichen Prozessen untersuchen und die Änderung Ihres gewählten Parameters mit der Umsatzänderung vergleichen.',
    'suggestion.infoDetailDaily':
      'Sie können die Änderungen am angegebenen Tag in der von Ihnen ausgewählten Kategorie im Vergleich zum vorherigen Tag sehen.',
    'suggestion.infoDetailWeekly':
      'In der von Ihnen ausgewählten Kategorie können Sie die Änderungen der Woche innerhalb des angegebenen Tages im Vergleich zur letzten Woche anzeigen. Wöchentliche Änderungen werden ab Wochenbeginn berechnet. Beispiel: Am 4. Tag der Woche werden die ersten drei Tage dieser Woche mit den ersten drei Tagen der letzten Woche verglichen.',
    'suggestion.infoDetailMonthly':
      'Sie können die Änderungen in diesem Monat im angegebenen Tagesbereich in der von Ihnen ausgewählten Kategorie anzeigen. Monatliche Änderungen werden ab Monatsanfang berechnet. Beispiel: Am 21. Tag des Monats werden die ersten 20 Tage dieses Monats mit den ersten 20 Tagen des letzten Monats verglichen.',
    'suggestion.infoDetailStore':
      'Sie können die Änderungen in der ausgewählten Kategorie mit den Änderungen im gesamten Geschäft für denselben Datumsbereich vergleichen.',
    'suggestion.infoDetailDep':
      'Sie können die Änderungen in der von Ihnen ausgewählten Kategorie mit den Änderungen in der oberen Kategorie für denselben Datumsbereich vergleichen.',
    'trends.detailTitle': 'Trends',
    'trends.detailPageDes':
      'Auf dieser Seite können Sie die Zunahme und Abnahme der Kategorietrends in den täglichen, wöchentlichen und monatlichen Perioden sehen und mit den Verkaufstrends vergleichen. Sie können die Daten untersuchen, indem Sie entsprechend ihrem Standort im Geschäft in den Kartenmodus wechseln.',
    'trends.infoDaily':
      'Sie können die Zunahme und Abnahme der Trends der Kategorien als Anzahl der Tage entsprechend der ausgewählten Variablen und dem Umsatz anzeigen. Bsp.: Ein zunehmender Trend für fünf Tage.',
    'trends.infoWeekly':
      'Sie können die Zunahme und Abnahme der Trends der Kategorien als Anzahl der Wochen entsprechend sehen',
    'trends.infoMonthly':
      'Sie können die Zu- und Abnahmetrends der Kategorien in Bezug auf die Anzahl der Monate für die ausgewählte Variable und Verkäufe sehen. Bsp.: Der Abwärtstrend für 3 Monate.  ',
    'forecast.editInfo':
      'Mit der Bearbeitungsoption können Sie ein neues Feld aus den Kamerafeldern hinzufügen, das vorhandene Feld in der Gruppe löschen, den Gruppennamen oder die Informationen ändern und speichern.',
    'live.customerCountChart': 'Anzahl der Besucher',
    'live.route': 'Wohnen',
    'live.visitor': 'Anzahl der Live-Besucher',
    'login.Register': 'Registrieren',
    'login.MemberShipResponse':
      'Ihre Mitgliedschaftsanfrage ist eingegangen. Die Antwort wird so schnell wie möglich erfolgen.',
    'membership.Name': 'Name',
    'membership.Surname': 'Nachname',
    'membership.email': 'Email',
    'membership.Brand': 'Marke',
    'average.values': 'Durchschnittliche Werte',
    'notification.sketchWarning': 'There are unscratched sketchs! ',
    'notification.skectAdd': 'Click to add sketch',
    'notification.brandWarning': 'Categories have not been added to brand yet!',
    'notification.brandAdd': 'Click to add categories',
    'Total.Entrance': 'Total Number of  Entering',
    'Total.EntranceAverega': 'Total Number of  Entering Average',
    'Total.WeekdayEntrance': 'Weekday Average Customer Entering',
    'Total.WeekenddayEntrance': 'Weekend Average Customer Entering',
    'Total.WeekenddayEntranceTotal': 'Weekend Total Customer Entering',
    'settings.daily': 'Daily',
    'settings.weekly': 'Weekly',
    'global.CameraSnapshot': 'Camera Snapshot',
    'settings.cameraImage': 'Camera Image',
    'settings.noCameraImage': 'No camera view',
    'user.Mail': 'User Email',
    'settings.removeCam': 'Are you sure  that you want to delete selecteds ?',
    'created.userAndDate': 'Creation Date - Creator User',
    'suggestion.finding': 'Finden',
    'heatmap.value': 'Heat Map Value: ',
    'correlations.from': 'From',
    'correlations.to': 'To',
    'info.age': 'Alter',
    'suggestion.warning': 'No Suggestions!',
    'Customer.young': 'Number of young people',
    'Customer.adult': 'Number of adults',
    'Customer.old': 'Number of olds',
    'area.inArea': 'Time in the area',
    'area.outTime': 'Time to leave the area',
    'area.inTime': 'Time to enter the area',
    'personnel.tracking': 'Personnel tracking',
    'personnel.route': 'Route',
    'button.info.setBoard': 'Set Board',
    'button.info.setReceiver': 'Set Receiver',
    'info.selectBoard': 'Select Board',
    'info.selectReceiver': 'Select Receiver',
    'checkOut.region/store': 'Region/Store',
    'checkOut.general': 'General',
    'checkout.Comparison': 'Comparison',
    'checkout.density': 'Checkout Density',
    'checkout.ReceiptError': 'Receipt Integration Required',
    'checkout.customer': 'Customer',
    'checkout.potentialLoss': 'Potential Loss',
    'checkout.numberCheckout': 'Number of open checkout',
    'checkout.instantCus': 'Number of Instant Customers',
    'checkout.instantCusInfo': 'Number of customers currently in all checkouts',
    'checkout.instantCusWaitingAreaInfo':
      'Number of customers currently in all checkout waiting areas',
    'checkout.maxCusInfo':
      'Number of customers simultaneously present at all checkouts in the selected date range',
    'checkout.maxCusWaitingAreaInfo':
      'Number of customers simultaneously present in all checkout waiting areas during the selected date range',
    'checkout.checkout': 'Checkout',
    'checkout.checkoutWaitingArea': 'Waiting Area',
    'checkout.maxCus': 'Max number of customers',
    'checkout.numberCus': 'Number of customers',
    'checkout.queue': 'Queue Waiting Time',
    'checkout.process': 'Checkout Process Time',
    'checkout.integrationReq': 'Integration Required',
    'suggestion.numberOfSuggestions': 'Number Of Suggestions',
    'suggestions.see': 'See Suggestions',
    'button.info.Board': 'Board',
    'button.info.Receiver': ' Receiver',
    'button.info.ReceiverType': 'Receiver Type',
    'button.info.goBack': 'Go Back',
    'button.unconnectedAreas': 'Unconnected Areas',
    'button.oldSketches': 'Show Old Sketches',
    'reports.allReports': 'All Reports',
    'reports.startDate': 'Start Date',
    'reports.reportName': 'Report Name',
    'reports.dateRange': 'Date Range',
    'reports.downloadReport': 'Download Report',
    'reports.deleteReport': 'Delete Report',
    'reports.endDate': 'End Date',
    'reports.pleaseSelectReport': 'Please select a report file',
    'reports.selectReport': 'Select Report',
    'reports.uploadReport': 'Upload Report',
    'department.waiting.limit': 'Waiting time limit',
    'info.sub.Category.edit': 'Sub Category Edit',
    'number.customers.passed': 'Number Of Customers Passed',
    'info.update': 'Update',
    'info.add': 'Add',
    'info.conversionSale': 'Conversion Time To Sale',
    'live.capacity': 'Area Capacity',
    'live.liveOccupancyGraph': 'Live Occupancy Graph',
    'live.location.capacity': 'Location Capacity',
    'live.peopleData': 'Live People Data',
    'current.people': 'Current Number of People',
    'rotate.degrees': 'Rotate drawings',
    'rotate.degreesConfirmation': 'Are you sure you want to rotate?',
    'Customer.AgeGender.Title': 'Age & Gender',

    'storeSummary.TodayCount': 'Todays Traffic',
    'storeSummary.YesterdayCount': 'Yesterdays Traffic',
    'storeSummary.ThisWeekCount': 'Weekly Traffic',
    'storeSummary.ThisMonthCount': 'Monthly Traffic',
    'storeSummary.ThisYearCount': 'Yearly Traffic',
    'storeSummary.SalesAmount': 'Sales Amount',
    'storeSummary.SalesQuantity': 'Sales Quantity',
    'storeSummary.ConversionRatio': 'Conversion Rate',

    'weekDay.0': 'Sunday',
    'weekDay.1': 'Monday',
    'weekDay.2': 'Tuesday',
    'weekDay.3': 'Wednesday',
    'weekDay.4': 'Thursday',
    'weekDay.5': 'Friday',
    'weekDay.6': 'Saturday',

    'routes.ageGenderComp': 'Age & Gender Comparison',
    'routes.Iframe': 'Custom Reporting',
    Under13Female: 'Age (0-13) Female',
    B14and23Female: 'Age (14-23) Female',
    B24and35Female: 'Age (24-35) Female',
    B36and46Female: 'Age (36-46) Female',
    Over47Female: 'Age (47-) Female',

    Under13Male: 'Age (0-13) Male',
    B14and23Male: 'Age (14-23) Male',
    B24and35Male: 'Age (24-35) Male',
    B36and46Male: 'Age (36-46) Male',
    Over47Male: 'Age (47-) Male',
    'ageGender.moveCountsHeader': 'People Passed by the Store',
    'shopInShop.moveCountsHeader': 'People Passed by the Shop-in-Shop',

    'global.campaingn': 'Campaingn Create',
    'global.loading': 'Loading...',
    'global.empty': 'No data found.',
    'campaingn.name': 'Campaingn Name',
    'all.Brands': 'All Locations',

    ...suggestionComment.de,

    'socket.error': 'Connection Error!',
    'socket.disconnected': 'Socket connection error!',
    'total.demography': 'Total Demographics',
    'total.change': 'Total Change',
    'campaign.category.change': 'Campaign  Categories  Change',
    'trends.yearly': 'Yearly',
    'waiting.time': 'Waiting Time',
    'seconds.over': 'Seconds and Over',
    'names.visible': 'Show Names',
    'short.names.visible': 'Show Short Names',
    'values.visible': 'Show Values',
    'campaign.analysis': 'Campaign Analysis',
    'info.prompt.campaign':
      'Are you sure you want to delete the selected campaign?',
    'Total.Weekday.Customer': 'Weekday Total Customer Entering  ',
    'info.showMore': 'Show More',
    'info.showLess': 'Show Less',
    'info.summaryTable': 'General-Table',
    'info.StoreName': 'Store Name',
    'Endorsement/m2': 'Endorsement/M2',
    InvoicesCount: 'Invoices Count',
    'hourly.avarage': 'Hourly Average',
    'draw.door': 'Draw Door',
    'draw.entry.direction': 'Draw Entry Direction',
    'draw.count.line': 'Draw Count Line',
    Metric: 'Metric',
    'Peak.DensityHour': 'Peak Density Hours',
    'selected.area': 'Selected Area',
    ProductNotAllowed:
      'This product is not used in the selected location. You can review the products of this location on the {link}.',
    ProductNotAllowedLink: 'home page',
    'Avarage.Dwell': 'Average Dwell(sec)',
    'Over.15secAvarage': 'Average time over 15 seconds',
    'Invoice.Count': 'Invoice Count',
    'DailyCustomers.Over15': 'Daily number of customers over 15 seconds',
    'Numberof.Receipts': 'Number of Receipts',
    'PotentialLost.Customer': 'Potential Lost Customer ',
    'data.recalculated': 'Datas recalculated...',
    'data.tobe.calculated': 'Datas waiting to be calculated in line',
    'Unsubscribe.error': 'An Error occurred while unsubscribe!',
    'Unsubscribe.success': 'Successfully unsubscribed!',
    'current.version': 'Current Version',
    'select.atleastOneStore': 'You need to select at least 1 location.',
    personnels: 'Person(s)',
    Days: 'Days',
    'checkoutSummary.storeRegion': 'Store/Region',
    'checkoutSummary.AverageDwell': 'Average Dwell',
    'checkoutSummary.AverageDwellChange': 'Average Dwell Change',
    'checkoutSummary.DensityRatio': 'Density Ratio',
    'checkoutSummary.DensityRatioChange': 'Density Ratio Change',

    'checkoutSummary.ChWaitingAverageDwell': 'Waiting Area Average Dwell',
    'checkoutSummary.ChWaitingAverageDwellChange':
      'Waiting Area Average Dwell Change',
    'checkoutSummary.ChWaitingDensityRatio': 'Waiting Area Density Ratio',
    'checkoutSummary.ChWaitingDensityRatioChange':
      'Waiting Area Density Ratio Change',

    'checkoutSummary.PeakHour': 'Peak Hour',
    'checkoutSummary.NotificationCount': 'Notification Count',
    'checkoutSummary.NotificationCountChange': 'Notification Count Change',
    'checkoutSummary.InvoiceCount': 'Invoice Count',
    'checkoutSummary.AverageDailyInvoiceCount': 'Average Daily Invoice Count',
    'checkoutSummary.InvoiceCountChange': 'Invoice Count Change',
    'checkoutSummary.over15sec': 'Over 15 Seconds',
    'checkoutSummary.CustomerCount': 'Customer Count',
    'checkoutSummary.AverageDailyCustomerCount': 'Average Daily Customer Count',
    'checkoutSummary.CustomerCountChange': 'Customer Count Change',
    'checkoutSummary.Dwell': 'Dwell',
    'checkoutSummary.DwellChange': 'Dwell Change',
    'checkoutSummary.DelayRate': 'Delay Rate',
    'checkoutSummary.DelayRateChange': 'Delay Rate Change',
    'checkoutSummary.ConversionRate': 'Conversion Rate',
    'checkoutSummary.ConversionRateChange': 'Conversion Rate Change',
    'checkoutSummary.btw15and60sec': 'Between 15-60 Seconds',
    'checkoutSummary.over60sec': 'Over 60 Seconds',
    'sketches.sketchHistoryTitle': 'Update Sketch History',
    'sketches.sketchSavedWarning2': 'here.',
    'sketches.sketchSavedWarning1':
      'In order to name the saved sketch and set a starting date for it please click ',
    'sketches.updateSketchHistoryDate': 'Update Sketch History Date',
    checkoutSummary: {
      storeRegion: 'Store/Region'
    },
    'time.limit': 'Time Limit',
    PersonnelOver15Sec: 'Customer Count Over 15s',
    PersonnelOver30Sec: 'Customer Count Over 30s',
    PersonnelOver60Sec: 'Customer Count Over 60s',
    PersonnelOver90Sec: 'Customer Count Over 90s',
    PersonnelOver120Sec: 'Customer Count Over 120s',
    PersonnelOver180Sec: 'Customer Count Over 180s',
    'report.type': 'Report Type',
    subscribe: 'Subscribe',
    'info.SubDepartment.Remove':
      'Are you sure you want to delete the selected subcategories and the campaigns linked to these categories ?',

    'workforce.change': 'Workforce Change',
    'number.employees.working': 'Number of employees working',
    'bottom.shift.time': 'Bottom shift time',
    'top.shift.time': 'Top shift time',
    'workforce.loss.change': 'Workforce loss change',
    'employee.conversion.rate': 'Employee conversion rate',
    'employee.efficiency': 'Employee efficiency',
    'store.least.employeeefficiency': 'Store with least employee efficiency',
    'store.most.employeeefficiency': 'Store with most employee efficiency',
    'estimated.workforce.loss': 'Estimated workforce loss',
    'total.workforce.loss': 'Total workforce loss',
    'total.shift.time': 'Total shift time (hour:min)',
    'total.breaks': 'Total breaks',
    'total.customer': 'Total customer',
    'working.time.change': 'Working Time Change',
    'total.active.time': 'Total Active Time (hour:min)',
    'breaks.change': 'Breaks Change',
    'store.count': 'Store Count',
    'personnel.performance': 'Personnel Performance',
    'brand.summary': 'Brand Summary',
    'personnel.summary': 'Personnel Summary',
    'customer.change': 'Customer Change',
    'personnel.count.total': 'Personnel Count Total',
    'personnel.count.change': 'Personnel Count Change',
    'invoice.count.change': 'Invioce Count Change',
    'sale.quantity.change': 'Sale Quantity Change',
    'sale.amount.change': 'Sale Amount Change',
    'employee.conversion.rate.total': 'Employee conversion rate total',
    'employee.conversion.rate.change': 'Employee conversion rate change',
    'employeeefficiency.change':
      'Employee Efficiency Change (Active Time / Shift Time)',
    'employeeefficiency.total':
      'Employee Efficiency Total (Active Time / Shift Time)',
    'download.excel': 'Download Excel',
    'checkout.traffic': 'Checkout Traffic',
    'checkout.topStores': 'Checkout (Top Stores)',
    'checkout.bottomStores': 'Checkout (Bottom Stores)',
    'Meantime.over15sec': 'Mean time over 15sec',
    'numberof.notifications': 'Number of Notifications',
    '15sec.checkout.visitor': 'Checkout Visitor Number  Over 15sec',
    'visitor.number.over15sec': 'Visitor number over 15sec',
    'visitor.number.change.over15sec': 'Visitor number change over 15sec',
    'visitor.number.over60sec': 'Visitor number over 60sec',
    'visitor.number.change.over60sec': 'Visitor number change over 60sec',
    'mean.time.change.over15sec': 'Mean time change over 15sec',
    'mean.time.over15sec': 'Mean time over 15sec',
    'meantime.over15sec': 'Mean Time Over 15 sec',
    'meantime.changeover15sec': 'Mean Time Change Over 15 sec',
    'hourly.traffic.chart': 'Hourly Traffic Chart',
    'daily.traffic.chart': 'Daily Traffic Chart',
    'door.person.count': 'People Counting',
    'daily.amount': 'Daily Sale Amount',
    'daily.traffic': 'Daily Traffic',
    'daily.invoice': 'Daily Invoice',
    'daily.quantity': 'Daily Quantity',
    'monthly.amount': 'Monthly Sale Amount',
    'monthly.traffic': 'Monthly Traffic',
    'monthly.invoice': 'Monthly Invoice',
    'monthly.quantity': 'Monthly Sale Quantity',
    'previous.daily.amount': 'Previous Daily Amount',
    'previous.daily.traffic': 'Previous Daily Traffic',
    'previous.daily.invoice': 'Previous Daily Invoice',
    'previous.daily.quantity': 'Previous Daily Sale Quantity',
    'previous.monthly.amount': 'Previous Monthly Sale Amount',
    'previous.monthly.traffic': 'Previous Monthly Traffic',
    'previous.monthly.invoice': 'Previous Monthly Weekly',
    'previous.monthly.quantity': 'Previous Monthly Sale Quantity',
    'previous.monthly.amount': 'Previous Monthly Sale Amount',
    'previous.weekly.traffic': 'Previous Weekly Traffic',
    'previous.weekly.invoice': 'Previous Weekly Invoice',
    'previous.weekly.quantity': 'Previous Weekly Sale Quantity',
    'weekly.amount': 'Previous Sale Amount',
    'weekly.traffic': 'Previous Traffic',
    'weekly.invoice': 'Previous Invoice',
    'weekly.quantity': 'Previous Sale Quantity',
    'category.summary': 'Category Summary',
    'InStore.Analytics.TopStores': 'In-Store Analytics (Top Stores)',
    'InStore.Analytics.BottomStores': 'In-Store Analytics (Bottom Stores)',
    'InStore.Analytics.TopCategories': 'In-Store Analytics (Top Categories)',
    'InStore.Analytics.BottomCategories':
      'In-Store Analytics (Bottom Categories)',
    'store.based.traffic.perf': 'Store Based Traffic Performance',
    'traffic.change': 'Traffic Change',
    'invoicecount.change': 'Invoices Count Change',
    'top.ten.stores': 'Top 10 Stores',
    'InStore.Density': 'In-Store Density',
    'InStore.Density.Change': 'In-Store Density Change',
    'meantime.change': 'Mean Time Change',
    'conversionRate.change': 'Conversion Rate Change',
    'Visitor.Count.Over.15sec.Change': 'Change Visitor Count Over 15sec',
    'Visitor.Count.Over.15sec': 'Visitor Count Over 15sec',
    'Top.Categories': 'Top Categories',
    'Bottom.Categories': 'Bottom Categories',
    'info.currentSketch': 'Current Sketch',
    'info.sketchHistory': 'Sketch History',
    ShopInShopFrontLineCount: 'Shop-in-Shop Front Traffic',
    ShopInShopEntranceLineCount: 'Shop-in-Shop Entrance',
    ShopInShopFrontAndEntranceGraphTitle:
      'Shop-in-Shop Front Traffic and Entrance',
    ShopInShopLineCountsGraphTitle: 'Shop-in-Shop Line Counts',
    compare: 'Compare',
    firstArea: 'First area',
    secondArea: 'Second area',
    'info.ShopInShopFrontCount': 'SiS Front Count',
    'info.ShopInShopEntranceCount': 'SiS Entrance Count',
    'info.ShopInShopConversionRatio': 'SiS Conversion Rate',
    'notificationFilter.seenFilter': 'Seen Filter',
    'notificationFilter.seen': 'Seen',
    'notificationFilter.nonSeen': 'Unseen',
    'notificationFilter.all': 'All',
    'notificationFilter.respondedFilter': 'Responded Filter',
    'notificationFilter.responded': 'Responded',
    'notificationFilter.nonResponded': 'Unresponded',
    'notificationFilter.responseRate': 'Response Rate',
    'notificationFilter.total': 'Total',
    single: 'Singular',
    group: 'Group',
    lineEntranceGroupValue12578: 'Group of {value}',
    lineEntranceGroupValue34: 'Group of {value}',
    lineEntranceGroupValue6: 'Group of {value}',
    lineEntranceGroupValue9: 'Group of {value}',
    areYouSureDeletePrompt: 'Are you sure you want to delete this?',
    doorLine: 'Door Line',
    countingLine: 'Count Line',
    'drawingToolsInfos.close': 'Zoom in camera: W',
    'drawingToolsInfos.far': 'Zoom out camera: S',
    'drawingToolsInfos.reset': 'Reset zoom: R',
    'drawingToolsInfos.move': 'Move camera: hold Q',
    'drawingToolsInfos.polygonActions': 'Polygon actions: Right click',
    'drawingToolsInfos.polygonDrag': 'Drag polygon: Hold Shift',
    'drawingToolsInfos.addCorner':
      'Add corner: Hold Shift and left click on corner',
    'drawingToolsInfos.deleteCorner':
      'Delete corner: Hold Alt and left click on corner',
    lines: 'Lines',
    polygons: 'Polygons',
    noPolygons: 'No polygons found.',
    noLines: 'No lines found.',
    drawRectangle: 'Draw rectangle'
  }
};
