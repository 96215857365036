import React from 'react';
import './style.scss';

const Input = React.forwardRef((props, ref) => {
  const { className, ...restProps } = props;

  return (
    <input
      ref={ref}
      type="text"
      className={`form-input  ${className ?? ''}`}
      {...restProps}
    />
  );
});

export { Input };
