import { getCookie, setCookie } from '../Utils/cookie';

// User agent represent unique id for current browser. 
// this agent will inilialize after web site mounted.
// Used to deduplicate the user 
function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
const generate_x_session_id = () => uuidv4();

export const create_x_session_id = () => {
  const userAgent = generate_x_session_id();
  setCookie('x_session_id', userAgent, 1);
};

export const get_x_session_id = () => getCookie('x_session_id');

export const is_exist_x_session_id = () => {
  const userAgent = getCookie('x_session_id');
  return userAgent != '';
};
