export default {
  en: {
    'suggestion.findings550':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings551':
      'The increase, the average time spent in the scope of one of the density and time parameters of the person who created an increased number of customers to decreased area.',
    'suggestion.findings552':
      'The increase in sales as the density increases indicates that the potential in the area is very high.',
    'suggestion.findings553':
      'interest rate also increased compared to the previous date range.',
    'suggestion.findings554':
      'Since the interest rate and intensity increase in this area, the amount of sales increases, so the time spent in this area has a great effect on sales.',
    'suggestion.findings555':
      'Previous date range area of ​​the area in no time passing customers from the area despite the reduction in the time spent by interest rate increases that began to pass by the pass.',
    'suggestion.findings556':
      'Meanwhile, the area for 15 seconds and over time increase sales by customers that have increased the number of people who are likely to be customers who have spent time in the area and over 15 seconds.',
    'suggestion.findings560':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings561':
      'The increase, the average time spent in the scope of one of the density and time parameters of the person who created an increased number of customers to decreased area.',
    'suggestion.findings562':
      'The increase in sales as the density increases indicates that the potential in the area is very high.',
    'suggestion.findings563':
      'The interest rate has decreased compared to the previous date range.',
    'suggestion.findings564':
      'Although the rate of interest in this area has decreased, since the sales increase, the effect of the number of people on the sales is more than the time spent in this area.',
    'suggestion.findings570':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings571':
      'The increase, the average time spent in the scope of one of the density and time parameters of the person who created an increased number of customers to decreased area.',
    'suggestion.findings572':
      'The increase in density is expected to increase sales directly.',
    'suggestion.findings573':
      'Despite this, while density increased in the area compared to the previous date range, the sales remained constant, thus the area failed to reach the potential salepeople.',
    'suggestion.findings574':
      'Previous date range area of ​​the area in no time passing customers from the area despite the reduction in the time spent by interest rate increases that began to pass by the pass.',
    'suggestion.findings575':
      'Meanwhile, it also increased sales for 15 seconds and over time the number of people who are expected to directly improve the area.',
    'suggestion.findings576':
      'Despite this, sales remained unchanged, meaning people who spent time in the field did not buy either.',
    'suggestion.findings580':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings581':
      'The increase, the average time spent in the scope of one of the density and time parameters of the person who created an increased number of customers to decreased area.',
    'suggestion.findings582':
      'The increase in density is expected to directly increase sales.',
    'suggestion.findings583':
      'Despite this, while density increased in the area compared to the previous date range, the sales remained constant, thus the area failed to reach the potential salepeople.',
    'suggestion.findings590':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings591':
      'The increase, the average time spent in the scope of one of the density and time parameters of the person who created an increased number of customers to decreased area.',
    'suggestion.findings592':
      'This area is decreased density increased sales compared to the previous date range.',
    'suggestion.findings593':
      'Accordingly, although there was an opportunity in the  field, the field failed to reach potential sales.',
    'suggestion.findings594':
      'The interest rate has increased compared to the previous date range.',
    'suggestion.findings600':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings601':
      'The increase, the average time spent in the scope of one of the density and time parameters of the person who created an increased number of customers to decreased area.',
    'suggestion.findings602':
      'This area is decreased density increased sales compared to the previous date range.',
    'suggestion.findings603':
      'Accordingly, although there was an opportunity in the  field, the field failed to reach potential sales.',
    'suggestion.findings604':
      'Since the interest rate has decreased compared to the previous date range, the reason why the increase in density does not affect the sales may be that the customer does not spend enough time in the area.',
    'suggestion.findings610':
      'In the area, the number of people in accordance with a previous date range and increased density due to the increase both the time spent.',
    'suggestion.findings611':
      'This increase is expected to increase direct sales.',
    'suggestion.findings612':
      'The increase in sales as the density increases indicates that the potential in the area is very high.',
    'suggestion.findings613':
      'interest rate also increased compared to the previous date range.',
    'suggestion.findings614':
      'Since the interest rate and dentensity increase in this area, the amount of sales has also increased, thus the time spent in this area has a significant effect on sales.',
    'suggestion.findings620':
      'In the area, the number of people in accordance with a previous date range and increased density due to the increase both the time spent.',
    'suggestion.findings621':
      'This increase is expected to increase direct sales.',
    'suggestion.findings622':
      'The increase in sales as the density increases indicates that the potential in the area is very high.',
    'suggestion.findings623':
      'The interest rate has decreased compared to the previous date range',
    'suggestion.findings630':
      'In the area, the number of people in accordance with a previous date range and increased density due to the increase both the time spent.',
    'suggestion.findings631':
      'This increase is expected to increase direct sales, sales remained constant.',
    'suggestion.findings632':
      ' Potential in the area, the outcome of increasing density, could not be exploited.',
    'suggestion.findings633':
      'interest rate also increased compared to the previous date range.',
    'suggestion.findings634':
      'Since the rate of interest and density in this area remains constant while increasing, the increase in the time spent in this area may not have a positive effect on sales.',
    'suggestion.findings640':
      'In the area, the number of people in accordance with a previous date range and increased density due to the increase both the time spent.',
    'suggestion.findings641':
      'This increase is expected to increase direct sales, sales remained constant.',
    'suggestion.findings642':
      ' Potential in the area, the outcome of increasing density, could not be exploited.',
    'suggestion.findings643':
      'It is a good result that there is no decrease in sales.',
    'suggestion.findings644':
      'Because only the increased potential could not be utilized, the previous sales amount continued to be protected.',
    'suggestion.findings650':
      'In the area, the number of people in accordance with a previous date range and increased density due to the increase both the time spent.',
    'suggestion.findings651': 'Despite the density increased, sales decreased.',
    'suggestion.findings652':
      'As the interest rate, the time spent and the number of people increases, the sales decrease, so the area does not have the capability to meet the increasing density.',
    'suggestion.findings660':
      'In the area, the number of people in accordance with a previous date range and increased density due to the increase both the time spent.',
    'suggestion.findings661':
      'While the number of people and time increased, the interest rate in the area decreased.',
    'suggestion.findings670':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings671':
      'The reason for the increase is the increase observed in the number of people and time parameters that generate the density.',
    'suggestion.findings672':
      'The increase in sales as the density increases indicates that the potential in the area is very high.',
    'suggestion.findings680':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings681':
      'The reason for the increase is the increase in the time spent on the person and time parameters that creates the densitypeople.',
    'suggestion.findings682':
      'Increased density also increased sales, it indicates that there is a lot of potential and high sales productivity in the area.',
    'suggestion.findings690':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings691':
      'The reason for the increase is the increase in the time spent on the person and time parameters that creates the density.',
    'suggestion.findings692':
      'The increase in density is expected to increase sales while sales remained constant.',
    'suggestion.findings693':
      'Since the increase in density in this area is due to the increase in time, the longer time customers spend in this area does not have a positive effect on salespeople.',
    'suggestion.findings694':
      'If time continues to increase, it may be adversely affected sales in the next stage.',
    'suggestion.findings700':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings701':
      'The reason for the increase is the increase in the time spent on the person and time parameters that creates the densitypeople.',
    'suggestion.findings702':
      'The increase in density is expected to increase sales while sales remained constant.',
    'suggestion.findings703':
      'Since the increase in density in this area is due to the increase in time, the longer time customers spend in this area does not have a positive effect on salespeople.',
    'suggestion.findings704':
      'If time continues to increase, it may be adversely affected sales in the next stage.',
    'suggestion.findings710':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings711':
      'The reason for the increase is the increase in the time spent on the person and time parameters that creates the densitypeople.',
    'suggestion.findings712':
      'The number of people standing in the area with 15 seconds and over time increase has also increased.',
    'suggestion.findings713':
      'Accordingly, the vast majority of customers to the area began to spend a very long time.',
    'suggestion.findings714':
      'Sales decreased as time increased. That is, time increase poses a threat to salespeople.',
    'suggestion.findings720':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings721':
      'The reason for the increase is the increase in the time spent on the person and time parameters that creates the densitypeople.',
    'suggestion.findings722':
      'Despite the rise time, 15 seconds and over has decreased the number of people standing.',
    'suggestion.findings723':
      'Since the interest rate decreases despite the increase in time, the time spent by the customers in the area is not very longpeople.',
    'suggestion.findings730':
      'The density of the  areas remained constant from the previous date range.',
    'suggestion.findings731':
      'This is because the density of people and the number of people that make up the time parameter is the reduction of time increased.',
    'suggestion.findings732':
      'The increase in the interest rate and the number of people has increased in sales in the area. Alana, has come about customers.',
    'suggestion.findings733': 'sales potential in the area is high.',
    'suggestion.findings740':
      'The density of the  areas remained constant compared to the previous date range.',
    'suggestion.findings741':
      'This is because of the time and parameters that generate the density, while the time decreases while the number of people increases.',
    'suggestion.findings742':
      'As the time and interest rate decreases and the number of people increases, there may be a buy-and-go product group in this areapeople.',
    'suggestion.findings743':
      'The area sales productivity and potential increases.',
    'suggestion.findings750':
      'The density of the  areas remained constant compared to the previous date range.',
    'suggestion.findings751':
      'This is because of the time and parameters that generate the density, while the time decreases while the number of people increases.',
    'suggestion.findings752':
      'As the number of people increases and time decreases, the time factor is critical to purchase products from this area.',
    'suggestion.findings760':
      'The density of the  areas remained constant compared to the previous date range.',
    'suggestion.findings761':
      'This is because of the time and parameters that generate the density, while the time decreases while the number of people increases.',
    'suggestion.findings762':
      'If the time continues to decrease, this may cause a decrease for sales as wellpeople.',
    'suggestion.findings770':
      '24The density of the  areas remained constant compared to the previous date range.',
    'suggestion.findings771':
      'This is because of the time and parameters that generate the density, while the time decreases while the number of people increases.',
    'suggestion.findings772':
      'According to the increase in the number of people in high potential areas.',
    'suggestion.findings780':
      'The density of the  areas remained constant compared to the previous date range.',
    'suggestion.findings781':
      'This is because of the time and parameters that generate the density, while the time decreases while the number of people increases.',
    'suggestion.findings782':
      'According to the increase in the number of people in high potential areas.',
    'suggestion.findings783':
      'The decrease in the interest rate and time had a negative effect on sales',
    'suggestion.findings790':
      'Related area, depending on the reduction of the period increased number of people compared to the previous date range density has remained constant.',
    'suggestion.findings791':
      'Although the density was constant, the increase in the time spent and the interest rate increased the sales.',
    'suggestion.findings800':
      'The density of the  area remained constant due to the decrease in the number of people while the time increased compared to the previous date range.',
    'suggestion.findings801':
      'Although the density was constant, the increase in the time spent increased the salespeople.',
    'suggestion.findings802':
      'Since the interest rate decreases and sales increase, the area is a fast and efficient sales areapeople.',
    'suggestion.findings810':
      'The density of the  area remained constant due to the decrease in the number of people while the time increased compared to the previous date range.',
    'suggestion.findings811':
      'Although the interest rate increased while the density was constant, the sales in the area did not changepeople.',
    'suggestion.findings812':
      'Accordingly, 15 seconds over the area to customers and to increase sales to spend time is not critical.',
    'suggestion.findings820':
      'The density of the  area remained constant due to the decrease in the number of people while the time increased compared to the previous date range.',
    'suggestion.findings821':
      'Although the interest rate decreased while the density was constant, the sales in the area did not changepeople.',
    'suggestion.findings822':
      'If the sales rate was below the interest rate, the potential customer could not be converted into sales.',
    'suggestion.findings830':
      'The density of the  area remained constant due to the decrease in the number of people while the time increased compared to the previous date range.',
    'suggestion.findings831':
      'Density has increased interest rates steady, and decreased sales in the area.',
    'suggestion.findings832':
      'While the interest rate increases, the decrease in sales indicates that the customer who spent a long time in the field could not find what they were looking for or left the area without buying due to the long waiting timepeople.',
    'suggestion.findings840':
      'The density of the  area remained constant due to the decrease in the number of people while the time increased compared to the previous date range.',
    'suggestion.findings841':
      'Density interest rate and decreased sales in the constant area.',
    'suggestion.findings842':
      'Accordingly, the reduction in the number of people who had over 15 seconds and the area has a direct negative impact on sales.',
    'suggestion.findings850':
      'While the number of people in the area increased compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings851':
      'The interest rate has also increased due to the increasing number of people compared to the previous date rangepeople.',
    'suggestion.findings852':
      'As the number of people and the rate of interest increased, the number of customers coming and passing from the area increased significantly as the time decreasedpeople.',
    'suggestion.findings860':
      'While the number of people in the area increased compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings861':
      'As the interest rate and time decrease while the sales increase, the product in the area can be a fast buy and go product.',
    'suggestion.findings870':
      'While the number of people in the area increased compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings871':
      'As the number of people increased and the time decreased, the interest rate increased, so many people came to the areapeople.',
    'suggestion.findings872':
      'The increase in the interest rate was not contrary to the expected benefit to sales.',
    'suggestion.findings880':
      'While the number of people in the area increased compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings881':
      'Since the time and interest rate decrease as the person increases, the time spent by the customer in the area is lesspeople.',
    'suggestion.findings882':
      'This may be because the product has not attracted attention or the customer has passed this area due to a problem with the store planpeople.',
    'suggestion.findings890':
      'While the number of people in the area increased compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings891':
      'Interested customers are expected to purchase the product.',
    'suggestion.findings900':
      'While the number of people in the area increased compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings910':
      'While the time in the  area increased compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings911':
      'Staff numbers, product prices are optimal balance in this area.',
    'suggestion.findings912': 'The area increases the sales productivity.',
    'suggestion.findings920':
      'While the time in the  area increased compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings921':
      'Since the rate of interest in this area decreases and sales increase,  interest is not a factor in increasing salespeople.',
    'suggestion.findings922':
      'Accordingly, it is likely that quick sell products are sold in this area.',
    'suggestion.findings930':
      'While the time in the  area increased compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings940':
      'While the time in the  area increased compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings941':
      'Since the rate of interest in this area is decreasing and sales remain constant, it is not an interest factor in increasing salespeople.',
    'suggestion.findings942':
      'Accordingly, it is likely that quick sell products are sold in this area.',
    'suggestion.findings950':
      'While the time in the  area increased compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings960':
      'While the time in the  area increased compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings961':
      'Since the rate of interest in this area decreases and sales also decrease, the interest affects salespeople.',
    'suggestion.findings970':
      'In the area, the density decreased due to the decrease in both the number of people and the time spent compared to the previous date range.',
    'suggestion.findings971':
      'As the number of people decreases and sales increase, sales are made to almost every customerpeople.',
    'suggestion.findings972':
      'Since the sales increase while the time is decreasing, sales in the area are realized rapidly.',
    'suggestion.findings973':
      'If the number of people decreases while the interest rate increases and the time decreases, the number of people coming to the area is too highpeople.',
    'suggestion.findings980':
      'In the area, the density decreased due to the decrease in both the number of people and the time spent compared to the previous date range.',
    'suggestion.findings981':
      'The time factor is ineffective in this area, as the interest rate and time decrease while sales increase. A quick buy and go product line might be on sale in the area.',
    'suggestion.findings990':
      'In the area, the density decreased due to the decrease in both the number of people and the time spent compared to the previous date range.',
    'suggestion.findings991':
      'The decrease in person and time in this area did not change the sales.',
    'suggestion.findings1000':
      'In related area, the number of people compared to the previous date range and density has decreased due to reduced both the time spent.',
    'suggestion.findings1010':
      'In related area, the number of people compared to the previous date range and density has decreased due to reduced both the time spent.',
    'suggestion.findings1011':
      'Although the number of customers increased in this area, sales did not increase.',
    'suggestion.findings1012':
      'The interest could not find the required response, potential was not used.',
    'suggestion.findings1020':
      'In the area, the density decreased due to the decrease in both the number of people and the time spent compared to the previous date range.',
    'suggestion.findings1021':
      'The density, the sales, and the interest rate in the area have decreased.',
    'suggestion.findings1022':
      'Accordingly, the worst possible scenario has come true.',
    'suggestion.findings1030':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1031':
      'The reason for the increase is the increase in the number of people among the people and time parameters that generates the density.',
    'suggestion.findings1032':
      'The increase in sales as the density increases indicates that the potential in the area is very high.',
    'suggestion.findings1033':
      'Interest rates remained constant compared to the previous date range.',
    'suggestion.findings1034':
      'Although the rate of interest in this area remains constant, as the sales increase, the effect of the number of people on the sales is greater than the time spent in this area.',
    'suggestion.findings1040':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1041':
      'The reason for the increase is the increase in the number of people among the people and time parameters that generates the densitypeople.',
    'suggestion.findings1042':
      ' The increase in density is expected to increase sales directly.',
    'suggestion.findings1043':
      ' Despite this, while density increased in the area compared to the previous date range, the sales remained constant, thus the area failed to reach the potential salepeople.',
    'suggestion.findings1050':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1051':
      ' The reason for the increase is the increase in the number of people among the people and time parameters that generates the densitypeople.',
    'suggestion.findings1052':
      ' This area has constant density increased sales decreased compared to the previous date range.',
    'suggestion.findings1053':
      'Accordingly, despite the opportunity in the area, the area has failed to reach potential sales.',
    'suggestion.findings1060':
      'In the area, the density increased thanks to the increase in both the number of people and the time spent compared to the previous date range.',
    'suggestion.findings1061':
      'This increase is expected to increase sales directly.',
    'suggestion.findings1062':
      'The increase in sales as the density increases indicates that the potential in the area is very high.',
    'suggestion.findings1063':
      'Interest rates remained constant compared to the previous date range.',
    'suggestion.findings1070':
      'In the area, the density increased thanks to the increase in both the number of people and the time spent compared to the previous date range.',
    'suggestion.findings1071':
      'This increase is expected to increase direct sales, sales remained constant.',
    'suggestion.findings1072':
      ' Potential in the area, the outcome of increasing density, could not be exploited.',
    'suggestion.findings1073':
      'It is a good result that there is no decrease in sales.',
    'suggestion.findings1074':
      'Because only the increased potential could not be utilized, the previous sales amount continued to be protected.',
    'suggestion.findings1080':
      'In the area, the density increased thanks to the increase in both the number of people and the time spent compared to the previous date range.',
    'suggestion.findings1081':
      'While the number of people and time increased, the interest in the area remained constant.',
    'suggestion.findings1090':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1091':
      'The reason for the increase is the increase in the time spent on the person and time parameters that creates the densitypeople.',
    'suggestion.findings1092':
      'Increased density also increased sales, it indicates that there is a lot of potential and high sales productivity in the area.',
    'suggestion.findings1100':
      '57The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1101':
      'The reason for the increase is the increase in the time spent on the person and time parameters that creates the densitypeople.',
    'suggestion.findings1102':
      'The increase in density is expected to increase sales while sales remained constant.',
    'suggestion.findings1103':
      'Since the increase in density in this area is due to the increase in time, the longer time customers spend in this area does not have a positive effect on salespeople.',
    'suggestion.findings1110':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1111':
      'The reason for the increase is the increase in the time spent on the person and time parameters that creates the densitypeople.',
    'suggestion.findings1112':
      'Although the time increased, the number of people for 15 seconds or more remained stable.',
    'suggestion.findings1113':
      'Since the interest rate remains constant although the time increases, the time spent by the customers in the area is generally not more than 15 seconds.',
    'suggestion.findings1120':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1121':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1122':
      'The increase in sales as the density increases indicates that the potential in the area is very high.',
    'suggestion.findings1123':
      'interest rate also increased compared to the previous date range.',
    'suggestion.findings1124':
      'Since the interest rate and intensity increase in this area, the amount of sales increases, so the time spent in this area has a great effect on sales.',
    'suggestion.findings1130':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1131':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1132':
      'The increase in sales as the density increases indicates that the potential in the area is very high.',
    'suggestion.findings1133':
      'The interest rate has decreased compared to the previous date range.',
    'suggestion.findings1134':
      'Although the rate of interest in this area has decreased, since the sales increase, the effect of the number of people on the sales is more than the time spent in this area.',
    'suggestion.findings1140':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1141':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1142':
      'The increase in sales as the density increases indicates that the potential in the area is very high.',
    'suggestion.findings1143':
      'Interest rates remained constant compared to the previous date range.',
    'suggestion.findings1144':
      'Although the rate of interest in this area remains constant, as the sales increase, the effect of the number of people on the sales is greater than the time spent in this area.',
    'suggestion.findings1150':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1151':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1152':
      'The increase in density is expected to increase sales directly.',
    'suggestion.findings1153':
      'Despite this, while density increased in the area compared to the previous date range, the sales remained constant, thus the area failed to reach the potential salepeople.',
    'suggestion.findings1160':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1161':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1162':
      'The increase in density is expected to increase sales directly.',
    'suggestion.findings1163':
      'Despite this, while density increased in the area compared to the previous date range, the sales remained constant, thus the area failed to reach the potential salepeople.',
    'suggestion.findings1170':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1171':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1172':
      'The increase in density is expected to increase sales directly.',
    'suggestion.findings1173':
      'Despite this, while density increased in the area compared to the previous date range, the sales remained constant, thus the area failed to reach the potential salepeople.',
    'suggestion.findings1180':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1181':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1182':
      'This area increased sales compared to the previous date range density has remained constant.',
    'suggestion.findings1183':
      'Accordingly, although there was an opportunity in the  area, the area failed to reach potential sales.',
    'suggestion.findings1184':
      'The interest rate has increased compared to the previous date range.',
    'suggestion.findings1185':
      'According to this; The reason why the increase in density does not affect the sales may be that the customer spends too much time in the area but cannot find what he wants.',
    'suggestion.findings1190':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1191':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1192':
      'This area has constant density increased sales decreased compared to the previous date range.',
    'suggestion.findings1193':
      'Accordingly, although there was an opportunity in the  area, the area failed to reach potential sales.',
    'suggestion.findings1200':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1201':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1202':
      'This area has constant density increased sales decreased compared to the previous date range.',
    'suggestion.findings1203':
      'Accordingly, although there was an opportunity in the  area, the area failed to reach potential sales.',
    'suggestion.findings1210':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1211':
      'The reason for the increase is the increase in the time spent while the number of people is constant, among the person and time parameters that make up the densitypeople.',
    'suggestion.findings1212':
      'The increase in sales as the density increases indicates that the potential in the area is very high.',
    'suggestion.findings1220':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1221':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1222':
      'The increase in sales as the density increases indicates that the potential in the area is very high.',
    'suggestion.findings1223':
      'The interest rate has decreased compared to the previous date range.',
    'suggestion.findings1224':
      'Although the rate of interest in this area has decreased, since the sales increase, the effect of the number of people on the sales is more than the time spent in this area.',
    'suggestion.findings1230':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1231':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1232':
      'The increase in sales as the density increases indicates that the potential in the area is very high.',
    'suggestion.findings1233':
      'Interest rates remained constant compared to the previous date range.',
    'suggestion.findings1234':
      'Although the rate of interest in this area remains constant, as the sales increase, the effect of the number of people on the sales is greater than the time spent in this area.',
    'suggestion.findings1240':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1241':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1242':
      'The increase in density is expected to increase sales directly.',
    'suggestion.findings1243':
      'Despite this, while density increased in the area compared to the previous date range, the sales remained constant, thus the area failed to reach the potential salepeople.',
    'suggestion.findings1244':
      'Since the interest rate has increased compared to the previous date range, the reason why the increase in density does not affect the sales may be that the customer spends too much time in the field but cannot find what they want.',
    'suggestion.findings1250':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1251':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1252':
      'The increase in density is expected to increase sales directly.',
    'suggestion.findings1253':
      'Despite this, while density increased in the area compared to the previous date range, the sales remained constant, thus the area failed to reach the potential salepeople.',
    'suggestion.findings1254':
      'Since the interest rate has decreased compared to the previous date range, the reason why the increase in density does not affect the sales may be that the customer does not spend enough time in the area.',
    'suggestion.findings1260':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1261':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1262':
      'The increase in density is expected to increase sales directly.',
    'suggestion.findings1263':
      'Despite this, while density increased in the area compared to the previous date range, the sales remained constant, thus the area failed to reach the potential salepeople.',
    'suggestion.findings1264':
      'Since the interest rate remained constant compared to the previous date range, the reason that the increase in density does not affect the sales may be that the customer does not spend enough time in the area.',
    'suggestion.findings1270':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1271':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1272':
      'This area increased sales compared to the previous date range density has remained constant.',
    'suggestion.findings1273':
      'Accordingly, although there was an opportunity in the  area, the area failed to reach potential sales.',
    'suggestion.findings1274':
      'The interest rate has increased compared to the previous date range.',
    'suggestion.findings1275':
      'According to this; The reason why the increase in density does not affect the sales may be that the customer spends too much time in the area but cannot find what he wants.',
    'suggestion.findings1280':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1281':
      'The reason for the increase is that the number of people increases while the time is constant, one of the person and time parameters that generates the density.',
    'suggestion.findings1282':
      'This area has constant density increased sales decreased compared to the previous date range.',
    'suggestion.findings1283':
      'Accordingly, although there was an opportunity in the  area, the area failed to reach potential sales.',
    'suggestion.findings1284':
      'Since the interest rate has decreased compared to the previous date range, the reason why the increase in density does not affect the sales may be that the customer does not spend enough time in the area.',
    'suggestion.findings1290':
      'The density of the  areas has increased compared to the previous date range.',
    'suggestion.findings1291':
      'The reason for the increase is the increase in the time spent while the number of people is constant, among the person and time parameters that make up the densitypeople.',
    'suggestion.findings1292':
      'Although the time increased, the number of people for 15 seconds or more remained stable.',
    'suggestion.findings1293':
      'Since the interest rate remains constant although the time increases, the time spent by the customers in the area is generally not more than 15 seconds.',
    'suggestion.findings1300':
      'The density of the  areas remained constant compared to the previous date range.',
    'suggestion.findings1301':
      'This is because of the time and parameters that generate the density, while the time decreases while the number of people increases.',
    'suggestion.findings1302':
      'As time decreases, the interest rate remains constant and the number of people and the sale increases, there may be a go-to product group in this area.',
    'suggestion.findings1303':
      'The area sales productivity and potential increases.',
    'suggestion.findings1310':
      'The density of the  areas remained constant compared to the previous date range.',
    'suggestion.findings1311':
      'This is because of the time and parameters that generate the density, while the time decreases while the number of people increases.',
    'suggestion.findings1312':
      'If the time continues to decrease, this may cause a decrease for sales as wellpeople.',
    'suggestion.findings1320':
      'The density of the  areas remained constant compared to the previous date range.',
    'suggestion.findings1321':
      'This is because of the time and parameters that generate the density, while the time decreases while the number of people increases.',
    'suggestion.findings1322':
      'As the number of people increases, the potential in the area is high. While the interest was constant, the decrease in the time had a negative effect on sales.',
    'suggestion.findings1330':
      'The density of the  area remained constant due to the decrease in the number of people while the time increased compared to the previous date range.',
    'suggestion.findings1331':
      'Although the density was constant, the increase in the time spent increased the salespeople.',
    'suggestion.findings1332':
      'While the rate of interest does not change, the area can be a fast and efficient sales area as the sales increase.',
    'suggestion.findings1340':
      'The density of the  area remained constant due to the decrease in the number of people while the time increased compared to the previous date range.',
    'suggestion.findings1341':
      'While the density and interest rate were constant, the sales in the area also remained constant.',
    'suggestion.findings1342':
      'If the sales rate was below the interest rate, the potential customer could not be converted into sales.',
    'suggestion.findings1350':
      'The density of the  area remained constant due to the decrease in the number of people while the time increased compared to the previous date range.',
    'suggestion.findings1351':
      'Density and interest rate decreased sales in the constant area.',
    'suggestion.findings1352':
      'Accordingly, the decrease in the number of people in the area had a negative effect on sales directly.',
    'suggestion.findings1360':
      'The density remained constant in the area as the time and number of people remained constant compared to the previous date range.',
    'suggestion.findings1361':
      'Although the density was constant, the increase in the interest rate increased the sales.',
    'suggestion.findings1370':
      'Depending on the density of the related areas remain constant while the number of people compared to the previous date range and density remained constant.',
    'suggestion.findings1371':
      'Although density was constant, sales increased.',
    'suggestion.findings1372':
      'The reason for this is the decrease in the number of people who spend 15 seconds or more, the area may be a fast and efficient sales area.',
    'suggestion.findings1380':
      'Depending on the density of the related areas remain constant while the number of people compared to the previous date range and density remained constant.',
    'suggestion.findings1381':
      'Although density was constant, sales increased.',
    'suggestion.findings1382':
      'The reason for this is the decrease in the number of people who spend 15 seconds or more, the area may be a fast and efficient sales area.',
    'suggestion.findings1390':
      'Depending on the density of the related areas remain constant while the number of people compared to the previous date range and density remained constant.',
    'suggestion.findings1391':
      'Although the interest rate increased while the density was constant, the sales in the area did not changepeople.',
    'suggestion.findings1392':
      'Accordingly, it is not significant for the customer to spend 15 seconds or more in the area to increase sales.',
    'suggestion.findings1393':
      'On the contrary, the increase in time may be due to staff shortages or customer inability to find what they are looking for, posing a threat to sales efficiency.',
    'suggestion.findings1400':
      'Depending on the density of the related areas remain constant while the number of people compared to the previous date range and density remained constant.',
    'suggestion.findings1401':
      'Although the interest rate decreased while the density was constant, the sales in the area did not changepeople.',
    'suggestion.findings1402':
      'If the sales rate was below the interest rate, the potential customer could not be converted into sales.',
    'suggestion.findings1410':
      'Depending on the density of the related areas remain constant while the number of people compared to the previous date range and density remained constant.',
    'suggestion.findings1411':
      'While the density and interest rate were constant, the sales in the area also remained constant.',
    'suggestion.findings1412':
      'If the sales rate was below the interest rate, the potential customer could not be converted into sales.',
    'suggestion.findings1420':
      'Depending on the density of the related areas remain constant while the number of people compared to the previous date range and density remained constant.',
    'suggestion.findings1421':
      'Density has increased interest rates steady, and decreased sales in the area.',
    'suggestion.findings1422':
      'The decrease in sales while the interest rate increases may indicate that the customer who spent a long time in the area could not find what they were looking for or left the area without buying due to the long waiting time.',
    'suggestion.findings1430':
      '90Depending on the density of the related areas remain constant while the number of people compared to the previous date range and density remained constant.',
    'suggestion.findings1431':
      'Density interest rate and decreased sales in the constant area.',
    'suggestion.findings1432':
      'Accordingly, the reduction in the number of people who had over 15 seconds and the area has a direct negative impact on sales.',
    'suggestion.findings1440':
      'Depending on the density of the related areas remain constant while the number of people compared to the previous date range and density remained constant.',
    'suggestion.findings1441':
      'Density and interest rate decreased sales in the constant area.',
    'suggestion.findings1450':
      'While the number of people in the area increased compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings1451':
      'Interest rate constant and time decreased sales by product in the area of art that can go get the product quickly.',
    'suggestion.findings1460':
      'While the number of people in the area increased compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings1461':
      'Since the time decreases as the person increases and the interest rate remains constant, the time spent by the customer in the area decreases.',
    'suggestion.findings1462':
      'This may be because the product has not attracted attention or the customer has passed this area due to a problem with the store planpeople.',
    'suggestion.findings1470':
      'While the number of people in the area increased compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings1480':
      'While the time in the  area increased compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings1481':
      'Since the sales increase while the interest rate in this area is constant, interest is not directly a factor in increasing sales.',
    'suggestion.findings1482':
      'Accordingly, it is likely that quick sell products are sold in this area.',
    'suggestion.findings1490':
      'While the time in the area increased compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings1500':
      'While the time in the area increased compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings1501':
      'Since the rate of interest in this area is constant and sales decrease, the interest does not directly affect the sales.',
    'suggestion.findings1510':
      'In the area, the density decreased due to the decrease in both the number of people and the time spent compared to the previous date range.',
    'suggestion.findings1511':
      'According to interest rate steady, and time decreased sales increases, while factor in this area is not directly effective.',
    'suggestion.findings1512':
      'In the area, a product group may be sold to get faster.',
    'suggestion.findings1520':
      'In the area, the density decreased due to the decrease in both the number of people and the time spent compared to the previous date range.',
    'suggestion.findings1521':
      'Interest rate in this area has remained constant.',
    'suggestion.findings1530':
      'In the area, the density decreased due to the decrease in both the number of people and the time spent compared to the previous date range.',
    'suggestion.findings1531':
      'Both the density and sales in the area decreased and the interest rate remained constant.',
    'suggestion.findings1532':
      'Accordingly, the worst possible scenario has come true.',
    'suggestion.findings1540':
      'While the time was constant in the  area compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings1541':
      'Staff numbers, product prices are optimal balance in this area.',
    'suggestion.findings1542': 'The area is increasing sales productivity.',
    'suggestion.findings1550':
      'While the time was constant in the  area compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings1551':
      'Since the rate of interest in this area decreases and sales increase,  interest is not a factor in increasing salespeople.',
    'suggestion.findings1552':
      'Accordingly, it is likely that quick sell products are sold in this area.',
    'suggestion.findings1560':
      'While the time was constant in the  area compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings1561':
      'Since the sales increase while the interest rate in this area is constant, interest is not directly a factor in increasing sales.',
    'suggestion.findings1562':
      'Accordingly, it is likely that quick sell products are sold in this area.',
    'suggestion.findings1570':
      'While the time was constant in the  area compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings1580':
      'While the time was constant in the  area compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings1581':
      'Since the interest rate in this area is constant and the sales also remain constant, interest (spending 15 seconds or more in the field) is not a direct factor in increasing sales.',
    'suggestion.findings1582':
      'Accordingly, it is likely that quick sell products are sold in this area.',
    'suggestion.findings1590':
      'While the time was constant in the  area compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings1600':
      'While the time remained constant in the area compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings1610':
      'While the time was constant in the  area compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings1620':
      'While the time was constant in the  area compared to the previous date range, the density decreased due to the decrease in the number of people.',
    'suggestion.findings1621':
      'Since the rate of interest in this area is constant and sales decrease, the interest does not directly affect the sales.',
    'suggestion.findings1630':
      'While the number of people in the area was constant compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings1631':
      'Interest rate also increased compared to the previous date range.',
    'suggestion.findings1632':
      'As the number of people was constant and the interest rate increased, the number of customers coming and passing from the area increased significantly as the time decreased.',
    'suggestion.findings1640':
      'While the number of people in the area was constant compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings1641':
      'Interest rate increases and decreases hard time selling that based products in the area, can go get the product quickly.',
    'suggestion.findings1650':
      'While the number of people in the area was constant compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings1651':
      'Since the amount of sales increases with the interest rate and time constant, the product in the area can be a fast buy and go product.',
    'suggestion.findings1660':
      'While the number of people in the area was constant compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings1661':
      'As the number of people remained constant and the time decreased while the interest rate increased, most of the people coming to the area started to spend much less time in the area',
    'suggestion.findings1670':
      'While the number of people in the area was constant compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings1671':
      'Since the time and interest rate decreases when the person is constant, the time spent by the customer in the area decreases.',
    'suggestion.findings1672':
      'This may be because the product has not attracted attention or the customer has passed this area due to a problem with the store plan.',
    'suggestion.findings1680':
      'In the area, while the number of people was constant compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings1690':
      'In the area, while the number of people was constant compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings1700':
      'In the area, while the number of people was constant compared to the previous date range, the density decreased due to the decrease in time.',
    'suggestion.findings1710':
      'In the area, while the number of people was constant compared to the previous date range, the density decreased due to the decrease in time.',

    'suggestion.comment550':
      'Further action should be taken to raise the sales target.',
    'suggestion.comment551':
      'In order to further increase sales, actions should be taken to ensure that customers spend more time in the area.',
    'suggestion.comment552':
      'For this; personnel interest, product price balance, product variety can be reviewed.',
    'suggestion.comment553':
      'In order to increase sales, action should be taken to ensure that customers who pass the field show interest in the area.',
    'suggestion.comment554':
      'Store plans be revised, staff interest can be increased, product range and  product price stability can be revised.',
    'suggestion.comment560':
      'Further action should be taken to raise the sales target.',
    'suggestion.comment561':
      'The interest rate has decreased compared to the previous date range.',
    'suggestion.comment562':
      'Although the rate of interest in this area has decreased, since the sales increase, the effect of the number of people on the sales is more than the time spent in this area.',
    'suggestion.comment570':
      'Action should be taken to ensure that customers who pass the area show interest in the area.',
    'suggestion.comment571':
      'Store plans be revised, staff interest can be increased, product range and  product price stability can be revised.',
    'suggestion.comment572':
      'Staff to increase the interest and / or would be expected to increase sales of the product supported by area information card.',
    'suggestion.comment580':
      'Since the interest rate has decreased compared to the previous date range, the reason why the increase in density does not affect the sales may be that the customer does not spend enough time in the area.',
    'suggestion.comment581':
      'Steps should be taken to ensure that the customer spends more time in the area in order to increase sales.',
    'suggestion.comment582':
      'Personnel interest, product range, store plan can be reviewedpeople.',
    'suggestion.comment590':
      'According to this; The reason why the increase in density does not affect the sales may be that the customer spends too much time in the area but cannot find what they want.',
    'suggestion.comment591':
      'In order to accelerate the customer and thus increase the sales; Personnel interest can be increased, the area can be supported with product information cards.',
    'suggestion.comment592':
      'If the product variety is thought to be much, it can be reduced.',
    'suggestion.comment600':
      'Steps should be taken to ensure that the customer spends more time in the area in order to increase sales.',
    'suggestion.comment601':
      'For this, the store area can be reviewed and the product range can be increased.',
    'suggestion.comment602':
      'While the number of people increased, the interest rate and time spent decreased, thus the number of personnel in this area may be insufficient.',
    'suggestion.comment610':
      'Further action should be taken to raise the sales target.',
    'suggestion.comment611':
      'In order to further increase sales, actions should be taken to ensure that customers spend more time in the area.',
    'suggestion.comment612':
      'For this; personnel interest, product price balance, product variety can be reviewed.',
    'suggestion.comment620':
      'Further action should be taken to raise the sales target.',
    'suggestion.comment621':
      'Despite the increase in density in this area, as the interest rate decreases, it is necessary to take action to increase the number of people coming to the area rather than the time spent to increase sales.',
    'suggestion.comment622':
      'For this, the store plan can be reviewed and a campaign can be made.',
    'suggestion.comment630':
      'The increase in time may be because the customer cannot find what they are looking for. The number of personnel and service quality should be examined.',
    'suggestion.comment640':
      'Since the rate of interest in the area decreases, the reason that the sales remain constant as the density increases may be the lack of personnel interest or the product variety is too much than it should be.',
    'suggestion.comment650':
      'Since the increase in the number of people plays a role in the density increase, the area can be supported with product information cards to accommodate the increased density.',
    'suggestion.comment651':
      'Number of staff or knowledge is critical in this area. Or, it may have too much product variety.',
    'suggestion.comment652':
      'Since the area cannot accommodate the increased density, an enlargement of the area may be consideredpeople.',
    'suggestion.comment660':
      'As sales are decreasing, it may be necessary for the customer to spend 15 seconds or more to purchase products in this area.',
    'suggestion.comment661':
      'The reason for the decrease in the interest rate and therefore the sales may be the insufficient attention of the personnel or the low product variety.',
    'suggestion.comment662':
      'Since the area cannot accommodate the increased density, an enlargement of the area may be consideredpeople.',
    'suggestion.comment670':
      'Since both the time and the interest rate increase in this area, the amount of sales also increases, so it is significant to ensure that the customer spends longer time in the area to increase sales even more.',
    'suggestion.comment671':
      'To increase the time; store plan, staff interest, product price balance, product variety can be reviewed.',
    'suggestion.comment680':
      "Despite the decrease in the number of people coming to the area and the number of interested people, as the sales increase, the personnel's interest in this area with the customer for a very short time immediately affects the customers' to shopping.",
    'suggestion.comment681':
      'Or customers who are conscious and know what they are looking for come to the area and have no trouble understanding the product range.',
    'suggestion.comment690':
      'The reason why the customer starts to spend a longer time in the area may be the lack of personnel or the increased product variety.',
    'suggestion.comment700':
      'Action must be taken to accelerate the customers.',
    'suggestion.comment701':
      'The reason why the customer starts to spend a longer time in the area may be the lack of personnel or the increased product variety.',
    'suggestion.comment710':
      'Urgently need to take action to accelerate customers.',
    'suggestion.comment711':
      'For this, the number of staff and quality of service should be questioned. Also, reducing product variety can be consideredpeople.',
    'suggestion.comment720':
      'However, as the time increases and sales decrease, action may be required to speed up the customer.',
    'suggestion.comment721':
      'For this, the number of personnel and their interest and product variety should be reviewed.',
    'suggestion.comment730':
      'If the number of people can be increased without decreasing the sales, the potential sales in the area will increase.',
    'suggestion.comment740':
      'If the time can be reduced and the number of people increased without decreasing the sales, the potential sales in the area will increase even more.',
    'suggestion.comment750':
      'The more time the customer spends in the area, the more sales are expected to increase.',
    'suggestion.comment751':
      ' If time continues to decrease, and this will pose a threat to sales.',
    'suggestion.comment752':
      'The reason why sales do not increase while the rate of interest increases may be that the customer cannot find what they are looking for.for.',
    'suggestion.comment753': ' Product diversity should be revised.',
    'suggestion.comment760':
      'Number of customers and to increase the amount of time, it will increase sales efficiency.',
    'suggestion.comment761':
      'Since the interest rate decreases and the sales do not decrease, the customer may not need to spend too long in the area to purchase products from this area.',
    'suggestion.comment770':
      "Although the interest rate increased, the decrease in the time spent had a negative effect on the sales.customers who spend 15 seconds or more in this area are expected to buy. The time spent to increase sales must be long enough to give importance to. The store plans be revised to ensure that the customer's spend longer in the area. Staff interest can be increased.",
    'suggestion.comment780':
      'In order to increase sales in this area, care must be taken to ensure that the time is sufficient.',
    'suggestion.comment781':
      "The store plans be revised to ensure that the customer's spend longer in the area. Staff interest can be increased.",
    'suggestion.comment790':
      'As the number of people who spend 15 seconds or more in the area increases, sales are expected to increase.',
    'suggestion.comment791':
      ' Therefore, in order to increase the sales even more, it is necessary to pay attention not to decrease the time while increasing the number of people.',
    'suggestion.comment800':
      'Increasing the number of customers will increase the sales even more.',
    'suggestion.comment810':
      "The increase in time may be due to staff shortages or the customers' inability to find what they are looking for and pose a threat to sales efficiency.",
    'suggestion.comment811':
      'In this area, it is necessary to take action to increase the number of people in order to increase sales.',
    'suggestion.comment812':
      'For this, the product-price balance can be reviewed. Campaigns can be made in the area.',
    'suggestion.comment820':
      'In this area, it is necessary to take action to increase the number of people and not lose the number of interested customers in order to increase sales.',
    'suggestion.comment821':
      ' For this, the product-price balance can be reviewed. Campaigns can be made in the area.',
    'suggestion.comment830':
      'In this area, to increase sales and to increase the number of people you need to take action to reduce the time spent.',
    'suggestion.comment831':
      'For this, the interest and number of personnel should be increased. Supporting the area with product information cards is also expected to reduce the time spent.',
    'suggestion.comment840':
      'In this area with sales potential, it is necessary to take action to increase the number of people in order not to lose customer interest and to increase salespeople.',
    'suggestion.comment841':
      'It is very significant to pay attention not to decrease the time while the number of people increases.',
    'suggestion.comment842':
      'For this, the interest and number of personnel should be increased. Supporting the area with product information cards is also expected to reduce the time spent.',
    'suggestion.comment850':
      'Increasing the interest rate is expected to have a positive effect on sales.',
    'suggestion.comment851':
      'Increasing staff support is expected to increase the interest rate.',
    'suggestion.comment860':
      'The percentage increase in the number of people is expected to increase the sales at the same rate.',
    'suggestion.comment870':
      'Although it is an area where fast sales occur, it should be analyzed if the sales do not show the expected increase.',
    'suggestion.comment880':
      'Staff interest can also be increased to increase the time the customer spends in the area.',
    'suggestion.comment890':
      'If price changes compared to the previous date range of the product concerned may be coming to the new price is expensive.',
    'suggestion.comment891':
      'If the product is a product that requires details, the customer may be waiting for a staff contact.',
    'suggestion.comment892':
      'If a change is made in the number of staff compared to the previous date range, the area is directly affected by this change.',
    'suggestion.comment900':
      'Since the decrease in the interest rate and the time spent negatively affects the sale, action should be taken to increase the time spent.',
    'suggestion.comment901':
      'If the time is increased, the interest rate and the sales will increase accordingly.',
    'suggestion.comment902':
      ' If the product price changes made in the related area may be coming to new customers is expensive prices.',
    'suggestion.comment910':
      'In order to increase sales in this area, action should be taken to accelerate the customer.',
    'suggestion.comment920':
      'The most effective parameter to increase the number of people and therefore sales in this area is price.',
    'suggestion.comment930':
      'The length of time may be a threat, since the interest rate and time increase in this area and the sales do not change.',
    'suggestion.comment931':
      'If preventions are not taken to reduce the time, the length of time in the next period may cause a decrease in sales.',
    'suggestion.comment932':
      'The reason of the increase may be due to inability to find the time that customers are looking for.',
    'suggestion.comment933':
      'Increasing the number of people is expected to increase sales.',
    'suggestion.comment934':
      'Price is the main factor to increase the number of people.',
    'suggestion.comment940':
      'Price is the main factor in increasing the number of people.',
    'suggestion.comment950':
      'As the interest rate and time increase, sales decrease, so the length of time may be a threat.',
    'suggestion.comment951':
      'Price is the main factor in increasing the number of people.',
    'suggestion.comment952':
      'If the decrease in the number of people in this area is parallel to the decrease in the number of customers in the store, the marketing activities in the store should be reworked.',
    'suggestion.comment960':
      'Price is the main factor in increasing the number of people.',
    'suggestion.comment961':
      'If the decrease in the number of people in this area is parallel to the decrease in the number of customers in the store, the marketing activities in the store should be reworked.',
    'suggestion.comment970':
      'It is necessary to take action to increase the interest rate even more than the number of people coming.',
    'suggestion.comment971': 'Personnel interest should not be overlooked.',
    'suggestion.comment980':
      'If a product group in the area to get quick attention should be paid in stock. Customers come to the area will create problems unable to find items.',
    'suggestion.comment981':
      'If the price changes made by the previous date range, new price policy has worked.',
    'suggestion.comment990':
      'Accordingly, it is observed that while there was a very high potential in the previous period, it could not be utilized enough.',
    'suggestion.comment1000':
      'The decrease in person and time did not change the sale, which indicates that the potential could not be utilized in the previous period.',
    'suggestion.comment1010':
      'Staff need to pay attention to the relationship with the customer.',
    'suggestion.comment1020':
      'Area should be analyzed in depth and must be taken up urgently the action.',
    'suggestion.comment1030':
      'Further action should be taken to raise the sales target.',
    'suggestion.comment1031':
      'The number of people coming to the area to further increase sales should be increased.',
    'suggestion.comment1032':
      'For this; store plans and campaigns can be reviewed.',
    'suggestion.comment1040':
      'Since the interest rate remained constant compared to the previous date range, the reason that the increase in density does not affect the sales may be that the customer does not spend enough time in the area.',
    'suggestion.comment1041':
      'Steps should be taken to ensure that the customer spends more time in the area in order to increase sales.',
    'suggestion.comment1050':
      'Since the interest rate remained constant compared to the previous date range, the reason that the increase in density does not affect the sales may be that the customer does not spend enough time in the area.',
    'suggestion.comment1051':
      'Steps should be taken to ensure that the customer spends more time in the area in order to increase sales.',
    'suggestion.comment1052':
      'For this, the store can be reviewed and the product range can be increased.',
    'suggestion.comment1053':
      'Number of people that interest rate increases and decreases relative to the number of staff time spent in this area may be inadequate.',
    'suggestion.comment1060':
      'Further action should be taken to raise the sales target.',
    'suggestion.comment1061':
      'This area corresponds to the interest rate increase in density by constant sales has increased to increase sales to increase the number of people coming into the area rather than time spent must take action.',
    'suggestion.comment1062':
      'For this, the store plan can be reviewed and a campaign can be made.',
    'suggestion.comment1070':
      'Since the rate of interest in the field does not increase but remains constant, the reason for the fact that the sales remain constant while the density increases may be the lack of personnel interest or the product variety is too much than it should be.',
    'suggestion.comment1080':
      'According to sales declined, the reason for the increase in parallel with an increase in the density of the sales in this area can remain constant rate of interest.',
    'suggestion.comment1081':
      'Staff interest and product diversity can be reviewed to increase the interest rate and therefore sales.',
    'suggestion.comment1082':
      'Since the area cannot accommodate the increased density, an enlargement of the area may be consideredpeople.',
    'suggestion.comment1090':
      "That a decrease in the number of people coming into the area and despite the increased sales to remain constant based on the number of people interested in very little time even to deal with the customer's staff in this area to act immediately to turn to the customer's shopping.",
    'suggestion.comment1091':
      'Or customers who are conscious and know what they are looking for come to the area and have no trouble understanding the product range.',
    'suggestion.comment1100':
      'If time continues to increase, it may be adversely affected sales in the next stage.',
    'suggestion.comment1101':
      'Action must be taken to accelerate the customers.',
    'suggestion.comment1102':
      'The reason why the customer starts to spend a longer time in the area may be the lack of personnel or the increased product variety.',
    'suggestion.comment1110':
      'However, as the time increases and sales decrease, action may be required to speed up the customer.',
    'suggestion.comment1111':
      'For this, the number of personnel and their interest and product variety should be reviewed.',
    'suggestion.comment1120':
      'Further action should be taken to raise the sales target.',
    'suggestion.comment1121':
      'In order to further increase sales, actions should be taken to ensure that customers spend more time in the area.',
    'suggestion.comment1122':
      'For this; personnel interest, product price balance, product variety can be reviewed.',
    'suggestion.comment1130':
      'Further action should be taken to raise the sales target.',
    'suggestion.comment1131':
      'The number of people coming to the area to further increase sales should be increased.',
    'suggestion.comment1132':
      'For this; store plans and campaigns can be reviewed.',
    'suggestion.comment1140':
      'Further action should be taken to raise the sales target.',
    'suggestion.comment1141':
      'The number of people coming to the area to further increase sales should be increased.',
    'suggestion.comment1142':
      'For this; store plans and campaigns can be reviewed.',
    'suggestion.comment1150':
      'Since the interest rate has increased compared to the previous date range, the reason why the increase in density does not affect the sales may be that the customer spends too much time in the field but cannot find what they want.',
    'suggestion.comment1151':
      'In order to accelerate the customer and thus increase the sales; staff interest can be increased, the area can be supported with product information cards, and product variety can be reduced.',
    'suggestion.comment1160':
      'Since the interest rate has decreased compared to the previous date range, the reason why the increase in density does not affect the sales may be that the customer does not spend enough time in the area.',
    'suggestion.comment1161':
      'Steps should be taken to ensure that the customer spends more time in the area in order to increase sales.',
    'suggestion.comment1170':
      'Since the interest rate remained constant compared to the previous date range, the reason that the increase in density does not affect the sales may be that the customer does not spend enough time in the area.',
    'suggestion.comment1171':
      'Steps should be taken to ensure that the customer spends more time in the area in order to increase sales.',
    'suggestion.comment1180':
      'In order to accelerate the customer and thus increase the sales; staff interest can be increased, the relevant area can be supported with product information cards.',
    'suggestion.comment1181':
      'If the product variety is thought to be much, it can be reduced.',
    'suggestion.comment1190':
      'Since the interest rate has decreased compared to the previous date range, the reason why the increase in density does not affect the sales may be that the customer does not spend enough time in the area.',
    'suggestion.comment1191':
      'Steps should be taken to ensure that the customer spends more time in the area in order to increase sales.',
    'suggestion.comment1192':
      'For this, the store can be reviewed and the product range can be increased.',
    'suggestion.comment1193':
      'Number of people that interest rate increases and decreases relative to the number of staff time spent in this area may be inadequate.',
    'suggestion.comment1200':
      'Since the interest rate remained constant compared to the previous date range, the reason that the increase in density does not affect the sales may be that the customer does not spend enough time in the area.',
    'suggestion.comment1201':
      'Steps should be taken to ensure that the customer spends more time in the area in order to increase sales.',
    'suggestion.comment1202':
      'For this, the store can be reviewed and the product range can be increased.',
    'suggestion.comment1203':
      'Number of people that interest rate increases and decreases relative to the number of staff time spent in this area may be inadequate.',
    'suggestion.comment1210':
      'Since both the time and the interest rate increase in this area, the amount of sales also increases, so it is significant to ensure that the customer spends longer time in the area to increase sales even more.',
    'suggestion.comment1211':
      'To increase the time; store plan, staff interest, product price balance, product variety can be reviewed.',
    'suggestion.comment1220':
      'Further action should be taken to raise the sales target.',
    'suggestion.comment1221':
      'The number of people coming to the area to further increase sales should be increased.',
    'suggestion.comment1222':
      'For this; store plans and campaigns can be reviewed.',
    'suggestion.comment1230':
      'Further action should be taken to raise the sales target.',
    'suggestion.comment1231':
      'The number of people coming to the area to further increase sales should be increased.',
    'suggestion.comment1232':
      'For this; store plans and campaigns can be reviewed.',
    'suggestion.comment1240':
      'In order to accelerate the customer and thus increase the sales; staff interest can be increased, the area can be supported with product information cards, and product variety can be reduced.',
    'suggestion.comment1250':
      'Steps should be taken to ensure that the customer spends more time in the area in order to increase sales.',
    'suggestion.comment1260':
      'Steps should be taken to ensure that the customer spends more time in the area in order to increase sales.',
    'suggestion.comment1270':
      'In order to accelerate the customer and thus increase the sales; staff interest can be increased, the relevant area can be supported with product information cards.',
    'suggestion.comment1271':
      'If the product variety is thought to be much, it can be reduced.',
    'suggestion.comment1280':
      'Steps should be taken to ensure that the customer spends more time in the area in order to increase sales.',
    'suggestion.comment1281':
      'For this, the store can be reviewed and the product range can be increased.',
    'suggestion.comment1282':
      'Number of people that interest rate increases and decreases relative to the number of staff time spent in this area may be inadequate.',
    'suggestion.comment1290':
      'According to increased sales decreased time of action may be necessary to accelerate the customer.',
    'suggestion.comment1291':
      'For this, the number of personnel and their interest and product variety should be reviewed.',
    'suggestion.comment1300':
      'If the time can be reduced and the number of people increased without decreasing the sales, the potential sales in the area will increase even more.',
    'suggestion.comment1310':
      'Number of customers and to increase the amount of time, it will increase sales efficiency.',
    'suggestion.comment1311':
      "According to interest rate steady, sales fall, so long in the customer's area to purchase products from this area may not need to spend.",
    'suggestion.comment1320':
      'In order to increase sales in this area, care must be taken to ensure that the time is sufficient.',
    'suggestion.comment1321':
      "The store plans be revised to ensure that the customer's spend longer in the area. Staff interest can be increased.",
    'suggestion.comment1330':
      'Increasing the number of customers will increase the sales even more.',
    'suggestion.comment1340':
      'In this area, it is necessary to take action to increase the number of people and not lose the number of interested customers in order to increase sales.',
    'suggestion.comment1341':
      'For this, the product-price balance can be reviewed. Campaigns can be made in the area.',
    'suggestion.comment1350':
      'It is significant to attract more customers to the area in order to increase the sales potential in the area.',
    'suggestion.comment1360':
      'As the number of people who spend 15 seconds or more in the area increases, sales are expected to increase.',
    'suggestion.comment1361':
      'Therefore, in order to increase the sales even more, it is necessary to pay attention not to decrease the time while increasing the number of people.',
    'suggestion.comment1370':
      'Increasing the number of customers will increase the sales even more.',
    'suggestion.comment1380':
      'Increasing the number of customers will increase the sales even more.',
    'suggestion.comment1390':
      'In this area, it is necessary to take action to increase the number of people in order to increase sales.',
    'suggestion.comment1391':
      'For this, the product-price balance can be reviewed. Campaigns can be made in the area.',
    'suggestion.comment1400':
      'In this area, it is necessary to take action to increase the number of people and not lose the number of interested customers in order to increase sales.',
    'suggestion.comment1401':
      'For this, the product-price balance can be reviewed. Campaigns can be made in the area.',
    'suggestion.comment1410':
      '8n this area, it is necessary to take action to increase the number of people and not lose the number of interested customers in order to increase sales.',
    'suggestion.comment1411':
      'For this, the product-price balance can be reviewed. Campaigns can be made in the area.',
    'suggestion.comment1420':
      'In this area, to increase sales and to increase the number of people you need to take action to reduce the time spent.',
    'suggestion.comment1421':
      'For this, the interest and number of personnel should be increased. Supporting the area with product information cards is also expected to reduce the time spent.',
    'suggestion.comment1430':
      'In this area with sales potential, it is necessary to take action to increase the number of people in order not to lose customer interest and to increase salespeople.',
    'suggestion.comment1431':
      'It is very significant to pay attention not to decrease the time while the number of people increases.',
    'suggestion.comment1432':
      'For this, the interest and number of personnel should be increased. Supporting the area with product information cards is also expected to increase customer interest.',
    'suggestion.comment1440':
      'It is significant to attract more customers to the area in order to increase the sales potential in the area.',
    'suggestion.comment1450':
      'The percentage increase in the number of people is expected to increase the sales at the same rate.',
    'suggestion.comment1460':
      'Staff interest can also be increased to increase the time the customer spends in the area.',
    'suggestion.comment1470':
      'Since the decrease in the time spent while the interest rate is constant negatively affects the sales, action should be taken to increase the time spent.',
    'suggestion.comment1471':
      'Since interest rate is increased and sales will increase accordingly.',
    'suggestion.comment1472':
      'If the product price changes made in the related area may be coming to new customers is expensive prices.',
    'suggestion.comment1480':
      'The most effective parameter to increase the number of people and therefore sales in this area is price.',
    'suggestion.comment1490':
      'Since the rate of interest in this area is constant and the sales remain constant, the rate of interest may be determinant in increasing sales.',
    'suggestion.comment1491':
      'In order to ensure that the customer shows interest, the area can be supported with product information cards, and personnel interest can be increased.',
    'suggestion.comment1500':
      'To increase sales, it is necessary to focus on increasing the number of people.',
    'suggestion.comment1501':
      'Price is the main factor in increasing the number of people.',
    'suggestion.comment1502':
      'If the decrease in the number of people in this area is parallel to the decrease in the number of customers in the store, the marketing activities in the store should be reworked.',
    'suggestion.comment1510':
      'If a product group in the area to get quick attention should be paid in stock. Customers come to the area will create problems unable to find items.',
    'suggestion.comment1511':
      'If the price changes made by the previous date range, new price policy has worked.',
    'suggestion.comment1520':
      'The decrease in person and time did not change the sale, which indicates that the potential could not be utilized in the previous period.',
    'suggestion.comment1530':
      'Related areas should be analyzed in depth and must be taken up urgently the action.',
    'suggestion.comment1540':
      'In order to increase sales in this area, action should be taken to accelerate the customer.',
    'suggestion.comment1550':
      'The most effective parameter to increase the number of people and therefore sales in this area is price.',
    'suggestion.comment1560':
      'The most effective parameter to increase the number of people and therefore sales in this area is price.',
    'suggestion.comment1570':
      'Interest rate in this area by that time constant and increased sales change, the length of time can be a threat.',
    'suggestion.comment1571':
      'If preventions are not taken to reduce the time, the length of time in the next period may cause a decrease in sales.',
    'suggestion.comment1572':
      'The reason of the increase may be due to inability to find the time that customers are looking for.',
    'suggestion.comment1573':
      'Increasing the number of people is expected to increase sales.',
    'suggestion.comment1574':
      'Price is the main factor to increase the number of people.',
    'suggestion.comment1580':
      'Price is the main factor in increasing the number of people.',
    'suggestion.comment1590':
      'Since the rate of interest in this area is constant and the sales remain constant, the rate of interest may be determinant in increasing sales.',
    'suggestion.comment1591':
      'In order to ensure that the customer shows interest, the area can be supported with product information cards, and personnel interest can be increased.',
    'suggestion.comment1600':
      'Since the amount of sales decreases while the time is constant and the interest rate increases in this area, the increase in the time may pose a threat in the futurepeople.',
    'suggestion.comment1601':
      'Price is the main factor in increasing the number of people.',
    'suggestion.comment1602':
      'If the decrease in the number of people in this area is parallel to the decrease in the number of customers in the store, the marketing activities in the store should be reworked.',
    'suggestion.comment1610':
      'Since the rate of interest in this area decreases and sales also decrease, the interest affects salespeople.',
    'suggestion.comment1611':
      'Price is the main factor in increasing the number of people.',
    'suggestion.comment1612':
      'If the decrease in the number of people in this area is parallel to the decrease in the number of customers in the store, the marketing activities in the store should be reworked.',
    'suggestion.comment1620':
      'To increase sales, it is necessary to focus on increasing the number of people.',
    'suggestion.comment1621':
      'Price is the main factor in increasing the number of people.',
    'suggestion.comment1622':
      'If the decrease in the number of people in this area is parallel to the decrease in the number of customers in the store, the marketing activities in the store should be reworked.',
    'suggestion.comment1630':
      'Increasing the interest rate is expected to have a positive effect on sales.',
    'suggestion.comment1631':
      'Increasing staff support is expected to increase the interest rate.',
    'suggestion.comment1640':
      'The percentage increase in the number of people is expected to increase the sales at the same rate.',
    'suggestion.comment1650':
      'The percentage increase in the number of people is expected to increase the sales at the same rate.',
    'suggestion.comment1660':
      'Although it is an area where fast sales occur, it should be analyzed if the sales do not show the expected increase.',
    'suggestion.comment1670':
      'Staff interest can also be increased to increase the time the customer spends in the area.',
    'suggestion.comment1680':
      'Since the rate of interest in this area is constant and the sales remain constant, the rate of interest may be determinant in increasing sales.',
    'suggestion.comment1681':
      'In order to ensure that the customer shows interest, the area can be supported with product information cards, and personnel interest can be increased.',
    'suggestion.comment1690':
      'Interested customers are expected to purchase the product.',
    'suggestion.comment1691':
      'If price changes compared to the previous date range of the product concerned may be coming to the new price is expensive.',
    'suggestion.comment1692':
      'If the product is a product that requires details, the customer may be waiting for a staff contact.',
    'suggestion.comment1693':
      'If a change is made in the number of staff compared to the previous date range, the area is directly affected by this change.',
    'suggestion.comment1700':
      'Since the decrease in the interest rate and the time spent negatively affects the sale, action should be taken to increase the time spent.',
    'suggestion.comment1701':
      'Since interest rate is increased and sales will increase accordingly.',
    'suggestion.comment1702':
      'If the product price changes made in the related area may be coming to new customers is expensive prices.',
    'suggestion.comment1710':
      'Since the decrease in the time spent while the interest rate is constant negatively affects the sales, action should be taken to increase the time spent.',
    'suggestion.comment1711':
      'Since interest rate is increased and sales will increase accordingly.',
    'suggestion.comment1712':
      'If the product price changes made in the related area may be coming to new customers is expensive prices.'
  },
  tr: {
    'suggestion.findings550':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings551':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden bir kişinin alanda geçirdiği ortalama süre azalırken alana gelen kişi sayısının artmasıdır. ',
    'suggestion.findings552':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin çok yüksek olduğunu gösterir. ',
    'suggestion.findings553':
      'Önceki tarih aralığına göre ilgi oranı da artmıştır. ',
    'suggestion.findings554':
      'Bu alanda ilgi oranı ve yoğunluk artarken satış da arttığına göre bu alanda geçirilen sürenin satışa olan etkisi büyüktür. ',
    'suggestion.findings555':
      'Önceki tarih aralığına göre geçirilen sürenin azalmasına rağmen ilgi oranı arttığına göre alana gelen ziyaretçilerin bir kısmı alanda hiç vakit geçirmeden alanı pas geçmeye başlamıştır. ',
    'suggestion.findings556':
      'Bu sırada, alanda 15 saniye ve üzeri zaman geçiren kişi sayısı da artmış olduğuna göre satışları arttıran ziyaretçilerin, alanda 15 saniye ve üzeri süre geçirmiş olan ziyaretçiler olması muhtemeldir.',
    'suggestion.findings560':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings561':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden bir kişinin alanda geçirdiği ortalama süre azalırken alana gelen kişi sayısının artmasıdır. ',
    'suggestion.findings562':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin çok yüksek olduğunu gösterir.',
    'suggestion.findings563':
      'Önceki tarih aralığına göre ilgi oranı azalmıştır. ',
    'suggestion.findings564':
      'Bu alanda ilgi oranı azalmasına rağmen satış arttığına göre bu alanda geçirilen süreden ziyade kişi sayısının satışa olan etkisi daha fazladır.',
    'suggestion.findings570':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings571':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden bir kişinin alanda geçirdiği ortalama süre azalırken alana gelen kişi sayısının artmasıdır. ',
    'suggestion.findings572':
      'Yoğunluktaki artışın doğrudan satışı arttırması beklenir.',
    'suggestion.findings573':
      'Buna rağmen, ilgili alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit kalmış, alan potansiyel satışa erişme konusunda başarısız   olmuştur. ',
    'suggestion.findings574':
      'Önceki tarih aralığına göre geçirilen sürenin azalmasına rağmen ilgi oranı arttığına göre alana gelen ziyaretçilerin bir kısmı alanda hiç vakit geçirmeden alanı pas geçmeye başlamıştır.',
    'suggestion.findings575':
      'Bu sırada, alanda 15 saniye ve üzeri zaman geçiren kişi sayısı da arttığı için bunun satışı doğrudan arttırması beklenir.',
    'suggestion.findings576':
      'Buna rağmen satış değişmemiştir, yani alanda zaman geçiren kişiler de satın almamıştır.',
    'suggestion.findings580':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings581':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden bir kişinin alanda geçirdiği ortalama süre azalırken alana gelen kişi sayısının   artmasıdır. ',
    'suggestion.findings582':
      'Yoğunluktaki artışın doğrudan satışı arttırması beklenir. ',
    'suggestion.findings583':
      'Buna rağmen, ilgili alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit kalmış, alan potansiyel satışa erişme konusunda başarısız olmuştur.',
    'suggestion.findings590':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings591':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden bir kişinin alanda geçirdiği ortalama süre azalırken alana gelen kişi sayısının artmasıdır. ',
    'suggestion.findings592':
      'Bu alanda bir önceki tarih aralığına göre yoğunluk artarken satış azalmıştır.',
    'suggestion.findings593':
      'Buna göre, ilgili alanda fırsat olmasına rağmen potansiyel satışa erişme konusunda alan, başarısız olmuştur. ',
    'suggestion.findings594':
      'İlgi oranı ise bir önceki tarih aralığına göre artmıştır.',
    'suggestion.findings600':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings601':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden bir kişinin alanda geçirdiği ortalama süre azalırken alana gelen kişi sayısının artmasıdır. ',
    'suggestion.findings602':
      'Bu alanda bir önceki tarih aralığına göre yoğunluk artarken satış azalmıştır.',
    'suggestion.findings603':
      'Buna göre, ilgili alanda fırsat olmasına rağmen potansiyel satışa erişme konusunda alan, başarısız olmuştur. ',
    'suggestion.findings604':
      'Önceki tarih aralığına göre ilgi oranı da azaldığına göre, yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda yeteri kadar zaman geçirmemesi olabilir.',
    'suggestion.findings610':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin artmasına bağlı olarak yoğunluk artmıştır. ',
    'suggestion.findings611':
      'Bu artışın satışı doğrudan arttırması beklenir. ',
    'suggestion.findings612':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin çok yüksek olduğunu gösterir.',
    'suggestion.findings613':
      'Önceki tarih aralığına göre ilgi oranı da artmıştır. ',
    'suggestion.findings614':
      'Bu alanda igi oranı ve yoğunluk artarken satış da arttığına göre bu alanda geçirilen sürenin satışa olan etkisi büyüktür.',
    'suggestion.findings620':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin artmasına bağlı olarak yoğunluk artmıştır. ',
    'suggestion.findings621':
      'Bu artışın satışı doğrudan arttırması beklenir. ',
    'suggestion.findings622':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin çok yüksek olduğunu gösterir. ',
    'suggestion.findings623':
      'Önceki tarih aralığına göre ilgi oranı azalmıştır.',
    'suggestion.findings630':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin artmasına bağlı olarak yoğunluk artmıştır. ',
    'suggestion.findings631':
      'Bu artışın satışı doğrudan arttırması beklenirken, satış sabit kalmıştır. ',
    'suggestion.findings632':
      'Alandaki artan yoğunluk potansiyelinden yararlanılamamıştır. ',
    'suggestion.findings633':
      'Önceki tarih aralığına göre ilgi oranı da artmıştır. ',
    'suggestion.findings634':
      'Bu alanda igi oranı ve yoğunluk artarken sabit kaldığına göre bu alanda geçirilen sürenin artması satışa olumlu etki etmiyor olabilir.',
    'suggestion.findings640':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin artmasına bağlı olarak yoğunluk artmıştır. ',
    'suggestion.findings641':
      'Bu artışın satışı doğrudan arttırması beklenirken, satış sabit kalmıştır. ',
    'suggestion.findings642':
      'Alandaki artan yoğunluk potansiyelinden yararlanılamamıştır. ',
    'suggestion.findings643': 'Satışların azalmaması iyiye işarettir. ',
    'suggestion.findings644':
      'Çünkü sadece artan potansiyelden yararlanılamamış, önceki satış tutarı korunmaya devam etmiştir.',
    'suggestion.findings650':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin artmasına bağlı olarak yoğunluk artmıştır. ',
    'suggestion.findings651': 'Yoğunluk artmasına rağmen, satış azalmıştır. ',
    'suggestion.findings652':
      'İlgi oranı, geçirilen süre ve kişi sayısı artarken satış azaldığına göre ilgili alan, artan yoğunluğu karşılayacak altyapıya sahip değildir.',
    'suggestion.findings660':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin artmasına bağlı olarak yoğunluk artmıştır. ',
    'suggestion.findings661':
      'Kişi sayısı ve süre artarken alandaki ilgi oranı azalmıştır.',
    'suggestion.findings670':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings671':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden geçirilen sürenin artmasıdır. ',
    'suggestion.findings672':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin çok yüksek olduğunu gösterir.',
    'suggestion.findings680':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings681':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden geçirilen sürenin artmasıdır. ',
    'suggestion.findings682':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin ve satış verimliliğinin çok yüksek olduğunu gösterir.',
    'suggestion.findings690':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings691':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden geçirilen sürenin artmasıdır. ',
    'suggestion.findings692':
      'Yoğunluktaki artışın satışı arttırması beklenirken satış sabit kalmıştır. ',
    'suggestion.findings693':
      'Bu alandaki yoğunluk artışı süre artışından kaynaklandığına göre, bu alanda ziyaretçilerin daha uzun zaman harcaması satışlara olumlu etki etmemektedir. ',
    'suggestion.findings694':
      'Süre artmaya devam ederse, sonraki aşamada satışlar bundan olumsuz etkilenebilir.',
    'suggestion.findings700':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings701':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden geçirilen sürenin artmasıdır. ',
    'suggestion.findings702':
      'Yoğunluktaki artışın satışı arttırması beklenirken satış sabit kalmıştır. ',
    'suggestion.findings703':
      'Bu alandaki yoğunluk artışı süre artışından kaynaklandığına göre, bu alanda ziyaretçilerin daha uzun zaman harcaması satışlara olumlu etki etmemektedir. ',
    'suggestion.findings704':
      'Süre artmaya devam ederse, sonraki aşamada satışlar bundan olumsuz etkilenebilir.',
    'suggestion.findings710':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings711':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden geçirilen sürenin artmasıdır. ',
    'suggestion.findings712':
      'Süre artışıyla birlikte alanda 15 saniye ve üzeri duran kişi sayısı da artmıştır. ',
    'suggestion.findings713':
      'Buna göre, ziyaretçilerin büyük çoğunluğu alanda çok uzun süre geçirmeye başlamıştır. ',
    'suggestion.findings714':
      'Süre artarken satış azalmıştır. Yani, süre artışı satış üzerinde tehdit oluşturmaktadır.',
    'suggestion.findings720':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings721':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden geçirilen sürenin artmasıdır. ',
    'suggestion.findings722':
      'Süre artmasına rağmen, 15 saniye ve üzeri duran kişi sayısı azalmıştır. ',
    'suggestion.findings723':
      'Süre artmasına rağmen ilgi oranı azaldığına göre ziyaretçilerin alanda geçirdiği süre çok uzun değildir.',
    'suggestion.findings730':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına sabit kalmıştır.',
    'suggestion.findings731':
      'Bunun sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sayısı artarken sürenin azalmasıdır.',
    'suggestion.findings732':
      'İlgi oranı ve kişi sayısının artması, alandaki satışları da arttırmıştır. Alana, ilgili ziyaretçi gelmeye başlamıştır.',
    'suggestion.findings733': 'Alandaki satış potansiyeli yüksektir.',
    'suggestion.findings740':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre sabit kalmıştır. ',
    'suggestion.findings741':
      'Bunun sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sayısının artarken sürenin azalmasıdır. ',
    'suggestion.findings742':
      'Süre ve ilgi oranı azalıp kişi sayısı artarken satışlar arttığına göre bu alanda al-git bir ürün grubu bulunuyor olabilir. ',
    'suggestion.findings743':
      'Alanın satış verimliliği ve potansiyeli yükselmektedir.',
    'suggestion.findings750':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre sabit kalmıştır. ',
    'suggestion.findings751':
      'Bunun sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sayısının artarken sürenin azalmasıdır. ',
    'suggestion.findings752':
      'Kişi sayısı artıp süre azalırken satışlar da azaldığına göre bu alandan ürün satın almak için süre faktörü kritiktir.',
    'suggestion.findings760':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre sabit kalmıştır. ',
    'suggestion.findings761':
      'Bunun sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sayısının artarken sürenin azalmasıdır. ',
    'suggestion.findings762':
      'Süre azalmaya devam ederse, bu durum satışlar için tehdit oluşturabilir.',
    'suggestion.findings770':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre sabit kalmıştır. ',
    'suggestion.findings771':
      'Bunun sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sayısının artarken sürenin azalmasıdır. ',
    'suggestion.findings772':
      'Kişi sayısı da arttığına göre alandaki potansiyel yüksektir.',
    'suggestion.findings780':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre sabit kalmıştır. ',
    'suggestion.findings781':
      'Bunun sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sayısının artarken sürenin azalmasıdır. ',
    'suggestion.findings782':
      'Kişi sayısı da arttığına göre alandaki potansiyel yüksektir. ',
    'suggestion.findings783':
      'İlgi oranı ve sürenin azalması, satışa olumsuz etki etmiştir.',
    'suggestion.findings790':
      'İlgili alanda bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak yoğunluk sabit kalmıştır.',
    'suggestion.findings791':
      'Yoğunluk sabit olmasına rağmen, geçirilen süre ve ilgi oranındaki artış satışları arttırmıştır.',
    'suggestion.findings800':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak sabit kalmıştır.',
    'suggestion.findings801':
      'Yoğunluk sabit olmasına rağmen, geçirilen süredeki artış satışları arttırmıştır.',
    'suggestion.findings802':
      'İlgi oranı azalırken satış arttığına göre alan hızlı ve verimli bir satış alanıdır.',
    'suggestion.findings810':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak sabit kalmıştır.',
    'suggestion.findings811':
      'Yoğunluk sabitken ilgi oranı artmasına rağmen alandaki satış değişmemiştir.',
    'suggestion.findings812':
      'Buna göre, satışları arttırmak için ziyaretçinin alanda 15 saniye ve üzeri zaman geçirmesi kritik değildir.',
    'suggestion.findings820':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak sabit kalmıştır.',
    'suggestion.findings821':
      'Yoğunluk sabitken ilgi oranı azalmasına rağmen alandaki satış değişmemiştir. ',
    'suggestion.findings822':
      'Satış oranı ilgi oranının altında kaldıysa potansiyel ziyaretçi kaçmıştır.',
    'suggestion.findings830':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak sabit kalmıştır.',
    'suggestion.findings831':
      'Yoğunluk sabitken ilgi oranı artmış ve alandaki satış da azalmıştır.',
    'suggestion.findings832':
      'İlgi oranı artarken satışın azalması, alanda uzun süre geçiren ziyaretçinin aradığını bulamadığını veya uzun bekleme süresinden kaynaklı olarak satın almadan alanı terk ettiğini gösterir.',
    'suggestion.findings840':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak sabit kalmıştır.',
    'suggestion.findings841':
      'Yoğunluk sabitken ilgi oranı ve alandaki satış azalmıştır.',
    'suggestion.findings842':
      'Buna göre, alanda 15 saniye ve üzeri geçiren kişi sayısının azalması doğrudan satışa olumsuz etki etmiştir.',
    'suggestion.findings850':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı artarken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings851':
      'Önceki tarih aralığına göre artan kişi sayısına bağlı olarak ilgi oranı da artmıştır. ',
    'suggestion.findings852':
      'Kişi sayısı ve ilgi oranı artarken süre azaldığına göre alandan gelip geçen ziyaretçi sayısı da çok artmıştır.',
    'suggestion.findings860':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı artarken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings861':
      'İlgi oranı ve süre azalırken satış arttığına göre alandaki ürün, hızlı al git ürün olabilir.',
    'suggestion.findings870':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı artarken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings871':
      'Kişi sayısı artıp süre azalırken ilgi oranı arttırığına göre alana çok kişi gelmiştir.',
    'suggestion.findings872':
      'İlgi oranının artması beklenenin aksine satışa fayda etmemiştir.',
    'suggestion.findings880':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı artarken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings881':
      'Kişi artarken süre ve ilgi oranı azaldığına göre, ziyaretçinin alanda geçirdiği süre azdır.',
    'suggestion.findings882':
      'Bunun sebebi ürünün ilgi çekmemesi veya mağaza planından kaynaklı bir sorun nedeniyle ziyaretçinin alanı pas geçmesi olabilir.',
    'suggestion.findings890':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı artarken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings891':
      'İlgilenen ziyaretçinin ürün satın alması beklenir.',
    'suggestion.findings900':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı artarken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings910':
      'İlgili alanda bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings911':
      'Personel sayısı, ürün fiyat dengesi bu alanda optimum düzeydedir.',
    'suggestion.findings912': 'Alanın satış verimliliği artmaktadır.',
    'suggestion.findings920':
      'İlgili alanda bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings921':
      'Bu alanda ilgi oranı azalırken satış arttığına göre, satışların arttırılmasında ilgi etken değildir. ',
    'suggestion.findings922':
      'Buna göre bu alanda quick sell denilen hızlı al git ürünü satılıyor olması muhtemeldir.',
    'suggestion.findings930':
      'İlgili alanda bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings940':
      'İlgili alanda bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings941':
      'Bu alanda ilgi oranı azalırken satış sabit kaldığına göre, satışların arttırılmasında ilgi etken değildir. ',
    'suggestion.findings942':
      'Buna göre bu alanda quick sell denilen hızlı al git ürünü satılıyor olması muhtemeldir.',
    'suggestion.findings950':
      'İlgili alanda bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings960':
      'İlgili alanda bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings961':
      'Bu alanda ilgi oranı azalırken satış da azaldığına göre ilgi, satış üzerinde etkilidir.',
    'suggestion.findings970':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings971':
      'Kişi sayısı azalırken satış arttığına göre, neredeyse gelen her ziyaretçiye satış yapılmaktadır.',
    'suggestion.findings972':
      'Süre azalırken satış arttığına göre, alanda hızlı satış gerçekleşmektedir.',
    'suggestion.findings973':
      'Kişi sayısı azalıp ilgi oranı artarken süre de azalıyorsa alana gelen kişi sayısı çok fazladır.',
    'suggestion.findings980':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings981':
      'İlgi oranı ve süre azalırken satış arttığına göre, bu alanda süre faktörü etkisizdir. Alanda, hızlı al git bir ürün grubu satılıyor olabilir.',
    'suggestion.findings990':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin azalmasına bağlı olarak yoğunluk azalmıştır. ',
    'suggestion.findings991':
      'Bu alanda kişi ve sürenin azalması satışı değiştirmemiştir.',
    'suggestion.findings1000':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1010':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin azalmasına bağlı olarak yoğunluk azalmıştır. ',
    'suggestion.findings1011':
      'Bu alanda ilgili ziyaretçi sayısı artmasına rağmen satış artmamıştır.',
    'suggestion.findings1012':
      'Buna göre ziyaretçi ilgisi karşılıksız kalmış, alana gelip ilgi gösteren ziyaretçi kaçırılmıştır.',
    'suggestion.findings1020':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin azalmasına bağlı olarak yoğunluk azalmıştır. ',
    'suggestion.findings1021':
      'Alanda hem yoğunluk hem satış hem de ilgi oranı azalmıştır.',
    'suggestion.findings1022':
      'Buna göre, olabilecek en kötü senaryo gerçekleşmiştir.',
    'suggestion.findings1030':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1031':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sayısının artmasıdır. ',
    'suggestion.findings1032':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin çok yüksek olduğunu gösterir. ',
    'suggestion.findings1033':
      'Önceki tarih aralığına göre ilgi oranı sabit kalmıştır. ',
    'suggestion.findings1034':
      'Bu alanda ilgi oranı sabit kalmasına rağmen satış arttığına göre bu alanda geçirilen süreden ziyade kişi sayısının satışa olan etkisi daha fazladır.',
    'suggestion.findings1040':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1041':
      ' Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sayısının artmasıdır. ',
    'suggestion.findings1042':
      ' Yoğunluktaki artışın doğrudan satışı arttırması beklenir. ',
    'suggestion.findings1043':
      ' Buna rağmen, ilgili alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit kalmış, alan potansiyel satışa erişme konusunda başarısız olmuştur.',
    'suggestion.findings1050':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1051':
      ' Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sayısının artmasıdır. ',
    'suggestion.findings1052':
      ' Bu alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit azalmıştır. ',
    'suggestion.findings1053':
      ' Buna göre, ilgili alanda fırsat olmasına rağmen potansiyel satışa erişme konusunda alan, başarısız olmuştur.',
    'suggestion.findings1060':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin artmasına bağlı olarak yoğunluk artmıştır. ',
    'suggestion.findings1061':
      'Bu artışın satışı doğrudan arttırması beklenir. ',
    'suggestion.findings1062':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin çok yüksek olduğunu gösterir. ',
    'suggestion.findings1063':
      'Önceki tarih aralığına göre ilgi oranı sabit kalmıştır.',
    'suggestion.findings1070':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin artmasına bağlı olarak yoğunluk artmıştır. ',
    'suggestion.findings1071':
      'Bu artışın satışı doğrudan arttırması beklenirken, satış sabit kalmıştır. ',
    'suggestion.findings1072':
      'Alandaki artan yoğunluk potansiyelinden yararlanılamamıştır. ',
    'suggestion.findings1073': 'Satışların azalmaması iyiye işarettir. ',
    'suggestion.findings1074':
      'Çünkü sadece artan potansiyelden yararlanılamamış, önceki satış tutarı korunmaya devam etmiştir.',
    'suggestion.findings1080':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin artmasına bağlı olarak yoğunluk artmıştır. ',
    'suggestion.findings1081':
      'Kişi sayısı ve süre artarken alandaki ilgi oranı sabit kalmıştır.',
    'suggestion.findings1090':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1091':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden geçirilen sürenin artmasıdır. ',
    'suggestion.findings1092':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin ve satış verimliliğinin çok yüksek olduğunu gösterir.',
    'suggestion.findings1100':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1101':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden geçirilen sürenin artmasıdır. ',
    'suggestion.findings1102':
      'Yoğunluktaki artışın satışı arttırması beklenirken satış sabit kalmıştır. ',
    'suggestion.findings1103':
      'Bu alandaki yoğunluk artışı süre artışından kaynaklandığına göre, bu alanda ziyaretçilerin daha uzun zaman harcaması satışlara olumlu etki etmemektedir.',
    'suggestion.findings1110':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1111':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden geçirilen sürenin artmasıdır. ',
    'suggestion.findings1112':
      'Süre artmasına rağmen, 15 saniye ve üzeri duran kişi sayısı sabit kalmıştır. ',
    'suggestion.findings1113':
      'Süre artmasına rağmen ilgi oranı sabit kaldığına göre ziyaretçilerin alanda geçirdiği süre genelde 15 saniyenin üstünde değildir.',
    'suggestion.findings1120':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1121':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden  süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1122':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin çok yüksek olduğunu gösterir. ',
    'suggestion.findings1123':
      'Önceki tarih aralığına göre ilgi oranı da artmıştır. ',
    'suggestion.findings1124':
      'Bu alanda ilgi oranı ve yoğunluk artarken satış da arttığına göre bu alanda geçirilen sürenin satışa olan etkisi büyüktür.',
    'suggestion.findings1130':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1131':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1132':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin çok yüksek olduğunu gösterir. ',
    'suggestion.findings1133':
      'Önceki tarih aralığına göre ilgi oranı azalmıştır. ',
    'suggestion.findings1134':
      'Bu alanda ilgi oranı azalmasına rağmen satış arttığına göre bu alanda geçirilen süreden ziyade kişi sayısının satışa olan etkisi daha fazladır.',
    'suggestion.findings1140':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1141':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1142':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin çok yüksek olduğunu gösterir. ',
    'suggestion.findings1143':
      'Önceki tarih aralığına göre ilgi oranı sabit kalmıştır. ',
    'suggestion.findings1144':
      'Bu alanda ilgi oranı sabit kalmasına rağmen satış arttığına göre bu alanda geçirilen süreden ziyade kişi sayısının satışa olan etkisi daha fazladır.',
    'suggestion.findings1150':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1151':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden  süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1152':
      'Yoğunluktaki artışın doğrudan satışı arttırması beklenir.',
    'suggestion.findings1153':
      'Buna rağmen, ilgili alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit kalmış, alan potansiyel satışa erişme konusunda başarısız olmuştur.',
    'suggestion.findings1160':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1161':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1162':
      'Yoğunluktaki artışın doğrudan satışı arttırması beklenir. ',
    'suggestion.findings1163':
      'Buna rağmen, ilgili alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit kalmış, alan potansiyel satışa erişme konusunda başarısız olmuştur.',
    'suggestion.findings1170':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1171':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1172':
      'Yoğunluktaki artışın doğrudan satışı arttırması beklenir. ',
    'suggestion.findings1173':
      'Buna rağmen, ilgili alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit kalmış, alan potansiyel satışa erişme konusunda başarısız olmuştur.',
    'suggestion.findings1180':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1181':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1182':
      'Bu alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit kalmıştır. ',
    'suggestion.findings1183':
      'Buna göre, ilgili alanda fırsat olmasına rağmen potansiyel satışa erişme konusunda alan, başarısız olmuştur. ',
    'suggestion.findings1184':
      'İlgi oranı ise bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1185':
      'Buna göre; yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda çok fazla zaman geçirmesi fakat aradığını bulamaması olabilir.',
    'suggestion.findings1190':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1191':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1192':
      'Bu alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit azalmıştır. ',
    'suggestion.findings1193':
      'Buna göre, ilgili alanda fırsat olmasına rağmen potansiyel satışa erişme konusunda alan, başarısız olmuştur.',
    'suggestion.findings1200':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1201':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1202':
      'Bu alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit azalmıştır. ',
    'suggestion.findings1203':
      'Buna göre, ilgili alanda fırsat olmasına rağmen potansiyel satışa erişme konusunda alan, başarısız olmuştur.',
    'suggestion.findings1210':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1211':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sabitken geçirilen sürenin artmasıdır. ',
    'suggestion.findings1212':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin çok yüksek olduğunu gösterir.',
    'suggestion.findings1220':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1221':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1222':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin çok yüksek olduğunu gösterir. ',
    'suggestion.findings1223':
      'Önceki tarih aralığına göre ilgi oranı azalmıştır. ',
    'suggestion.findings1224':
      'Bu alanda ilgi oranı azalmasına rağmen satış arttığına göre bu alanda geçirilen süreden ziyade kişi sayısının satışa olan etkisi daha fazladır.',
    'suggestion.findings1230':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1231':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1232':
      'Yoğunluk artarken satışın da artması, alandaki potansiyelin çok yüksek olduğunu gösterir. ',
    'suggestion.findings1233':
      'Önceki tarih aralığına göre ilgi oranı sabit kalmıştır. ',
    'suggestion.findings1234':
      'Bu alanda ilgi oranı sabit kalmasına rağmen satış arttığına göre bu alanda geçirilen süreden ziyade kişi sayısının satışa olan etkisi daha fazladır.',
    'suggestion.findings1240':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1241':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1242':
      'Yoğunluktaki artışın doğrudan satışı arttırması beklenir.',
    'suggestion.findings1243':
      'Buna rağmen, ilgili alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit kalmış, alan potansiyel satışa erişme konusunda başarısız olmuştur. ',
    'suggestion.findings1244':
      'Önceki tarih aralığına göre ilgi oranı da arttığına göre, yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda çok fazla zaman geçirmesi fakat aradığını bulamaması olabilir.',
    'suggestion.findings1250':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1251':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1252':
      'Yoğunluktaki artışın doğrudan satışı arttırması beklenir. ',
    'suggestion.findings1253':
      'Buna rağmen, ilgili alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit kalmış, alan potansiyel satışa erişme konusunda başarısız olmuştur. ',
    'suggestion.findings1254':
      'Önceki tarih aralığına göre ilgi oranı da azaldığına göre, yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda yeteri kadar zaman geçirmemesi olabilir.',
    'suggestion.findings1260':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1261':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1262':
      'Yoğunluktaki artışın doğrudan satışı arttırması beklenir. ',
    'suggestion.findings1263':
      'Buna rağmen, ilgili alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit kalmış, alan potansiyel satışa erişme konusunda başarısız olmuştur. ',
    'suggestion.findings1264':
      'Önceki tarih aralığına göre ilgi oranı da sabit kaldığına göre, yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda yeteri kadar zaman geçirmemesi olabilir.',
    'suggestion.findings1270':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1271':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1272':
      'Bu alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit kalmıştır. ',
    'suggestion.findings1273':
      'Buna göre, ilgili alanda fırsat olmasına rağmen potansiyel satışa erişme konusunda alan, başarısız olmuştur. ',
    'suggestion.findings1274':
      'İlgi oranı ise bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1275':
      'Buna göre; yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda çok fazla zaman geçirmesi fakat aradığını bulamaması olabilir.',
    'suggestion.findings1280':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1281':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden süre sabitken kişi sayısının artmasıdır. ',
    'suggestion.findings1282':
      'Bu alanda bir önceki tarih aralığına göre yoğunluk artarken satış sabit azalmıştır. ',
    'suggestion.findings1283':
      'Buna göre, ilgili alanda fırsat olmasına rağmen potansiyel satışa erişme konusunda alan, başarısız olmuştur. ',
    'suggestion.findings1284':
      'Önceki tarih aralığına göre ilgi oranı da azaldığına göre, yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda yeteri kadar zaman geçirmemesi olabilir.',
    'suggestion.findings1290':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre artmıştır. ',
    'suggestion.findings1291':
      'Artışın sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sabitken geçirilen sürenin artmasıdır. ',
    'suggestion.findings1292':
      'Süre artmasına rağmen, 15 saniye ve üzeri duran kişi sayısı sabit kalmıştır. ',
    'suggestion.findings1293':
      'Süre artmasına rağmen ilgi oranı sabit kaldığına göre ziyaretçilerin alanda geçirdiği süre genelde 15 saniyenin üstünde değildir.',
    'suggestion.findings1300':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre sabit kalmıştır. ',
    'suggestion.findings1301':
      'Bunun sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sayısının artarken sürenin azalmasıdır. ',
    'suggestion.findings1302':
      'Süre azalıp ilgi oranı sabit kalırken ve kişi sayısı artarken satışlar arttığına göre bu alanda al-git bir ürün grubu bulunuyor olabilir. ',
    'suggestion.findings1303':
      'Alanın satış verimliliği ve potansiyeli yükselmektedir.',
    'suggestion.findings1310':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre sabit kalmıştır. ',
    'suggestion.findings1311':
      'Bunun sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sayısının artarken sürenin azalmasıdır. ',
    'suggestion.findings1312':
      'Süre azalmaya devam ederse, bu durum satışlar için tehdit oluşturabilir.',
    'suggestion.findings1320':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre sabit kalmıştır. ',
    'suggestion.findings1321':
      'Bunun sebebi, yoğunluğu oluşturan kişi ve süre parametrelerinden kişi sayısının artarken sürenin azalmasıdır. ',
    'suggestion.findings1322':
      'Kişi sayısı da arttığına göre alandaki potansiyel yüksektir. İlgi sabitken sürenin azalması, satışa olumsuz etki etmiştir.',
    'suggestion.findings1330':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak sabit kalmıştır.',
    'suggestion.findings1331':
      'Yoğunluk sabit olmasına rağmen, geçirilen süredeki artış satışları arttırmıştır.',
    'suggestion.findings1332':
      'İlgi oranı değişmezken satış arttığına göre alan hızlı ve verimli bir satış alanı olabilir.',
    'suggestion.findings1340':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak sabit kalmıştır.',
    'suggestion.findings1341':
      'Yoğunluk ve ilgi oranı sabitken alandaki satış da sabit kalmıştır. ',
    'suggestion.findings1342':
      'Satış oranı ilgi oranının altında kaldıysa potansiyel ziyaretçi kaçmıştır.',
    'suggestion.findings1350':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak sabit kalmıştır.',
    'suggestion.findings1351':
      'Yoğunluk ve ilgi oranı sabitken alandaki satış azalmıştır.',
    'suggestion.findings1352':
      'Buna göre, alanda kişi sayısının azalması doğrudan satışa olumsuz etki etmiştir.',
    'suggestion.findings1360':
      'İlgili alanda bir önceki tarih aralığına göre süre ve kişi sayısının sabit kalmasına bağlı olarak yoğunluk sabit kalmıştır.',
    'suggestion.findings1361':
      'Yoğunluk sabit olmasına rağmen, ilgi oranındaki artış satışları arttırmıştır.',
    'suggestion.findings1370':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre ve kişi sayısının sabit kalmasına bağlı olarak yoğunluk sabit kalmıştır.',
    'suggestion.findings1371':
      'Yoğunluk sabit olmasına rağmen, satış artmıştır.',
    'suggestion.findings1372':
      'Bunun sebebi, 15 saniye ve üzerinde zaman geçiren kişi sayısındaki azalma ise alan hızlı ve verimli bir satış alanı olabilir.',
    'suggestion.findings1380':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre ve kişi sayısının sabit kalmasına bağlı olarak yoğunluk sabit kalmıştır.',
    'suggestion.findings1381':
      'Yoğunluk sabit olmasına rağmen, satış artmıştır.',
    'suggestion.findings1382':
      'Bunun sebebi, 15 saniye ve üzerinde zaman geçiren kişi sayısındaki azalma ise alan hızlı ve verimli bir satış alanı olabilir.',
    'suggestion.findings1390':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre ve kişi sayısının sabit kalmasına bağlı olarak yoğunluk sabit kalmıştır. ',
    'suggestion.findings1391':
      'Yoğunluk sabitken ilgi oranı artmasına rağmen alandaki satış değişmemiştir.',
    'suggestion.findings1392':
      'Buna göre, satışları arttırmak için ziyaretçinin alanda 15 saniye ve üzeri zaman geçirmesi bu alan için çok kritik değildir.',
    'suggestion.findings1393':
      'Tam tersine, sürenin çok artması personel yetersizliğinden veya ziyaretçinin aradığını bulamamasından kaynaklanıp satış verimliliğinde tehdit oluşturuyor olabilir.',
    'suggestion.findings1400':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre ve kişi sayısının sabit kalmasına bağlı olarak yoğunluk sabit kalmıştır.',
    'suggestion.findings1401':
      'Yoğunluk sabitken ilgi oranı azalmasına rağmen alandaki satış değişmemiştir. ',
    'suggestion.findings1402':
      'Satış oranı ilgi oranının altında kaldıysa potansiyel ziyaretçi kaçmıştır.',
    'suggestion.findings1410':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre ve kişi sayısının sabit kalmasına bağlı olarak yoğunluk sabit kalmıştır.',
    'suggestion.findings1411':
      'Yoğunluk ve ilgi oranı sabitken alandaki satış da sabit kalmıştır. ',
    'suggestion.findings1412':
      'Satış oranı ilgi oranının altında kaldıysa potansiyel ziyaretçi kaçmıştır.',
    'suggestion.findings1420':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre ve kişi sayısının sabit kalmasına bağlı olarak yoğunluk sabit kalmıştır.',
    'suggestion.findings1421':
      'Yoğunluk sabitken ilgi oranı artmış ve alandaki satış da azalmıştır.',
    'suggestion.findings1422':
      'İlgi oranı artarken satışın azalması, alanda uzun süre geçiren ziyaretçinin aradığını bulamadığını veya uzun bekleme süresinden kaynaklı olarak satın almadan alanı terk ettiğini gösteriyor olabilir.',
    'suggestion.findings1430':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre ve kişi sayısının sabit kalmasına bağlı olarak yoğunluk sabit kalmıştır. ',
    'suggestion.findings1431':
      'Yoğunluk sabitken ilgi oranı ve alandaki satış azalmıştır.',
    'suggestion.findings1432':
      'Buna göre, alanda 15 saniye ve üzeri geçiren kişi sayısının azalması doğrudan satışa olumsuz etki etmiştir.',
    'suggestion.findings1440':
      'İlgili alanın yoğunluğu bir önceki tarih aralığına göre süre ve kişi sayısının sabit kalmasına bağlı olarak yoğunluk sabit kalmıştır. ',
    'suggestion.findings1441':
      'Yoğunluk ve ilgi oranı sabitken alandaki satış azalmıştır.',
    'suggestion.findings1450':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı artarken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1451':
      'İlgi oranı sabit ve süre azalırken satış arttığına göre alandaki ürün, hızlı al git ürün olabilir.',
    'suggestion.findings1460':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı artarken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1461':
      'Kişi artarken süre azaldığına ve ilgi oranı sabit kaldığına göre ziyaretçinin alanda geçirdiği süre azalmaktadır.',
    'suggestion.findings1462':
      'Bunun sebebi ürünün ilgi çekmemesi veya mağaza planından kaynaklı bir sorun nedeniyle ziyaretçinin alanı pas geçmesi olabilir.',
    'suggestion.findings1470':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı artarken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1480':
      'İlgili alanda bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1481':
      'Bu alanda ilgi oranı sabitken satış arttığına göre, satışların arttırılmasında ilgi direkt olarak etken değildir. ',
    'suggestion.findings1482':
      'Buna göre bu alanda quick sell denilen hızlı al git ürünü satılıyor olması muhtemeldir.',
    'suggestion.findings1490':
      'İlgili alanda bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1500':
      'İlgili alanda bir önceki tarih aralığına göre süre artarken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1501':
      'Bu alanda ilgi oranı sabitken satış azaldığına göre ilgi, satış üzerinde doğrudan etkili değildir.',
    'suggestion.findings1510':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1511':
      'İlgi oranı sabitken ve süre azalırken satış arttığına göre, bu alanda süre faktörü doğrudan etkili değildir. ',
    'suggestion.findings1512':
      'Alanda, hızlı al git bir ürün grubu satılıyor olabilir.',
    'suggestion.findings1520':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin azalmasına bağlı olarak yoğunluk azalmıştır. ',
    'suggestion.findings1521': 'Bu alandaki ilgi oranı, sabit kalmıştır.',
    'suggestion.findings1530':
      'İlgili alanda, bir önceki tarih aralığına göre hem kişi sayısının hem de geçirilen sürenin azalmasına bağlı olarak yoğunluk azalmıştır. ',
    'suggestion.findings1531':
      'Alanda hem yoğunluk hem satış azalmış ve ilgi oranı sabit kalmıştır.',
    'suggestion.findings1532':
      'Buna göre, olabilecek en kötü senaryo gerçekleşmiştir.',
    'suggestion.findings1540':
      'İlgili alanda bir önceki tarih aralığına göre süre sabitken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1541':
      'Personel sayısı, ürün fiyat dengesi bu alanda optimum düzeydedir.',
    'suggestion.findings1542': 'Alanın satış verimliliği artmaktadır.',
    'suggestion.findings1550':
      'İlgili alanda bir önceki tarih aralığına göre süre sabitken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1551':
      'Bu alanda ilgi oranı azalırken satış arttığına göre, satışların arttırılmasında ilgi etken değildir. ',
    'suggestion.findings1552':
      'Buna göre bu alanda quick sell denilen hızlı al git ürünü satılıyor olması muhtemeldir.',
    'suggestion.findings1560':
      'İlgili alanda bir önceki tarih aralığına göre süre sabitken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1561':
      'Bu alanda ilgi oranı sabitken satış arttığına göre, satışların arttırılmasında ilgi direkt olarak etken değildir. ',
    'suggestion.findings1562':
      'Buna göre bu alanda quick sell denilen hızlı al git ürünü satılıyor olması muhtemeldir.',
    'suggestion.findings1570':
      'İlgili alanda bir önceki tarih aralığına göre süre sabitken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1580':
      'İlgili alanda bir önceki tarih aralığına göre süre sabitken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1581':
      'Bu alanda ilgi oranı sabitken satış da sabit kaldığına göre, satışların arttırılmasında ilgi (alanda 15 saniye ve üzeri zaman geçirme) doğrudan etken değildir. ',
    'suggestion.findings1582':
      'Buna göre bu alanda quick sell denilen hızlı al git ürünü satılıyor olması muhtemeldir.',
    'suggestion.findings1590':
      'İlgili alanda bir önceki tarih aralığına göre süre sabitken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1600':
      'İlgili alanda bir önceki tarih aralığına göre süre sabit kalırken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1610':
      'İlgili alanda bir önceki tarih aralığına göre süre sabitken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1620':
      'İlgili alanda bir önceki tarih aralığına göre süre sabitken kişi sayısının azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1621':
      'Bu alanda ilgi oranı sabitken satış azaldığına göre ilgi, satış üzerinde doğrudan etkili değildir.',
    'suggestion.findings1630':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı sabitken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1631':
      'Önceki tarih aralığına göre ilgi oranı da artmıştır. ',
    'suggestion.findings1632':
      'Kişi sayısı sabit ve ilgi oranı artarken süre azaldığına göre alandan gelip geçen ziyaretçi sayısı da çok artmıştır.',
    'suggestion.findings1640':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı sabitken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1641':
      'Süre sabit ve İlgi oranı azalırken satış arttığına göre alandaki ürün, hızlı al git ürün olabilir.',
    'suggestion.findings1650':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı sabitken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1651':
      'İlgi oranı ve süre sabitken satış arttığına göre alandaki ürün, hızlı al git ürün olabilir.',
    'suggestion.findings1660':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı sabitken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1661':
      'Kişi sayısı sabit kalıp süre azalırken ilgi oranı arttırığına göre alana gelen kişilerin çoğu alanda çok daha az zaman geçirmeye başlamıştır.',
    'suggestion.findings1670':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı sabitken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1671':
      'Kişi sabitken süre ve ilgi oranı azaldığına göre, ziyaretçinin alanda geçirdiği süre azalmaktadır.',
    'suggestion.findings1672':
      'Bunun sebebi ürünün ilgi çekmemesi veya mağaza planından kaynaklı bir sorun nedeniyle ziyaretçinin alanı pas geçmesi olabilir.',
    'suggestion.findings1680':
      'İlgili alanda bir önceki tarih aralığına göre kişi sabitken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1690':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı sabitken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1700':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı sabitken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',
    'suggestion.findings1710':
      'İlgili alanda bir önceki tarih aralığına göre kişi sayısı sabitken sürenin azalmasına bağlı olarak yoğunluk azalmıştır.',

    'suggestion.comment550':
      'Satış hedefini daha da yükseltmeye yönelik aksiyon alınmalıdır. ',
    'suggestion.comment551':
      'Satışı daha da arttırmak için ziyaretçilerin alanda daha fazla zaman geçirmesi sağlanmalıdır.',
    'suggestion.comment552':
      'Bunun için; personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'suggestion.comment553':
      'Satışları arttırmak için alanı pas geçen ziyaretçilerin alana ilgi göstermesini sağlamak üzere aksiyon alınmalıdır.',
    'suggestion.comment554':
      'Mağaza planı gözden geçirilebilir, personel ilgisi arttırılabilir, ürün gamı ve ürün fiyat dengesi gözden geçirilebilir.',
    'suggestion.comment560':
      'Satış hedefini daha da yükseltmeye yönelik aksiyon alınmalıdır.',
    'suggestion.comment561':
      'Önceki tarih aralığına göre ilgi oranı azalmıştır. ',
    'suggestion.comment562':
      'Bu alanda ilgi oranı azalmasına rağmen satış arttığına göre bu alanda geçirilen süreden ziyade kişi sayısının satışa olan etkisi daha fazladır.',
    'suggestion.comment570':
      'Alanı pas geçen ziyaretçilerin alana ilgi göstermesini sağlamak üzere aksiyon alınmalıdır.',
    'suggestion.comment571':
      'Mağaza planı gözden geçirilebilir, personel ilgisi arttırılabilir, ürün gamı ve ürün fiyat dengesi gözden geçirilebilir, ',
    'suggestion.comment572':
      'Personel ilgisini arttırmanın ve/veya alanın ürün bilgi kartlarıyla desteklenmesinin satışları arttırması beklenir.',
    'suggestion.comment580':
      'Önceki tarih aralığına göre ilgi oranı da azaldığına göre, yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda yeteri kadar zaman geçirmemesi olabilir. ',
    'suggestion.comment581':
      'Satışları arttırmak için ziyaretçinin alanda daha fazla zaman geçirmesini sağlanmalıdır.',
    'suggestion.comment582':
      'Personel ilgisi, ürün gamı, mağaza planı gözden geçirilebilir.',
    'suggestion.comment590':
      'Buna göre; yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda çok fazla zaman geçirmesi fakat aradığını bulamaması olabilir. ',
    'suggestion.comment591':
      ' Ziyaretçiyi hızlandırmak ve dolayısıyla satışları arttırmak için; personel ilgisi arttırılabilir, ilgili alan ürün bilgi kartlarıyla desteklenebilir. ',
    'suggestion.comment592':
      ' Ürün çeşitliliğinin fazla olduğu düşünülüyorsa azaltılabilir.',
    'suggestion.comment600':
      'Satışları arttırmak için ziyaretçinin alanda daha fazla zaman geçirmesini sağlanmalıdır. ',
    'suggestion.comment601':
      ' Bunun için mağaza olanı gözden geçirilebilir, ürün çeşitliliği arttırılabilir. ',
    'suggestion.comment602':
      ' Kişi sayısı artarken ilgi oranı ve geçirilen süre azaldığına göre bu alanda personel sayısı yetersiz kalıyor olabilir.',
    'suggestion.comment610':
      'Satış hedefini daha da yükseltmeye yönelik aksiyon alınmalıdır. ',
    'suggestion.comment611':
      'Satışı daha da arttırmak için ziyaretçilerin alanda daha fazla zaman geçirmesi sağlanmalıdır. ',
    'suggestion.comment612':
      'Bunun için; personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'suggestion.comment620':
      'Satış hedefini daha da yükseltmeye yönelik aksiyon alınmalıdır.',
    'suggestion.comment621':
      'Bu alanda yoğunluk artışına karşılık ilgi oranı azalırken satış arttığına göre satışları arttırmak için geçirilen süreden ziyade alana gelen kişi sayısını arttırmaya yönelik aksiyon almak gerekir. ',
    'suggestion.comment622':
      'Bunun için mağaza planı gözden geçirilebilir, kampanya yapılabilir.',
    'suggestion.comment630':
      'Süre artışının sebebi ziyaretçinin aradığını bulamaması olabilir. Personel sayısı ve hizmet kalitesi sorgulanmalıdır.',
    'suggestion.comment640':
      'Alandaki ilgi oranı azaldığına göre, yoğunluk artarken satışların sabit kalmasının nedeni personel ilgisinin yetersiz kalması veya ürün çeşitliliğinin olması gerektiğinden çok fazla olması olabilir.',
    'suggestion.comment650':
      'Yoğunluk artışında kişi sayısındaki artış da etkili olduğu için artan yoğunluğu kaldırabilmek için alan, ürün bilgi kartlarıyla desteklenebilir. ',
    'suggestion.comment651':
      'Personel sayısı veya bilgisi bu alanda kritiktir. Veya, ürün çeşitliliği çok fazla gelmiş olabilir. ',
    'suggestion.comment652':
      'Alan, artan yoğunluğu karşılayamadığı için alanın büyütülmesi düşünülebilir.',
    'suggestion.comment660':
      'Satışlar azaldığına göre, bu alanda ziyaretçinin ürün satın alması için 15 saniye ve üzeri zaman geçirmesi etkilidir.',
    'suggestion.comment661':
      'İlgi oranının ve dolayısıyla satışların azalmasının sebebi personel ilgisinin yetersiz kalması veya ürün çeşitliliğinin az olması olabilir. ',
    'suggestion.comment662':
      'Alan, artan yoğunluğu karşılayamadığı için alanın büyütülmesi düşünülebilir.',
    'suggestion.comment670':
      'Bu alanda hem süre hem de ilgi oranı artarken satışlar arttığı için satışları daha da arttırmak için ziyaretçinin alanda daha uzun zaman geçirmesini sağlamak önemlidir. ',
    'suggestion.comment671':
      'Süreyi arttırmak için; mağaza planı, personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'suggestion.comment680':
      'Alana gelen kişi sayısı ve ilgilenen kişi sayısı azalmasına rağmen satış arttığına göre bu alanda personelin ziyaretçiyle çok az bir süre bile ilgilenmesi ziyaretçinin alışverişe yönelmesine hemen etki etmektedir. ',
    'suggestion.comment681':
      'Veya, alana bilinçli, ne aradığını bilen ziyaretçi gelmekte ve ürün çeşitliliğini anlamakta sıkıntı yaşamamaktadır.',
    'suggestion.comment690':
      'Ziyaretçinin alanda daha uzun süre geçirmeye başlamasının sebebi personel yetersizliği veya ürün çeşitliliğinin arttırılmış olması olabilir.',
    'suggestion.comment700':
      'Ziyaretçiyi hızlandırmak için aksiyon alınmalıdır.',
    'suggestion.comment701':
      'Ziyaretçinin alanda daha uzun süre geçirmeye başlamasının sebebi personel yetersizliği veya ürün çeşitliliğinin arttırılmış olması olabilir.',
    'suggestion.comment710':
      'Acilen ziyaretçiyi hızlandırmak için aksiyon almak gerekir. ',
    'suggestion.comment711':
      'Bunun için personel sayısı ve hizmet kalitesi sorgulanmalıdır. Ayrıca, ürün çeşitliliğinin azaltılması düşünülebilir.',
    'suggestion.comment720':
      'Yine de, süre artarken satış azaldığına göre ziyaretçinin hızlandırılması için aksiyon alınması gerekebilir. ',
    'suggestion.comment721':
      'Bunun için, personel sayısı ve ilgisi ile ürün çeşitliliği gözden geçirilmelidir.',
    'suggestion.comment730':
      'Satışları düşürmeden süreyi azaltıp kişi sayısı arttırılabilirse alandaki potansiyel satış artacaktır.',
    'suggestion.comment740':
      'Satışları düşürmeden süre azaltılıp kişi sayısı arttırılabilirse alandaki potansiyel satış daha da artacaktır.',
    'suggestion.comment750':
      'Ziyaretçinin alanda daha fazla zaman geçirmesinin satışları da arttırması beklenir. ',
    'suggestion.comment751':
      ' Süre azalmaya devam ederse, bu durum satışlar için tehdit oluşturacaktır. ',
    'suggestion.comment752':
      ' İlgi oranı artarken satışların artmamasının sebebi, ziyaretçinin aradığını bulamaması olabilir. ',
    'suggestion.comment753': ' Ürün çeşitliliği gözden geçirilmelidir.',
    'suggestion.comment760':
      'Ziyaretçi sayısını ve süreyi arttırmak, satış verimini arttıracaktır. ',
    'suggestion.comment761':
      'İlgi oranı azalırken satış düşmediğine göre, bu alandan ürün satın almak için ziyaretçinin alanda çok uzun süreler geçirmesine gerek olmayabilir.',
    'suggestion.comment770':
      'İlgi oranı da artmasına rağmen geçirilen sürenin azalması, satışa olumsuz etki etmiştir. Bu alanda, 15 saniye ve üzeri zaman geçiren ziyaretçinin satın alması beklenir. Satışları arttırmak için geçirilen sürenin yeteri kadar uzun olmasına önem vermek gerekir. Ziyaretçinin alanda daha uzun süre geçirmesini sağlamak için mağaza planı gözden geçirilebilir. Personel ilgisi arttırılabilir.',
    'suggestion.comment780':
      'Bu alanda satışları arttırmak için geçirilen sürenin yeteri kadar uzun olmasına önem vermek gerekir. ',
    'suggestion.comment781':
      'Ziyaretçinin alanda daha uzun süre geçirmesini sağlamak için mağaza planı gözden geçirilebilir. Personel ilgisi arttırılabilir.',
    'suggestion.comment790':
      'Alanda 15 saniye ve üzeri zaman geçiren kişi sayısı arttıkça satışların da artması beklenir.',
    'suggestion.comment791':
      ' Dolayısıyla, satışları daha da arttırmak için kişi sayısını arttırırken sürenin azalmamasına dikkat etmek gerekir.',
    'suggestion.comment800':
      'Ziyaretçi sayısını arttırmak satışları daha da arttıracaktır.',
    'suggestion.comment810':
      'Sürenin çok artması personel yetersizliğinden veya ziyaretçinin aradığını bulamamasından kaynaklanıp satış verimliliğinde tehdit oluşturuyor olabilir.',
    'suggestion.comment811':
      'Bu alanda, satışları arttırmak için kişi sayısını arttırmaya yönelik aksiyon almak gerekir.',
    'suggestion.comment812':
      'Bunun için, ürün-fiyat dengesi gözden geçirilebilir. İlgili alanda kampanya yapılabilir.',
    'suggestion.comment820':
      'Bu alanda, satışları arttırmak için kişi sayısını arttırmaya ve aynı zamanda ilgilenen ziyaretçi sayısını kaybetmemeye yönelik aksiyon almak gerekir.',
    'suggestion.comment821':
      ' Bunun için, ürün-fiyat dengesi gözden geçirilebilir. İlgili alanda kampanya yapılabilir.',
    'suggestion.comment830':
      'Bu alanda, satışları arttırmak için kişi sayısını arttırmaya ve geçirilen süreyi azaltmaya yönelik aksiyon almak gerekir.',
    'suggestion.comment831':
      'Bunun için personel ilgisi ve sayısı arttırılmalıdır. Alanın ürün bilgi kartlarıyla desteklenmesinin de geçirilen süreyi azaltması beklenir.',
    'suggestion.comment840':
      'Satış potansiyeli olan bu alanda, ziyaretçi ilgisini kaybetmemek ve satışları arttırmak için kişi sayısını arttırmaya yönelik aksiyon almak gerekir.',
    'suggestion.comment841':
      'Kişi sayısı artarken sürenin azalmamasına dikkat etmek çok önemlidir.',
    'suggestion.comment842':
      'Bunun için personel ilgisi ve sayısı arttırılmalıdır. Alanın ürün bilgi kartlarıyla desteklenmesinin de geçirilen süreyi azaltması beklenir.',
    'suggestion.comment850':
      'İlgi oranını arttırmanın satışa da olumlu etki etmesi beklenir.',
    'suggestion.comment851':
      'Personel desteğini arttırmanın ilgi oranını da arttırması beklenir.',
    'suggestion.comment860':
      'Kişi sayısındaki yüzdesel artışın satışı da aynı oranda arttırması beklenir.',
    'suggestion.comment870':
      'Mevcutta hızlı satış gerçekleşen bir alan olmasına rağmen satışın beklenen artışı göstermemesi analiz edilmelidir.',
    'suggestion.comment880':
      'Ziyaretçinin alanda geçirdiği süreyi arttırmak için personel ilgisi de arttırılabilir.',
    'suggestion.comment890':
      'Önceki tarih aralığına göre ilgili üründe fiyat değişimi varsa yeni fiyat pahalı geliyor olabilir.',
    'suggestion.comment891':
      'Ürün, detay gerektiren bir ürün ise ziyaretçi personel teması bekliyor olabilir.',
    'suggestion.comment892':
      'Personel sayısında önceki tarih aralığına göre bir değişiklik yapıldıysa, alan bu değişimden direkt etkilenmiştir.',
    'suggestion.comment900':
      'İlgi oranı ve geçirilen sürenin azalması satışı olumsuz etkilediğine göre geçirilen süreyi arttırmaya yönelik aksiyon alınmalıdır.',
    'suggestion.comment901':
      ' Süre arttırılırsa ilgi oranı ve buna bağlı olarak satış da artacaktır.',
    'suggestion.comment902':
      ' İlgili alandaki üründe fiyat değişimi yapıldıysa yeni fiyat ziyaretçiye pahalı geliyor olabilir.',
    'suggestion.comment910':
      'Bu alanda satışları arttırmak için ziyaretçiyi hızlandırmaya yönelik aksiyon alınmalıdır.',
    'suggestion.comment920':
      'Bu alanda kişi sayısını ve dolayısıyla satışları arttırmak için en etken madde fiyattır.',
    'suggestion.comment930':
      'Bu alanda ilgi oranı ve süre artarken satış değişmediğine göre, sürenin uzunluğu tehdit oluşturuyor olabilir.',
    'suggestion.comment931':
      'Süreyi azaltmak için önlem alınmazsa sonraki dönemde sürenin uzunluğu satışların düşmesine sebep olabilir.',
    'suggestion.comment932':
      'Sürenin artmasının sebebi ziyaretçinin aradığını bulamamasından kaynaklanıyor olabilir.',
    'suggestion.comment933':
      'Kişi sayısını arttırmanın satışları da arttırması beklenir.',
    'suggestion.comment934': 'Kişi sayısını arttırmak için ana etken fiyattır.',
    'suggestion.comment940': 'Kişi sayısını artttırmada fiyat ana etkendir.',
    'suggestion.comment950':
      'Bu alanda ilgi oranı ve süre artarken satış azaldığına göre sürenin uzunluğu tehdit oluşturuyor olabilir.',
    'suggestion.comment951': 'Kişi sayısını arttırmada fiyat ana etkendir.',
    'suggestion.comment952':
      'Eğer bu alandaki kişi azalması mağazadaki ziyaretçi sayısındaki azalmaya paralel ise mağaza içerisindeki pazarlama aktiviteleri yeniden çalışılmalıdır.',
    'suggestion.comment960': 'Kişi sayısını arttırmada fiyat ana etkendir.',
    'suggestion.comment961':
      'Eğer bu alandaki kişi azalması mağazadaki ziyaretçi sayısındaki azalmaya paralel ise mağaza içerisindeki pazarlama aktiviteleri yeniden çalışılmalıdır.',
    'suggestion.comment970':
      'İlgi oranını gelen kişi sayısına oranla daha da arttırmak için aksiyon almak gerekir.',
    'suggestion.comment971': 'Personel ilgisi gözden kaçırılmamalıdır.',
    'suggestion.comment980':
      'Alanda hızlı al git bir ürün grubu varsa, stoğa dikkat edilmelidir. Ziyaretçinin alana gelip ürün bulamaması sıkıntı yaratacaktır.',
    'suggestion.comment981':
      'Önceki tarih aralığına göre fiyat değişikliği yapıldıysa yeni fiyat politikası işe yaramıştır.',
    'suggestion.comment990':
      'Buna göre bir önceki dönemde çok yüksek potansiyel varken bundan yeteri kadar yararlanılamadığı gözlemlenir.',
    'suggestion.comment1000':
      'Kişi ve sürenin azalması satışı değiştirmemiş bu da bir önceki dönemde potansiyelden yararlanılamadığını gösterir.',
    'suggestion.comment1010':
      'Personelin ziyaretçiyle olan ilişkisine dikkat etmek gerekir.',
    'suggestion.comment1020':
      'İlgili alan acilen en derinlemesine kadar analiz edilmeli ve aksiyon alınmalıdır.',
    'suggestion.comment1030':
      'Satış hedefini daha da yükseltmeye yönelik aksiyon alınmalıdır.',
    'suggestion.comment1031':
      'Satışları daha da arttırmak için alana gelen kişi sayısı arttırılmalıdır.',
    'suggestion.comment1032':
      'Bunun için; mağaza planı ve kampanyalar gözden geçirilebilir.',
    'suggestion.comment1040':
      'Önceki tarih aralığına göre ilgi oranı da sabit kaldığına göre, yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda yeteri kadar zaman geçirmemesi olabilir. ',
    'suggestion.comment1041':
      'Satışları arttırmak için ziyaretçinin alanda daha fazla zaman geçirmesini sağlanmalıdır.',
    'suggestion.comment1050':
      'Önceki tarih aralığına göre ilgi oranı da sabit kaldığına göre, yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda yeteri kadar zaman geçirmemesi olabilir. ',
    'suggestion.comment1051':
      'Satışları arttırmak için ziyaretçinin alanda daha fazla zaman geçirmesini sağlanmalıdır. ',
    'suggestion.comment1052':
      'Bunun için mağaza olanı gözden geçirilebilir, ürün çeşitliliği arttırılabilir. ',
    'suggestion.comment1053':
      'Kişi sayısı artarken ilgi oranı ve geçirilen süre azaldığına göre bu alanda personel sayısı yetersiz kalıyor olabilir.',
    'suggestion.comment1060':
      'Satış hedefini daha da yükseltmeye yönelik aksiyon alınmalıdır. ',
    'suggestion.comment1061':
      'Bu alanda yoğunluk artışına karşılık ilgi oranı sabitken satış arttığına göre satışları arttırmak için geçirilen süreden ziyade alana gelen kişi sayısını arttırmaya yönelik aksiyon almak gerekir. ',
    'suggestion.comment1062':
      'Bunun için mağaza planı gözden geçirilebilir, kampanya yapılabilir.',
    'suggestion.comment1070':
      'Alandaki ilgi oranı artmayıp sabit kaldığına göre, yoğunluk artarken satışların sabit kalmasının nedeni personel ilgisinin yetersiz kalması veya ürün çeşitliliğinin olması gerektiğinden çok fazla olması olabilir.',
    'suggestion.comment1080':
      'Satışlar azaldığına göre, bu alanda satışın yoğunluk artışıyla paralel olarak artmamasının sebebi ilgi oranının sabit kalması olabilir.',
    'suggestion.comment1081':
      'İlgi oranını ve dolayısıyla satışları arttırmak personel ilgisi ve ürün çeşitliliği gözden geçirilebilir.',
    'suggestion.comment1082':
      'Alan, artan yoğunluğu karşılayamadığı için alanın büyütülmesi düşünülebilir.',
    'suggestion.comment1090':
      'Alana gelen kişi sayısının azalmasına ve ilgilenen kişi sayısının sabit kalmasına rağmen satış arttığına göre bu alanda personelin ziyaretçiyle çok az bir süre bile ilgilenmesi ziyaretçinin alışverişe yönelmesine hemen etki etmektedir. ',
    'suggestion.comment1091':
      'Veya, alana bilinçli, ne aradığını bilen ziyaretçi gelmekte ve ürün çeşitliliğini anlamakta sıkıntı yaşamamaktadır.',
    'suggestion.comment1100':
      'Süre artmaya devam ederse, sonraki aşamada satışlar bundan olumsuz etkilenebilir. ',
    'suggestion.comment1101':
      'Ziyaretçiyi hızlandırmak için aksiyon alınmalıdır.',
    'suggestion.comment1102':
      'Ziyaretçinin alanda daha uzun süre geçirmeye başlamasının sebebi personel yetersizliği veya ürün çeşitliliğinin arttırılmış olması olabilir.',
    'suggestion.comment1110':
      'Yine de, süre artarken satış azaldığına göre ziyaretçinin hızlandırılması için aksiyon alınması gerekebilir. ',
    'suggestion.comment1111':
      'Bunun için, personel sayısı ve ilgisi ile ürün çeşitliliği gözden geçirilmelidir.',
    'suggestion.comment1120':
      'Satış hedefini daha da yükseltmeye yönelik aksiyon alınmalıdır. ',
    'suggestion.comment1121':
      'Satışı daha da arttırmak için ziyaretçilerin alanda daha fazla zaman geçirmesi sağlanmalıdır. ',
    'suggestion.comment1122':
      'Bunun için; personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'suggestion.comment1130':
      'Satış hedefini daha da yükseltmeye yönelik aksiyon alınmalıdır.',
    'suggestion.comment1131':
      'Satışları daha da arttırmak için alana gelen kişi sayısı arttırılmalıdır.',
    'suggestion.comment1132':
      'Bunun için; mağaza planı ve kampanyalar gözden geçirilebilir.',
    'suggestion.comment1140':
      'Satış hedefini daha da yükseltmeye yönelik aksiyon alınmalıdır.',
    'suggestion.comment1141':
      'Satışları daha da arttırmak için alana gelen kişi sayısı arttırılmalıdır.',
    'suggestion.comment1142':
      'Bunun için; mağaza planı ve kampanyalar gözden geçirilebilir.',
    'suggestion.comment1150':
      'Önceki tarih aralığına göre ilgi oranı da arttığına göre, yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda çok fazla zaman geçirmesi fakat aradığını bulamaması olabilir. ',
    'suggestion.comment1151':
      'Ziyaretçiyi hızlandırmak ve dolayısıyla satışları arttırmak için; personel ilgisi arttırılabilir, ilgili alan ürün bilgi kartlarıyla desteklenebilir, ürün çeşitliliği azaltılabilir.',
    'suggestion.comment1160':
      'Önceki tarih aralığına göre ilgi oranı da azaldığına göre, yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda yeteri kadar zaman geçirmemesi olabilir. ',
    'suggestion.comment1161':
      'Satışları arttırmak için ziyaretçinin alanda daha fazla zaman geçirmesini sağlanmalıdır.',
    'suggestion.comment1170':
      'Önceki tarih aralığına göre ilgi oranı da sabit kaldığına göre, yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda yeteri kadar zaman geçirmemesi olabilir. ',
    'suggestion.comment1171':
      'Satışları arttırmak için ziyaretçinin alanda daha fazla zaman geçirmesini sağlanmalıdır.',
    'suggestion.comment1180':
      'Ziyaretçiyi hızlandırmak ve dolayısıyla satışları arttırmak için; personel ilgisi arttırılabilir, ilgili alan ürün bilgi kartlarıyla desteklenebilir. ',
    'suggestion.comment1181':
      'Ürün çeşitliliğinin fazla olduğu düşünülüyorsa azaltılabilir.',
    'suggestion.comment1190':
      'Önceki tarih aralığına göre ilgi oranı da azaldığına göre, yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda yeteri kadar zaman geçirmemesi olabilir. ',
    'suggestion.comment1191':
      'Satışları arttırmak için ziyaretçinin alanda daha fazla zaman geçirmesini sağlanmalıdır. ',
    'suggestion.comment1192':
      'Bunun için mağaza olanı gözden geçirilebilir, ürün çeşitliliği arttırılabilir. ',
    'suggestion.comment1193':
      'Kişi sayısı artarken ilgi oranı ve geçirilen süre azaldığına göre bu alanda personel sayısı yetersiz kalıyor olabilir.',
    'suggestion.comment1200':
      'Önceki tarih aralığına göre ilgi oranı da sabit kaldığına göre, yoğunluk artışının satışa etki etmemesinin sebebi ziyaretçinin alanda yeteri kadar zaman geçirmemesi olabilir. ',
    'suggestion.comment1201':
      'Satışları arttırmak için ziyaretçinin alanda daha fazla zaman geçirmesini sağlanmalıdır. ',
    'suggestion.comment1202':
      'Bunun için mağaza olanı gözden geçirilebilir, ürün çeşitliliği arttırılabilir. ',
    'suggestion.comment1203':
      'Kişi sayısı artarken ilgi oranı ve geçirilen süre azaldığına göre bu alanda personel sayısı yetersiz kalıyor olabilir.',
    'suggestion.comment1210':
      'Bu alanda hem süre hem de ilgi oranı artarken satışlar arttığı için satışları daha da arttırmak için ziyaretçinin alanda daha uzun zaman geçirmesini sağlamak önemlidir. ',
    'suggestion.comment1211':
      'Süreyi arttırmak için; mağaza planı, personel ilgisi, ürün fiyat dengesi, ürün çeşitliliği gözden geçirilebilir.',
    'suggestion.comment1220':
      'Satış hedefini daha da yükseltmeye yönelik aksiyon alınmalıdır.',
    'suggestion.comment1221':
      'Satışları daha da arttırmak için alana gelen kişi sayısı arttırılmalıdır.',
    'suggestion.comment1222':
      'Bunun için; mağaza planı ve kampanyalar gözden geçirilebilir.',
    'suggestion.comment1230':
      'Satış hedefini daha da yükseltmeye yönelik aksiyon alınmalıdır.',
    'suggestion.comment1231':
      'Satışları daha da arttırmak için alana gelen kişi sayısı arttırılmalıdır.',
    'suggestion.comment1232':
      'Bunun için; mağaza planı ve kampanyalar gözden geçirilebilir.',
    'suggestion.comment1240':
      'Ziyaretçiyi hızlandırmak ve dolayısıyla satışları arttırmak için; personel ilgisi arttırılabilir, ilgili alan ürün bilgi kartlarıyla desteklenebilir, ürün çeşitliliği azaltılabilir.',
    'suggestion.comment1250':
      'Satışları arttırmak için ziyaretçinin alanda daha fazla zaman geçirmesini sağlanmalıdır.',
    'suggestion.comment1260':
      'Satışları arttırmak için ziyaretçinin alanda daha fazla zaman geçirmesini sağlanmalıdır.',
    'suggestion.comment1270':
      'Ziyaretçiyi hızlandırmak ve dolayısıyla satışları arttırmak için; personel ilgisi arttırılabilir, ilgili alan ürün bilgi kartlarıyla desteklenebilir. ',
    'suggestion.comment1271':
      'Ürün çeşitliliğinin fazla olduğu düşünülüyorsa azaltılabilir.',
    'suggestion.comment1280':
      'Satışları arttırmak için ziyaretçinin alanda daha fazla zaman geçirmesini sağlanmalıdır. ',
    'suggestion.comment1281':
      'Bunun için mağaza olanı gözden geçirilebilir, ürün çeşitliliği arttırılabilir. ',
    'suggestion.comment1282':
      'Kişi sayısı artarken ilgi oranı ve geçirilen süre azaldığına göre bu alanda personel sayısı yetersiz kalıyor olabilir.',
    'suggestion.comment1290':
      'Süre artarken satış azaldığına göre ziyaretçinin hızlandırılması için aksiyon alınması gerekebilir. ',
    'suggestion.comment1291':
      'Bunun için, personel sayısı ve ilgisi ile ürün çeşitliliği gözden geçirilmelidir.',
    'suggestion.comment1300':
      'Satışları düşürmeden süre azaltılıp kişi sayısı arttırılabilirse alandaki potansiyel satış daha da artacaktır.',
    'suggestion.comment1310':
      'Ziyaretçi sayısını ve süreyi arttırmak, satış verimini arttıracaktır. ',
    'suggestion.comment1311':
      'İlgi oranı sabitken satış düşmediğine göre, bu alandan ürün satın almak için ziyaretçinin alanda çok uzun süreler geçirmesine gerek olmayabilir.',
    'suggestion.comment1320':
      'Bu alanda satışları arttırmak için geçirilen sürenin yeteri kadar uzun olmasına önem vermek gerekir. ',
    'suggestion.comment1321':
      'Ziyaretçinin alanda daha uzun süre geçirmesini sağlamak için mağaza planı gözden geçirilebilir. Personel ilgisi arttırılabilir.',
    'suggestion.comment1330':
      'Ziyaretçi sayısını arttırmak satışları daha da arttıracaktır.',
    'suggestion.comment1340':
      'Bu alanda, satışları arttırmak için kişi sayısını arttırmaya ve aynı zamanda ilgilenen ziyaretçi sayısını kaybetmemeye yönelik aksiyon almak gerekir.',
    'suggestion.comment1341':
      'Bunun için, ürün-fiyat dengesi gözden geçirilebilir. İlgili alanda kampanya yapılabilir.',
    'suggestion.comment1350':
      'Alandaki satış potansiyelini arttırmak için alana daha fazla ziyaretçi çekmek önemlidir.',
    'suggestion.comment1360':
      'Alanda 15 saniye ve üzeri zaman geçiren kişi sayısı arttıkça satışların da artması beklenir.',
    'suggestion.comment1361':
      'Dolayısıyla, satışları daha da arttırmak için kişi sayısını arttırırken sürenin azalmamasına dikkat etmek gerekir.',
    'suggestion.comment1370':
      'Ziyaretçi sayısını arttırmak satışları daha da arttıracaktır.',
    'suggestion.comment1380':
      'Ziyaretçi sayısını arttırmak satışları daha da arttıracaktır.',
    'suggestion.comment1390':
      'Bu alanda, satışları arttırmak için kişi sayısını arttırmaya yönelik aksiyon almak gerekir.',
    'suggestion.comment1391':
      'Bunun için, ürün-fiyat dengesi gözden geçirilebilir. İlgili alanda kampanya yapılabilir.',
    'suggestion.comment1400':
      'Bu alanda, satışları arttırmak için kişi sayısını arttırmaya ve aynı zamanda ilgilenen ziyaretçi sayısını kaybetmemeye yönelik aksiyon almak gerekir.',
    'suggestion.comment1401':
      'Bunun için, ürün-fiyat dengesi gözden geçirilebilir. İlgili alanda kampanya yapılabilir.',
    'suggestion.comment1410':
      'Bu alanda, satışları arttırmak için kişi sayısını arttırmaya ve aynı zamanda ilgilenen ziyaretçi sayısını kaybetmemeye yönelik aksiyon almak gerekir.',
    'suggestion.comment1411':
      'Bunun için, ürün-fiyat dengesi gözden geçirilebilir. İlgili alanda kampanya yapılabilir.',
    'suggestion.comment1420':
      'Bu alanda, satışları arttırmak için kişi sayısını arttırmaya ve geçirilen süreyi azaltmaya yönelik aksiyon almak gerekir.',
    'suggestion.comment1421':
      'Bunun için personel ilgisi ve sayısı arttırılmalıdır. Alanın ürün bilgi kartlarıyla desteklenmesinin de geçirilen süreyi azaltması beklenir.',
    'suggestion.comment1430':
      'Satış potansiyeli olan bu alanda, ziyaretçi ilgisini kaybetmemek ve satışları arttırmak için kişi sayısını arttırmaya yönelik aksiyon almak gerekir.',
    'suggestion.comment1431':
      'Kişi sayısı artarken sürenin azalmamasına dikkat etmek çok önemlidir.',
    'suggestion.comment1432':
      'Bunun için personel ilgisi ve sayısı arttırılmalıdır. Alanın ürün bilgi kartlarıyla desteklenmesinin de ziyaretçi ilgisini arttırması beklenir.',
    'suggestion.comment1440':
      'Alandaki satış potansiyelini arttırmak için alana daha fazla ziyaretçi çekmek önemlidir.',
    'suggestion.comment1450':
      'Kişi sayısındaki yüzdesel artışın satışı da aynı oranda arttırması beklenir.',
    'suggestion.comment1460':
      'Ziyaretçinin alanda geçirdiği süreyi arttırmak için personel ilgisi de arttırılabilir.',
    'suggestion.comment1470':
      'İlgi oranı sabitken geçirilen sürenin azalması satışı olumsuz etkilediğine göre geçirilen süreyi arttırmaya yönelik aksiyon alınmalıdır.',
    'suggestion.comment1471':
      'Süre arttırılırsa ilgi oranı ve buna bağlı olarak satış da artacaktır.',
    'suggestion.comment1472':
      'İlgili alandaki üründe fiyat değişimi yapıldıysa yeni fiyat ziyaretçiye pahalı geliyor olabilir.',
    'suggestion.comment1480':
      'Bu alanda kişi sayısını ve dolayısıyla satışları arttırmak için en etken madde fiyattır.',
    'suggestion.comment1490':
      'Bu alanda ilgi oranı sabitken satış sabit kaldığına göre, satışların arttırılmasında ilgi oranı belirleyici olabilir. ',
    'suggestion.comment1491':
      'Ziyaretçinin ilgi göstermesini sağlamak için alan ürün bilgi kartlarıyla desteklenebilir, personel ilgisi arttırılabilir.',
    'suggestion.comment1500':
      'Satışları arttırmak için kişi sayısını arttırmaya odaklanmak gerekir.',
    'suggestion.comment1501': 'Kişi sayısını arttırmada fiyat ana etkendir.',
    'suggestion.comment1502':
      'Eğer bu alandaki kişi azalması mağazadaki ziyaretçi sayısındaki azalmaya paralel ise mağaza içerisindeki pazarlama aktiviteleri yeniden çalışılmalıdır.',
    'suggestion.comment1510':
      'Alanda hızlı al git bir ürün grubu varsa, stoğa dikkat edilmelidir. Ziyaretçinin alana gelip ürün bulamaması sıkıntı yaratacaktır.',
    'suggestion.comment1511':
      'Önceki tarih aralığına göre fiyat değişikliği yapıldıysa yeni fiyat politikası işe yaramıştır.',
    'suggestion.comment1520':
      'Kişi ve sürenin azalması satışı değiştirmemiş bu da bir önceki dönemde potansiyelden yararlanılamadığını gösterir.',
    'suggestion.comment1530':
      'İlgili alan acilen en derinlemesine kadar analiz edilmeli ve aksiyon alınmalıdır.',
    'suggestion.comment1540':
      'Bu alanda satışları arttırmak için ziyaretçiyi hızlandırmaya yönelik aksiyon alınmalıdır.',
    'suggestion.comment1550':
      'Bu alanda kişi sayısını ve dolayısıyla satışları arttırmak için en etken madde fiyattır.',
    'suggestion.comment1560':
      'Bu alanda kişi sayısını ve dolayısıyla satışları arttırmak için en etken madde fiyattır.',
    'suggestion.comment1570':
      'Bu alanda ilgi oranı sabitken ve süre artarken satış değişmediğine göre, sürenin uzunluğu tehdit oluşturuyor olabilir.',
    'suggestion.comment1571':
      'Süreyi azaltmak için önlem alınmazsa sonraki dönemde sürenin uzunluğu satışların düşmesine sebep olabilir.',
    'suggestion.comment1572':
      'Sürenin artmasının sebebi ziyaretçinin aradığını bulamamasından kaynaklanıyor olabilir.',
    'suggestion.comment1573':
      'Kişi sayısını arttırmanın satışları da arttırması beklenir.',
    'suggestion.comment1574':
      'Kişi sayısını arttırmak için ana etken fiyattır.',
    'suggestion.comment1580': 'Kişi sayısını arttırmada fiyat ana etkendir.',
    'suggestion.comment1590':
      'Bu alanda ilgi oranı sabitken satış sabit kaldığına göre, satışların arttırılmasında ilgi oranı belirleyici olabilir. ',
    'suggestion.comment1591':
      'Ziyaretçinin ilgi göstermesini sağlamak için alan ürün bilgi kartlarıyla desteklenebilir, personel ilgisi arttırılabilir.',
    'suggestion.comment1600':
      'Bu alanda süre sabitken ve ilgi oranı artarken satış azaldığına göre sürenin uzaması ileride tehdit oluşturabilir.',
    'suggestion.comment1601': 'Kişi sayısını arttırmada fiyat ana etkendir.',
    'suggestion.comment1602':
      'Eğer bu alandaki kişi azalması mağazadaki ziyaretçi sayısındaki azalmaya paralel ise mağaza içerisindeki pazarlama aktiviteleri yeniden çalışılmalıdır.',
    'suggestion.comment1610':
      'Bu alanda ilgi oranı azalırken satış da azaldığına göre ilgi, satış üzerinde etkilidir.',
    'suggestion.comment1611': 'Kişi sayısını arttırmada fiyat ana etkendir.',
    'suggestion.comment1612':
      'Eğer bu alandaki kişi azalması mağazadaki ziyaretçi sayısındaki azalmaya paralel ise mağaza içerisindeki pazarlama aktiviteleri yeniden çalışılmalıdır.',
    'suggestion.comment1620':
      'Satışları arttırmak için kişi sayısını arttırmaya odaklanmak gerekir.',
    'suggestion.comment1621': 'Kişi sayısını arttırmada fiyat ana etkendir.',
    'suggestion.comment1622':
      'Eğer bu alandaki kişi azalması mağazadaki ziyaretçi sayısındaki azalmaya paralel ise mağaza içerisindeki pazarlama aktiviteleri yeniden çalışılmalıdır.',
    'suggestion.comment1630':
      'İlgi oranını arttırmanın satışa da olumlu etki etmesi beklenir.',
    'suggestion.comment1631':
      'Personel desteğini arttırmanın ilgi oranını da arttırması beklenir.',
    'suggestion.comment1640':
      'Kişi sayısındaki yüzdesel artışın satışı da aynı oranda arttırması beklenir.',
    'suggestion.comment1650':
      'Kişi sayısındaki yüzdesel artışın satışı da aynı oranda arttırması beklenir.',
    'suggestion.comment1660':
      'Mevcutta hızlı satış gerçekleşen bir alan olmasına rağmen satışın beklenen artışı göstermemesi analiz edilmelidir.',
    'suggestion.comment1670':
      'Ziyaretçinin alanda geçirdiği süreyi arttırmak için personel ilgisi de arttırılabilir.',
    'suggestion.comment1680':
      'Bu alanda ilgi oranı sabitken satış sabit kaldığına göre, satışların arttırılmasında ilgi oranı belirleyici olabilir. ',
    'suggestion.comment1681':
      'Ziyaretçinin ilgi göstermesini sağlamak için alan ürün bilgi kartlarıyla desteklenebilir, personel ilgisi arttırılabilir.',
    'suggestion.comment1690':
      'İlgilenen ziyaretçinin ürün satın alması beklenir. ',
    'suggestion.comment1691':
      'Önceki tarih aralığına göre ilgili üründe fiyat değişimi varsa yeni fiyat pahalı geliyor olabilir.',
    'suggestion.comment1692':
      'Ürün, detay gerektiren bir ürün ise ziyaretçi personel teması bekliyor olabilir.',
    'suggestion.comment1693':
      'Personel sayısında önceki tarih aralığına göre bir değişiklik yapıldıysa, alan bu değişimden direkt etkilenmiştir.',
    'suggestion.comment1700':
      'İlgi oranı ve geçirilen sürenin azalması satışı olumsuz etkilediğine göre geçirilen süreyi arttırmaya yönelik aksiyon alınmalıdır.',
    'suggestion.comment1701':
      'Süre arttırılırsa ilgi oranı ve buna bağlı olarak satış da artacaktır.',
    'suggestion.comment1702':
      'İlgili alandaki üründe fiyat değişimi yapıldıysa yeni fiyat ziyaretçiye pahalı geliyor olabilir.',
    'suggestion.comment1710':
      'İlgi oranı sabitken geçirilen sürenin azalması satışı olumsuz etkilediğine göre geçirilen süreyi arttırmaya yönelik aksiyon alınmalıdır.',
    'suggestion.comment1711':
      'Süre arttırılırsa ilgi oranı ve buna bağlı olarak satış da artacaktır.',
    'suggestion.comment1712':
      'İlgili alandaki üründe fiyat değişimi yapıldıysa yeni fiyat ziyaretçiye pahalı geliyor olabilir.'
  },

  de: {
    'suggestion.findings550':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings551':
      'Die Erhöhung, die durchschnittliche Zeit im Rahmen einer der Dichte und Zeitparameter der Person ausgegeben, die eine erhöhte Anzahl von Kunden zu einer verminderten Bereich geschaffen.',
    'suggestion.findings552':
      'Der Umsatzanstieg mit zunehmender Dichte zeigt an, dass das Potenzial in dem Bereich sehr hoch ist.',
    'suggestion.findings553':
      'Zinssatz erhöhte auch im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings554':
      'Da der Zinssatz und Intensitätssteigerung in diesem Bereich, die Höhe der Umsatzsteigerungen, so dass die in diesem Bereich verbrachte Zeit hat einen großen Einfluss auf den Umsatz.',
    'suggestion.findings555':
      'Zurück Datumsbereich Fläche des Gebiets, in kürzester Zeit Kunden aus dem Bereich trotz der Verringerung der durch Zinserhöhungen verbrachte Zeit vergeht, die durch den Durchgang zu passieren begannen.',
    'suggestion.findings556':
      'Inzwischen hat die Fläche für 15 Sekunden und im Laufe der Zeit den Umsatz steigern durch die Kunden, die die Zahl der Menschen zugenommen haben, die wahrscheinlich Kunden sein sollen, die Zeit in der Gegend und über 15 Sekunden ausgegeben haben.',
    'suggestion.findings560':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings561':
      'Die Erhöhung, die durchschnittliche Zeit im Rahmen einer der Dichte und Zeitparameter der Person ausgegeben, die eine erhöhte Anzahl von Kunden zu einer verminderten Bereich geschaffen.',
    'suggestion.findings562':
      'Der Umsatzanstieg mit zunehmender Dichte zeigt an, dass das Potenzial in dem Bereich sehr hoch ist.',
    'suggestion.findings563':
      'Der Zinssatz wurde in den vorangegangenen Zeitraum sank im Vergleich.',
    'suggestion.findings564':
      'Obwohl die Rate in diesem Bereich von Interesse verringert hat, da die Verkäufe zu erhöhen, ist die Wirkung der Zahl der Menschen auf dem Umsatz mehr als die Zeit, in diesem Bereich ausgegeben.',
    'suggestion.findings570':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings571':
      'Die Erhöhung, die durchschnittliche Zeit im Rahmen einer der Dichte und Zeitparameter der Person ausgegeben, die eine erhöhte Anzahl von Kunden zu einer verminderten Bereich geschaffen.',
    'suggestion.findings572':
      'Die Erhöhung der Dichte wird erwartet, Verkäufe direkt zu erhöhen.',
    'suggestion.findings573':
      'Trotz diesem, während Dichte im Bereich zum vorherigen Datumsbereich im Vergleich zu, blieb der Umsatz konstant, so dass der Bereich den möglichen Verkauf erreichen gescheitert people.',
    'suggestion.findings574':
      'Zurück Datumsbereich Fläche des Gebiets, in kürzester Zeit Kunden aus dem Bereich trotz der Verringerung der durch Zinserhöhungen verbrachte Zeit vergeht, die durch den Durchgang zu passieren begannen.',
    'suggestion.findings575':
      'Inzwischen ist es erhöhte auch Umsatz für 15 Sekunden und im Laufe der Zeit die Zahl der Menschen, die zu erwarten sind, um direkt den Bereich zu verbessern.',
    'suggestion.findings576':
      'Trotzdem blieb der Umsatz unverändert, das heißt Menschen, die Zeit im Feld verbrachte auch nicht gekauft haben.',
    'suggestion.findings580':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings581':
      'Die Erhöhung, die durchschnittliche Zeit im Rahmen einer der Dichte und Zeitparameter der Person ausgegeben, die eine erhöhte Anzahl von Kunden zu einer verminderten Bereich geschaffen.',
    'suggestion.findings582':
      'Die Erhöhung der Dichte wird voraussichtlich direkt Umsatz steigern.',
    'suggestion.findings583':
      'Trotz diesem, während Dichte im Bereich zum vorherigen Datumsbereich im Vergleich zu, blieb der Umsatz konstant, so dass der Bereich den möglichen Verkauf erreichen gescheitert people.',
    'suggestion.findings590':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings591':
      'Die Erhöhung, die durchschnittliche Zeit im Rahmen einer der Dichte und Zeitparameter der Person ausgegeben, die eine erhöhte Anzahl von Kunden zu einer verminderten Bereich geschaffen.',
    'suggestion.findings592':
      'Dieser Bereich ist Dichte erhöhte sich der Umsatz verringerte sich im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings593':
      'Dementsprechend kann, obwohl es eine Möglichkeit in dem Feld war, konnte das Feld Umsatzpotenzial erreichen.',
    'suggestion.findings594':
      'Der Zinssatz ist gestiegen im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings600':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings601':
      'Die Erhöhung, die durchschnittliche Zeit im Rahmen einer der Dichte und Zeitparameter der Person ausgegeben, die eine erhöhte Anzahl von Kunden zu einer verminderten Bereich geschaffen.',
    'suggestion.findings602':
      'Dieser Bereich ist Dichte erhöhte sich der Umsatz verringerte sich im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings603':
      'Dementsprechend kann, obwohl es eine Möglichkeit in dem Feld war, konnte das Feld Umsatzpotenzial erreichen.',
    'suggestion.findings604':
      'Da der Zinssatz zum vorherigen Zeitraum verringert hat, der Grund, warum die Erhöhung der Dichte nicht den Umsatz auswirkt kann sein, dass der Kunde nicht genug Zeit, sich in der Gegend verbringen.',
    'suggestion.findings610':
      'In der Gegend, die Zahl der Menschen in Übereinstimmung mit einer früheren Datumsbereich und eine erhöhte Dichte aufgrund der Zunahme sowohl in der Zeit verbrachte.',
    'suggestion.findings611':
      'Dieser Anstieg wird erwartet, direkten Umsatz zu steigern.',
    'suggestion.findings612':
      'Der Umsatzanstieg mit zunehmender Dichte zeigt an, dass das Potenzial in dem Bereich sehr hoch ist.',
    'suggestion.findings613':
      'Zinssatz erhöhte auch im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings614':
      'Da der Zinssatz und dentensity Anstieg in diesem Bereich hat sich die Höhe des Umsatzes ebenfalls erhöht, so dass die in diesem Bereich verbrachte Zeit hat einen signifikanten Einfluss auf den Umsatz.',
    'suggestion.findings620':
      'In der Gegend, die Zahl der Menschen in Übereinstimmung mit einer früheren Datumsbereich und eine erhöhte Dichte aufgrund der Zunahme sowohl in der Zeit verbrachte.',
    'suggestion.findings621':
      'Dieser Anstieg wird erwartet, direkten Umsatz zu steigern.',
    'suggestion.findings622':
      'Der Umsatzanstieg mit zunehmender Dichte zeigt an, dass das Potenzial in dem Bereich sehr hoch ist.',
    'suggestion.findings623':
      'Der Zinssatz ist gesunken im Vergleich zum vorherigen Zeitraum',
    'suggestion.findings630':
      'In der Gegend, die Zahl der Menschen in Übereinstimmung mit einer früheren Datumsbereich und eine erhöhte Dichte aufgrund der Zunahme sowohl in der Zeit verbrachte.',
    'suggestion.findings631':
      'Dieser Anstieg wird erwartet, direkten Umsatz zu steigern, blieb der Umsatz konstant.',
    'suggestion.findings632':
      ' Potential im Bereich könnte das Ergebnis der zunehmenden Dichte, nicht ausgenutzt werden.',
    'suggestion.findings633':
      'Zinssatz erhöhte auch im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings634':
      'Da der Zinssatz und die Dichte in diesem Bereich konstant bleibt bei gleichzeitiger Erhöhung der Zunahme der in diesem Bereich verbrachte Zeit nicht einen positiven Effekt auf den Umsatz haben.',
    'suggestion.findings640':
      'In der Gegend, die Zahl der Menschen in Übereinstimmung mit einer früheren Datumsbereich und eine erhöhte Dichte aufgrund der Zunahme sowohl in der Zeit verbrachte.',
    'suggestion.findings641':
      'Dieser Anstieg wird erwartet, direkten Umsatz zu steigern, blieb der Umsatz konstant.',
    'suggestion.findings642':
      ' Potential im Bereich könnte das Ergebnis der zunehmenden Dichte, nicht ausgenutzt werden.',
    'suggestion.findings643':
      'Es ist ein gutes Ergebnis, dass es keinen Umsatzrückgang ist.',
    'suggestion.findings644':
      'Denn nur das erhöhte Potenzial nicht genutzt werden konnte, setzte sich der bisherige Umsatz Menge geschützt werden.',
    'suggestion.findings650':
      'In der Gegend, die Zahl der Menschen in Übereinstimmung mit einer früheren Datumsbereich und eine erhöhte Dichte aufgrund der Zunahme sowohl in der Zeit verbrachte.',
    'suggestion.findings651':
      'Trotz der Dichte erhöht, verringerte sich der Umsatz.',
    'suggestion.findings652':
      'Als Zinssatz, verbrachte die Zeit und die Zahl der Menschen steigt, sinken die Verkäufe, so wird der Bereich nicht die Fähigkeit haben, die zunehmende Dichte gerecht zu werden.',
    'suggestion.findings660':
      'In der Gegend, die Zahl der Menschen in Übereinstimmung mit einer früheren Datumsbereich und eine erhöhte Dichte aufgrund der Zunahme sowohl in der Zeit verbrachte.',
    'suggestion.findings661':
      'Während die Zahl der Menschen und der Zeit erhöht, verringert der Zinssatz in der Gegend.',
    'suggestion.findings670':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings671':
      'Der Grund für den Anstieg ist die Zunahme der Anzahl der Personen und Zeitparameter beobachtet, dass die Dichte erzeugen.',
    'suggestion.findings672':
      'Der Umsatzanstieg mit zunehmender Dichte zeigt an, dass das Potenzial in dem Bereich sehr hoch ist.',
    'suggestion.findings680':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings681':
      'Der Grund für den Anstieg ist die Erhöhung der Zeit auf der Person und Zeitparameter ausgegeben, die die Dichte erzeugt people.',
    'suggestion.findings682':
      'Eine erhöhte Dichte auch Umsatz erhöht, es zeigt, dass es ein großes Potenzial und eine hohe Produktivität im Vertrieb in der Region.',
    'suggestion.findings690':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings691':
      'Der Grund für den Anstieg ist die Erhöhung der Zeit auf der Person und Zeitparameter ausgegeben, die die Dichte erzeugt.',
    'suggestion.findings692':
      'Die Erhöhung der Dichte wird erwartet, den Umsatz zu steigern, während der Umsatz konstant blieb.',
    'suggestion.findings693':
      'Da der Anstieg in diesem Bereich in der Dichte der Zunahme der Zeit fällig ist, verbringen die längere Zeit Kunden in diesem Bereich keine positive Wirkung auf den Umsatz hat people.',
    'suggestion.findings694':
      'Wenn es die Zeit weiter zu erhöhen, kann es sich negativ auf Umsatz in der nächsten Stufe betroffen sein.',
    'suggestion.findings700':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings701':
      'Der Grund für den Anstieg ist die Erhöhung der Zeit auf der Person und Zeitparameter ausgegeben, die die Dichte erzeugt people.',
    'suggestion.findings702':
      'Die Erhöhung der Dichte wird erwartet, den Umsatz zu steigern, während der Umsatz konstant blieb.',
    'suggestion.findings703':
      'Da der Anstieg in diesem Bereich in der Dichte der Zunahme der Zeit fällig ist, verbringen die längere Zeit Kunden in diesem Bereich keine positive Wirkung auf den Umsatz hat people.',
    'suggestion.findings704':
      'Wenn es die Zeit weiter zu erhöhen, kann es sich negativ auf Umsatz in der nächsten Stufe betroffen sein.',
    'suggestion.findings710':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings711':
      'Der Grund für den Anstieg ist die Erhöhung der Zeit auf der Person und Zeitparameter ausgegeben, die die Dichte erzeugt people.',
    'suggestion.findings712':
      'Die Zahl der Menschen in der Region mit 15 Sekunden und im Laufe der Zeit erhöhen stehen hat ebenfalls zugenommen.',
    'suggestion.findings713':
      'Dementsprechend begann die überwiegende Mehrheit der Kunden auf den Bereich eine sehr lange Zeit zu verbringen.',
    'suggestion.findings714':
      'Der Umsatz ging im Laufe der Zeit erhöht. Das heißt, stellt Zeit Anstieg eine Bedrohung für die Verkäufe people.',
    'suggestion.findings720':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings721':
      'Der Grund für den Anstieg ist die Erhöhung der Zeit auf der Person und Zeitparameter ausgegeben, die die Dichte erzeugt people.',
    'suggestion.findings722':
      'Trotz der Anstiegszeit, 15 Sekunden und über abgenommen hat die Zahl der Menschen stehen.',
    'suggestion.findings723':
      'Da der Zinssatz in der Zeit abnimmt, trotz der Erhöhung, die von den Kunden im Bereich verbrachte Zeit ist nicht sehr lang people.',
    'suggestion.findings730':
      'Die Dichte der Bereiche blieb konstant aus dem vorherigen Zeitraum.',
    'suggestion.findings731':
      'Dies liegt daran, die Dichte der Menschen und die Zahl der Menschen, die die Zeitparameter bilden ist die Reduzierung der Zeit erhöht.',
    'suggestion.findings732':
      'Die Erhöhung des Zinssatzes und die Zahl der Menschen hat sich der Umsatz im Bereich erhöht. Alana hat über die Kunden kommen.',
    'suggestion.findings733': 'Umsatzpotenzial im Bereich hoch ist.',
    'suggestion.findings740':
      'Die Dichte der Bereiche konstant blieb im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings741':
      'Dies ist wegen der Zeit und Parameter, die die Dichte erzeugen, während der Zeit, während die Zahl der Menschen steigt ab.',
    'suggestion.findings742':
      'Da die Zeit und Zinssatz ab, und die Zahl der Menschen steigt, kann es zu einer Buy-and-Go-Produktgruppe in diesem Bereich sein people.',
    'suggestion.findings743':
      'Der Bereich Vertriebsproduktivität und Potential erhöht.',
    'suggestion.findings750':
      'Die Dichte der Bereiche konstant blieb im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings751':
      'Dies ist wegen der Zeit und Parameter, die die Dichte erzeugen, während der Zeit, während die Zahl der Menschen steigt ab.',
    'suggestion.findings752':
      'Da die Zahl der Menschen steigen und die Zeit abnimmt, ist der Zeitfaktor entscheidend Produkte aus diesem Bereich zu erwerben.',
    'suggestion.findings760':
      'Die Dichte der Bereiche konstant blieb im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings761':
      'Dies ist wegen der Zeit und Parameter, die die Dichte erzeugen, während der Zeit, während die Zahl der Menschen steigt ab.',
    'suggestion.findings762':
      'Wenn die Zeit weiter abnimmt, kann dies auch einen Rückgang für den Vertrieb verursachen people.',
    'suggestion.findings770':
      '24Die Dichte der Bereiche konstant blieb im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings771':
      'Dies ist wegen der Zeit und Parameter, die die Dichte erzeugen, während der Zeit, während die Zahl der Menschen steigt ab.',
    'suggestion.findings772':
      'Nach der Erhöhung der Zahl der Menschen in hohen Potential Bereichen.',
    'suggestion.findings780':
      'Die Dichte der Bereiche konstant blieb im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings781':
      'Dies ist wegen der Zeit und Parameter, die die Dichte erzeugen, während der Zeit, während die Zahl der Menschen steigt ab.',
    'suggestion.findings782':
      'Nach der Erhöhung der Zahl der Menschen in hohen Potential Bereichen.',
    'suggestion.findings783':
      'Der Rückgang des Zinssatzes und der Zeit wirkten sich negativ auf den Umsatz',
    'suggestion.findings790':
      'Verwandtes Gebiet, auf der Reduzierung der Periode je erhöhte Anzahl von Menschen im Vergleich zum vorherigen Zeitraum Dichte konstant geblieben ist.',
    'suggestion.findings791':
      'Obwohl die Dichte konstant war, verbrachte die Erhöhung der Zeit und der Zinssatz den Umsatz erhöht.',
    'suggestion.findings800':
      'Die Dichte der Fläche konstant blieb aufgrund der Abnahme der Zahl der Menschen, während die Zeit zum vorherigen Zeitraum erhöhte sich im Vergleich.',
    'suggestion.findings801':
      'Obwohl die Dichte konstant war, verbrachte die Erhöhung der Zeit, um die Verkäufe erhöht people.',
    'suggestion.findings802':
      'Da der Zinssatz sinkt und Umsatz zu steigern, ist der Bereich, ein schneller und effizienten Vertriebsbereich people.',
    'suggestion.findings810':
      'Die Dichte der Fläche konstant blieb aufgrund der Abnahme der Zahl der Menschen, während die Zeit zum vorherigen Zeitraum erhöhte sich im Vergleich.',
    'suggestion.findings811':
      'Obwohl der Zinssatz erhöht, während die Dichte konstant war, im Bereich der Umsatz nicht ändern people.',
    'suggestion.findings812':
      'Dementsprechend 15 Sekunden über den Bereich, den Kunden und den Umsatz zu steigern, Zeit zu verbringen, ist nicht kritisch.',
    'suggestion.findings820':
      'Die Dichte der Fläche konstant blieb aufgrund der Abnahme der Zahl der Menschen, während die Zeit zum vorherigen Zeitraum erhöhte sich im Vergleich.',
    'suggestion.findings821':
      'Obwohl der Zinssatz verringert, während die Dichte konstant war, im Bereich der Umsatz nicht ändern people.',
    'suggestion.findings822':
      'Wenn die Verkaufsrate unter dem Zinssatz war, konnte der potenzielle Kunde nicht in Umsatz umgewandelt werden.',
    'suggestion.findings830':
      'Die Dichte der Fläche konstant blieb aufgrund der Abnahme der Zahl der Menschen, während die Zeit zum vorherigen Zeitraum erhöhte sich im Vergleich.',
    'suggestion.findings831':
      'Dichte hat die Zinsen erhöht stetig, und verringerte sich der Umsatz in der Region.',
    'suggestion.findings832':
      'Während der Zinserhöhungen zeigt der Rückgang der Verkäufe, dass der Kunde, der eine lange Zeit auf dem Gebiet verbracht nicht finden kann, was sie suchten oder die Gegend verlassen, ohne zu kaufen aufgrund der langen Wartezeit people.',
    'suggestion.findings840':
      'Die Dichte der Fläche konstant blieb aufgrund der Abnahme der Zahl der Menschen, während die Zeit zum vorherigen Zeitraum erhöhte sich im Vergleich.',
    'suggestion.findings841':
      'Dichte Zinssatz und verringerte sich der Umsatz im konstanten Bereich.',
    'suggestion.findings842':
      'Dementsprechend hat eine direkte negative Auswirkung der Verringerung der Zahl der Menschen auf den Umsatz, die mehr als 15 Sekunden und die Gegend hatte.',
    'suggestion.findings850':
      'Während die Zahl der Menschen in der Region zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings851':
      'Der Zinssatz ist auch wegen der zunehmenden Zahl der Menschen steigt im Vergleich zum vorherigen Zeitraum people.',
    'suggestion.findings852':
      'Da die Anzahl der Personen und der Zinssatz erhöht, die Zahl der Kunden kommen und aus dem Bereich vorbei erhöhte signifikant die Zeit verringert people.',
    'suggestion.findings860':
      'Während die Zahl der Menschen in der Region zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings861':
      'Da der Zinssatz und die Zeit ab, während der Umsatz erhöht, kann das Produkt im Bereich ein schnell kaufen und geht Produkt.',
    'suggestion.findings870':
      'Während die Zahl der Menschen in der Region zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings871':
      'Da die Zahl der Menschen erhöht und die Zeit verringert wird, erhöht den Zinssatz, so viele Menschen in die Gegend kam people.',
    'suggestion.findings872':
      'Die Erhöhung des Zinssatzes war auf die erwartete Nutzen für die Verkäufe nicht zuwiderläuft.',
    'suggestion.findings880':
      'Während die Zahl der Menschen in der Region zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings881':
      'Seit der Zeit und Zinssenkung, wie die Person zunimmt, wird die Zeit durch die Kunden im Bereich verbrachte weniger people.',
    'suggestion.findings882':
      'Dies kann, weil das Produkt die Aufmerksamkeit nicht angezogen hat oder der Kunde diesen Bereich aufgrund eines Problems mit dem Laden Plan bestanden hat people.',
    'suggestion.findings890':
      'Während die Zahl der Menschen in der Region zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings891':
      'Interessierte Kunden werden erwartet, um das Produkt zu kaufen.',
    'suggestion.findings900':
      'Während die Zahl der Menschen in der Region zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings910':
      'Während der Zeit in der Gegend zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte auf den Rückgang der Zahl der Menschen aufgrund.',
    'suggestion.findings911':
      'Die Zahl der Mitarbeiter, die Produktpreise sind optimale Balance in diesem Bereich.',
    'suggestion.findings912':
      'Der Bereich erhöht die Produktivität im Vertrieb.',
    'suggestion.findings920':
      'Während der Zeit in der Gegend zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte auf den Rückgang der Zahl der Menschen wegen.',
    'suggestion.findings921':
      'Da die Geschwindigkeit in diesem Bereich von Interesse verringert und den Umsatz steigern, ist Interesse kein Faktor Umsatz steigern people.',
    'suggestion.findings922':
      'Dementsprechend ist es wahrscheinlich, dass eine schnelle Verkaufsprodukte in diesem Bereich verkauft werden.',
    'suggestion.findings930':
      'Während der Zeit in der Gegend zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte auf den Rückgang der Zahl der Menschen aufgrund.',
    'suggestion.findings940':
      'Während der Zeit in der Gegend zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte auf den Rückgang der Zahl der Menschen aufgrund.',
    'suggestion.findings941':
      'Da die Geschwindigkeit in diesem Bereich von Interesse nimmt ab und Vertrieb konstant bleibt, ist es kein Interesse Faktor Umsatz steigern people.',
    'suggestion.findings942':
      'Dementsprechend ist es wahrscheinlich, dass eine schnelle Verkaufsprodukte in diesem Bereich verkauft werden.',
    'suggestion.findings950':
      'Während der Zeit in der Gegend zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte auf den Rückgang der Zahl der Menschen aufgrund people.',
    'suggestion.findings960':
      'Während der Zeit in der Gegend zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte auf den Rückgang der Zahl der Menschen aufgrund people.',
    'suggestion.findings961':
      'Da die Geschwindigkeit in diesem Bereich von Interesse verringert und Umsatzrückgang auch wirkt sich die Zinsen Umsatz people.',
    'suggestion.findings970':
      'Im Bereich verringerte sich die Dichte aufgrund der Abnahme sowohl in der Anzahl der Personen und die verbrachte Zeit im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings971':
      'Da die Zahl der Menschen nimmt und den Umsatz steigern, Umsatz zu fast jedem Kunden gemacht people.',
    'suggestion.findings972':
      'Da die Umsätze steigern, während die Zeit abnimmt, den Umsatz im Bereich rasch realisiert werden.',
    'suggestion.findings973':
      'Wenn die Zahl der Menschen, während der Zinserhöhungen ab und die Zeit abnimmt, ist die Zahl der Menschen in der Gegend kommen zu hoch people.',
    'suggestion.findings980':
      'Im Bereich verringerte sich die Dichte aufgrund der Abnahme sowohl in der Anzahl der Personen und die verbrachte Zeit im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings981':
      'Der Zeitfaktor ist unwirksam in diesem Bereich, da der Zinssatz und die Zeit ab, während Umsatz zu steigern. Eine schnelle kaufen und Go-Produktlinie kann im Bereich zum Verkauf stehen.',
    'suggestion.findings990':
      'Im Bereich verringerte sich die Dichte aufgrund der Abnahme sowohl in der Anzahl der Personen und die verbrachte Zeit im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings991':
      'Der Rückgang der Person und Zeit in diesem Bereich änderte sich nicht den Verkauf.',
    'suggestion.findings1000':
      'In den entsprechenden Bereich, die Zahl der Menschen im Vergleich zum vorherigen Zeitraum und die Dichte verringert hat aufgrund der reduzierten sowohl die Zeit damit verbracht.',
    'suggestion.findings1010':
      'In den entsprechenden Bereich, die Zahl der Menschen im Vergleich zum vorherigen Zeitraum und die Dichte verringert hat aufgrund der reduzierten sowohl die Zeit damit verbracht.',
    'suggestion.findings1011':
      'Obwohl die Zahl der Kunden in diesem Bereich erhöht, den Umsatz steigerte nicht.',
    'suggestion.findings1012':
      'Das Interesse konnte nicht die erforderliche Antwort finden, wurde Potential nicht genutzt.',
    'suggestion.findings1020':
      'Im Bereich verringerte sich die Dichte aufgrund der Abnahme sowohl in der Anzahl der Personen und die verbrachte Zeit im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1021':
      'Die Dichte, die Verkäufe und der Zinssatz im Bereich gesunken.',
    'suggestion.findings1022':
      'Dementsprechend hat das schlimmste mögliche Szenario wahr werden.',
    'suggestion.findings1030':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1031':
      'Der Grund für den Anstieg ist die Zunahme der Zahl der Menschen unter den Menschen und Zeitparametern, die die Dichte erzeugt.',
    'suggestion.findings1032':
      'Der Umsatzanstieg mit zunehmender Dichte zeigt an, dass das Potenzial in dem Bereich sehr hoch ist.',
    'suggestion.findings1033':
      'Die Zinssätze konstant blieben im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1034':
      'Obwohl die Rate in diesem Bereich von Interesse konstant bleibt, da die Verkäufe zu erhöhen, ist die Wirkung der Zahl der Menschen auf dem Umsatz größer als die Zeit in diesem Bereich ausgegeben.',
    'suggestion.findings1040':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1041':
      'Der Grund für den Anstieg ist die Zunahme der Zahl der Menschen unter den Menschen und Zeitparametern, die die Dichte erzeugt people.',
    'suggestion.findings1042':
      ' Die Erhöhung der Dichte wird erwartet, Verkäufe direkt zu erhöhen.',
    'suggestion.findings1043':
      ' Trotz diesem, während Dichte im Bereich zum vorherigen Datumsbereich im Vergleich zu, blieb der Umsatz konstant, so dass der Bereich den möglichen Verkauf erreichen gescheitert people.',
    'suggestion.findings1050':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1051':
      ' Der Grund für den Anstieg ist die Zunahme der Zahl der Menschen unter den Menschen und Zeitparametern, die die Dichte erzeugt people.',
    'suggestion.findings1052':
      ' Dieser Bereich hat konstante Dichte erhöhte sich der Umsatz sank im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1053':
      'Dementsprechend trotz der Möglichkeit, im Bereich, hat das Gebiet gescheitert potenziellen Umsatz zu erreichen.',
    'suggestion.findings1060':
      'Im Bereich erhöhte sich die Dichte durch die Zunahme sowohl der Anzahl der Personen und die verbrachte Zeit im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1061':
      'Dieser Anstieg wird erwartet, Verkäufe direkt zu erhöhen.',
    'suggestion.findings1062':
      'Der Umsatzanstieg mit zunehmender Dichte zeigt an, dass das Potenzial in dem Bereich sehr hoch ist.',
    'suggestion.findings1063':
      'Die Zinssätze konstant blieben im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1070':
      'Im Bereich erhöhte sich die Dichte durch die Zunahme sowohl der Anzahl der Personen und die verbrachte Zeit im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1071':
      'Dieser Anstieg wird erwartet, direkten Umsatz zu steigern, blieb der Umsatz konstant.',
    'suggestion.findings1072':
      ' Potential im Bereich könnte das Ergebnis der zunehmenden Dichte, nicht ausgenutzt werden.',
    'suggestion.findings1073':
      'Es ist ein gutes Ergebnis, dass es keinen Umsatzrückgang ist.',
    'suggestion.findings1074':
      'Denn nur das erhöhte Potenzial nicht genutzt werden konnte, setzte sich der bisherige Umsatz Menge geschützt werden.',
    'suggestion.findings1080':
      'Im Bereich erhöhte sich die Dichte durch die Zunahme sowohl der Anzahl der Personen und die verbrachte Zeit im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1081':
      'Während die Zahl der Menschen und der Zeit erhöht, blieb das Interesse im Bereich konstant.',
    'suggestion.findings1090':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1091':
      'Der Grund für den Anstieg ist die Erhöhung der Zeit auf der Person und Zeitparameter ausgegeben, die die Dichte erzeugt people.',
    'suggestion.findings1092':
      'Eine erhöhte Dichte auch Umsatz erhöht, es zeigt, dass es ein großes Potenzial und eine hohe Produktivität im Vertrieb in der Region.',
    'suggestion.findings1100':
      '57Die Dichte der Flächen hat sich im Vergleich zu dem vorherigen Zeitpunkt Bereich erhöht.',
    'suggestion.findings1101':
      'Der Grund für den Anstieg ist die Erhöhung der Zeit auf der Person und Zeitparameter ausgegeben, die die Dichte erzeugt people.',
    'suggestion.findings1102':
      'Die Erhöhung der Dichte wird erwartet, den Umsatz zu steigern, während der Umsatz konstant blieb.',
    'suggestion.findings1103':
      'Da der Anstieg in diesem Bereich in der Dichte der Zunahme der Zeit fällig ist, verbringen die längere Zeit Kunden in diesem Bereich keine positive Wirkung auf den Umsatz hat people.',
    'suggestion.findings1110':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1111':
      'Der Grund für den Anstieg ist die Erhöhung der Zeit auf der Person und Zeitparameter ausgegeben, die die Dichte erzeugt people.',
    'suggestion.findings1112':
      'Obwohl die Zeit erhöht, die Zahl der Menschen für 15 Sekunden oder mehr stabil geblieben.',
    'suggestion.findings1113':
      'Da der Zinssatz, obwohl die Zeit steigt konstant bleibt, ist die Zeit, die von den Kunden im Bereich verbrachte die Regel nicht mehr als 15 Sekunden.',
    'suggestion.findings1120':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1121':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1122':
      'Der Umsatzanstieg mit zunehmender Dichte zeigt an, dass das Potenzial in dem Bereich sehr hoch ist.',
    'suggestion.findings1123':
      'Zinssatz erhöhte auch im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1124':
      'Da der Zinssatz und Intensitätssteigerung in diesem Bereich, die Höhe der Umsatzsteigerungen, so dass die in diesem Bereich verbrachte Zeit hat einen großen Einfluss auf den Umsatz.',
    'suggestion.findings1130':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1131':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1132':
      'Der Umsatzanstieg mit zunehmender Dichte zeigt an, dass das Potenzial in dem Bereich sehr hoch ist.',
    'suggestion.findings1133':
      'Der Zinssatz wurde in den vorangegangenen Zeitraum sank im Vergleich.',
    'suggestion.findings1134':
      'Obwohl die Rate in diesem Bereich von Interesse verringert hat, da die Verkäufe zu erhöhen, ist die Wirkung der Zahl der Menschen auf dem Umsatz mehr als die Zeit, in diesem Bereich ausgegeben.',
    'suggestion.findings1140':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1141':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1142':
      'Der Umsatzanstieg mit zunehmender Dichte zeigt an, dass das Potenzial in dem Bereich sehr hoch ist.',
    'suggestion.findings1143':
      'Die Zinssätze konstant blieben im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1144':
      'Obwohl die Rate in diesem Bereich von Interesse konstant bleibt, da die Verkäufe zu erhöhen, ist die Wirkung der Zahl der Menschen auf dem Umsatz größer als die Zeit in diesem Bereich ausgegeben.',
    'suggestion.findings1150':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1151':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1152':
      'Die Erhöhung der Dichte wird erwartet, Verkäufe direkt zu erhöhen.',
    'suggestion.findings1153':
      'Trotz diesem, während Dichte im Bereich zum vorherigen Datumsbereich im Vergleich zu, blieb der Umsatz konstant, so dass der Bereich den möglichen Verkauf erreichen gescheitert people.',
    'suggestion.findings1160':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1161':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1162':
      'Die Erhöhung der Dichte wird erwartet, Verkäufe direkt zu erhöhen.',
    'suggestion.findings1163':
      'Trotz diesem, während Dichte im Bereich zum vorherigen Datumsbereich im Vergleich zu, blieb der Umsatz konstant, so dass der Bereich den möglichen Verkauf erreichen gescheitert people.',
    'suggestion.findings1170':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1171':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1172':
      'Die Erhöhung der Dichte wird erwartet, Verkäufe direkt zu erhöhen.',
    'suggestion.findings1173':
      'Trotz diesem, während Dichte im Bereich zum vorherigen Datumsbereich im Vergleich zu, blieb der Umsatz konstant, so dass der Bereich den möglichen Verkauf erreichen gescheitert people.',
    'suggestion.findings1180':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1181':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1182':
      'In diesem Bereich stieg der Umsatz im Vergleich zum vorherigen Zeitraum Dichte konstant geblieben.',
    'suggestion.findings1183':
      'Dementsprechend kann, obwohl es eine Möglichkeit in der Gegend war, konnte der Bereich potenziellen Umsatz erreichen.',
    'suggestion.findings1184':
      'Der Zinssatz ist gestiegen im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1185':
      'Demzufolge; Der Grund, warum die Erhöhung der Dichte nicht den Umsatz auswirkt kann sein, dass der Kunde in der Gegend zu viel Zeit damit verbringt, aber nicht finden können, was er will.',
    'suggestion.findings1190':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1191':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1192':
      'Dieser Bereich hat konstante Dichte erhöhte sich der Umsatz sank im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1193':
      'Dementsprechend kann, obwohl es eine Möglichkeit in der Gegend war, konnte der Bereich potenziellen Umsatz erreichen.',
    'suggestion.findings1200':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1201':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1202':
      'Dieser Bereich hat konstante Dichte erhöhte sich der Umsatz sank im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1203':
      'Dementsprechend kann, obwohl es eine Möglichkeit in der Gegend war, konnte der Bereich potenziellen Umsatz erreichen.',
    'suggestion.findings1210':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1211':
      'Der Grund für den Anstieg ist die Zunahme der verbrachte Zeit, während die Zahl der Menschen konstant ist, unter den Menschen und Zeitparameter, die bis die Dichte machen people.',
    'suggestion.findings1212':
      'Der Umsatzanstieg mit zunehmender Dichte zeigt an, dass das Potenzial in dem Bereich sehr hoch ist.',
    'suggestion.findings1220':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1221':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1222':
      'Der Umsatzanstieg mit zunehmender Dichte zeigt an, dass das Potenzial in dem Bereich sehr hoch ist.',
    'suggestion.findings1223':
      'Der Zinssatz wurde in den vorangegangenen Zeitraum sank im Vergleich.',
    'suggestion.findings1224':
      'Obwohl die Rate in diesem Bereich von Interesse verringert hat, da die Verkäufe zu erhöhen, ist die Wirkung der Zahl der Menschen auf dem Umsatz mehr als die Zeit, in diesem Bereich ausgegeben.',
    'suggestion.findings1230':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1231':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1232':
      'Der Umsatzanstieg mit zunehmender Dichte zeigt an, dass das Potenzial in dem Bereich sehr hoch ist.',
    'suggestion.findings1233':
      'Die Zinssätze konstant blieben im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1234':
      'Obwohl die Rate in diesem Bereich von Interesse konstant bleibt, da die Verkäufe zu erhöhen, ist die Wirkung der Zahl der Menschen auf dem Umsatz größer als die Zeit in diesem Bereich ausgegeben.',
    'suggestion.findings1240':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1241':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1242':
      'Die Erhöhung der Dichte wird erwartet, Verkäufe direkt zu erhöhen.',
    'suggestion.findings1243':
      'Trotz diesem, während Dichte im Bereich zum vorherigen Datumsbereich im Vergleich zu, blieb der Umsatz konstant, so dass der Bereich den möglichen Verkauf erreichen gescheitert people.',
    'suggestion.findings1244':
      'Da der Zinssatz wurde in den vorangegangenen Zeitraum erhöhte sich im Vergleich, der Grund, warum die Erhöhung der Dichte sein nicht den Umsatz auswirken kann, dass der Kunde im Feld zu viel Zeit damit verbringt, aber nicht finden können, was sie wollen.',
    'suggestion.findings1250':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1251':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1252':
      'Die Erhöhung der Dichte wird erwartet, Verkäufe direkt zu erhöhen.',
    'suggestion.findings1253':
      'Trotz diesem, während Dichte im Bereich zum vorherigen Datumsbereich im Vergleich zu, blieb der Umsatz konstant, so dass der Bereich den möglichen Verkauf erreichen gescheitert people.',
    'suggestion.findings1254':
      'Da der Zinssatz zum vorherigen Zeitraum verringert hat, der Grund, warum die Erhöhung der Dichte nicht den Umsatz auswirkt kann sein, dass der Kunde nicht genug Zeit, sich in der Gegend verbringen.',
    'suggestion.findings1260':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1261':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1262':
      'Die Erhöhung der Dichte wird erwartet, Verkäufe direkt zu erhöhen.',
    'suggestion.findings1263':
      'Trotz diesem, während Dichte im Bereich zum vorherigen Datumsbereich im Vergleich zu, blieb der Umsatz konstant, so dass der Bereich den möglichen Verkauf erreichen gescheitert people.',
    'suggestion.findings1264':
      'Da der Zinssatz konstant geblieben vorherigen Datumsbereich im Vergleich zu, dass der Grund die Zunahme der Dichte nicht die Verkäufe nicht beeinflussen kann sein, dass der Kunde genügend Zeit nicht in der Gegend verbringen.',
    'suggestion.findings1270':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1271':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1272':
      'In diesem Bereich stieg der Umsatz im Vergleich zum vorherigen Zeitraum Dichte konstant geblieben.',
    'suggestion.findings1273':
      'Dementsprechend kann, obwohl es eine Möglichkeit in der Gegend war, konnte der Bereich potenziellen Umsatz erreichen.',
    'suggestion.findings1274':
      'Der Zinssatz ist gestiegen im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1275':
      'Demzufolge; Der Grund, warum die Erhöhung der Dichte nicht den Umsatz auswirkt kann sein, dass der Kunde in der Gegend zu viel Zeit damit verbringt, aber nicht finden können, was er will.',
    'suggestion.findings1280':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1281':
      'Der Grund für den Anstieg ist, dass die Zahl der Menschen steigt, während die Zeitkonstante ist, eine der Person und Zeitparameter, die die Dichte erzeugt.',
    'suggestion.findings1282':
      'Dieser Bereich hat konstante Dichte erhöhte sich der Umsatz sank im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1283':
      'Dementsprechend kann, obwohl es eine Möglichkeit in der Gegend war, konnte der Bereich potenziellen Umsatz erreichen.',
    'suggestion.findings1284':
      'Da der Zinssatz zum vorherigen Zeitraum verringert hat, der Grund, warum die Erhöhung der Dichte nicht den Umsatz auswirkt kann sein, dass der Kunde nicht genug Zeit, sich in der Gegend verbringen.',
    'suggestion.findings1290':
      'Die Dichte der Bereiche hat sich im Vergleich zum vorherigen Zeitraum erhöht.',
    'suggestion.findings1291':
      'Der Grund für den Anstieg ist die Zunahme der verbrachte Zeit, während die Zahl der Menschen konstant ist, unter den Menschen und Zeitparameter, die bis die Dichte machen people.',
    'suggestion.findings1292':
      'Obwohl die Zeit erhöht, die Zahl der Menschen für 15 Sekunden oder mehr stabil geblieben.',
    'suggestion.findings1293':
      'Da der Zinssatz, obwohl die Zeit steigt konstant bleibt, ist die Zeit, die von den Kunden im Bereich verbrachte die Regel nicht mehr als 15 Sekunden.',
    'suggestion.findings1300':
      'Die Dichte der Bereiche konstant blieb im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1301':
      'Dies ist wegen der Zeit und Parameter, die die Dichte erzeugen, während der Zeit, während die Zahl der Menschen steigt ab.',
    'suggestion.findings1302':
      'Im Laufe der Zeit abnimmt, kann der Zinssatz konstant bleibt und die Anzahl der Personen und den Verkauf erhöht sich, da in diesem Bereich ein Go-to-Produktgruppe sein.',
    'suggestion.findings1303':
      'Der Bereich Vertriebsproduktivität und Potential erhöht.',
    'suggestion.findings1310':
      'Die Dichte der Bereiche konstant blieb im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1311':
      'Dies ist wegen der Zeit und Parameter, die die Dichte erzeugen, während der Zeit, während die Zahl der Menschen steigt ab.',
    'suggestion.findings1312':
      'Wenn die Zeit weiter abnimmt, kann dies auch einen Rückgang für den Vertrieb verursachen people.',
    'suggestion.findings1320':
      'Die Dichte der Bereiche konstant blieb im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1321':
      'Dies ist wegen der Zeit und Parameter, die die Dichte erzeugen, während der Zeit, während die Zahl der Menschen steigt ab.',
    'suggestion.findings1322':
      'Da die Zahl der Menschen steigt, ist das Potenzial im Bereich hoch. Während die Zinsen konstant war, hatte die Abnahme der Zeit einen negativen Einfluss auf den Umsatz.',
    'suggestion.findings1330':
      'Die Dichte der Fläche konstant blieb aufgrund der Abnahme der Zahl der Menschen, während die Zeit zum vorherigen Zeitraum erhöhte sich im Vergleich.',
    'suggestion.findings1331':
      'Obwohl die Dichte konstant war, verbrachte die Erhöhung der Zeit, um die Verkäufe erhöht people.',
    'suggestion.findings1332':
      'Während der Zinssatz nicht ändert, kann der Bereich eine schnelle und effiziente Verkaufsfläche sein, da die Umsätze steigern.',
    'suggestion.findings1340':
      'Die Dichte der Fläche konstant blieb aufgrund der Abnahme der Zahl der Menschen, während die Zeit zum vorherigen Zeitraum erhöhte sich im Vergleich.',
    'suggestion.findings1341':
      'Während die Dichte und Zins konstant waren, blieben die Umsätze im Bereich ebenfalls konstant.',
    'suggestion.findings1342':
      'Wenn die Verkaufsrate unter dem Zinssatz war, konnte der potenzielle Kunde nicht in Umsatz umgewandelt werden.',
    'suggestion.findings1350':
      'Die Dichte der Fläche konstant blieb aufgrund der Abnahme der Zahl der Menschen, während die Zeit zum vorherigen Zeitraum erhöhte sich im Vergleich.',
    'suggestion.findings1351':
      'Dichte und Zins verringerte sich der Umsatz im konstanten Bereich.',
    'suggestion.findings1352':
      'Dementsprechend hatte der Rückgang der Zahl der Menschen in der Region einen negativen Effekt auf den Umsatz direkt.',
    'suggestion.findings1360':
      'Die Dichte blieb konstant in der Umgebung wie die Zeit und die Anzahl der Menschen blieb konstant im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1361':
      'Obwohl die Dichte konstant war, stieg die Erhöhung des Zinssatzes den Umsatz.',
    'suggestion.findings1370':
      'In Abhängigkeit von der Dichte der entsprechenden Bereiche bleibt konstant, während die Zahl der Menschen im Vergleich zum vorherigen Zeitraum und die Dichte konstant blieb.',
    'suggestion.findings1371': 'Obwohl Dichte konstant war, stieg der Umsatz.',
    'suggestion.findings1372':
      'Der Grund hierfür ist der Rückgang der Zahl der Menschen, die 15 Sekunden verbringen oder mehr beträgt, kann die Fläche eine schnelle und effiziente Verkaufsfläche sein.',
    'suggestion.findings1380':
      'In Abhängigkeit von der Dichte der entsprechenden Bereiche bleibt konstant, während die Zahl der Menschen im Vergleich zum vorherigen Zeitraum und die Dichte konstant blieb.',
    'suggestion.findings1381': 'Obwohl Dichte konstant war, stieg der Umsatz.',
    'suggestion.findings1382':
      'Der Grund hierfür ist der Rückgang der Zahl der Menschen, die 15 Sekunden verbringen oder mehr beträgt, kann die Fläche eine schnelle und effiziente Verkaufsfläche sein.',
    'suggestion.findings1390':
      'In Abhängigkeit von der Dichte der entsprechenden Bereiche bleibt konstant, während die Zahl der Menschen im Vergleich zum vorherigen Zeitraum und die Dichte konstant blieb.',
    'suggestion.findings1391':
      'Obwohl der Zinssatz erhöht, während die Dichte konstant war, im Bereich der Umsatz nicht ändern people.',
    'suggestion.findings1392':
      'Dementsprechend ist es nicht von Bedeutung für die Kunden 15 Sekunden zu verbringen oder mehr im Bereich Umsatz zu steigern.',
    'suggestion.findings1393':
      'Im Gegenteil kann die Zunahme der Zeit von Personalmangel oder Kunden Unfähigkeit zurückzuführen sein, zu finden, was sie suchen, eine Bedrohung für die Vertriebseffizienz aufwirft.',
    'suggestion.findings1400':
      'In Abhängigkeit von der Dichte der entsprechenden Bereiche bleibt konstant, während die Zahl der Menschen im Vergleich zum vorherigen Zeitraum und die Dichte konstant blieb.',
    'suggestion.findings1401':
      'Obwohl der Zinssatz verringert, während die Dichte konstant war, im Bereich der Umsatz nicht ändern people.',
    'suggestion.findings1402':
      'Wenn die Verkaufsrate unter dem Zinssatz war, konnte der potenzielle Kunde nicht in Umsatz umgewandelt werden.',
    'suggestion.findings1410':
      'In Abhängigkeit von der Dichte der entsprechenden Bereiche bleibt konstant, während die Zahl der Menschen im Vergleich zum vorherigen Zeitraum und die Dichte konstant blieb.',
    'suggestion.findings1411':
      'Während die Dichte und Zins konstant waren, blieben die Umsätze im Bereich ebenfalls konstant.',
    'suggestion.findings1412':
      'Wenn die Verkaufsrate unter dem Zinssatz war, konnte der potenzielle Kunde nicht in Umsatz umgewandelt werden.',
    'suggestion.findings1420':
      'In Abhängigkeit von der Dichte der entsprechenden Bereiche bleibt konstant, während die Zahl der Menschen im Vergleich zum vorherigen Zeitraum und die Dichte konstant blieb.',
    'suggestion.findings1421':
      'Dichte hat die Zinsen erhöht stetig, und verringerte sich der Umsatz in der Region.',
    'suggestion.findings1422':
      'Der Umsatzrückgang, während die Zinserhöhungen können darauf hindeuten, dass der Kunde, der eine lange Zeit in der Gegend verbracht nicht finden kann, was sie suchten oder die Gegend verließ aufgrund der langen Wartezeit, ohne zu kaufen.',
    'suggestion.findings1430':
      '90Depending von der Dichte der entsprechenden Bereiche bleibt konstant, während die Zahl der Menschen zum vorherigen Datumsbereich verglichen und Dichte konstant blieb.',
    'suggestion.findings1431':
      'Dichte Zinssatz und verringerte sich der Umsatz im konstanten Bereich.',
    'suggestion.findings1432':
      'Dementsprechend hat eine direkte negative Auswirkung der Verringerung der Zahl der Menschen auf den Umsatz, die mehr als 15 Sekunden und die Gegend hatte.',
    'suggestion.findings1440':
      'In Abhängigkeit von der Dichte der entsprechenden Bereiche bleibt konstant, während die Zahl der Menschen im Vergleich zum vorherigen Zeitraum und die Dichte konstant blieb.',
    'suggestion.findings1441':
      'Dichte und Zins verringerte sich der Umsatz im konstanten Bereich.',
    'suggestion.findings1450':
      'Während die Zahl der Menschen in der Region zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings1451':
      'Zinssatz konstant und Zeit verringerte sich der Umsatz nach Produkten im Bereich der Kunst, die das Produkt schnell erhalten gehen.',
    'suggestion.findings1460':
      'Während die Zahl der Menschen in der Region zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings1461':
      'Da die Zeit, als die Person nimmt ab, und der Zinssatz konstant bleibt, verbrachte die Zeit durch den Kunden im Bereich abnimmt.',
    'suggestion.findings1462':
      'Dies kann, weil das Produkt die Aufmerksamkeit nicht angezogen hat oder der Kunde diesen Bereich aufgrund eines Problems mit dem Laden Plan bestanden hat people.',
    'suggestion.findings1470':
      'Während die Zahl der Menschen in der Region zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings1480':
      'Während der Zeit in der Gegend zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte auf den Rückgang der Zahl der Menschen aufgrund people.',
    'suggestion.findings1481':
      'Da die Umsätze in diesem Bereich der Zinssatz konstant erhöhen, während, ist Interesse nicht direkt ein Faktor Umsatz steigern.',
    'suggestion.findings1482':
      'Dementsprechend ist es wahrscheinlich, dass eine schnelle Verkaufsprodukte in diesem Bereich verkauft werden.',
    'suggestion.findings1490':
      'Während der Zeit in der Gegend zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte auf den Rückgang der Zahl der Menschen aufgrund people.',
    'suggestion.findings1500':
      'Während der Zeit in der Gegend zum vorherigen Zeitraum erhöhte sich im Vergleich verringerte sich die Dichte auf den Rückgang der Zahl der Menschen aufgrund people.',
    'suggestion.findings1501':
      'Da der Zinssatz in diesem Bereich konstant ist und Verkäufe verringern, wird das Interesse nicht direkt den Umsatz auswirken.',
    'suggestion.findings1510':
      'Im Bereich verringerte sich die Dichte aufgrund der Abnahme sowohl in der Anzahl der Personen und die verbrachte Zeit im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1511':
      'Nach Zinssatz stabil, und die Zeit verringerte sich der Umsatz steigt, während Faktor in diesem Bereich nicht direkt wirksam ist.',
    'suggestion.findings1512':
      'Im Bereich kann eine Produktgruppe schneller erhalten werden verkauft.',
    'suggestion.findings1520':
      'Im Bereich verringerte sich die Dichte aufgrund der Abnahme sowohl in der Anzahl der Personen und die verbrachte Zeit im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1521': 'Zinssatz in diesem Bereich konstant geblieben.',
    'suggestion.findings1530':
      'Im Bereich verringerte sich die Dichte aufgrund der Abnahme sowohl in der Anzahl der Personen und die verbrachte Zeit im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1531':
      'Sowohl die Dichte und Umsatz im Bereich verringert und der Zinssatz konstant blieb.',
    'suggestion.findings1532':
      'Dementsprechend hat das schlimmste mögliche Szenario wahr werden.',
    'suggestion.findings1540':
      'Während die Zeitkonstante in der zum vorherigen Datumsbereich im Vergleich Bereich war, verringerte sich die Dichte auf den Rückgang der Zahl der Menschen wegen.',
    'suggestion.findings1541':
      'Die Zahl der Mitarbeiter, die Produktpreise sind optimale Balance in diesem Bereich.',
    'suggestion.findings1542':
      'Der Bereich steigt die Produktivität des Vertriebs.',
    'suggestion.findings1550':
      'Während die Zeitkonstante in der zum vorherigen Datumsbereich im Vergleich Bereich war, verringerte sich die Dichte auf den Rückgang der Zahl der Menschen wegen.',
    'suggestion.findings1551':
      'Da die Geschwindigkeit in diesem Bereich von Interesse verringert und den Umsatz steigern, ist Interesse kein Faktor Umsatz steigern people.',
    'suggestion.findings1552':
      'Dementsprechend ist es wahrscheinlich, dass eine schnelle Verkaufsprodukte in diesem Bereich verkauft werden.',
    'suggestion.findings1560':
      'Während die Zeitkonstante in der zum vorherigen Datumsbereich im Vergleich Bereich war, verringerte sich die Dichte auf den Rückgang der Zahl der Menschen wegen.',
    'suggestion.findings1561':
      'Da die Umsätze in diesem Bereich der Zinssatz konstant erhöhen, während, ist Interesse nicht direkt ein Faktor Umsatz steigern.',
    'suggestion.findings1562':
      'Dementsprechend ist es wahrscheinlich, dass eine schnelle Verkaufsprodukte in diesem Bereich verkauft werden.',
    'suggestion.findings1570':
      'Während die Zeitkonstante in der zum vorherigen Datumsbereich im Vergleich Bereich war, verringerte sich die Dichte auf den Rückgang der Zahl der Menschen wegen.',
    'suggestion.findings1580':
      'Während die Zeitkonstante in der zum vorherigen Datumsbereich im Vergleich Bereich war, verringerte sich die Dichte auf den Rückgang der Zahl der Menschen wegen.',
    'suggestion.findings1581':
      'Da der Zinssatz in diesem Bereich konstant ist und der Umsatz konstant bleiben, Zinsen (Ausgaben 15 Sekunden oder mehr im Feld) ist kein direkter Faktor Umsatz steigern.',
    'suggestion.findings1582':
      'Dementsprechend ist es wahrscheinlich, dass eine schnelle Verkaufsprodukte in diesem Bereich verkauft werden.',
    'suggestion.findings1590':
      'Während die Zeitkonstante in der zum vorherigen Datumsbereich im Vergleich Bereich war, verringerte sich die Dichte auf den Rückgang der Zahl der Menschen wegen.',
    'suggestion.findings1600':
      'Während die Zeitkonstante im Bereich blieb im Vergleich zum vorherigen Zeitraum verringerte sich die Dichte auf den Rückgang der Zahl der Menschen wegen.',
    'suggestion.findings1610':
      'Während die Zeitkonstante in der zum vorherigen Datumsbereich im Vergleich Bereich war, verringerte sich die Dichte auf den Rückgang der Zahl der  Menschen wegen.',
    'suggestion.findings1620':
      'Während die Zeitkonstante in der zum vorherigen Datumsbereich im Vergleich Bereich war, verringerte sich die Dichte auf den Rückgang der Zahl der Menschen wegen.',
    'suggestion.findings1621':
      'Da der Zinssatz in diesem Bereich konstant ist und Verkäufe verringern, wird das Interesse nicht direkt den Umsatz auswirken.',
    'suggestion.findings1630':
      'Während die Zahl der Menschen in der Region konstant zum vorherigen Zeitraum verglichen wurde, verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings1631':
      'Zinssatz erhöhte auch im Vergleich zum vorherigen Zeitraum.',
    'suggestion.findings1632':
      'Da die Zahl der Menschen konstant und der Zinssatz war, erhöhte signifikant die Anzahl der Kunden kommen und aus dem Bereich vorbei wie die Zeit verringert.',
    'suggestion.findings1640':
      'Während die Zahl der Menschen in der Region konstant zum vorherigen Zeitraum verglichen wurde, verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings1641':
      'Zinserhöhungen und nimmt harte Zeit, dass basierte Produkte im Bereich Verkauf, das Produkt schnell gehen bekommen.',
    'suggestion.findings1650':
      'Während die Zahl der Menschen in der Region konstant zum vorherigen Zeitraum verglichen wurde, verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings1651':
      'Da die Menge an Umsatzsteigerungen mit dem Zinssatz und Zeitkonstante, das Produkt im Bereich kann ein schneller kaufen und gehen Produkt.',
    'suggestion.findings1660':
      'Während die Zahl der Menschen in der Region konstant zum vorherigen Zeitraum verglichen wurde, verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings1661':
      'Da die Zahl der Menschen konstant blieb und die Zeit verringert, während der Zinssatz erhöht, die meisten Menschen in der Gegend kommen begannen viel weniger Zeit in der Gegend zu verbringen',
    'suggestion.findings1670':
      'Während die Zahl der Menschen in der Region konstant zum vorherigen Zeitraum verglichen wurde, verringerte sich die Dichte aufgrund der Abnahme in der Zeit.',
    'suggestion.findings1671':
      'Da die Zeit und Zinssatz sinkt, wenn die Person konstant ist, verbrachte die Zeit durch die Kunden im Bereich abnimmt.',
    'suggestion.findings1672':
      'Dies kann, weil das Produkt die Aufmerksamkeit nicht angezogen hat oder der Kunde diesen Bereich aufgrund eines Problems mit dem Laden Plan bestanden hat.',
    'suggestion.findings1680':
      'Im Bereich, während die Zahl der Menschen konstant war im Vergleich zum vorherigen Zeitraum verringerte sich die Dichte auf den Rückgang der Zeit fällig.',
    'suggestion.findings1690':
      'Im Bereich, während die Zahl der Menschen konstant war im Vergleich zum vorherigen Zeitraum verringerte sich die Dichte auf den Rückgang der Zeit fällig.',
    'suggestion.findings1700':
      'Im Bereich, während die Zahl der Menschen konstant war im Vergleich zum vorherigen Zeitraum verringerte sich die Dichte auf den Rückgang der Zeit fällig.',
    'suggestion.findings1710':
      'Im Bereich, während die Zahl der Menschen konstant war im Vergleich zum vorherigen Zeitraum verringerte sich die Dichte auf den Rückgang der Zeit fällig.',

    'suggestion.comment550':
      'Weitere Maßnahmen sollen das Umsatzziel zu erhöhen genommen werden.',
    'suggestion.comment551':
      'Um weiter den Umsatz steigern, sollten Maßnahmen sichergestellt werden, zu, dass die Kunden mehr Zeit in der Gegend verbringen.',
    'suggestion.comment552':
      'Dafür; Personal Interesse, Produktpreis Balance, kann Produktvielfalt überprüft werden.',
    'suggestion.comment553':
      'Um den Umsatz zu steigern, sollten Maßnahmen ergriffen werden, dass die Kunden zu gewährleisten, die das Feld zeigt Interesse an dem Gebiet passieren.',
    'suggestion.comment554':
      'Speicherpläne überarbeitet werden, können die Mitarbeiter Interesse erhöht werden, Sortiments- und Produktpreisstabilität revidiert werden.',
    'suggestion.comment560':
      'Weitere Maßnahmen sollen das Umsatzziel zu erhöhen genommen werden.',
    'suggestion.comment561':
      'Der Zinssatz wurde in den vorangegangenen Zeitraum sank im Vergleich.',
    'suggestion.comment562':
      'Obwohl die Rate in diesem Bereich von Interesse verringert hat, da die Verkäufe zu erhöhen, ist die Wirkung der Zahl der Menschen auf dem Umsatz mehr als die Zeit, in diesem Bereich ausgegeben.',
    'suggestion.comment570':
      'Die Maßnahmen sollen, dass die Kunden zu achten, die das Gebiet zeigt Interesse an dem Gebiet passieren.',
    'suggestion.comment571':
      'Speicherpläne überarbeitet werden, können die Mitarbeiter Interesse erhöht werden, Sortiments- und Produktpreisstabilität revidiert werden.',
    'suggestion.comment572':
      'Mitarbeiter zu erhöhen, um die Zinsen und / oder zu erwarten Verkauf des Produkts durch Bereich Informationskarte unterstützt zu erhöhen.',
    'suggestion.comment580':
      'Da der Zinssatz zum vorherigen Zeitraum verringert hat, der Grund, warum die Erhöhung der Dichte nicht den Umsatz auswirkt kann sein, dass der Kunde nicht genug Zeit, sich in der Gegend verbringen.',
    'suggestion.comment581':
      'Es sollten Maßnahmen sichergestellt werden, dass der Kunde mehr Zeit in der Gegend um den Umsatz zu steigern verbringt.',
    'suggestion.comment582':
      'Personal Interesse, Produktpalette, kann Speicher-Plan überprüft werden people.',
    'suggestion.comment590':
      'Demzufolge; Der Grund, warum die Erhöhung der Dichte nicht den Umsatz auswirkt kann sein, dass der Kunde in der Gegend zu viel Zeit damit verbringt, aber nicht finden können, was sie wollen.',
    'suggestion.comment591':
      'Um die Kunden zu erhöhen und somit den Umsatz zu beschleunigen; Personal Zinsen erhöht werden kann, kann der Bereich mit Produktinformationskarten unterstützt werden.',
    'suggestion.comment592':
      'Wenn die Produktvielfalt gedacht wird, viel zu sein, es kann reduziert werden.',
    'suggestion.comment600':
      'Es sollten Maßnahmen sichergestellt werden, dass der Kunde mehr Zeit in der Gegend um den Umsatz zu steigern verbringt.',
    'suggestion.comment601':
      'Dazu kann der Speicherbereich überprüft werden und die Produktpalette vergrößert werden kann.',
    'suggestion.comment602':
      'Während die Zahl der Menschen erhöht, verringert der Zinssatz und die Zeit damit verbracht, so dass die Zahl der Mitarbeiter in diesem Bereich unzureichend sein kann.',
    'suggestion.comment610':
      'Weitere Maßnahmen sollen das Umsatzziel zu erhöhen genommen werden.',
    'suggestion.comment611':
      'Um weiter den Umsatz steigern, sollten Maßnahmen sichergestellt werden, zu, dass die Kunden mehr Zeit in der Gegend verbringen.',
    'suggestion.comment612':
      'Dafür; Personal Interesse, Produktpreis Balance, kann Produktvielfalt überprüft werden.',
    'suggestion.comment620':
      'Weitere Maßnahmen sollen das Umsatzziel zu erhöhen genommen werden.',
    'suggestion.comment621':
      'Trotz der Zunahme der Dichte in diesem Bereich, da der Zinssatz sinkt, ist es notwendig, Maßnahmen zu ergreifen, die Zahl der Menschen in der Gegend kommen zu erhöhen, anstatt die Zeit damit verbracht den Umsatz zu steigern.',
    'suggestion.comment622':
      'Dazu kann der Speicher-Plan überprüft werden und eine Kampagne gemacht werden kann.',
    'suggestion.comment630':
      'Der Anstieg der Zeit sein kann, weil der Kunde nicht finden, was sie suchen. Die Anzahl der Mitarbeiter und Servicequalität zu prüfen.',
    'suggestion.comment640':
      'Da der Zinssatz in den Bereich ab, dass der Grund der Umsatz konstant bleiben, da die Dichte erhöht sich der Mangel an Personal Interesse sein können oder die Produktvielfalt ist zu viel, als es sein sollte.',
    'suggestion.comment650':
      'Da der Anstieg der Zahl der Menschen eine Rolle bei der Dichtezunahme spielt, kann der Bereich mit Produktinformation Karten unterstützt werden, um die erhöhte Dichte gerecht zu werden.',
    'suggestion.comment651':
      'Anzahl der Mitarbeiter oder Wissen ist in diesem Bereich von entscheidender Bedeutung. Oder es kann zu viel Produktvielfalt haben.',
    'suggestion.comment652':
      'Da der Bereich der erhöhten Dichte nicht aufnehmen kann, kann eine Vergrößerung des Bereichs betrachtet werden people.',
    'suggestion.comment660':
      'Als Umsatz sinkt, kann es notwendig sein, dass der Kunde 15 Sekunden zu verbringen oder mehr zu kaufen Produkte in diesem Bereich.',
    'suggestion.comment661':
      'Der Grund für den Rückgang des Zinssatzes und damit die Verkäufe kann die unzureichende Aufmerksamkeit des Personals oder der niedrigen Produktvielfalt sein.',
    'suggestion.comment662':
      'Da der Bereich der erhöhten Dichte nicht aufnehmen kann, kann eine Vergrößerung des Bereichs betrachtet werden people.',
    'suggestion.comment670':
      'Da sowohl die Zeit und die Zinserhöhung in diesem Bereich, die Höhe des Umsatzes erhöht ich auch, so dass es wichtig ist, um sicherzustellen, dass der Kunde mehr Zeit im Bereich verbringt den Umsatz zu steigern sogar noch mehr.',
    'suggestion.comment671':
      'Um die Zeit zu erhöhen; Shop Plan, Personal Interesse, Produktpreis Balance, kann Produktvielfalt überprüft werden.',
    'suggestion.comment680':
      'Trotz der Verringerung der Zahl der Menschen in der Gegend und die Anzahl der Interessenten kommen, da die Umsatzsteigerung, das Interesse der Mitarbeiter in diesem Bereich mit dem Kunden für eine sehr kurze Zeit wirkt sich sofort die Kunden an den Geschäften.',
    'suggestion.comment681':
      'Oder Kunden, die bewusst sind und wissen, was sie für gekommen, um die Region suchen und keine Probleme haben, das Verständnis die Produktpalette.',
    'suggestion.comment690':
      'Der Grund, warum der Kunde beginnt eine längere Zeit in der Gegend zu verbringen, kann der Mangel an Personal oder die erhöhte Produktvielfalt sein.',
    'suggestion.comment700':
      'Aktion müssen die Kunden zu beschleunigen genommen werden.',
    'suggestion.comment701':
      'Der Grund, warum der Kunde beginnt eine längere Zeit in der Gegend zu verbringen, kann der Mangel an Personal oder die erhöhte Produktvielfalt sein.',
    'suggestion.comment710':
      'Dringend müssen Maßnahmen ergreifen, Kunden zu beschleunigen.',
    'suggestion.comment711':
      'Dazu sollte die Zahl der Mitarbeiter und die Qualität der Dienstleistung in Frage gestellt werden. Auch kann Reduzierung der Produktvielfalt in Betracht gezogen werden people.',
    'suggestion.comment720':
      'Da jedoch die Zeit steigt und Umsatz verringern, können Maßnahmen erforderlich werden, um die Kunden zu beschleunigen.',
    'suggestion.comment721':
      'Dazu sollte die Zahl der Mitarbeiter und ihr Interesse und Produktvielfalt überprüft werden.',
    'suggestion.comment730':
      'Wenn die Zahl der Menschen ohne Verringerung der Umsatz erhöht werden kann, erhöht sich die potenziellen Umsatz in der Region.',
    'suggestion.comment740':
      'Wenn die Zeit reduziert werden kann und die Zahl der Menschen erhöht, ohne die Verkäufe abnimmt, erhöht sich die potenziellen Umsatz im Bereich sogar noch mehr.',
    'suggestion.comment750':
      'Je mehr Zeit der Kunde im Bereich verbringt, desto mehr Umsatz steigern erwartet.',
    'suggestion.comment751':
      ' Wenn es die Zeit weiter abnimmt, und dies wird eine Bedrohung für die Verkäufe darstellen.',
    'suggestion.comment752':
      'Der Grund, warum Verkäufe nicht zunehmen, während der Zinssatz erhöht sein kann, dass der Kunde nicht finden können, was sie suchen for.for.',
    'suggestion.comment753': ' Produktvielfalt soll überarbeitet werden.',
    'suggestion.comment760':
      'Anzahl der Kunden und die Höhe der Zeit zu erhöhen, wird der Umsatz Effizienz steigern.',
    'suggestion.comment761':
      'Da der Zinssatz sinkt und die Verkäufe nicht verringern, muss der Kunde möglicherweise nicht zu lange, um den Kauf von Produkten aus diesem Bereich in der Umgebung zu verbringen.',
    'suggestion.comment770':
      'Obwohl der Zinssatz die Abnahme der Zeit damit verbracht hatte einen negativen Effekt auf dem sales.customers erhöht, der 15 Sekunden oder mehr in diesem Bereich verbringen kaufen erwartet. Die Zeit bis zum Umsatz steigern ausgegeben wird, muss lang genug sein, geben Bedeutung zu. Die Speicherpläne überarbeitet werden, um sicherzustellen, dass die Kunden verbringen mehr in der Gegend. Mitarbeiter Interesse erhöht werden.',
    'suggestion.comment780':
      'Um den Verkauf in diesem Bereich zu erhöhen, muss darauf geachtet werden, um sicherzustellen, dass die Zeit ausreicht.',
    'suggestion.comment781':
      'Die Speicherpläne überarbeitet werden, um sicherzustellen, dass die Kunden verbringen mehr in der Gegend. Mitarbeiter Interesse erhöht werden.',
    'suggestion.comment790':
      'Da die Zahl der Menschen, die 15 Sekunden oder mehr in dem Bereich erhöht verbringen, den Umsatz steigern dürfte.',
    'suggestion.comment791':
      ' Deshalb, um die Verkäufe noch weiter zu erhöhen, ist es notwendig, achten Sie nicht die Zeit zu verringern, während die Zahl der Menschen zu erhöhen.',
    'suggestion.comment800':
      'die Anzahl der Kunden, desto stärker werden die Verkäufe noch mehr erhöhen.',
    'suggestion.comment810':
      'Die Erhöhung der Zeit kann zu Personalengpässen oder den Kunden Unfähigkeit zurückzuführen sein, zu finden, was sie suchen und eine Bedrohung für die Vertriebseffizienz darstellen.',
    'suggestion.comment811':
      'In diesem Bereich ist es notwendig, Maßnahmen zu ergreifen, die Zahl der Menschen zu erhöhen, um den Umsatz zu steigern.',
    'suggestion.comment812':
      'Hierzu kann die Produkt-Preis Balance überprüft werden. Kampagnen können in dem Bereich gemacht werden.',
    'suggestion.comment820':
      'In diesem Bereich ist es notwendig, Maßnahmen zu ergreifen, um die Anzahl der Menschen und nicht verliert die Zahl der interessierten Kunden zu erhöhen, um den Umsatz zu steigern.',
    'suggestion.comment821':
      ' Hierzu kann die Produkt-Preis Balance überprüft werden. Kampagnen können in dem Bereich gemacht werden.',
    'suggestion.comment830':
      'In diesem Bereich, den Umsatz zu steigern und die Anzahl der Menschen, die Sie Handlungsbedarf zu erhöhen, um sich die Zeit nehmen verbracht zu reduzieren.',
    'suggestion.comment831':
      'Dazu sollte das Interesse und die Zahl der Mitarbeiter erhöht werden. Unterstützung der Bereich mit Produktinformationskarten ist auch die Zeit zu reduzieren verbrachte erwartet.',
    'suggestion.comment840':
      'In diesem Bereich mit einem Umsatzpotenzial, ist es notwendig, Maßnahmen zu ergreifen, um die Anzahl der Menschen, um nicht zu verlieren, das Interesse der Kunden und steigern den Umsatz zu erhöhen people.',
    'suggestion.comment841':
      'Es ist sehr wichtig zu achten nicht die Zeit, während die Zahl der Menschen steigt zu verringern.',
    'suggestion.comment842':
      'Dazu sollte das Interesse und die Zahl der Mitarbeiter erhöht werden. Unterstützung der Bereich mit Produktinformationskarten ist auch die Zeit zu reduzieren verbrachte erwartet.',
    'suggestion.comment850':
      'Eine Erhöhung der Zinssatz wird voraussichtlich einen positiven Effekt auf den Umsatz haben.',
    'suggestion.comment851':
      'Zunehmende personelle Unterstützung wird erwartet, dass der Zinssatz zu erhöhen.',
    'suggestion.comment860':
      'Der prozentuale Anstieg in der Zahl der Menschen wird erwartet, dass die Umsätze mit der gleichen Geschwindigkeit zu erhöhen.',
    'suggestion.comment870':
      'Obwohl es ein Bereich ist, wo schnelle Verkäufe auftreten, sollte es analysiert werden, wenn die Verkäufe nicht den erwarteten Anstieg zeigen.',
    'suggestion.comment880':
      'Mitarbeiter Interesse kann auch die Zeit zu erhöhen, erhöht werden, der Kunde im Bereich verbringt.',
    'suggestion.comment890':
      'Wenn Preisänderungen im Vergleich zum vorherigen Zeitraum der betroffenen Ware auf den neuen Preis kommen können, ist teuer.',
    'suggestion.comment891':
      'Wenn das Produkt ein Produkt ist, das Detail erfordert, kann der Kunde für einen Mitarbeiter Kontakt warten.',
    'suggestion.comment892':
      'Wenn eine Änderung in der Anzahl der Mitarbeiter im Vergleich zum vorherigen Zeitraum vorgenommen wird, wird der Bereich direkt von dieser Änderung betroffen.',
    'suggestion.comment900':
      'Da die Abnahme der Zinssatz und die sich negativ auf den Verkauf auswirkt verbrachte Zeit, sollten Maßnahmen ergriffen werden, um die Zeit damit verbracht zu erhöhen.',
    'suggestion.comment901':
      'Wenn die Zeit erhöht wird, wird der Zinssatz und der Umsatz entsprechend steigern.',
    'suggestion.comment902':
      ' Wenn die Produktpreisänderungen in dem verwandten Bereich gemacht können, um neue Kunden sind teuer Preise kommen werden.',
    'suggestion.comment910':
      'Um den Verkauf in diesem Bereich zu erhöhen, sollten Maßnahmen ergriffen werden, um die Kunden zu beschleunigen.',
    'suggestion.comment920':
      'Der effektivste Parameter die Zahl der Menschen zu erhöhen und damit der Umsatz in diesem Bereich ist der Preis.',
    'suggestion.comment930':
      'Die Länge der Zeit kann eine Gefahr, da der Zinssatz und Zeiterhöhung in diesem Bereich sein und die Verkäufe nicht ändern.',
    'suggestion.comment931':
      'Wenn Verhinderungen nicht getroffen werden, die Zeit zu reduzieren, kann die Länge der Zeit, in der nächsten Zeit einen Umsatzrückgang führen.',
    'suggestion.comment932':
      'Der Grund des Anstiegs kann wegen Unfähigkeit sein, die Zeit zu finden, dass Kunden suchen.',
    'suggestion.comment933':
      'Die Erhöhung der Zahl der Menschen, wird erwartet, den Umsatz zu steigern.',
    'suggestion.comment934':
      'Der Preis ist der wichtigste Faktor die Zahl der Menschen zu erhöhen.',
    'suggestion.comment940':
      'Der Preis ist der wichtigste Faktor bei der Zahl der Menschen zu erhöhen.',
    'suggestion.comment950':
      'Da der Zinssatz und die Zeit zu erhöhen, Verkäufe zu verringern, so dass die Länge der Zeit eine Gefahr sein kann.',
    'suggestion.comment951':
      'Der Preis ist der wichtigste Faktor bei der Zahl der Menschen zu erhöhen.',
    'suggestion.comment952':
      'Wenn die Verringerung der Zahl der Menschen in diesem Bereich auf den Rückgang der Zahl der Kunden im Laden parallel ist, sollten die Marketingaktivitäten im Laden nachgearbeitet werden.',
    'suggestion.comment960':
      'Der Preis ist der wichtigste Faktor bei der Zahl der Menschen zu erhöhen.',
    'suggestion.comment961':
      'Wenn die Verringerung der Zahl der Menschen in diesem Bereich auf den Rückgang der Zahl der Kunden im Laden parallel ist, sollten die Marketingaktivitäten im Laden nachgearbeitet werden.',
    'suggestion.comment970':
      'Es ist notwendig, Maßnahmen zu ergreifen, um den Zinssatz zu erhöhen noch mehr als die Zahl der Menschen kommen.',
    'suggestion.comment971':
      'Personal Interesse sollte nicht übersehen werden.',
    'suggestion.comment980':
      'Wenn eine Produktgruppe im Bereich schnell Aufmerksamkeit bekommen sollte in Aktien bezahlt werden. Die Kunden kommen in den Bereich wird zu Problemen führen derzeit keine Gegenstände finden.',
    'suggestion.comment981':
      'Wenn die Preisänderungen durch den vorherigen Zeitraum vorgenommen hat neue Preispolitik gearbeitet.',
    'suggestion.comment990':
      'Dementsprechend wird beobachtet, dass es zwar ein sehr hohes Potenzial in der Vorperiode war, konnte es nicht genug genutzt werden.',
    'suggestion.comment1000':
      'Der Rückgang der Person und die Zeit nicht, den Verkauf ändern, was darauf hindeutet, dass das Potenzial nicht in der Vorperiode verwendet werden könnte.',
    'suggestion.comment1010':
      'Mitarbeiter Notwendigkeit, achten Sie auf die Beziehung mit dem Kunden.',
    'suggestion.comment1020':
      'Die Umgebung sollte eingehend analysiert werden und müssen dringend Maßnahmen ergriffen werden.',
    'suggestion.comment1030':
      'Weitere Maßnahmen sollen das Umsatzziel zu erhöhen genommen werden.',
    'suggestion.comment1031':
      'Die Zahl der Menschen in das Gebiet weiter zu steigern den Umsatz kommen sollte erhöht werden.',
    'suggestion.comment1032':
      'Dafür; Speicherpläne und Kampagnen können überprüft werden.',
    'suggestion.comment1040':
      'Da der Zinssatz konstant geblieben vorherigen Datumsbereich im Vergleich zu, dass der Grund die Zunahme der Dichte nicht die Verkäufe nicht beeinflussen kann sein, dass der Kunde genügend Zeit nicht in der Gegend verbringen.',
    'suggestion.comment1041':
      'Es sollten Maßnahmen sichergestellt werden, dass der Kunde mehr Zeit in der Gegend um den Umsatz zu steigern verbringt.',
    'suggestion.comment1050':
      'Da der Zinssatz konstant geblieben vorherigen Datumsbereich im Vergleich zu, dass der Grund die Zunahme der Dichte nicht die Verkäufe nicht beeinflussen kann sein, dass der Kunde genügend Zeit nicht in der Gegend verbringen.',
    'suggestion.comment1051':
      'Es sollten Maßnahmen sichergestellt werden, dass der Kunde mehr Zeit in der Gegend um den Umsatz zu steigern verbringt.',
    'suggestion.comment1052':
      'Hierzu kann der Speicher überprüft werden und die Produktpalette vergrößert werden kann.',
    'suggestion.comment1053':
      'Zahl der Menschen, dass Zinserhöhungen und nimmt Bezug auf die Anzahl der Mitarbeiter Zeit in diesem Bereich verbracht unzureichend sein.',
    'suggestion.comment1060':
      'Weitere Maßnahmen sollen das Umsatzziel zu erhöhen genommen werden.',
    'suggestion.comment1061':
      'Dieser Bereich entspricht dem Zinssatz Erhöhung der Dichte durch konstanten Umsatz erhöht hat den Umsatz zu steigern, die Zahl der Menschen in den Bereich anstatt Zeit handeln verbrachte kommen erhöhen müssen.',
    'suggestion.comment1062':
      'Dazu kann der Speicher-Plan überprüft werden und eine Kampagne gemacht werden kann.',
    'suggestion.comment1070':
      'Da die Rate im Bereich der Zinsen nicht erhöht, sondern bleibt konstant, der Grund für die Tatsache, dass der Umsatz konstant bleiben, während die Dichte erhöht sich der Mangel an Personal Interesse sein können oder die Produktvielfalt ist zu viel, als es sein sollte.',
    'suggestion.comment1080':
      'Laut sank den Umsatz, der Grund für die Zunahme der parallel mit einer Zunahme der Dichte des Umsatzes in diesem Bereich kann konstanter Zinssatz bleiben.',
    'suggestion.comment1081':
      'Mitarbeiter Interesse und Produktvielfalt kann den Zinssatz und damit den Umsatz zu steigern prüft werden.',
    'suggestion.comment1082':
      'Da der Bereich der erhöhten Dichte nicht aufnehmen kann, kann eine Vergrößerung des Bereichs betrachtet werden people.',
    'suggestion.comment1090':
      'Dass eine Abnahme der Zahl der Menschen, in den Bereich kommen und trotz der Umsatzsteigerung konstant zu bleiben, bezogen auf die Zahl der Menschen in sehr kurzer Zeit auch zu viel mit den Kunden Personal in diesem Bereich interessiert sind, sofort zu handeln, um den Kunden einkaufen drehen .',
    'suggestion.comment1091':
      'Oder Kunden, die bewusst sind und wissen, was sie für gekommen, um die Region suchen und keine Probleme haben, das Verständnis die Produktpalette.',
    'suggestion.comment1100':
      'Wenn es die Zeit weiter zu erhöhen, kann es sich negativ auf Umsatz in der nächsten Stufe betroffen sein.',
    'suggestion.comment1101':
      'Aktion müssen die Kunden zu beschleunigen genommen werden.',
    'suggestion.comment1102':
      'Der Grund, warum der Kunde beginnt eine längere Zeit in der Gegend zu verbringen, kann der Mangel an Personal oder die erhöhte Produktvielfalt sein.',
    'suggestion.comment1110':
      'Da jedoch die Zeit steigt und Umsatz verringern, können Maßnahmen erforderlich werden, um die Kunden zu beschleunigen.',
    'suggestion.comment1111':
      'Dazu sollte die Zahl der Mitarbeiter und ihr Interesse und Produktvielfalt überprüft werden.',
    'suggestion.comment1120':
      'Weitere Maßnahmen sollen das Umsatzziel zu erhöhen genommen werden.',
    'suggestion.comment1121':
      'Um weiter den Umsatz steigern, sollten Maßnahmen sichergestellt werden, zu, dass die Kunden mehr Zeit in der Gegend verbringen.',
    'suggestion.comment1122':
      'Dafür; Personal Interesse, Produktpreis Balance, kann Produktvielfalt überprüft werden.',
    'suggestion.comment1130':
      'Weitere Maßnahmen sollen das Umsatzziel zu erhöhen genommen werden.',
    'suggestion.comment1131':
      'Die Zahl der Menschen in das Gebiet weiter zu steigern den Umsatz kommen sollte erhöht werden.',
    'suggestion.comment1132':
      'Dafür; Speicherpläne und Kampagnen können überprüft werden.',
    'suggestion.comment1140':
      'Weitere Maßnahmen sollen das Umsatzziel zu erhöhen genommen werden.',
    'suggestion.comment1141':
      'Die Zahl der Menschen in das Gebiet weiter zu steigern den Umsatz kommen sollte erhöht werden.',
    'suggestion.comment1142':
      'Dafür; Speicherpläne und Kampagnen können überprüft werden.',
    'suggestion.comment1150':
      'Da der Zinssatz wurde in den vorangegangenen Zeitraum erhöhte sich im Vergleich, der Grund, warum die Erhöhung der Dichte sein nicht den Umsatz auswirken kann, dass der Kunde im Feld zu viel Zeit damit verbringt, aber nicht finden können, was sie wollen.',
    'suggestion.comment1151':
      'Um die Kunden zu erhöhen und somit den Umsatz zu beschleunigen; Personal Zinsen erhöht werden können, kann der Bereich mit Produktinformation Karten unterstützt werden, und Produktvielfalt reduziert werden kann.',
    'suggestion.comment1160':
      'Da der Zinssatz zum vorherigen Zeitraum verringert hat, der Grund, warum die Erhöhung der Dichte nicht den Umsatz auswirkt kann sein, dass der Kunde nicht genug Zeit, sich in der Gegend verbringen.',
    'suggestion.comment1161':
      'Es sollten Maßnahmen sichergestellt werden, dass der Kunde mehr Zeit in der Gegend um den Umsatz zu steigern verbringt.',
    'suggestion.comment1170':
      'Da der Zinssatz konstant geblieben vorherigen Datumsbereich im Vergleich zu, dass der Grund die Zunahme der Dichte nicht die Verkäufe nicht beeinflussen kann sein, dass der Kunde genügend Zeit nicht in der Gegend verbringen.',
    'suggestion.comment1171':
      'Es sollten Maßnahmen sichergestellt werden, dass der Kunde mehr Zeit in der Gegend um den Umsatz zu steigern verbringt.',
    'suggestion.comment1180':
      'Um die Kunden zu erhöhen und somit den Umsatz zu beschleunigen; Personal Interesse erhöht werden kann, der relevante Bereich mit Produktinformation Karten unterstützt werden.',
    'suggestion.comment1181':
      'Wenn die Produktvielfalt gedacht wird, viel zu sein, es kann reduziert werden.',
    'suggestion.comment1190':
      'Da der Zinssatz zum vorherigen Zeitraum verringert hat, der Grund, warum die Erhöhung der Dichte nicht den Umsatz auswirkt kann sein, dass der Kunde nicht genug Zeit, sich in der Gegend verbringen.',
    'suggestion.comment1191':
      'Es sollten Maßnahmen sichergestellt werden, dass der Kunde mehr Zeit in der Gegend um den Umsatz zu steigern verbringt.',
    'suggestion.comment1192':
      'Hierzu kann der Speicher überprüft werden und die Produktpalette vergrößert werden kann.',
    'suggestion.comment1193':
      'Zahl der Menschen, dass Zinserhöhungen und nimmt Bezug auf die Anzahl der Mitarbeiter Zeit in diesem Bereich verbracht unzureichend sein.',
    'suggestion.comment1200':
      'Da der Zinssatz konstant geblieben vorherigen Datumsbereich im Vergleich zu, dass der Grund die Zunahme der Dichte nicht die Verkäufe nicht beeinflussen kann sein, dass der Kunde genügend Zeit nicht in der Gegend verbringen.',
    'suggestion.comment1201':
      'Es sollten Maßnahmen sichergestellt werden, dass der Kunde mehr Zeit in der Gegend um den Umsatz zu steigern verbringt.',
    'suggestion.comment1202':
      'Hierzu kann der Speicher überprüft werden und die Produktpalette vergrößert werden kann.',
    'suggestion.comment1203':
      'Zahl der Menschen, dass Zinserhöhungen und nimmt Bezug auf die Anzahl der Mitarbeiter Zeit in diesem Bereich verbracht unzureichend sein.',
    'suggestion.comment1210':
      'Da sowohl die Zeit und die Zinserhöhung in diesem Bereich, die Höhe des Umsatzes erhöht ich auch, so dass es wichtig ist, um sicherzustellen, dass der Kunde mehr Zeit im Bereich verbringt den Umsatz zu steigern sogar noch mehr.',
    'suggestion.comment1211':
      'Um die Zeit zu erhöhen; Shop Plan, Personal Interesse, Produktpreis Balance, kann Produktvielfalt überprüft werden.',
    'suggestion.comment1220':
      'Weitere Maßnahmen sollen das Umsatzziel zu erhöhen genommen werden.',
    'suggestion.comment1221':
      'Die Zahl der Menschen in das Gebiet weiter zu steigern den Umsatz kommen sollte erhöht werden.',
    'suggestion.comment1222':
      'Dafür; Speicherpläne und Kampagnen können überprüft werden.',
    'suggestion.comment1230':
      'Weitere Maßnahmen sollen das Umsatzziel zu erhöhen genommen werden.',
    'suggestion.comment1231':
      'Die Zahl der Menschen in das Gebiet weiter zu steigern den Umsatz kommen sollte erhöht werden.',
    'suggestion.comment1232':
      'Dafür; Speicherpläne und Kampagnen können überprüft werden.',
    'suggestion.comment1240':
      'Um die Kunden zu erhöhen und somit den Umsatz zu beschleunigen; Personal Zinsen erhöht werden können, kann der Bereich mit Produktinformation Karten unterstützt werden, und Produktvielfalt reduziert werden kann.',
    'suggestion.comment1250':
      'Es sollten Maßnahmen sichergestellt werden, dass der Kunde mehr Zeit in der Gegend um den Umsatz zu steigern verbringt.',
    'suggestion.comment1260':
      'Es sollten Maßnahmen sichergestellt werden, dass der Kunde mehr Zeit in der Gegend um den Umsatz zu steigern verbringt.',
    'suggestion.comment1270':
      'Um die Kunden zu erhöhen und somit den Umsatz zu beschleunigen; Personal Interesse erhöht werden kann, der relevante Bereich mit Produktinformation Karten unterstützt werden.',
    'suggestion.comment1271':
      'Wenn die Produktvielfalt gedacht wird, viel zu sein, es kann reduziert werden.',
    'suggestion.comment1280':
      'Es sollten Maßnahmen sichergestellt werden, dass der Kunde mehr Zeit in der Gegend um den Umsatz zu steigern verbringt.',
    'suggestion.comment1281':
      'Hierzu kann der Speicher überprüft werden und die Produktpalette vergrößert werden kann.',
    'suggestion.comment1282':
      'Zahl der Menschen, dass Zinserhöhungen und nimmt Bezug auf die Anzahl der Mitarbeiter Zeit in diesem Bereich verbracht unzureichend sein.',
    'suggestion.comment1290':
      'Nach stieg der Umsatz verringerte Einwirkungszeit, den Kunden zu beschleunigen kann notwendig sein.',
    'suggestion.comment1291':
      'Dazu sollte die Zahl der Mitarbeiter und ihr Interesse und Produktvielfalt überprüft werden.',
    'suggestion.comment1300':
      'Wenn die Zeit reduziert werden kann und die Zahl der Menschen erhöht, ohne die Verkäufe abnimmt, erhöht sich die potenziellen Umsatz im Bereich sogar noch mehr.',
    'suggestion.comment1310':
      'Anzahl der Kunden und die Höhe der Zeit zu erhöhen, wird der Umsatz Effizienz steigern.',
    'suggestion.comment1311':
      'Nach Zins stetigem Umsatz fallen, so lange in der Region des Kunden zum Kauf von Produkten aus diesem Bereich kann nicht verbringen müssen.',
    'suggestion.comment1320':
      'Um den Verkauf in diesem Bereich zu erhöhen, muss darauf geachtet werden, um sicherzustellen, dass die Zeit ausreicht.',
    'suggestion.comment1321':
      'Die Speicherpläne überarbeitet werden, um sicherzustellen, dass die Kunden verbringen mehr in der Gegend. Mitarbeiter Interesse erhöht werden.',
    'suggestion.comment1330':
      'die Anzahl der Kunden, desto stärker werden die Verkäufe noch mehr erhöhen.',
    'suggestion.comment1340':
      'In diesem Bereich ist es notwendig, Maßnahmen zu ergreifen, um die Anzahl der Menschen und nicht verliert die Zahl der interessierten Kunden zu erhöhen, um den Umsatz zu steigern.',
    'suggestion.comment1341':
      'Hierzu kann die Produkt-Preis Balance überprüft werden. Kampagnen können in dem Bereich gemacht werden.',
    'suggestion.comment1350':
      'Es ist bezeichnend, mehr Kunden in der Gegend, um zu gewinnen das Umsatzpotenzial in der Region zu erhöhen.',
    'suggestion.comment1360':
      'Da die Zahl der Menschen, die 15 Sekunden oder mehr in dem Bereich erhöht verbringen, den Umsatz steigern dürfte.',
    'suggestion.comment1361':
      'Deshalb, um die Verkäufe noch weiter zu erhöhen, ist es notwendig, achten Sie nicht die Zeit zu verringern, während die Zahl der Menschen zu erhöhen.',
    'suggestion.comment1370':
      'die Anzahl der Kunden, desto stärker werden die Verkäufe noch mehr erhöhen.',
    'suggestion.comment1380':
      'die Anzahl der Kunden, desto stärker werden die Verkäufe noch mehr erhöhen.',
    'suggestion.comment1390':
      'In diesem Bereich ist es notwendig, Maßnahmen zu ergreifen, die Zahl der Menschen zu erhöhen, um den Umsatz zu steigern.',
    'suggestion.comment1391':
      'Hierzu kann die Produkt-Preis Balance überprüft werden. Kampagnen können in dem Bereich gemacht werden.',
    'suggestion.comment1400':
      'In diesem Bereich ist es notwendig, Maßnahmen zu ergreifen, um die Anzahl der Menschen und nicht verliert die Zahl der interessierten Kunden zu erhöhen, um den Umsatz zu steigern.',
    'suggestion.comment1401':
      'Hierzu kann die Produkt-Preis Balance überprüft werden. Kampagnen können in dem Bereich gemacht werden.',
    'suggestion.comment1410':
      '8n in diesem Bereich ist es notwendig, Maßnahmen zu ergreifen, um die Anzahl der Menschen und nicht verliert die Zahl der interessierten Kunden zu erhöhen, um den Umsatz zu steigern.',
    'suggestion.comment1411':
      'Hierzu kann die Produkt-Preis Balance überprüft werden. Kampagnen können in dem Bereich gemacht werden.',
    'suggestion.comment1420':
      'In diesem Bereich, den Umsatz zu steigern und die Anzahl der Menschen, die Sie Handlungsbedarf zu erhöhen, um sich die Zeit nehmen verbracht zu reduzieren.',
    'suggestion.comment1421':
      'Dazu sollte das Interesse und die Zahl der Mitarbeiter erhöht werden. Unterstützung der Bereich mit Produktinformationskarten ist auch die Zeit zu reduzieren verbrachte erwartet.',
    'suggestion.comment1430':
      'In diesem Bereich mit einem Umsatzpotenzial, ist es notwendig, Maßnahmen zu ergreifen, um die Anzahl der Menschen, um nicht zu verlieren, das Interesse der Kunden und steigern den Umsatz zu erhöhen people.',
    'suggestion.comment1431':
      'Es ist sehr wichtig zu achten nicht die Zeit, während die Zahl der Menschen steigt zu verringern.',
    'suggestion.comment1432':
      'Dazu sollte das Interesse und die Zahl der Mitarbeiter erhöht werden. Unterstützung der Bereich mit Produktinformationskarten wird auch erhöhen das Interesse der Kunden erwartet.',
    'suggestion.comment1440':
      'Es ist bezeichnend, mehr Kunden in der Gegend, um zu gewinnen das Umsatzpotenzial in der Region zu erhöhen.',
    'suggestion.comment1450':
      'Der prozentuale Anstieg in der Zahl der Menschen wird erwartet, dass die Umsätze mit der gleichen Geschwindigkeit zu erhöhen.',
    'suggestion.comment1460':
      'Mitarbeiter Interesse kann auch die Zeit zu erhöhen, erhöht werden, der Kunde im Bereich verbringt.',
    'suggestion.comment1470':
      'Da die Abnahme der verbrachte Zeit, während der Zinssatz konstant negativ auf die Verkäufe auswirkt, sollten Maßnahmen ergriffen werden, um die Zeit damit verbracht zu erhöhen.',
    'suggestion.comment1471':
      'Da ist Zinssatz erhöht und Umsatz entsprechend steigern wird.',
    'suggestion.comment1472':
      'Wenn die Produktpreisänderungen in dem verwandten Bereich gemacht können, um neue Kunden sind teuer Preise kommen werden.',
    'suggestion.comment1480':
      'Der effektivste Parameter die Zahl der Menschen zu erhöhen und damit der Umsatz in diesem Bereich ist der Preis.',
    'suggestion.comment1490':
      'Da der Zinssatz in diesem Bereich konstant ist und der Umsatz konstant bleibt, kann der Zinssatz in steigenden Umsätzen Determinante sein.',
    'suggestion.comment1491':
      'Damit der Kunde zeigt Interesse zu gewährleisten, kann der Bereich mit Produktinformation Karten unterstützt werden, und Personal Interesse erhöht werden kann.',
    'suggestion.comment1500':
      'Um den Umsatz zu steigern, ist es notwendig, auf die Erhöhung der Zahl der Menschen zu konzentrieren.',
    'suggestion.comment1501':
      'Der Preis ist der wichtigste Faktor bei der Zahl der Menschen zu erhöhen.',
    'suggestion.comment1502':
      'Wenn die Verringerung der Zahl der Menschen in diesem Bereich auf den Rückgang der Zahl der Kunden im Laden parallel ist, sollten die Marketingaktivitäten im Laden nachgearbeitet werden.',
    'suggestion.comment1510':
      'Wenn eine Produktgruppe im Bereich schnell Aufmerksamkeit bekommen sollte in Aktien bezahlt werden. Die Kunden kommen in den Bereich wird zu Problemen führen derzeit keine Gegenstände finden.',
    'suggestion.comment1511':
      'Wenn die Preisänderungen durch den vorherigen Zeitraum vorgenommen hat neue Preispolitik gearbeitet.',
    'suggestion.comment1520':
      'Der Rückgang der Person und die Zeit nicht, den Verkauf ändern, was darauf hindeutet, dass das Potenzial nicht in der Vorperiode verwendet werden könnte.',
    'suggestion.comment1530':
      'Verwandte Bereiche sollten eingehend analysiert werden und müssen dringend Maßnahmen ergriffen werden.',
    'suggestion.comment1540':
      'Um den Verkauf in diesem Bereich zu erhöhen, sollten Maßnahmen ergriffen werden, um die Kunden zu beschleunigen.',
    'suggestion.comment1550':
      'Der effektivste Parameter die Zahl der Menschen zu erhöhen und damit der Umsatz in diesem Bereich ist der Preis.',
    'suggestion.comment1560':
      'Der effektivste Parameter die Zahl der Menschen zu erhöhen und damit der Umsatz in diesem Bereich ist der Preis.',
    'suggestion.comment1570':
      'Zinssatz in diesem Bereich bis zu diesem Zeitpunkt konstant und steigerte den Umsatz zu ändern, kann die Länge der Zeit eine Bedrohung darstellen.',
    'suggestion.comment1571':
      'Wenn Verhinderungen nicht getroffen werden, die Zeit zu reduzieren, kann die Länge der Zeit, in der nächsten Zeit einen Umsatzrückgang führen.',
    'suggestion.comment1572':
      'Der Grund des Anstiegs kann wegen Unfähigkeit sein, die Zeit zu finden, dass Kunden suchen.',
    'suggestion.comment1573':
      'Die Erhöhung der Zahl der Menschen, wird erwartet, den Umsatz zu steigern.',
    'suggestion.comment1574':
      'Der Preis ist der wichtigste Faktor die Zahl der Menschen zu erhöhen.',
    'suggestion.comment1580':
      'Der Preis ist der wichtigste Faktor bei der Zahl der Menschen zu erhöhen.',
    'suggestion.comment1590':
      'Da der Zinssatz in diesem Bereich konstant ist und der Umsatz konstant bleibt, kann der Zinssatz in steigenden Umsätzen Determinante sein.',
    'suggestion.comment1591':
      'Damit der Kunde zeigt Interesse zu gewährleisten, kann der Bereich mit Produktinformation Karten unterstützt werden, und Personal Interesse erhöht werden kann.',
    'suggestion.comment1600':
      'Da die Höhe des Umsatzes ab, während die Zeitkonstante und die Zinserhöhungen in diesem Bereich ist, kann die Erhöhung der Zeit eine Bedrohung in der Zukunft darstellen people.',
    'suggestion.comment1601':
      'Der Preis ist der wichtigste Faktor bei der Zahl der Menschen zu erhöhen.',
    'suggestion.comment1602':
      'Wenn die Verringerung der Zahl der Menschen in diesem Bereich auf den Rückgang der Zahl der Kunden im Laden parallel ist, sollten die Marketingaktivitäten im Laden nachgearbeitet werden.',
    'suggestion.comment1610':
      'Da die Geschwindigkeit in diesem Bereich von Interesse verringert und Umsatzrückgang auch wirkt sich die Zinsen Umsatz people.',
    'suggestion.comment1611':
      'Der Preis ist der wichtigste Faktor bei der Zahl der Menschen zu erhöhen.',
    'suggestion.comment1612':
      'Wenn die Verringerung der Zahl der Menschen in diesem Bereich auf den Rückgang der Zahl der Kunden im Laden parallel ist, sollten die Marketingaktivitäten im Laden nachgearbeitet werden.',
    'suggestion.comment1620':
      'Um den Umsatz zu steigern, ist es notwendig, auf die Erhöhung der Zahl der Menschen zu konzentrieren.',
    'suggestion.comment1621':
      'Der Preis ist der wichtigste Faktor bei der Zahl der Menschen zu erhöhen.',
    'suggestion.comment1622':
      'Wenn die Verringerung der Zahl der Menschen in diesem Bereich auf den Rückgang der Zahl der Kunden im Laden parallel ist, sollten die Marketingaktivitäten im Laden nachgearbeitet werden.',
    'suggestion.comment1630':
      'Eine Erhöhung der Zinssatz wird voraussichtlich einen positiven Effekt auf den Umsatz haben.',
    'suggestion.comment1631':
      'Zunehmende personelle Unterstützung wird erwartet, dass der Zinssatz zu erhöhen.',
    'suggestion.comment1640':
      'Der prozentuale Anstieg in der Zahl der Menschen wird erwartet, dass die Umsätze mit der gleichen Geschwindigkeit zu erhöhen.',
    'suggestion.comment1650':
      'Der prozentuale Anstieg in der Zahl der Menschen wird erwartet, dass die Umsätze mit der gleichen Geschwindigkeit zu erhöhen.',
    'suggestion.comment1660':
      'Obwohl es ein Bereich ist, wo schnelle Verkäufe auftreten, sollte es analysiert werden, wenn die Verkäufe nicht den erwarteten Anstieg zeigen.',
    'suggestion.comment1670':
      'Mitarbeiter Interesse kann auch die Zeit zu erhöhen, erhöht werden, der Kunde im Bereich verbringt.',
    'suggestion.comment1680':
      'Da der Zinssatz in diesem Bereich konstant ist und der Umsatz konstant bleibt, kann der Zinssatz in steigenden Umsätzen Determinante sein.',
    'suggestion.comment1681':
      'Damit der Kunde zeigt Interesse zu gewährleisten, kann der Bereich mit Produktinformation Karten unterstützt werden, und Personal Interesse erhöht werden kann.',
    'suggestion.comment1690':
      'Interessierte Kunden werden erwartet, um das Produkt zu kaufen.',
    'suggestion.comment1691':
      'Wenn Preisänderungen im Vergleich zum vorherigen Zeitraum der betroffenen Ware auf den neuen Preis kommen können, ist teuer.',
    'suggestion.comment1692':
      'Wenn das Produkt ein Produkt ist, das Detail erfordert, kann der Kunde für einen Mitarbeiter Kontakt warten.',
    'suggestion.comment1693':
      'Wenn eine Änderung in der Anzahl der Mitarbeiter im Vergleich zum vorherigen Zeitraum vorgenommen wird, wird der Bereich direkt von dieser Änderung betroffen.',
    'suggestion.comment1700':
      'Da die Abnahme der Zinssatz und die sich negativ auf den Verkauf auswirkt verbrachte Zeit, sollten Maßnahmen ergriffen werden, um die Zeit damit verbracht zu erhöhen.',
    'suggestion.comment1701':
      'Da ist Zinssatz erhöht und Umsatz entsprechend steigern wird.',
    'suggestion.comment1702':
      'Wenn die Produktpreisänderungen in dem verwandten Bereich gemacht können, um neue Kunden sind teuer Preise kommen werden.',
    'suggestion.comment1710':
      'Da die Abnahme der verbrachte Zeit, während der Zinssatz konstant negativ auf die Verkäufe auswirkt, sollten Maßnahmen ergriffen werden, um die Zeit damit verbracht zu erhöhen.',
    'suggestion.comment1711':
      'Da ist Zinssatz erhöht und Umsatz entsprechend steigern wird.',
    'suggestion.comment1712':
      'Wenn die Produktpreisänderungen in dem verwandten Bereich gemacht können, um neue Kunden sind teuer Preise kommen werden.'
  }
};
