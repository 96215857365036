import {
  SET_START_DATE,
  SET_END_DATE,
  SET_START_TIME,
  SET_END_TIME,
  FUNNEL_THRESHOLDS_ADD,
  SET_PARAM,
  SET_MODAL,
  SET_PARAMS,
  FUNNEL_THRESHOLDS_REMOVE,
  REMOVE_PARAMS,
  SET_INITIAL,
  SET_SYNC_BOUNDARIES,
  SET_FILTER_TYPE,
  SET_STATISTIC,
  SET_DATE_PARAMS,
  SET_COMPARISON_PARAMS,
  SELECTED_COMPARISON_STORE_CHANGE,
  SET_HOMEBRAND_PARAMS,
  SET_PERFORMANCE_TABLE_PARAMS,
  SET_HEATMAP_DATE_PARAMS,
  SET_START_HEATMAP_DATE,
  SET_END_HEATMAP_DATE,
  SET_START_HEATMAP_TIME,
  SET_END_HEATMAP_TIME,
  PERFORMANCETABLE_DATE_ONCHANGE,
  SET_PREVSTART_DATE,
  SET_PREVEND_DATE,
  SET_PREVSTART_TIME,
  SET_PREVEND_TIME,
  URL_SEARCH_PARAMS,
  SET_SUGGESTION_DATES,
  SET_BACKUPDATES,
  SELECT_PERSONNELID_CHANGE,
  SET_COMPARISON_LAYES,
  SET_TRENDS_FILTER_TYPE,
  SELECTED_CHECKOUT_COMPARISON_STORE_CHANGE,
  SELECTED_AGEGENDER_COMPARISON_STORE_CHANGE,
  CONVERSION_RATE_THRESOLDS,
  CONVERSION_RATE_THRESOLDS_REMOVE,
  SET_HOMEAGEBRAND_PARAMS,
  SET_CATEGORY_FILTER_TYPE,
  SET_SELECTED_DEPARTMENTID,
  SET_SELECTED_PERSONNEL,
  SET_IS_PERSONNEL_SELECTED,
  WAITING_TIMES_THERESOLDS,
  WAITING_TIMES_THERESOLDS_REMOVE,
  SET_START_TIME_VALUE,
  SET_PREVSTART_TIME_VALUE,
  SET_END_TIME_VALUE,
  SET_PREVEND_TIME_VALUE,
  SET_METRIC_TYPE,
  SET_SELECTED_STORE_IDS,
  SET_SHOW_TOTAL,
  SET_CORRELATION_THRESHOLD,
  TOGGLE_SHOW_NAVBAR_ON_SMALL_SCREEN,
  SET_SKETCH_ID
} from './types';
import { formatDateDisplay } from '../ReduxReducers/paramsReducer';
import { formatDistance, subDays } from 'date-fns';
import { calcTime } from '../Helper/commons';

export const dateOnChange = dates => dispatch => {
  const { sdate, edate, stime, etime, disableBackupDate, startTime, endTime } =
    dates;
  let distdates = formatDistance(sdate, edate, { unit: 'day' }).split(' ')[0];
  distdates = isNaN(distdates) ? 0 : parseInt(distdates);
  dispatch({
    type: SET_START_DATE,
    payload: sdate
  });
  dispatch({
    type: SET_END_DATE,
    payload: edate
  });
  if (stime) {
    dispatch({
      type: SET_START_TIME,
      payload: stime
    });
    dispatch({
      type: SET_PREVSTART_TIME,
      payload: stime
    });
  }
  if (startTime !== undefined) {
    dispatch({
      type: SET_START_TIME_VALUE,
      payload: startTime
    });
    dispatch({
      type: SET_PREVSTART_TIME_VALUE,
      payload: startTime
    });
  }
  if (endTime !== undefined) {
    dispatch({
      type: SET_END_TIME_VALUE,
      payload: endTime
    });
    dispatch({
      type: SET_PREVEND_TIME_VALUE,
      payload: endTime
    });
  }
  if (etime) {
    dispatch({
      type: SET_END_TIME,
      payload: etime
    });
    dispatch({
      type: SET_PREVEND_TIME,
      payload: etime
    });
  }
  dispatch({
    type: SET_PREVSTART_DATE,
    payload: subDays(subDays(sdate, 1), distdates)
  });
  dispatch({
    type: SET_PREVEND_DATE,
    payload: subDays(sdate, 1)
  });

  dispatch({
    type: URL_SEARCH_PARAMS,
    payload: {
      sDate: formatDateDisplay(new Date(sdate.getTime())),
      eDate: formatDateDisplay(new Date(edate.getTime()))
    }
  });
  if (!disableBackupDate)
    dispatch({
      type: SET_BACKUPDATES,
      payload: [new Date(sdate.getTime()), new Date(edate.getTime())]
    });
};

export const heatmapDateOnChange = dates => dispatch => {
  const { sdate, edate, stime, etime } = dates;
  dispatch({
    type: SET_START_HEATMAP_DATE,
    payload: sdate
  });
  dispatch({
    type: SET_END_HEATMAP_DATE,
    payload: edate
  });
  dispatch({
    type: SET_START_HEATMAP_TIME,
    payload: stime
  });
  dispatch({
    type: SET_END_HEATMAP_TIME,
    payload: etime
  });
};

export const comparisonDateOnChange = dates => dispatch => {
  dispatch({
    type: SET_DATE_PARAMS,
    payload: dates
  });
};

export const comparisonStoreOnChange = (newStore, uniqueName) => dispatch => {
  dispatch({
    type: SELECTED_COMPARISON_STORE_CHANGE,
    payload: newStore,
    uniqueName: uniqueName
  });
};

export const funnelThresholdsAdd = t => dispatch => {
  dispatch({
    type: FUNNEL_THRESHOLDS_ADD,
    payload: t
  });
};

export const funnelThresholdsRemove = t => dispatch => {
  dispatch({
    type: FUNNEL_THRESHOLDS_REMOVE,
    payload: t
  });
};

export const setParam = (paramName, value) => dispatch => {
  dispatch({
    type: SET_PARAM,
    value: value,
    paramName: paramName
  });
};

export const setParamModal = (paramName, value) => dispatch => {
  dispatch({
    type: SET_MODAL,
    value: value,
    paramName: paramName
  });
};

export const setParams = arr => dispatch => {
  dispatch({
    type: SET_PARAMS,
    paramsArray: arr
  });
};

export const addToInitital = obj => dispatch => {
  dispatch({
    type: SET_INITIAL,
    payload: obj
  });
};

export const setSyncBoundaries = obj => dispatch => {
  dispatch({
    type: SET_SYNC_BOUNDARIES,
    payload: obj
  });
};

export const setFilterType = obj => dispatch => {
  dispatch({
    type: SET_FILTER_TYPE,
    payload: obj
  });
  dispatch({
    type: URL_SEARCH_PARAMS,
    payload: {
      filterType: obj
    }
  });
};
export const setShowTotal = showFilter => dispatch => {
  dispatch({
    type: SET_SHOW_TOTAL,
    payload: showFilter
  });
};
export const setTrendsFilterType = obj => dispatch => {
  dispatch({
    type: SET_TRENDS_FILTER_TYPE,
    payload: obj
  });
};

export const setStatisticShow = payload => dispatch => {
  dispatch({
    type: SET_STATISTIC,
    payload: payload
  });
};

export const setComparisonV2 = params => dispatch => {
  dispatch({
    type: SET_COMPARISON_PARAMS,
    payload: params
  });
};

export const setHomeBrand = params => dispatch => {
  dispatch({
    type: SET_HOMEBRAND_PARAMS,
    payload: params
  });
};
export const setHomeAgeBrand = params => dispatch => {
  dispatch({
    type: SET_HOMEAGEBRAND_PARAMS,
    payload: params
  });
};

export const prevDateOnChange = dates => dispatch => {
  const { prevsdate, prevedate, prevstime, prevetime } = dates;
  dispatch({
    type: SET_PREVSTART_DATE,
    payload: prevsdate
  });
  dispatch({
    type: SET_PREVEND_DATE,
    payload: prevedate
  });
  dispatch({
    type: SET_PREVSTART_TIME,
    payload: prevstime
  });
  dispatch({
    type: SET_PREVEND_TIME,
    payload: prevetime
  });
};
export const setSuggestionDates = dates => dispatch => {
  dispatch({
    type: SET_SUGGESTION_DATES,
    payload: dates
  });
};
export const setPersonnelID = ID => dispatch => {
  dispatch({
    type: SELECT_PERSONNELID_CHANGE,
    payload: ID
  });
};
export const setComparisonLayers = Layer => dispatch => {
  dispatch({
    type: SET_COMPARISON_LAYES,
    payload: Layer
  });
};
export const setTZOffset = tZone => dispatch => {
  dispatch(setParam('TimeZoneOffset', tZone));
};
export const checOutComparisonStoreOnChange =
  (newStore, uniqueName) => dispatch => {
    dispatch({
      type: SELECTED_CHECKOUT_COMPARISON_STORE_CHANGE,
      payload: newStore,
      uniqueName: uniqueName
    });
  };
export const ageGenderComparisonStoreOnChange =
  (newStore, uniqueName) => dispatch => {
    dispatch({
      type: SELECTED_AGEGENDER_COMPARISON_STORE_CHANGE,
      payload: newStore,
      uniqueName: uniqueName
    });
  };
export const conversionRateThresholdsAdd = t => dispatch => {
  dispatch({
    type: CONVERSION_RATE_THRESOLDS,
    payload: t
  });
};
export const conversionRateThresholdsRemove = t => dispatch => {
  dispatch({
    type: CONVERSION_RATE_THRESOLDS_REMOVE,
    payload: t
  });
};
export const setCategoryFilterType = t => dispatch => {
  dispatch({
    type: SET_CATEGORY_FILTER_TYPE,
    payload: t
  });
};
export const setSelectedDepartment = val => dispatch => {
  dispatch({
    type: SET_SELECTED_DEPARTMENTID,
    payload: val
  });
};

export const setSelectedPersonnel = personnel => dispatch => {
  dispatch({
    type: SET_SELECTED_PERSONNEL,
    payload: personnel
  });
};

export const setIsPersonnelSelected = boolean => dispatch => {
  dispatch({
    type: SET_IS_PERSONNEL_SELECTED,
    payload: boolean
  });
};

export const waitingTimesThresholdsAdd = t => dispatch => {
  dispatch({
    type: WAITING_TIMES_THERESOLDS,
    payload: t
  });
};
export const waitingTimesThresholdsRemove = t => dispatch => {
  dispatch({
    type: WAITING_TIMES_THERESOLDS_REMOVE,
    payload: t
  });
};

export const setMetricType = metricType => dispatch => {
  dispatch({
    type: SET_METRIC_TYPE,
    payload: metricType
  });
};

export const setSelectedStoreIds = storeIds => dispatch => {
  dispatch({
    type: SET_SELECTED_STORE_IDS,
    payload: storeIds
  });
};

export const getDefaultHours = defaultTimes => (dispatch, getState) => {
  const store = getState().store.selectedStores.globalStoreParam;
  if (store) {
    const { OpenTime, CloseTime } = defaultTimes ? defaultTimes : store;
    dispatch(setParam('startTime', OpenTime));
    dispatch(setParam('endTime', CloseTime));

    const { stime, etime } = calcTime(OpenTime, CloseTime);

    dispatch(setParam('stime', stime));
    dispatch(setParam('etime', etime));
  }
};

export const setCorrelationThreshold = threshold => dispatch => {
  dispatch({
    type: SET_CORRELATION_THRESHOLD,
    payload: threshold
  });
};

export const toggleShowNavbarOnSmallScreen = () => dispatch => {
  dispatch({
    type: TOGGLE_SHOW_NAVBAR_ON_SMALL_SCREEN
  });
};

export const setSketchId = id => dispatch => {
  dispatch({
    type: SET_SKETCH_ID,
    payload: id
  });
};
