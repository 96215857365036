import { axiosBuilder } from '@udentify/uxios';

export default function setAuth(authMethod) {
  if (authMethod.methodObj) {
    return Promise.resolve(authMethod.addAuth());
  } else {
    return Promise.reject(authMethod.removeAuth());
  }
}

class AuthChecker {
  constructor(methodObj, axiosInstance) {
    this.methodObj = methodObj;
    this.axiosInstance = axiosInstance;
  }
  addAuth() {
    return true;
  }
  removeAuth() {
    return false;
  }
  getStatus() {
    return false;
  }
}

export class AuthToken extends AuthChecker {
  constructor(methodObj) {
    super(methodObj);
    this.methodObj = methodObj;
    this.axiosInstance = axiosBuilder.getInstance();
  }
  addAuth() {
    this.axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${this.methodObj}`;
    return true;
  }
  removeAuth() {
    delete this.axiosInstance.defaults.headers.common['Authorization'];
    return false;
  }
  getStatus() {
    return (
      this.axiosInstance.defaults.headers.common['Authorization'] !== undefined
    );
  }
}

export class ApiKeyToken extends AuthChecker {
  constructor(methodObj) {
    super(methodObj);
    this.methodObj = methodObj;
    this.axiosInstance = axiosBuilder.getInstance();
  }
  addAuth() {
    this.axiosInstance.defaults.headers.common['Public-Key'] =
      this.methodObj.PublicKey;
    this.axiosInstance.defaults.headers.common['Api-Key'] =
      this.methodObj.ApiKey;
    return true;
  }
  removeAuth() {
    delete this.axiosInstance.defaults.headers.common['Public-Key'];
    delete this.axiosInstance.defaults.headers.common['Api-Key'];
  }
  getStatus() {
    return (
      this.axiosInstance.defaults.headers.common['Api-Key'] !== undefined ||
      this.axiosInstance.defaults.headers.common['Public-Key']
    );
  }
}
